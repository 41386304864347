const MASTER = 't1';
const ZEUS = 't2-1';
const GODDESS = 't2-2';

export function getBackgroundColorImage(current_title, attribute, attribute_list) {
  if (current_title === ZEUS) {
    return '/img/prof_background/black_haikei.jpg';
  }
  if (current_title === GODDESS) {
    return '/img/prof_background/white_haikei.jpg';
  }
  if (current_title === MASTER) {
    switch(attribute) {
      case attribute_list.FIRE:
        return '/img/prof_background/aka_haikei.jpg';
      case attribute_list.ICE:
        return '/img/prof_background/ao_haikei.jpg';
      case attribute_list.WIND:
        return '/img/prof_background/pink_haikei.jpg';
      case attribute_list.EARTH:
        return '/img/prof_background/cha_haikei.jpg';
      case attribute_list.NONE:
      default:
      return '/img/prof_background/white_simple_haikei.jpg';
    }
  }
  switch(attribute) {
    case attribute_list.FIRE:
      return '/img/prof_background/aka_simple_haikei.jpg';
    case attribute_list.ICE:
      return '/img/prof_background/ao_simple_haikei.jpg';
    case attribute_list.WIND:
      return '/img/prof_background/pink_simple_haikei.jpg';
    case attribute_list.EARTH:
      return '/img/prof_background/cha_simple_haikei.jpg';
    case attribute_list.NONE:
    default:
      return '/img/prof_background/white_simple_haikei.jpg';
  }
}

export function getFrameImage(current_title, attribute, attribute_list, isStreamer) {
  if (current_title === ZEUS) {
    return '/img/prof_frame/kuro_icon.png';
  }
  if (current_title === GODDESS) {
    return '/img/prof_frame/gin_icon.png';
  }
  if (current_title === MASTER || isStreamer) {
    switch(attribute) {
      case attribute_list.FIRE:
        return '/img/prof_frame/aka_metallic_icon.png';
      case attribute_list.ICE:
        return '/img/prof_frame/ao_metallic_icon.png';
      case attribute_list.WIND:
        return '/img/prof_frame/pink_metallic_icon.png';
      case attribute_list.EARTH:
        return '/img/prof_frame/cha_metallic_icon.png';
      case attribute_list.NONE:
      default:
        return '/img/prof_frame/white_icon.png';
    }
  }
  switch(attribute) {
    case attribute_list.FIRE:
      return '/img/prof_frame/aka_mono_icon.png';
    case attribute_list.ICE:
      return '/img/prof_frame/ao_mono_icon.png';
    case attribute_list.WIND:
      return '/img/prof_frame/pink_mono_icon.png';
    case attribute_list.EARTH:
      return '/img/prof_frame/cha_mono_icon.png';
    case attribute_list.NONE:
    default:
      return '/img/prof_frame/white_icon.png';
  }
}