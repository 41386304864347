<template lang="pug">
  .root
    component-header-index

    stripe-checkout(
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    )

    div(:class="ui.Expansion === true ? 'stream-bg' : ''")
    <div class="center">
      div(class="container-flex")
        div(:class="ui.Expansion === true ? '' : 'content-live'")
            <div class="liveStayus">
              <div class="liveStayus-item ">
                <div @click="onAvatarClick($store.state.live.info.profile.id)" style="list-style-type:none; width: 40px;margin-left: 0px;margin-top: 0px;">
                  <a @click="" class="liveStayus-item-icon">
                    <pan-thumb :avatar="liverAvatar" :attribute="$store.state.live.info.profile.attribute" :isStreamer="true"/>
                  </a>
                    </div>
                <div class="room-title-text"><p><b>{{ $store.state.live.info.room_info.name }}</b></p></div>
              </div>
              <div class="liveStayus-item">
                <div><p><img src="/img/clock_icon_gray.svg"> {{ elapsedTime }}</p></div>
                <div><p><img src="/img/profile_icon_gray.svg"> {{ $store.state.live.onlineCount }}</p></div>
                share-link(:text="shareText" :url="shareUrl")
              </div>
            </div>

            <div :class="ui.Expansion === true ? 'stream-container-ex' : 'stream-container'">
                //- 配信完了であればリロードアイコンを出す
                div(v-if="isLiveOver" class="over-screen")
                  i(class="fas fa-sync fa-2x over-refresh")
                //- 配信ビデオ
                VideoRect(ref="videoRect")

                a(@click="ExpansionGiftClose()")
                  div(:class="ui.ExpansionGift === true ? 'expansion-gift-close' : 'd-n'")
                div(:class="ui.ExpansionGift === true ? 'expansion-gift' : 'd-n'")
                  <div v-if="isPanelGift">

                    <ul class="expansion-tab-list">
                        <li class="tab-itom">
                            <input type="radio" id="expansiontab1" class="test-radio" name="expansiontab" checked="checked">
                            <label class="expansion-gift-label" for="expansiontab1">
                              <b style="margin-left: 10px;" v-if="!isLivePaid">属性ギフト</b>
                              <b style="margin-left: 10px;" v-if="isLivePaid">有料配信ギフト</b>
                            </label>
                            <div v-if="!isLivePaid" class="tab-box attribute-gift-sp-beside">
                              div(class="content-sp-zoom")
                                div(class="point-list")
                                  div
                                    <img src="/img/star-icon.png" class="star-icon">{{ $store.state.user.c2 }}
                                  div
                                    <img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.c1 }}
                                <hr style="border-top: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                                <p class="font-16-12 G_icon" style="text-align: right; margin-right: 20px"><img src="/img/gold-icon.png"><a style="color:#2396c2;" @click="onBuy()">購入はこちら>></a></p>
                                <p class="font-16-12">{{ $store.state.user.attribute_str }}</p>
                                div(v-if="$store.state.user.attribute_str === '無属性'" class="none-attribute font-16-12")
                                  div 属性が選択されていません。
                                  div マイプロフィールページで
                                  div 属性を選択してください。
                                  a(class="prof-link")
                                  u(@click="linkProfile") >>マイプロフィールページはこちら

                                div(class="zokusei-content")
                                  //ブースター
                                  <div v-if="$store.state.user.attribute_str !== '無属性'" class="zokusei" @click="onGiftItemComment($store.state.user.gift_attribute_booster[0], 'c1', true)">
                                    div(v-if="$store.state.user.attribute_str === '炎属性'")
                                      <div><span style="color: #fff;"><img src="/img/booster1.png" alt="" class="img-80-60"></span></div>
                                    div(v-if="$store.state.user.attribute_str === '氷属性'")
                                      <div><span style="color: #fff;"><img src="/img/booster2.png" alt="" class="img-80-60"></span></div>
                                    div(v-if="$store.state.user.attribute_str === '風属性'")
                                      <div><span style="color: #fff;"><img src="/img/booster3.png" alt="" class="img-80-60"></span></div>
                                    div(v-if="$store.state.user.attribute_str === '地属性'")
                                      <div><span style="color: #fff;"><img src="/img/booster4.png" alt="" class="img-80-60"></span></div>
                                    <div><span style="color: #fff;" class="font-16-12"><p>ブースター</p></span></div>
                                    div(class="attribute-price")
                                      <div class="font-16-12"><img src="/img/star-icon.png" class="star-icon">{{ $store.state.user.gift_attribute_booster[0].c2 }}</div>
                                      <div class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_booster[0].c1 }}</div>
                                  </div>

                                  //演出
                                  <div v-if="$store.state.user.attribute_str !== '無属性'" class="zokusei">
                                    <div class="flex-c" @click="onGiftItemComment($store.state.user.gift_attribute_animation[0], 'c1', true)">
                                      div(v-if="$store.state.user.attribute_str === '炎属性'")
                                        <div><span style="color: #fff;"><img src="/img/attribute1.png" alt="" class="img-80-60"></span></div>
                                      div(v-if="$store.state.user.attribute_str === '氷属性'")
                                        <div><span style="color: #fff;"><img src="/img/attribute2.png" alt="" class="img-80-60"></span></div>
                                      div(v-if="$store.state.user.attribute_str === '風属性'")
                                        <div><span style="color: #fff;"><img src="/img/attribute3.png" alt="" class="img-80-60"></span></div>
                                      div(v-if="$store.state.user.attribute_str === '地属性'")
                                        <div><span style="color: #fff;"><img src="/img/attribute4.png" alt="" class="img-80-60"></span></div>
                                      <div><span style="color: #fff;" class="font-16-12"><p>演出</p></span></div>
                                      div(class="attribute-price")
                                        <div class="font-16-12"><a style="color:#1561a5;"><img src="/img/star-icon.png" class="star-icon"></a>{{ $store.state.user.gift_attribute_animation[0].c2 }}</div>
                                        <div class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_animation[0].c1 }}</div>
                                    </div>
                                  </div>

                                  //魔獣
                                  <div  v-if="$store.state.user.attribute_str !== '無属性'" class="zokusei">
                                    <div v-if="this.$store.state.user.profile_profileable_titles.includes('t1')" class="flex-c" @click="onGiftItemComment($store.state.user.gift_attribute_boss[0], 'c1')">
                                      div(v-if="$store.state.user.attribute_str === '炎属性'")
                                        <div  rowspan="2"><span style="color: #fff;"><img src="/img/beast1.png" alt="" class="img-80-60" /></span></div>
                                      div(v-if="$store.state.user.attribute_str === '氷属性'")
                                        <div rowspan="2"><span style="color: #fff;"><img src="/img/beast2.png" alt="" class="img-80-60" /></span></div>
                                      div(v-if="$store.state.user.attribute_str === '風属性'")
                                        <div rowspan="2"><span style="color: #fff;"><img src="/img/beast3.png" alt="" class="img-80-60" /></span></div>
                                      div(v-if="$store.state.user.attribute_str === '地属性'")
                                        <div  rowspan="2"><span style="color: #fff;"><img src="/img/beast4.png" alt="" class="img-80-60" /></span></div>
                                      <div  rowspan="2"><span style="color: #fff;" class="font-16-12"><p>魔獣</p></span></div>
                                      div(class="attribute-price")
                                        <div  rowspan="2"class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_boss[0].c1 }}</div>
                                    </div>
                                    <div v-else class="flex-c">
                                      div(v-if="$store.state.user.attribute_str === '炎属性'")
                                        <div  rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-01-c1.png" alt="" class="img-80-60" /></span></div>
                                      div(v-if="$store.state.user.attribute_str === '氷属性'")
                                        <div  rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-02-c1.png" alt="" class="img-80-60" /></span></div>
                                      div(v-if="$store.state.user.attribute_str === '風属性'")
                                        <div  rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-03-c1.png" alt="" class="img-80-60" /></span></div>
                                      div(v-if="$store.state.user.attribute_str === '地属性'")
                                        <div  rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-04-c1.png" alt="" class="img-80-60" /></span></div>
                                      <div rowspan="2"><span style="color: #fff;" class="font-16-12"><p>魔獣</p></span></div>
                                      div(class="attribute-price")
                                        <div  rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_boss[0].c1 }}</div>
                                    </div>
                                  </div>

                                <hr style="border-top: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                                <p class="font-16-12">全属性対応</p>
                                div(class="zokusei-content")
                                  <div class="zokusei">
                                    <div v-if="$store.state.user.profile_profileable_titles.includes('t2-1') || $store.state.user.profile_profileable_titles.includes('t2-2')" class="flex-c" @click="onGiftItemComment($store.state.user.gift_attribute_all_booster[0], 'c1')">
                                      <div rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/gift-19-c5.png" alt="" class="img-80-60" /></span></div>
                                      <div rowspan="2"><span style="color: #fff;"><p class="font-16-12">ブースター</p></span></div>
                                      div(class="attribute-price")
                                        <div class="font-16-12" rowspan="2"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_booster[0].c1 }}</div>
                                    </div>
                                    <div v-else class="flex-c">
                                      <div rowspan="2"><span style="color: #666464;"><img src="../../../../assets/gift-icon/inactive/gift-19-c5.png" alt="" class="img-80-60" @click="dispNeedGod()" /></span></div>
                                      <div rowspan="2"><span style="color: #666464;"><p class="font-16-12">ブースター</p></span></div>
                                      div(class="attribute-price")
                                        <div rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_booster[0].c1 }}</div>
                                    </div>
                                  </div>
                                  <div class="zokusei">
                                    <div v-if="isSendableZeus()" class="flex-c" @click="onGiftItemComment($store.state.user.gift_attribute_all_boss_boy[0], 'c1')">
                                      <div rowspan="2"><span style="color: #666464;"><img src="/img/got1.png" alt="" class="img-80-60" /></span></div>
                                      <div rowspan="2"><span style="color: #fff;" class="font-16-12"><p class="font-16-12">ゼウス</p></span></div>
                                      div(class="attribute-price")
                                        <td rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_boy[0].c1 }}</td>
                                    </div>
                                    <div v-else class="flex-c">
                                      <div rowspan="2"><span style="color: #666464;"><img src="../../../../assets/gift-icon/inactive/gift7-05-c1.png" alt="" class="img-80-60" @click="dispNeedZeus()" /></span></div>
                                      <div rowspan="2"><span style="color: #666464;"><p class="font-16-12">ゼウス</p></span></div>
                                      div(class="attribute-price")
                                        <div rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_boy[0].c1 }}</div>
                                    </div>
                                  </div>
                                  <div class="zokusei">
                                    <div v-if="isSendableGoddess()" class="flex-c" @click="onGiftItemComment($store.state.user.gift_attribute_all_boss_girl[0], 'c1')">
                                      <div rowspan="2"><span style="color: #666464;"><img src="/img/got2.png" alt="" class="img-80-60" /></span></div>
                                      <div rowspan="2"><span style="color: #fff;"><p class="font-16-12">女神</p></span></div>
                                      div(class="attribute-price")
                                        <div rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_girl[0].c1 }}</div>
                                    </div>
                                    <div v-else class="flex-c">
                                      <div rowspan="2"><span style="color: #666464;"><img src="../../../../assets/gift-icon/inactive/gift7-06-c1.png" alt="" class="img-80-60" @click="dispNeedGoddess()" /></span></div>
                                      <div rowspan="2"><span style="color: #666464;"><p class="font-16-12">女神</p></span></div>
                                      div(class="attribute-price")
                                        <div rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_girl[0].c1 }}</div>
                                    </div>
                                  </div>

                                div(v-if="ui.isGiftItemComment" @click="onClose()" class="select-gift-popup-bg")
                                div(v-if="ui.isGiftItemComment" class="select-gift-popup" v-bind:style="{ top: setGiftPopUpTop('.attribute-gift-sp-beside')}")
                                  button(@click="onClose()" class="close-button") X
                                  div(v-if="!selectedGift.choiceFlag" class="select-gift-popup-detail m-b")
                                    img(:src="selectedGift.giftData.icon" class="img-80-60")
                                    span(v-if="selectedGift.giftPayType == 'c1'")
                                      <img src="/img/gold-icon.png" class="gold-icon">{{selectedGift.giftData.c1}}
                                    span(v-if="selectedGift.giftPayType == 'c2'")
                                      <img src="/img/star-icon.png" class="star-icon">{{selectedGift.giftData.c2}}
                                  div(v-if="selectedGift.choiceFlag" class="select-gift-popup-detail m-b")
                                    img(:src="selectedGift.giftData.icon" class="img-80-60")
                                    div(class="select-gift-popup-detail-select")
                                      <el-radio v-model="selectedGift.giftPayType" label="c1"><img src="/img/gold-icon.png" class="gold-icon">{{selectedGift.giftData.c1}}</el-radio>
                                      <el-radio v-model="selectedGift.giftPayType" label="c2"><img src="/img/star-icon.png" class="star-icon">{{selectedGift.giftData.c2}}</el-radio>
                                  div(v-if="$store.state.user.c1 < selectedGift.giftData.c1" class="select-gift-popup-notenough m-b") ※所持Gが足りていません
                                    div(class="select-gift-popup-notenough t-c")
                                      a(@click="onBuy()") >>G購入はこちら
                                  div(class="select-gift-popup-content")
                                    div(v-if="!(selectedGift.giftData.c1 < 1000 || selectedGift.giftData.c2 < 1000 || selectedGift.giftData.c3 < 1000)")
                                      input(v-model="selectedGift.giftComment" type="text" placeholder="コメントをしよう！" class="input-g-border")
                                    select(v-model="selectedGift.giftAmount" class="input-g-border")
                                      option(:value="amount.value" v-for="amount in giftAmountList") {{ amount.name }}
                                    div(class="t-c")
                                      button(@click="onGiftSend(selectedGift.giftData, selectedGift.giftPayType, selectedGift.giftAmount, selectedGift.giftComment)" v-preventReClick="3000" class="pink-button") 送信
                            </div>
                            //- 有料ギフトパネル
                            <div v-if="isLivePaid" class="tab-box">
                                div(class="pos-r")
                                  div(class="point-list")
                                    <img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.c1 }}
                                  <hr style="border: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                                  <p class="font-16-12 G_icon" style="margin-top:0px; margin-right:20px;"><img src="/img/gold-icon.png"><a style="color:#2396c2;" @click="onBuy()">購入はこちら>></a></p>
                                  div(class="nomgiftlist-content")
                                    <div class="nomgiftlist">

                                      <div v-for="item in paidGiftListExceptPenlight">
                                        <div v-if="item.c1 < 1000 || item.c2 || item.c3" @click="onGiftItemComment(item, item.payKeys[0])">
                                          <img :src="item.icon" alt="" width="70" height="70" />
                                          <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                          <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                          <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                        </div>
                                        <div v-else @click="onGiftItemComment(item, item.payKeys[0])">
                                          <img :src="item.icon" alt="" width="70" height="70" />
                                          <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                          <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                          <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                        </div>
                                      </div>

                                    </div>

                                  div(v-if="ui.isGiftItemComment" @click="onClose()" class="select-gift-popup-bg")
                                  div(v-if="ui.isGiftItemComment" class="select-gift-popup")
                                    button(@click="onClose()" class="close-button") X
                                    div(class="select-gift-popup-detail m-b")
                                      img(:src="selectedGift.giftData.icon" class="img-80-60")
                                      span(v-if="selectedGift.giftPayType == 'c1'")
                                        <img src="/img/gold-icon.png" class="gold-icon"> {{ selectedGift.giftData.c1 }}
                                      span(v-if="selectedGift.giftPayType == 'c2'")
                                        <img src="/img/star-icon.png" class="star-icon"> {{ selectedGift.giftData.c2 }}
                                    div(v-if="boosterIdList.indexOf(selectedGift.giftData.id) !== -1" class="booster-alert")
                                      div ※このアイテムは同属性の演出・魔獣をポイントアップしますが、ブースター自体にポイントはございません
                                    div(v-if="$store.state.user.c1 < selectedGift.giftData.c1" class="select-gift-popup-notenough m-b") ※所持Gが足りていません
                                      div
                                        a(@click="onBuy()") >>G購入はこちら
                                    div(class="select-gift-popup-content")
                                      div(v-if="!(selectedGift.giftData.c1 < 1000 || selectedGift.giftData.c2 < 1000 || selectedGift.giftData.c3 < 1000)")
                                        input(v-model="selectedGift.giftComment" type="text" placeholder="コメントをしよう！" class="input-g-border")
                                      //- ブースターだけ数が選べ無い
                                      div(v-if="boosterIdList.indexOf(selectedGift.giftData.id) === -1")
                                        select(v-model="selectedGift.giftAmount" class="input-g-border")
                                          option(:value="amount.value" v-for="amount in giftAmountList") {{ amount.name }}
                                      div(class="t-c")
                                        button(@click="onGiftSend(selectedGift.giftData, selectedGift.giftPayType, selectedGift.giftAmount, selectedGift.giftComment)" v-preventReClick="3000" class="pink-button") 送信
                            </div>
                        </li>
                        <li class="tab-itom">
                            <input type="radio" id="expansiontab2" class="test-radio" name="expansiontab">
                            <label class="expansion-gift-label" for="expansiontab2"><b style="margin-left: 10px;">通常ギフト</b></label>
                            <div class="tab-box">
                                div(class="pos-r content-sp-zoom")
                                  div(class="point-list")
                                    div
                                      <img src="/img/heart-icon.png" class="heart-icon">{{ $store.state.user.c3 }}
                                    div
                                      <img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.c1 }}
                                  <hr style="border-top: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                                  <p class="font-16-12 G_icon" style="margin-top:0px; margin-right:20px;"><img src="/img/gold-icon.png"><a style="color:#2396c2;" @click="onBuy()">購入はこちら>></a></p>
                                  div(class="nomgiftlist-content")
                                    <div class="nomgiftlist">

                                      <div v-for="item in (isLiveNormal ? $store.state.user.gift_normal : (isLiveEvent ? $store.state.user.gift_event : $store.state.user.gift_pay))">
                                        <div v-if="item.c1 < 1000 || item.c2 || item.c3" @click="onGiftItemComment(item, item.payKeys[0])">
                                          <img :src="item.icon" alt="" width="56" height="56" />
                                          <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                          <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                          <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                        </div>
                                        <div v-else @click="onGiftItemComment(item, item.payKeys[0])">
                                          <img :src="item.icon" alt="" width="56" height="56" />
                                          <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                          <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                          <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                        </div>
                                      </div>

                                    </div>

                                  div(v-if="ui.isGiftItemComment" @click="onClose()" class="select-gift-popup-bg")
                                  div(v-if="ui.isGiftItemComment" class="select-gift-popup")
                                    button(@click="onClose()" class="close-button") X
                                    div(class="select-gift-popup-detail m-b")
                                      img(:src="selectedGift.giftData.icon" class="img-80-60")
                                      span(v-if="selectedGift.giftPayType == 'c1'")
                                        <img src="/img/gold-icon.png" class="gold-icon"> {{ selectedGift.giftData.c1 }}
                                      span(v-if="selectedGift.giftPayType == 'c2'")
                                        <img src="/img/star-icon.png" class="star-icon"> {{ selectedGift.giftData.c2 }}
                                    div(v-if="$store.state.user.c1 < selectedGift.giftData.c1" class="select-gift-popup-notenough m-b") ※所持Gが足りていません
                                      div
                                        a(@click="onBuy()") >>G購入はこちら
                                    div(class="select-gift-popup-content")
                                      div(v-if="!(selectedGift.giftData.c1 < 1000 || selectedGift.giftData.c2 < 1000 || selectedGift.giftData.c3 < 1000)")
                                        input(v-model="selectedGift.giftComment" type="text" placeholder="コメントをしよう！" class="input-g-border")
                                      select(v-model="selectedGift.giftAmount" class="input-g-border")
                                        option(:value="amount.value" v-for="amount in giftAmountList") {{ amount.name }}
                                      div(class="t-c")
                                        button(@click="onGiftSend(selectedGift.giftData, selectedGift.giftPayType, selectedGift.giftAmount, selectedGift.giftComment)" v-preventReClick="3000" class="pink-button") 送信
                            </div>
                        </li>
                    </ul>
                  </div>

                a(@click="ExpansionCoinClose()")
                  div(:class="ui.ExpansionCoin === true ? 'expansion-gift-close' : 'd-n'")
                div(:class="ui.ExpansionCoin === true ? 'expansion-gift' : 'd-n'")
                  <div v-if="isPanelBuy">
                    <ul class="expansion-coin-tab-list clearfloat">
                      <li class="tab-itom">
                        <input type="radio" id="expansion-coin-list" class="test-radio" name="expansion-tab" checked="checked">
                        <label class="expansion-gift-label" for="expansion-coin-list" style="text-align:center;">
                          <b style="margin-left: 10px;">コイン</b>
                        </label>
                        <div class="clubfun-box tab-box">
                          <p class="font-16-12"><a @click="onClubfunDetail($store.state.live.info.liveable_id)">特典の詳細はコチラ</a></p>
                          <div class="attention">
                            <p class="p1"><span class="s1">※</span><span class="s2">必ず詳細をお読みになってか</span></p>
                            <p class="p1"><span class="s2">らご購入下さい。</span></p>
                            <p class="p1"><span class="s1">※</span><span class="s2">コイン</span><span class="s1">1</span><span class="s2">種類につき</span><span class="s1">10</span><span class="s2">枚</span></p>
                            <p class="p1"><span class="s2">まで購入可能です。</span></p>
                          </div>

                          <div class="coinCount-content-ex">
                            <div v-for="(item, index) in buyItemList.filter(v => v.c1 > 0)">
                              div(v-if="item.name" class="coinCount-text-name") {{ item.name }}
                              <div class="coinCount">
                                <div class="coinCount-img">
                                  <img :src="item.icon" alt="">
                                </div>
                                <div class="coinCount-right-ex">
                                  <div class="coinCount-detail">
                                    <div><span data="" class="badge badge-gold badge-pill">G</span>{{item.c1}}</div>
                                    <div><span class="glyphicon glyphicon-yen" aria-hidden="true"></span>{{item.c2}}</div>
                                    //- <div v-if="item.amountList.length <= 0" class="coin-over-text">※上限購入済み</div>
                                  </div>
                                  <div class="coinCount-select btn-group">
                                    <select v-model="item.amount" :disabled="item.amountList.length <= 0">
                                      <option value="0">選択</option>
                                      <option v-for="itemInner in item.amountList" :value="itemInner">{{itemInner}}</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="coinBuy">
                            <a @click="onClubfunBuy()">
                              <img src="/img/kounyu.png" style='cursor: pointer' alt="">
                            </a>
                          </div>

                        </div>
                      </li>
                    </ul>
                  </div>


                <div v-if="isLiveNormal || isLiveEvent || isLivePaid">
                  <div class="top-right">
                    <img src="/img/P_icon.svg" class="P_icon">
                    <b> {{ livePoint ? livePoint.toLocaleString() : 0 }} </b>
                  </div>
                </div>
                <div v-if="isLiveNormal || isLiveEvent">
                  <div v-if="!boosterTitleShow && titleMessage" class="topleft"><p style="text-align: center;"><b v-html="titleMessage"></b></p></div>
                  <div v-if="boosterTitleShow" class="topleft">
                    <vue-seamless-scroll :data="scrollboosterMessage" :class-option="optionLeft" class="seamless-warp2">
                      <ul class="item">
                          <li v-for="item in scrollboosterMessage">
                            <span v-if="isBoosterAll"> ★★★全属性{{item.title}} </span>
                            <span v-if="isBoosterFire"> ★★★炎属性{{item.title}} </span>
                            <span v-if="isBoosterIce"> ★★★氷属性{{item.title}} </span>
                            <span v-if="isBoosterWind"> ★★★風属性{{item.title}} </span>
                            <span v-if="isBoosterEarth"> ★★★地属性{{item.title}} </span>
                          </li>
                      </ul>
                    </vue-seamless-scroll>
                  </div>
                  //- <div class="topleft"><p style="text-align: center;"><b>{{ boosterMessage }}</b></p></div>
                </div>
                <div v-if="isLivePaid">
                  <div v-if="titleMessage" class="topleft"><p style="text-align: center;"><b v-html="titleMessage"></b></p></div>
                </div>
                <div v-if="isLiveClubfun">
                  <div v-if="!clubfunTitleShow && titleMessage" class="topleft"><p style="text-align: center;"><b v-html="titleMessage"></b></p></div>
                  <div v-if="clubfunTitleShow" class="topleft">
                    <vue-seamless-scroll :data="scrollClubfunMessage" :class-option="optionLeft" class="seamless-warp2">
                      <ul class="item">
                        <li v-for="item in scrollClubfunMessage">
                          <span> ★★★ {{item.title}} %達成★★★ </span>
                        </li>
                      </ul>
                    </vue-seamless-scroll>
                  </div>
                </div>
                <div v-if="videoGiftShow">
                  <div class="topleftgift">
                    <div class="giftsent">
                      <div class="giftsent-icon" style="margin-right: 4px;">
                        <img :src="lastGiftData.giftIcon" />
                        <span>×</span>
                        <span style="margin-right: 0px;">{{ lastGiftData.RAW.amount }}</span>
                      </div>
                      <div class="giftsent-right">
                        <div>
                          <span class="sender-nickname">{{lastGiftData.NICKNAME}} </span>
                          <span v-if="isLiveNormal || isLiveEvent" class="gift-point">
                            <span data="">
                              <img src="/img/P_pink_icon.svg" class="point_icon">
                            </span>{{lastGiftData.RAW.coupon_amount.toLocaleString()}}
                          </span>
                        </div>
                        <div v-if="lastGiftData.GIFT_ID">
                          <span>{{lastGiftData.GIFT_ID}}を送りました！</span>
                        </div>
                        <div v-else>
                          <span>コインを送りました！</span>
                        </div>
                        <div v-if="isLiveNormal || isLiveEvent">
                          <span><p class="giftsent-comment">{{lastGiftData.description}}</p></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isLivePaid">
                  <div class="bottomright">
                    <a herf="" @click="ExpansionGift()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/gift-icon.png"></a>
                    <a herf="" @click="Expansion()" :class="ui.Expansion === true ? 'd-n' : 'bottomright-sp'"><img src="/img/zoom_icon.png"></a>
                    <a herf="" @click="ExpansionClose()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/zoomout_icon.png"></a>
                  </div>
                </div>
                <div v-if="isLiveEvent">
                  <div class="bottomright">
                    <div class="bottomtarget"><p style="color:red;">現在<span style="color:red;">{{ $store.state.live.liveEventRank }}</span>位</p></div>
                    <a @click="onEventDetailClick($store.state.live.info.liveable_id)"><img src="/img/event_icon.png"></a>
                    <a herf="" @click="ExpansionGift()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/gift-icon.png"></a>
                    <a herf="" @click="Expansion()" :class="ui.Expansion === true ? 'd-n' : 'bottomright-sp'"><img src="/img/zoom_icon.png"></a>
                    <a herf="" @click="ExpansionClose()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/zoomout_icon.png"></a>
                  </div>
                </div>

                <div v-if="isLiveEvent">

                </div>
                <div v-if="isLiveNormal || isLiveEvent">
                  //--------- Animation Test------------
                  //--.pixi.absolute(ref="rectPixi" :style="{width: '30vw', height: '15vw', zIndex: 20}")
                  div(v-if="backgroundImgShow" v-bind:class="motionBackground")
                  .pixi.absolute(ref="rectPixi")
                  <div v-if="isAllGiftBooster || isBoosterAll" class="bottomleft-frame"><img src="/img/mission-complete/flame_rainbow.gif"></div>
                  <div v-if="isBoosterFire" class="bottomleft-frame"><img src="/img/mission-complete/flame_aka.gif"></div>
                  <div v-if="isBoosterIce" class="bottomleft-frame"><img src="/img/mission-complete/flame_ao.gif"></div>
                  <div v-if="isBoosterWind" class="bottomleft-frame"><img src="/img/mission-complete/flame_pink.gif"></div>
                  <div v-if="isBoosterEarth" class="bottomleft-frame"><img src="/img/mission-complete/flame_cha.gif"></div>
                  //- missionCompleteアイコン表示
                  MissionComplete(v-if="isPlayingMissionComplete" :isBeast="isMissionCompleteForBeast")

                  <div class="bottomleft">
                    <div v-if="isVisibleAttribute" class="lamp">
                        <img :src="attributeFireImage()"/>
                        <img :src="attributeIceImage()"/>
                        <img :src="attributeWindImage()"/>
                        <img :src="attributeEarthImage()"/>
                    </div>
                    <div v-if="isVisibleBeast" class="lamp">
                        <img :src="beastPhoenixImage()"/>
                        <img :src="beastDragonImage()"/>
                        <img :src="beastPegasusImage()"/>
                        <img :src="beastWhiteTigerImage()"/>
                    </div>
                  </div>
                </div>
                <div v-if="isLiveClubfun" class="clubfun-container">
                  .pixi.absolute(ref="rectPixi")
                  div.bottomleft-clubfun
                    div.bottomleft-coins(v-if="!clubfunPercentageThousands")
                      div.coins-icons(v-for="index in clubfunPercentageHundreds")
                        <img src="/img/clubfun_coin_par/10.png">
                      div.coins-icons(v-if="clubfunPercentageTens")
                        <img :src="getClubfunPercentageTensCoinImg()">
                    div.bottomleft-coins(v-if="clubfunPercentageThousands")
                      div.coins-icons(v-for="index in 10")
                        <img src="/img/clubfun_coin_par/10.png">
                    div.bottomright-number
                      <div class="number-icons" v-if="clubfunPercentageThousands > 0"><img :src="getClubfunPercentageThousandsImg()" ></div>
                      <div class="number-icons" v-if="clubfunPercentageHundreds > 0 || clubfunPercentageThousands > 0"><img :src="getClubfunPercentageHundredsImg()" ></div>
                      <div class="number-icons" v-if="clubfunPercentageTens > 0 || clubfunPercentageHundreds > 0"><img :src="getClubfunPercentageTensImg()" ></div>
                      <div class="number-icons"><img :src="getClubfunPercentageOnesImg()" ></div>
                      <div class="number-icons-par"><img src="/img/clubfun_coin_par/g_.png" ></div>
                  <div class="bottomright-clubfun">
                    <a herf="" @click="ExpansionCoin()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/gift-icon.png"></a>
                    <a herf="" @click="onClubfunDetail($store.state.live.info.liveable_id)" class="bottomright-mt"><img src="/img/crfun_icon.png"></a>
                    <a herf="" @click="Expansion()" :class="ui.Expansion === true ? 'd-n' : 'bottomright-sp'"><img src="/img/zoom_icon.png"></a>
                    <a herf="" @click="ExpansionClose()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/zoomout_icon.png"></a>
                  </div>
                </div>
                <div v-if="isLiveNormal">
                  <div class="bottomright">
                    <a herf="" @click="ExpansionGift()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/gift-icon.png"></a>
                    <div v-if="isLiveNormal && $store.state.live.info.target.id">
                      <div class="bottomtarget"><p><b> {{ $store.state.live.info.target.percentString ? $store.state.live.info.target.percentString + '%' : '0' + '%' }} </b></p></div>
                    </div>
                    <a v-if="haveTarget" herf="" @click="setPanelTarget()" :class="ui.Expansion === true ? 'target-icon' : 'target-icon'"><img src="/img/target_icon.png"></a>
                    <a herf="" @click="Expansion()" :class="ui.Expansion === true ? 'd-n' : 'bottomright-sp'"><img src="/img/zoom_icon.png"></a>
                    <a herf="" @click="ExpansionClose()" :class="ui.Expansion === true ? 'bottomright-sp' : 'd-n'"><img src="/img/zoomout_icon.png"></a>
                  </div>
                </div>
            </div>

            Comment(ref="comment" @commentAvatarClick="showUserProfile")

        div(class="content-event-right")
            <div class="clearfloat">
              <div v-if="isPanelGift">
                <ul class="tab-list clearfloat">
                    <li class="tab-itom comment-list-sp">
                      <input type="radio" id="comment-list-sp" class="test-radio" name="tab">
                      <label class="test-label" for="comment-list-sp">
                      <img src="/img/label_pink.png" class="label-bg-img label-bg-on">
                      <img src="/img/label_gray.png" class="label-bg-img label-bg-off">
                      <p style="margin-left: 10px;">コメント</p>
                      </label>
                    </li>
                    //- 左パネル
                    <li class="tab-itom">
                        <input type="radio" id="testTabRadio1" class="test-radio" name="tab" checked="checked">
                        <label class="test-label label-left-sp" for="testTabRadio1">
                          <img src="/img/label_pink.png" class="label-bg-img label-bg-on">
                          <img src="/img/label_gray.png" class="label-bg-img label-bg-off">
                          <p style="margin-left: 10px;" v-if="!isLivePaid">属性ギフト</p>
                          <p style="margin-left: 10px;" v-if="isLivePaid">有料配信ギフト</p>
                        </label>
                        //- 属性ギフトパネル
                        <div v-if="!isLivePaid" class="tab-box attribute-gift">
                            div(class="point-list")
                              div
                                <img src="/img/star-icon.png" class="star-icon">{{ $store.state.user.c2 }}
                              div
                                <img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.c1 }}
                            <hr style="border: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                            <p class="font-16-12 G_icon" style="margin-top:0px; margin-right:20px;"><img src="/img/gold-icon.png"><a style="color:#2396c2;" @click="onBuy()">購入はこちら>></a></p>
                            <p class="font-16-12" style="margin-top:40px;">{{ $store.state.user.attribute_str }}</p>
                            div(v-if="$store.state.user.attribute_str === '無属性'" class="none-attribute font-16-12")
                              p 属性が選択されていません。
                              p マイプロフィールページで
                              p 属性を選択してください。
                              a(class="prof-link")
                                u(@click="linkProfile") >>マイプロフィールページはこちら

                            //ブースター
                            <div v-if="$store.state.user.attribute_str !== '無属性'" class="zokusei">
                              <table style="margin-bottom:20px;" border="0">
                                <tbody>
                                  <tr class="attribute-item" @click="onGiftItemComment($store.state.user.gift_attribute_booster[0], 'c2', true)">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #fff; font-size: 18px;"><p class="font-16-12">ブースター</p></span></td>
                                    div(v-if="$store.state.user.attribute_str === '炎属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/booster1.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '氷属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/booster2.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '風属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/booster3.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '地属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/booster4.png" alt="" class="img-80-60" /></span></td>
                                    div(class="attribute-price")
                                      <td class="font-16-12"><img src="/img/star-icon.png" class="star-icon">{{ $store.state.user.gift_attribute_booster[0].c2 }}</td>
                                      <td class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_booster[0].c1 }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            //演出
                            <div v-if="$store.state.user.attribute_str !== '無属性'" class="zokusei">
                              <table border="0">
                                <tbody>
                                  <tr class="attribute-item" @click="onGiftItemComment($store.state.user.gift_attribute_animation[0], 'c2', true)">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #fff;"><p class="font-16-12">演出</p></span></td>
                                    div(v-if="$store.state.user.attribute_str === '炎属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/attribute1.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '氷属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/attribute2.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '風属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/attribute3.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '地属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/attribute4.png" alt="" class="img-80-60" /></span></td>
                                    div(class="attribute-price")
                                      <td class="font-16-12"><img src="/img/star-icon.png" class="star-icon">{{ $store.state.user.gift_attribute_animation[0].c2 }}</td>
                                      <td class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_animation[0].c1 }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            //魔獣
                            <div  v-if="$store.state.user.attribute_str !== '無属性'" class="zokusei">
                              <table border="0">
                                <tbody>
                                  <tr v-if="this.$store.state.user.profile_profileable_titles.includes('t1')" class="attribute-item" @click="onGiftItemComment($store.state.user.gift_attribute_boss[0], 'c1')">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #fff; font-size: 18px;"><p class="font-16-12">魔獣</p></span></td>
                                    div(v-if="$store.state.user.attribute_str === '炎属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/beast1.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '氷属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/beast2.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '風属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/beast3.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '地属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="/img/beast4.png" alt="" class="img-80-60" /></span></td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_boss[0].c1 }}</td>
                                  </tr>
                                  <tr v-else class="attribute-item">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #fff; font-size: 18px;"><p class="font-16-12">魔獣</p></span></td>
                                    div(v-if="$store.state.user.attribute_str === '炎属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-01-c1.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '氷属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-02-c1.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '風属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-03-c1.png" alt="" class="img-80-60" /></span></td>
                                    div(v-if="$store.state.user.attribute_str === '地属性'")
                                      <td style="width: 80px;" rowspan="2"><span style="color: #fff;"><img src="../../../../assets/gift-icon/inactive/gift7-04-c1.png" alt="" class="img-80-60" /></span></td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_boss[0].c1 }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <hr style="border: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                            <p class="font-16-12" style="margin-top:40px;">全属性対応</p>
                            <div class="zokusei">
                              <table border="0">
                                <tbody>
                                  <tr v-if="$store.state.user.profile_profileable_titles.includes('t2-1') || $store.state.user.profile_profileable_titles.includes('t2-2')" class="attribute-item" @click="onGiftItemComment($store.state.user.gift_attribute_all_booster[0], 'c1')">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #fff; font-size: 18px;"><p class="font-16-12">ブースター</p></span></td>
                                    <td style="width: 80px;" rowspan="2"><img src="../../../../assets/gift-icon/gift-19-c5.png" alt="" class="img-80-60" /></span></td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_booster[0].c1 }}</td>
                                  </tr>
                                  <tr v-else class="attribute-item"  @click="dispNeedGod()" >
                                    <td style="width: 100px;" rowspan="2"><span style="color: #666464; font-size: 18px;"><p class="font-16-12">ブースター</p></span></td>
                                    <td style="width: 80px;" rowspan="2">
                                      <span style="color: #666464;"><img src="../../../../assets/gift-icon/inactive/gift-19-c5.png" alt="" class="img-80-60" /></span>
                                    </td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_booster[0].c1 }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="zokusei">
                              <table border="0">
                                <tbody>
                                  <tr v-if="isSendableZeus()" class="attribute-item" @click="onGiftItemComment($store.state.user.gift_attribute_all_boss_boy[0], 'c1')">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #fff; font-size: 18px;"><p class="font-16-12">ゼウス</p></span></td>
                                    <td style="width: 80px;" rowspan="2">
                                      <span style="color: #666464;"><img src="/img/got1.png" alt="" class="img-80-60" /></span>
                                    </td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_boy[0].c1 }}</td>
                                  </tr>
                                  <tr v-else class="attribute-item" @click="dispNeedZeus()">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #666464; font-size: 18px;"><p class="font-16-12">ゼウス</p></span></td>
                                    <td style="width: 80px;" rowspan="2">
                                      <span style="color: #666464;"><img src="../../../../assets/gift-icon/inactive/gift7-05-c1.png" alt="" class="img-80-60" /></span>
                                    </td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_boy[0].c1 }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="zokusei">
                              <table border="0">
                                <tbody>
                                  <tr v-if="isSendableGoddess()" class="attribute-item" @click="onGiftItemComment($store.state.user.gift_attribute_all_boss_girl[0], 'c1')">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #fff; font-size: 18px;"><p class="font-16-12">女神</p></span></td>
                                    <td style="width: 80px;" rowspan="2"><img src="/img/got2.png" alt=""class="img-80-60" /></span></td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_girl[0].c1 }}</td>
                                  </tr>
                                  <tr v-else class="attribute-item" @click="dispNeedGoddess()">
                                    <td style="width: 100px;" rowspan="2"><span style="color: #666464; font-size: 18px;"><p class="font-16-12">女神</p></span></td>
                                    <td style="width: 80px;" rowspan="2"><img src="../../../../assets/gift-icon/inactive/gift7-06-c1.png" alt="" class="img-80-60" /></span></td>
                                    div(class="attribute-price")
                                      <td style="width: 120px;" rowspan="2" class="font-16-12"><img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.gift_attribute_all_boss_girl[0].c1 }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            div(v-if="ui.isGiftItemComment" @click="onClose()" class="select-gift-popup-bg")
                            div(v-if="ui.isGiftItemComment" class="select-gift-popup" v-bind:style="{ top: setGiftPopUpTop('.attribute-gift')}")
                              button(@click="onClose()" class="close-button") X
                              div(v-if="!selectedGift.choiceFlag" class="select-gift-popup-detail m-b")
                                img(:src="selectedGift.giftData.icon" width="80px" height="80px")
                                span(v-if="selectedGift.giftPayType == 'c1'")
                                  <img src="/img/gold-icon.png" class="gold-icon">{{selectedGift.giftData.c1}}
                                span(v-if="selectedGift.giftPayType == 'c2'")
                                  <img src="/img/star-icon.png" class="star-icon">{{selectedGift.giftData.c2}}
                              div(v-if="selectedGift.choiceFlag" class="select-gift-popup-detail m-b")
                                img(:src="selectedGift.giftData.icon" class="img-80-60")
                                div(class="select-gift-popup-detail-select")
                                  <el-radio v-model="selectedGift.giftPayType" label="c1"><img src="/img/gold-icon.png" class="gold-icon">{{selectedGift.giftData.c1}}</el-radio>
                                  <el-radio v-model="selectedGift.giftPayType" label="c2"><img src="/img/star-icon.png" class="star-icon">{{selectedGift.giftData.c2}}</el-radio>
                              div(v-if="boosterIdList.indexOf(selectedGift.giftData.id) !== -1" class="booster-alert")
                                div ※このアイテムは同属性の演出・魔獣をポイントアップしますが、ブースター自体にポイントはございません
                              div(v-if="$store.state.user.c1 < selectedGift.giftData.c1" class="select-gift-popup-notenough m-b") ※所持Gが足りていません
                                div
                                  a(@click="onBuy()") >>G購入はこちら
                              div(class="select-gift-popup-content")
                                div(v-if="!(selectedGift.giftData.c1 < 1000 || selectedGift.giftData.c2 < 1000 || selectedGift.giftData.c3 < 1000)")
                                  input(v-model="selectedGift.giftComment" type="text" placeholder="コメントをしよう！" class="input-g-border")
                                //- ブースターだけ数が選べ無い
                                div(v-if="boosterIdList.indexOf(selectedGift.giftData.id) === -1")
                                  select(v-model="selectedGift.giftAmount" class="input-g-border")
                                    option(:value="amount.value" v-for="amount in giftAmountList") {{ amount.name }}
                                div(class="t-c")
                                  img(@click="onGiftSend(selectedGift.giftData, selectedGift.giftPayType, selectedGift.giftAmount, selectedGift.giftComment)" v-preventReClick="3000" class="pointer" src="/img/soushin.png")
                        </div>
                        //- 有料ギフトパネル
                        <div v-if="isLivePaid" class="tab-box">
                            div(class="pos-r")
                              div(class="point-list")
                                <img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.c1 }}
                              <hr style="border: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                              <p class="font-16-12 G_icon" style="margin-top:0px; margin-right:20px;"><a style="color:#2396c2;" @click="onBuy()">購入はこちら>></a></p>
                              <div class="nomgiftlist">
                                <div @click="onPenlightGiftItem()">
                                  <img :src="require('../../../../assets/gift-icon/gift3-01-c10.png')" alt="" width="70" height="70" />
                                  <span data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon"> 100 </span>
                                </div>
                                <div v-for="item in paidGiftListExceptPenlight">
                                  <div v-if="setGiftItem(item)" @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                    <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                    <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                  <div v-if="item.c1 == 1000 || item.c1 > 1000" @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                    <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                    <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                </div>

                              </div>

                              //- ペンライトポップアップ
                              div(v-if="ui.isPenLight" @click="onClosePenLight()" class="select-gift-popup-bg")
                              div(v-if="ui.isPenLight" class="select-gift-popup-penlight")
                                div.selected-penlight()
                                  img(v-if="selectedGift.giftData" :src="selectedGift.giftData.icon" alt="" width="70" height="70")
                                  div(class="select-gift-popup-detail-penlight")
                                    span
                                      <img src="/img/gold-icon.png" class="gold-icon"> 100
                                    button(@click="onClosePenLight()" class="close-button") X
                                div(v-if="$store.state.user.c1 < 101" class="select-gift-popup-notenough m-b") ※所持Gが足りていません
                                  div
                                    a(@click="onBuy()") >>G購入はこちら
                                //- div.pen-light-box
                                //-   div(v-for="item in penlightGiftDataList")
                                //-     img(@click="selectedPenlight(item)" :src="item.icon" alt="" width="70" height="70")
                                div(class="select-gift-popup-content")
                                  select(v-model="penMovementNumber" class="input-g-border" @change="selectedPenlight()")
                                    option(:value="movement.value" v-for="movement in penMovementList") {{ movement.name }}
                                  select(v-model="penColorNumber" class="input-g-border" @change="selectedPenlight()")
                                    option(:value="color.value" v-for="color in penColorList") {{ color.name }}
                                  select(v-model="selectedGift.giftAmount" class="input-g-border")
                                    option(:value="amount.value" v-for="amount in giftAmountList") {{ amount.name }}
                                  div(class="t-c")
                                    button(@click="onGiftSend(selectedGift.giftData, selectedGift.giftData.payKeys[0], selectedGift.giftAmount)" v-preventReClick="3000" class="pink-button") 送信
                                //- コメントポップアップ
                              div(v-if="ui.isGiftItemComment" @click="onClose()" class="select-gift-popup-bg")
                              div(v-if="ui.isGiftItemComment" class="select-gift-popup-paid")
                                button(@click="onClose()" class="close-button") X
                                div(class="select-gift-popup-detail m-b")
                                  img(:src="selectedGift.giftData.icon" width="80px" height="80px")
                                  span(v-if="selectedGift.giftPayType == 'c1'")
                                    <img src="/img/gold-icon.png" class="gold-icon"> {{ selectedGift.giftData.c1 }}
                                  span(v-if="selectedGift.giftPayType == 'c2'")
                                    <img src="/img/star-icon.png" class="star-icon"> {{ selectedGift.giftData.c2 }}
                                div(v-if="$store.state.user.c1 < selectedGift.giftData.c1" class="select-gift-popup-notenough m-b") ※所持Gが足りていません
                                  div
                                    a(@click="onBuy()") >>G購入はこちら
                                div(class="select-gift-popup-content")
                                  div(v-if="!(selectedGift.giftData.c1 < 1000 || selectedGift.giftData.c2 < 1000 || selectedGift.giftData.c3 < 1000)")
                                    input(v-model="selectedGift.giftComment" type="text" placeholder="コメントをしよう！" class="input-g-border")
                                  select(v-model="selectedGift.giftAmount" class="input-g-border")
                                    option(:value="amount.value" v-for="amount in giftAmountList") {{ amount.name }}
                                  div(class="t-c")
                                    button(@click="onGiftSend(selectedGift.giftData, selectedGift.giftPayType, selectedGift.giftAmount, selectedGift.giftComment)" v-preventReClick="3000" class="pink-button") 送信
                        </div>

                    </li>
                    //- 右パネル
                    <li class="tab-itom">
                        <input type="radio" id="testTabRadio2" class="test-radio" name="tab">
                        <label class="test-label label-left" for="testTabRadio2">
                          <img src="/img/label_pink.png" class="label-bg-img label-bg-on">
                          <img src="/img/label_gray.png" class="label-bg-img label-bg-off">
                          <p style="margin-left: 10px;">通常ギフト</p>
                        </label>
                        <div class="tab-box normal-gift">
                            div(class="pos-r")
                              div(class="point-list")
                                div
                                  <img src="/img/heart-icon.png" class="heart-icon">{{ $store.state.user.c3 }}
                                div
                                  <img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.c1 }}
                              <hr style="border: 1px dashed rgba(118, 118, 123, 1)" width="90%">
                              <p class="font-16-12 G_icon" style="margin-top:0px; margin-right:20px;"><img src="/img/gold-icon.png"><a style="color:#2396c2;" @click="onBuy()">購入はこちら>></a></p>
                              //- 通常配信あるいはイベント配信の通常ギフト
                              <div v-if="isLiveNormal || isLiveEvent" class="nomgiftlist">
                                <div v-for="item in (isLiveNormal ? $store.state.user.gift_normal : $store.state.user.gift_event)" class="gift-list-item">
                                  <div v-if="item.c3" @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                  <div v-else-if="item.c1 < 1000 && !item.c2 && !item.c3" @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                    <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                    <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                  <div v-else @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                    <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                    <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                </div>
                              </div>
                              //- 有料配信の通常ギフト
                              <div v-if="isLivePaid" class="nomgiftlist">
                                <div v-for="item in $store.state.user.gift_pay_normal" class="gift-list-item">
                                  <div v-if="item.c3" @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                  <div v-else-if="item.c1 < 1000 && !item.c2 && !item.c3" @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                    <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                    <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                  <div v-else @click="onGiftItemComment(item, item.payKeys[0])">
                                    <img :src="item.icon" alt="" width="70" height="70" />
                                    <span v-if="item.c1" data="" class="pay-amount"><img src="/img/gold-icon.png" class="gold-icon">{{ item.c1 }}</span>
                                    <span v-if="item.c2" data="" class="pay-amount"><img src="/img/star-icon.png" class="star-icon">{{ item.c2 }}</span>
                                    <span v-if="item.c3" class="pay-amount"><span aria-hidden="true"><img src="/img/heart-icon.png" class="heart-icon">{{ item.c3 }}</span></span>
                                  </div>
                                </div>
                              </div>

                              // 通常ギフト
                              div(v-if="ui.isGiftItemComment" @click="onClose()" class="select-gift-popup-bg")
                              div(v-if="ui.isGiftItemComment" class="select-gift-popup" v-bind:style="{ top: setGiftPopUpTop('.normal-gift')}")
                                button(@click="onClose(); preventScrollGiftWindow();" class="close-button") X
                                div(class="select-gift-popup-detail m-b")
                                  img(:src="selectedGift.giftData.icon" width="80px" height="80px")
                                  span(v-if="selectedGift.giftPayType == 'c3'")
                                    <img src="/img/heart-icon.png" class="heart-icon"> {{ selectedGift.giftData.c3 }}
                                  span(v-if="selectedGift.giftPayType == 'c1'")
                                    <img src="/img/gold-icon.png" class="gold-icon"> {{ selectedGift.giftData.c1 }}
                                  span(v-if="selectedGift.giftPayType == 'c2'")
                                    <img src="/img/star-icon.png" class="star-icon"> {{ selectedGift.giftData.c2 }}
                                div(v-if="$store.state.user.c1 < selectedGift.giftData.c1" class="select-gift-popup-notenough m-b") ※所持Gが足りていません
                                  div
                                    a(@click="onBuy()") >>G購入はこちら
                                div(class="select-gift-popup-content")
                                  div(v-if="!(selectedGift.giftData.c1 < 1000 || selectedGift.giftData.c2 < 1000 || selectedGift.giftData.c3 < 1000)")
                                    input(v-model="selectedGift.giftComment" type="text" placeholder="コメントをしよう！" class="input-g-border")
                                  select(v-model="selectedGift.giftAmount" class="input-g-border")
                                    option(:value="amount.value" v-for="amount in giftAmountList") {{ amount.name }}
                                  div(class="t-c")
                                    img(@click="onGiftSend(selectedGift.giftData, selectedGift.giftPayType, selectedGift.giftAmount, selectedGift.giftComment)" v-preventReClick="3000" class="pointer" src="/img/soushin.png")
                        </div>
                    </li>
                </ul>
              </div>

              <div v-if="isPanelBuy">
                <ul class="tab-list clearfloat">
                  <li class="tab-itom comment-list-sp">
                    <input type="radio" id="comment-list-sp" class="test-radio" name="tab">
                    <label class="test-label" for="comment-list-sp">
                      <img src="/img/label_pink.png" class="label-bg-img label-bg-on">
                      <img src="/img/label_gray.png" class="label-bg-img label-bg-off">
                      <p style="margin-left: 10px;">コメント</p>
                    </label>
                  </li>
                  <li class="tab-itom">
                    <input type="radio" id="coin-list" class="test-radio" name="tab" checked="checked">
                    <label class="test-label" for="coin-list">
                      <img src="/img/label_pink.png" class="label-bg-img label-bg-on">
                      <img src="/img/label_gray.png" class="label-bg-img label-bg-off">
                      <p style="margin-left: 10px;">コイン</p>
                    </label>

                    <div class="clubfun-box tab-box">
                      <div class="clubfunBanner" @click="onClubfunDetail($store.state.live.info.liveable_id)"><img :src="$store.state.system.config.storage_addr_list.public + $store.state.live.liveClubfun.banner_store_path"/></div>
                      <p class="font-16-12"><a @click="onClubfunDetail($store.state.live.info.liveable_id)">特典の詳細はコチラ</a></p>
                      <div class="attention">
                        <div>※必ず詳細をお読みになってからご購入下さい。</div>
                        <div>※コイン1種類につき10枚まで購入可能です。</div>
                      </div>

                      <div class="coinCount-content">
                        <div v-for="(item, index) in buyItemList.filter(v => v.c1 > 0)" >
                          div(v-if="item.name" class="coinCount-text-name") {{ item.name }}
                          <div class="coinCount">
                            <div class="coinCount-img">
                              <img :src="item.icon" alt="">
                            </div>
                            <div class="coinCount-right">
                              <div class="coinCount-detail">
                                <div><span data="" class="badge badge-gold badge-pill">G</span>{{ Number(item.c1).toLocaleString() }}</div>
                                <div><span class="glyphicon glyphicon-yen" aria-hidden="true"></span>{{ Number(item.c2).toLocaleString() }}</div>
                                //- <div v-if="item.amountList.length <= 0" class="coin-over-text">※上限購入済み</div>
                              </div>
                              <div class="coinCount-select btn-group">
                                <select v-model="item.amount"  :disabled="item.amountList.length <= 0">
                                  <option value="0">選択</option>
                                  <option v-for="itemInner in item.amountList" :value="itemInner">{{itemInner}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="coinBuy">
                        <a @click="onClubfunBuy()">
                          <img src="/img/kounyu.png" style='cursor: pointer' alt="">
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div v-if="isPanelPayment" class="payment">
                ClubfunPayment(@selectBack="cancelBuyCoin" @onCashDone="onCashDone" :data="clubfunPaymentData" :liveInfo="$store.state.live.info" :canChange="true" )
              </div>

              //- 購入ポップアップ
              <div v-if="isPanelBuyPay" id="clickbuy">
                <div class="right-content-border">
                  //- <button href="#" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <button @click="onPanelClose()" title="close" class="close-button">Ｘ</button>

                  <div v-if="!ui.panelbuypay.confirm">
                    <div class="roomBanner">
                      <img :src="$store.state.system.config.storage_addr_list.public + $store.state.live.ticket.banner_store_path" />
                    </div>
                    <p>{{ $store.state.live.ticket.title }}</p>
                    <p><span data-v-1940bcb0="" aria-hidden="true" class="glyphicon glyphicon-user" style="color:#00afec"></span>{{ $store.state.live.ticket.profile_account_name }}</p>
                    <p><span data-v-6b0116fa="" aria-hidden="true" class="glyphicon glyphicon-time cc_cursor" style="color:#84b91f"></span> {{ $store.state.live.ticket.start_time | moment("M月DD日HH:mm") }}~{{ $store.state.live.ticket.end_time | moment("M月DD日HH:mm") }}(予定)</P>
                    <p>{{ $store.state.live.ticket.describe }}</p>
                    <div class="liveprice">
                      <p><img src="/img/coin.png">{{ $store.state.live.ticket.price }}</p>
                    </div>
                    <div class="priceattention">
                      //- <p v-if="!ui.panelbuypay.paid">※この配信は有料配信です</p>
                      <p v-if="ui.panelbuypay.paid && !isLiving">※この配信は購入済みです</p>
                      <p v-if="isLiving && !isLiveOver">※現在配信中</p>
                      <p v-if="false">※この配信はアーカイブです。※視聴期限は●年●月●日● :●まで。（購入は視聴期限の24時間前までです）</p>
                    </div>
                    <div class="kounyuyoyaku" v-if="!ui.panelbuypay.paid">
                      <img src="/img/kounyuyoyaku.png" onmouseover="this.src='/img/kounyuyoyaku_g.png'" onmouseout="this.src='/img/kounyuyoyaku.png'" alt="" @click="ui.panelbuypay.confirm = true;">
                    </div>
                    <div class="kounyuyoyaku" v-if="ui.panelbuypay.paid"> 配信開始まで今しばらくお待ちください
                    </div>
                  </div>

                  <div v-if="ui.panelbuypay.confirm">
                    <div>●Gで購入して視聴予約しますか？</div>
                    <div v-if="false">●Gで購入して視聴しますか？</div>
                    <button @click="onTicketBuy()">はい</button>
                    <button @click="ui.panelbuypay.confirm = false;">いいえ</button>
                  </div>

                </div>
              </div>

              <div  v-if="isPanelBuyCoin" >
                div(class="buy-coin-window")
                  <a @click="onPanelClose()" title="close" class="clicktarget-window-close">Ｘ</a>
                  <div v-if="!ui.panelbuycoin.confirm">
                    <p class="buy-coin-title">購入しますか？</p>

                    <div v-for="item in buyItemList.filter(v => v.amount > 0)" class="buy-coin-item">
                      <img :src="item.icon" alt="" width="70" height="70" />
                      <div class="buy-coin-item-detail">
                        <div>購入したコイン数：{{item.amount}}</div>
                        <div>G{{item.c1}}</div>
                        <div>({{item.c2}}円)</div>
                        <div v-if="item.select_box_a" :disabled="item.amountList.length <= 0">
                          <div>
                            <select v-model="item.a_value" class="optionsList">
                              <option value="">項目Aを選択してください</option>
                              <option v-for="itemOption in optionsList(item.options_a)" :value="itemOption">{{itemOption}}</option>
                            </select>
                          </div>
                          <div v-if="item.select_box_b">
                            <div>
                              <select v-model="item.b_value" class="optionsList">
                                <option value="">項目Bを選択してください</option>
                                <option v-for="itemOption in optionsList(item.options_b)" :value="itemOption">{{itemOption}}</option>
                              </select>
                              <div v-if="item.select_box_c">
                                <select v-model="item.c_value" class="optionsList">
                                  <option value="">項目Cを選択してください</option>
                                  <option v-for="itemOption in optionsList(item.options_c)" :value="itemOption">{{itemOption}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    div
                      label(for="buy-coin-check")
                        a(@click="crowdFundPage") クラファン配信利用規約
                        span と
                        a(@click="privacypage") プライバシーポリシー
                        span に同意する<input type="checkbox" id="buy-coin-check" v-model="ui.buyCheckBox">

                    div(class="buy-coin-button")
                      <button @click="ui.buyCheckBox ? onGold() : onAlert()" class="pink-button">Ｇで購入</button>
                      <button @click="ui.buyCheckBox ? onCash() : onAlert()" class="pink-button">即時決済</button>
                  </div>
                  <div v-if="ui.panelbuycoin.confirm">
                      <p>購入が完了しました</p>
                      <div v-for="item in buyItemListSuccess" class="t-c">
                        <li style="list-style: none;"><img src="/img/giftbox.png" alt="" width="70" height="70" /></li>
                        <div>{{item.amount}}</div>
                        <div>G{{item.c1}}（{{item.c2}}円)</div>
                      </div>
                  </div>
              </div>

              //- ターゲットポップアップ
              <div v-if="isPanelAddress" id="addressinput" class="clicktarget-window">
                <div>
                  <div class="addressinput">
                    <a @click="onPanelClose()" title="close" class="clicktarget-window-close">Ｘ</a>
                    <div v-if="$store.state.user.profile_profileable_country === '日本'">
                      <p style="font-size:22px;margin-left: 85px;"><b>特典送付先</b></p>
                      <p style="font-size:16px;margin-left: 71px;">
                        a(@click="linkPersonalInfo") 個人情報取り扱いについて
                      </p>
                    div
                      <div v-if="ui.fundDeliveryAddress == true">
                        <input type="radio" class="fund-delivery-radio" id="fund-delivery-done" value="done" v-model="ui.fundDeliveryRadio">
                        <label class="fund-delivery-label" for="fund-delivery-done">登録済みの住所を使う</label>
                        <br>
                      </div>
                      <input type="radio" class="fund-delivery-radio" id="fund-delivery-new" value="new" v-model="ui.fundDeliveryRadio" @change="clearFundBuy()">
                      <label class="fund-delivery-label" for="fund-delivery-new">新規入力</label>
                      <br>
                      <br>
                      a(@click="linkProfileAddress") 住所の登録はこちらから

                      div(v-if="ui.fundDeliveryRadio === 'new'")
                        <h5><b>苗字<b style="color:red;">＊</b></b></h5>
                        <div><input v-model="clubfunBuy.family_name" class="input" type="text" value=""></div>
                        <h5><b>名前<b style="color:red;">＊</b></b></h5>
                        <div><input v-model="clubfunBuy.given_name" class="input" type="text" value=""></div>
                        <h5><b>電話番号<b style="color:red;">＊</b></b></h5>
                        <div class="phoneNum">
                          <div style="margin-right: 20px;"><input v-model="clubfunBuy.phone_number1" class="input-phone no-spin" type="number" value=""></div><div style="margin-right: 20px;"><input v-model="clubfunBuy.phone_number2" class="input-phone no-spin" type="number" value=""></div><div><input v-model="clubfunBuy.phone_number3" class="input-phone no-spin" type="number" value=""></div>
                        </div>
                        <h5><b>郵便番号<b style="color:red;">＊</b></b></h5>
                        <div class="postNum">
                          <div><input v-model="clubfunBuy.postal_code1" class="input-post no-spin" type="number" value=""></div><div style="margin-left: -80px;"><input v-model="clubfunBuy.postal_code2" class="input-post no-spin" type="number" value=""></div>
                        </div>
                        <h5><b>都道府県<b style="color:red;">＊</b></b></h5>
                        div
                          select(v-model="clubfunBuy.prefecture" class="input")
                            option(v-for="option in ui.province" :value="option" class="input") {{ option }}
                        <h5><b>市区町村<b style="color:red;">＊</b></b></h5>
                        <div><input v-model="clubfunBuy.area" class="input" type="text" value="[選択]"></div>
                        <h5><b>住所<b style="color:red;">＊</b></b></h5>
                        <div><input v-model="clubfunBuy.address" class="input" type="text" value=""></div>
                        <h5><b>番地</b></h5>
                        <div class="bantiNum">
                          <div style="margin-right: 20px;"><input v-model="clubfunBuy.house_number1" class="input-banti no-spin" type="number" value=""></div><div style="margin-right: 20px;"><input v-model="clubfunBuy.house_number2" class="input-banti no-spin" type="number" value=""></div><div><input v-model="clubfunBuy.house_number3" class="input-banti no-spin" type="number" value=""></div>
                        </div>
                        <h5><b>マンション名</b></h5>
                        <div><input v-model="clubfunBuy.mansion_name" class="input" type="text" value=""></div>
                        <h5><b>部屋番号</b></h5>
                        <div class="roomNum">
                          <div><input v-model="clubfunBuy.room_number" class="input-room" type="text" oninput="if(value.length > 4)value = value.slice(0, 4)" value=""></div><div style="margin-left: -124px;"><b>号室</b></div>
                        </div>
                        <h5><b>会社名・部門名</b></h5>
                        <div><input v-model="clubfunBuy.company_name" class="input" type="text" value=""></div>
                        <p>※会社宛の場合は会社名および部門名を入力してください (全角25文字まで)</p>
                        <p></p>
                      </div>
                      div
                        <h5><b>備考</b></h5>
                        <div><input v-model="clubfunBuy.remarks" class="input" type="text" value=""></div>
                        <div v-if="ui.clubfunBuyError" style="color: red;" v-html="ui.clubfunBuyErrorText"></div>
                      <div class="inputconfirm">
                        <a @click="setPanelBuyCoin()"><img src="/img/kakunin.png" onmouseover="this.src='/img/kakunin_g.png'" onmouseout="this.src='/img/kakunin.png'" alt=""></a>
                      </div>

                    <div v-if="$store.state.user.profile_profileable_country !== '日本'">
                      <p>コインは「住んでいる国」が「日本」の方のみ購入可能となっております</p>
                    </div>
                  </div>
                </div>
              </div>
              //- ターゲットポップアップ
              //- <div id="clicktarget" class="clicktarget-window">
              //-   <div style="margin-left: 195px;">
              //-     <a href="#" title="close" class="clicktarget-window-close">Ｘ</a>
              //-     <h1>ターゲット</h1>
              //-     <div>1111111111111</div>
              //-     </div>
              //- </div>
            </div>

            div(v-if="isPanelTarget" class="right-content-border")
              component-target-view-index(@onRankAvatarClick='showUserProfile'  @close="onPanelClose()")
            div(v-if="isPanelProfile")
              component-profile-index(:profile_id="otherProfile_id" @close="onPanelClose()")
    </div>
    component-footer-index
</template>

<style src="./Base.scss" lang="scss" scoped>

</style>

<script>
import Comment from './Comment'
import VideoRect from './VideoRect'
import MissionComplete from './MissionComplete'
import ClubfunPayment from './ClubfunPayment'
import { buyClubfunGiftGold, buyTicket, getLiveAttrInfo, getLiveListEventDetail } from '@/api/live';
import { getFireEffect, getSnowEffect, getWindAirEffect, getWindFlowerEffect, getWindFlower2Effect } from '@/api/particles';
import { updateCouponHeart } from '@/api/coupon';
import { getDeliveryInfo } from '@/api/delivery_information';
import { createDeliveryInfoHistory } from '@/api/delivery_information_history';
import { validStringCount, stringCount } from '@/utils/inputLimit'

import _ from 'lodash'
import moment from 'moment'

import { LIVE_TYPE, PANEL_TYPE, province, AtrributeBoosterIdList, boosterIdList, penLightList, AtrributeList, BeastList, penMovementList, penColorList } from '../helper/def'
import { getLiveDurationStr, getImageUrl, buyCoinInfoValidation } from '@/utils/tools'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import {Emitter} from 'pixi-particles';
import audioPlayer from '@/utils/audioPlayer'

import { Radio } from 'element-ui';

import * as PIXI from 'pixi.js'
import 'pixi-spine';
import PanThumb from '@/components/PanThumb'
import ShareLink from '@/components/ShareLink'
import { initPixi,playSpineAnimation,resizePixi, preparationPixi } from '@/utils/spine'
window.PIXI = PIXI
// PIXI.utils.skipHello()

export default {
  name: "LiveComponentBase",
  components: {
    Comment,
    VideoRect,
    MissionComplete,
    ClubfunPayment,
    StripeCheckout,
    PanThumb,
    ShareLink,
    TemplateVars: {
      functional: true,
      render: (h, {props, scopedSlots}) => h('div', scopedSlots.default(props)),
    },
    'el-radio': Radio,
  },
  filters:{
    getAvatarUrl(url){
      return getImageUrl(url)
    }
  },
  data() {
    return {
      shareText: '',
      shareUrl: '',
      playQ:[],
      livePoint: 0,
      timerSyncLive:0,
      effectDuration:5000,
      effectWaitDuration:3000,
      updateInterval: undefined,
      prevAttrInfoActive: false,
      app:null,
      audio: undefined,
      isPlayingMissionComplete: false,
      isMissionCompleteForBeast: false,
      selectedGift: {
        giftData: [],
        giftComment: '',
        giftAmount: '',
        giftPayType: '',
        choiceFlag: false,
      },
      giftAmountList:[
        {value:'',name:''},
        {value:1,name:1},
        {value:2,name:2},
        {value:3,name:3},
        {value:4,name:4},
        {value:5,name:5},
        {value:6,name:6},
        {value:7,name:7},
        {value:8,name:8},
        {value:9,name:9},
        {value:10,name:10},
      ],
      pointUp: "",
      ui: {
        buyCheckBox: false,
        isGiftItemComment: false,
        isPenLight: false,
        panelType: '',
        panelTypeLast: '',
        panelbuypay: {
          confirm: '',
          paid: '',
        },
        panelbuycoin: {
          confirm: '',
        },
        clubfunBuyError: false,
        clubfunBuyErrorText: '',
        Expansion: false,
        ExpansionGift: false,
        ExpansionCoin: false,
        GiftPopUpScrollTop: 0,
        province: province,
        fundDeliveryRadio: 'new',
        fundDeliveryAddress: false,
      },
      videoGiftShow: false,
      backgroundImgShow: false,
      giftType: '',
      publishableKey: '',
      sessionId: '',

      buyItemList: [],
      buyItemListSuccess: [],

      pixiApp: null,
      particleContainer: null,
      effectFireEmmiter: undefined,
      effectFireOption: undefined,
      effectSnowEmmiter: undefined,
      effectSnowOption: undefined,
      effectWindAirEmmiter: undefined,
      effectWindAirOption: undefined,
      effectWindFlowerEmmiter1: undefined,
      effectWindFlowerEmmiter2: undefined,
      effectWindFlowerOption: undefined,
      effectWindFlower2Option: undefined,

      prevPercentage: 0,

      notTimeStamp: 0,
      //effectFireJsonB64: "ewoJImFscGhhIjogewoJCSJzdGFydCI6IDAuMywKCQkiZW5kIjogMAoJfSwKCSJzY2FsZSI6IHsKCQkic3RhcnQiOiAwLjI1LAoJCSJlbmQiOiAwLjU1LAoJCSJtaW5pbXVtU2NhbGVNdWx0aXBsaWVyIjogMQoJfSwKCSJjb2xvciI6IHsKCQkic3RhcnQiOiAiI2YyOTgxMSIsCgkJImVuZCI6ICIjZmMxMjEyIgoJfSwKCSJzcGVlZCI6IHsKCQkic3RhcnQiOiAyMDAsCgkJImVuZCI6IDIwMCwKCQkibWluaW11bVNwZWVkTXVsdGlwbGllciI6IDAuMgoJfSwKCSJhY2NlbGVyYXRpb24iOiB7CgkJIngiOiAwLAoJCSJ5IjogLTcwCgl9LAoJIm1heFNwZWVkIjogMCwKCSJzdGFydFJvdGF0aW9uIjogewoJCSJtaW4iOiAyMDAsCgkJIm1heCI6IDM0MAoJfSwKCSJub1JvdGF0aW9uIjogZmFsc2UsCgkicm90YXRpb25TcGVlZCI6IHsKCQkibWluIjogMCwKCQkibWF4IjogMAoJfSwKCSJsaWZldGltZSI6IHsKCQkibWluIjogNCwKCQkibWF4IjogNwoJfSwKCSJibGVuZE1vZGUiOiAiYWRkIiwKCSJmcmVxdWVuY3kiOiAwLjAwMSwKCSJlbWl0dGVyTGlmZXRpbWUiOiAtMSwKCSJtYXhQYXJ0aWNsZXMiOiA0MDAwLAoJInBvcyI6IHsKCQkieCI6IDAsCgkJInkiOiA3MDAKCX0sCgkiYWRkQXRCYWNrIjogZmFsc2UsCgkic3Bhd25UeXBlIjogInJlY3QiLAoJInNwYXduUmVjdCI6IHsKCQkieCI6IDAsCgkJInkiOiAwLAoJCSJ3IjogOTAwLAoJCSJoIjogMAoJfQp9",

      targetPercent: '',

      audioFiles: {
        normal: {
          "fire" : require("../../../../assets/voice/fire.mp3"),
          "snow" : require("../../../../assets/voice/snow.mp3"),
          "wind" : require("../../../../assets/voice/wind.mp3"),
          "Ball" : require("../../../../assets/voice/Ball.mp3"),
          "Dolphin" : require("../../../../assets/voice/Dolphin.mp3"),
          "Whale" : require("../../../../assets/voice/Whale.mp3"),
          "Phoenix" : require("../../../../assets/voice/Phoenix.mp3"),
          "WildPig" : require("../../../../assets/voice/WildPig.mp3"),
          "Meerkat" : require("../../../../assets/voice/Meerkat.mp3"),
          "NvShen_1" : require(`../../../../assets/voice/NvShen_1.mp3`),
          "NvShen_2" : require(`../../../../assets/voice/NvShen_2.mp3`),
          "NvShen_3" : require(`../../../../assets/voice/NvShen_3.mp3`),
          "Zeus_1" : require(`../../../../assets/voice/Zeus_1.mp3`),
          "Zeus_2" : require(`../../../../assets/voice/Zeus_2.mp3`),
          "Zeus_3" : require(`../../../../assets/voice/Zeus_3.mp3`),
          "PinkShit" : require("../../../../assets/voice/PinkShit.mp3"),
          "FlyHorse" : require("../../../../assets/voice/FlyHorse.mp3"),
          "IceDragon" : require("../../../../assets/voice/IceDragon.mp3"),
          "earth" : require("../../../../assets/voice/earth.mp3"),
          "Tiger" : require("../../../../assets/voice/Tiger.mp3"),
        },
        funds: {
          "10up" : require("../../../../assets/voice/10up.mp3"),
          "hanabi100" : require("../../../../assets/voice/hanabi100.mp3"),
          "hanabi200_300" : require("../../../../assets/voice/hanabi200_300.mp3"),
        },
      },

      eventInfo: {},
      clubfunBuy: {
          family_name : "",
          given_name : "",
          phone_number1 : "",
          phone_number2 : "",
          phone_number3 : "",
          postal_code1 : "",
          postal_code2 : "",
          prefecture : "",
          area : "",
          address : "",
          house_number1 : "",
          house_number2 : "",
          house_number3 : "",
          mansion_name : "",
          room_number : "",
          company_name : "",
          remarks : "",
      },
      // delivery_infomationを退避
      tmpFundBuy: {
          family_name : "",
          given_name : "",
          phone_number1 : "",
          phone_number2 : "",
          phone_number3 : "",
          postal_code1 : "",
          postal_code2 : "",
          prefecture : "",
          area : "",
          address : "",
          house_number1 : "",
          house_number2 : "",
          house_number3 : "",
          mansion_name : "",
          room_number : "",
          company_name : "",
      },
      elapsedTime: '',
      boosterIdList: boosterIdList,
      penLightList: penLightList,
      penMovementNumber:1,
      penColorNumber:1,
      penlightGiftDataList:[],
      paidGiftListExceptPenlight:[],
      scrollboosterMessage: [{'title' : 'ブースター発生中！★★★'}],
      scrollClubfunMessage: [],
      boosterTitleShow: false,
      clubfunTitleShow: false,
      isClick: true,
      firstFlag: true,
      timerLocalUpdate: 0,
      lastGiftData: '',
      otherProfile_id:'',
      clubfunPaymentData:'',
      isClubfunInit: true,
      penMovementList: penMovementList,
      penColorList: penColorList,
    }
  },
  props: {
    liveType: {
      type: Symbol,
      default: LIVE_TYPE.NORMAL
    }
  },
  mounted() {
    const rectPixi = this.$refs['rectPixi'];
    this.particleContainer = Object.seal(initPixi(window.devicePixelRatio,rectPixi,this.updateFrame,this.onAnimComplete))
    if (this.isLiveNormal || this.isLiveEvent) {
      preparationPixi([
        'Tiger_back', 'Zeus', 'Ball', 'Meerkat', 'NvShen', 'Phoenix','PinkShit',
        'IceDragon', 'Dolphin', 'Whale','WildPig','Tiger', 'FlyHorse',
      ]);
    } else if(this.isLiveClubfun) {
      preparationPixi(['yanhua_a','yanhua_b']);
    }
    if (window.innerWidth < 800) {
      if (window.innerWidth < window.innerHeight) {
        this.particleContainer.scale.x = this.particleContainer.scale.y = 0.5;
      }
    }
    this.$confirm("モーションによる音声再生があります。\n音声の出力を許可しますか？",{
          confirmButtonText: '許可',
          cancelButtonText: '取消',
          center: true
      }).then(() => {
        // https://dsuket.hatenablog.com/entry/2013/05/05/101430
        // 許可を得てから一括ロードする
        this.audio.LoadAll();
      }).catch(() => {
        return
      });
  },
  computed: {
    haveTarget(){
      return this.$store.state.live.info.target.id ? true : false
    },
    liverAvatar(){
      // const url = this.isLivePaid ? this.$store.state.live.ticket.profile_avatar_store_path : this.$store.state.live.info.profile.avatar_store_path
      return this.$store.state.live.info.profile.avatar_store_path
    },
    optionLeft () {
      return {
        direction: 2,
        limitMoveNum: 1,
        step: window.innerWidth > 820? 3 : 2,
        hoverStop: false,
        waitTime: 0,
      }
    },
    isLiveNormal() {
      return this.liveType === LIVE_TYPE.NORMAL
    },
    isLiveEvent() {
      return this.liveType === LIVE_TYPE.EVENT
    },
    isLiveClubfun() {
      return this.liveType === LIVE_TYPE.FUND
    },
    isLivePaid() {
      return this.liveType === LIVE_TYPE.PAID
    },

    isPanelGift() {
      return this.ui.panelType === PANEL_TYPE.GIFT
    },
    isPanelBuy() {
      return this.ui.panelType === PANEL_TYPE.BUY
    },
    isPanelTarget() {
      return this.ui.panelType === PANEL_TYPE.TARGET
    },
    isPanelAddress() {
      return this.ui.panelType === PANEL_TYPE.ADDRESS
    },
    isPanelBuyPay() {
      return this.ui.panelType === PANEL_TYPE.BUY_PAY
    },
    isPanelBuyCoin() {
      return this.ui.panelType === PANEL_TYPE.BUY_COIN
    },
    isPanelProfile() {
      return this.ui.panelType === PANEL_TYPE.PROFILE
    },
    isPanelPayment() {
      return this.ui.panelType === PANEL_TYPE.PAYMENT
    },

    lastGiftExtension() {
      let item;
      if (this.$store.state.live.commentList.length != 0) {
        item = this.$store.state.live.commentList[this.$store.state.live.commentList.length-1];
      }
      if (item && item.payload && item.payload.data === 'gift') {
        let temp = JSON.parse(item.payload.extension);
        if (item.payload.description){
          temp.description = item.payload.description;
        }
        return temp;
      }
      return ''
    },

    titleMessage() {
      // 配信画面のタイトルメッセージ、ブースターと配信者メッセージの中一番先のメッセージ表示
      const streamerMessageItem = _.findLast(this.$store.state.live.commentList, n => {
          const extension = JSON.parse(n.payload.extension);
          return extension.PROFILEABLE_TYPE === 'streamer';
        })
      if (streamerMessageItem) {
        const desc = streamerMessageItem.payload.description
        if(!this.$refs.comment){
          return _.isString(desc) ? desc.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='view_window'>$1</a>") : '';
        }else if(this.$refs.comment?.isMintInited){
          return _.isString(desc) ? desc.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='view_window'>$1</a>") : '';
        }
        // return streamerMessageItem.payload.description;
      }
      return null
    },
    isAllGiftBooster() {
      if (
        this.$store.state.live.liveAttributeInfo.all_gift_booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.all_gift_booster_time).isAfter(moment())
      ) {
        return true;
      }
      return false;
    },
    isBoosterAll() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_ALL
      ){
        return true;
      }
      return false;
    },
    isBoosterFire() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_FIRE
      ){
        return true;
      }
      return false;
    },
    isBoosterIce() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_ICE
      ){
        return true;
      }
      return false;
    },
    isBoosterWind() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_WIND
      ){
        return true;
      }
      return false;
    },
    isBoosterEarth() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_EARTH
      ){
        return true;
      }
      return false;
    },
    // 属性
    isAttributeFire() {
      if(
        this.$store.state.live.liveAttributeInfo.fire_time &&
        moment(this.$store.state.live.liveAttributeInfo.fire_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isAttributeIce() {
      if(
        this.$store.state.live.liveAttributeInfo.ice_time &&
        moment(this.$store.state.live.liveAttributeInfo.ice_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isAttributeWind() {
      if(
        this.$store.state.live.liveAttributeInfo.wind_time &&
        moment(this.$store.state.live.liveAttributeInfo.wind_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isAttributeEarth() {
      if(
        this.$store.state.live.liveAttributeInfo.earth_time &&
        moment(this.$store.state.live.liveAttributeInfo.earth_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isVisibleAttribute(){
      if( this.isAttributeFire || this.isAttributeIce || this.isAttributeWind || this.isAttributeEarth ){
        return true;
      }
      return false;
    },
    // 魔獣
    isBeastPhoenix(){
      if(
        this.$store.state.live.liveAttributeInfo.phoenix_time &&
        moment(this.$store.state.live.liveAttributeInfo.phoenix_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isBeastDragon(){
      if(
        this.$store.state.live.liveAttributeInfo.dragon_time &&
        moment(this.$store.state.live.liveAttributeInfo.dragon_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isBeastPegasus(){
      if(
        this.$store.state.live.liveAttributeInfo.pegasus_time &&
        moment(this.$store.state.live.liveAttributeInfo.pegasus_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isBeastWhiteTiger(){
      if(
        this.$store.state.live.liveAttributeInfo.white_tiger_time &&
        moment(this.$store.state.live.liveAttributeInfo.white_tiger_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isVisibleBeast(){
      if( this.isBeastPhoenix || this.isBeastDragon || this.isBeastPegasus || this.isBeastWhiteTiger ){
        return true;
      }
      return false;
    },
    boosterMessageShow(){
      if(this.isBoosterAll){
        return 1;
      }
      if(this.isBoosterFire) {
        return 2;
      }
      if(this.isBoosterIce) {
        return 3;
      }
      if (this.isBoosterWind) {
        return 4;
      }
      if (this.isBoosterEarth) {
        return 5;
      }
      return false;
    },
    isLiveOver() {
      if (this.$store.getters.enum_list.live_status_list.ENDED === this.$store.state.live.info.status) {
        return true
      }
      return false
    },
    isLiving(){
      // return  new Date(this.$store.state.live.ticket.start_time).getTime() < Date.now() && new Date(this.$store.state.live.ticket.end_time).getTime() > Date.now()
      return this.$store.getters.enum_list.live_status_list.ONGOING == this.$store.state.live.info.status
    },
    isBuyPay(){
      return this.$store.state.live.info.is_buy_pay;
    },
    clubfunPercentageOnes() {
      return this.$store.state.live.current_percentage.ones
    },
    clubfunPercentageTens() {
      return this.$store.state.live.current_percentage.tens
    },
    clubfunPercentageHundreds() {
      return this.$store.state.live.current_percentage.hundreds
    },
    clubfunPercentageThousands() {
      return this.$store.state.live.current_percentage.thousands
    },
    rankingShowMore() {
      return this.$store.state.live.info.target.otherRanking.length > 0 && this.$store.state.live.info.target.ranking.length < 13
    },
    penlightCode() {
      return '3-1-' + this.penMovementNumber + '-' + this.penColorNumber
    },
    motionBackground() {
      switch(this.giftType) {
        case 'NvShen':
          return {
            backgroundGoddess: true,
          }
        case 'Whale':
          return {
            backgroundSkyblue: true,
          }
        default:
          return {
            backgroundNon: true,
          }
      }
    },
  },
  watch: {
    lastGiftExtension(newVal){
      if (newVal) {
        // ライブポイントの更新
        this.livePoint = newVal.RAW.live_point;
        this.lastGiftData = newVal
        if (this.firstFlag) {
          this.firstFlag = false
          return
        }else{
          // クラファン
          if( this.isLiveClubfun ){
            // 10%毎の壁を越えた瞬間
            if( Math.floor(this.prevPercentage/10) != Math.floor(newVal.RAW.current_percentage/10) && !newVal.RAW.is_goal_effect ){
              this.audio.Play("10up");
            }
            // 花火演出 100%毎の壁を越えた瞬間
            if( newVal.RAW.is_goal_effect_a ){
              this.playClubfunTitle()
              // 花火モーション
              this.playHanabi1();
              if( newVal.RAW.is_goal_effect_a ){
                this.audio.Play("hanabi100");
              }
            }
            if( newVal.RAW.is_goal_effect_b ){
              this.playClubfunTitle()
              // 花火モーション
              this.playHanabi2();
              if( newVal.RAW.is_goal_effect_b ){
                this.audio.Play("hanabi200_300");
              }
            }
            this.prevPercentage = newVal.RAW.current_percentage;
          }
          // モーション
          clearTimeout(this.giftTimer);
          let _this = this;
          _this.videoGiftShow = true;

          // ギフト演出
          this.playMotion(newVal);
          this.giftTimer = setTimeout(function () {
            _this.videoGiftShow = false;
          }, 2000)
        }
      }
    },
    boosterMessageShow(newVal) {
      if (newVal) {
        clearTimeout(this.boosterTitleHideTimer);
        clearTimeout(this.boosterTitleShowTimer);
        let _this = this;
        _this.boosterTitleShow = true;
        let showFunc = () => {
            _this.boosterTitleShow = true
            this.boosterTitleHideTimer = setTimeout(()=>{
              _this.boosterTitleShow = false
            }, 12 * 1000)
        }
        const repeatCount = 5
        for (let index = 0; index < repeatCount; index++) {
          if ( index == 0 ){
            showFunc();
            continue;
          }
          this.boosterTitleHideTimer = setTimeout(()=>{
            showFunc()
          }, (30 *index) * 1000)
        }
      }
    },
    isLiveOver(newVal) {
      if(newVal) {
        this.$alert('配信終了しました')
        this.$refs.videoRect.stopVideo();
        clearInterval(this.timerSyncLive)
        clearTimeout(this.clubFunHeartGetTimer);
      }
    },
    //ギフトのポップアップが表示されている間はtab-boxのスクロールを無効
    'ui.isGiftItemComment': function(){
      this.preventScrollGiftWindow();
    },
  },
  beforeMount() {
    this.$store.dispatch('live/reset');
  },
  async created() {
    await this.init();

    // 有料配信、購入なし
    if (this.$store.state.live.ticket.id) {
      return;
    }

    // ライブポイント初期化
    if(this.isLiveNormal || this.isLiveEvent || this.isLivePaid){
      this.livePoint = this.$store.state.live.info.point;
    }

    // パーセント表示初期化
    if(this.isLiveClubfun){
      this.prevPercentage = this.$store.state.live.liveClubfunProcess;
    }

    // 通常配信イベント配信
    if (this.isLiveNormal || this.isLiveEvent){
      try {
        let res = await getLiveAttrInfo(this.$store.state.live.info.id);
        if (res.data.live_info) {
          this.$store.dispatch('live/updateLiveAttrInfo',res.data.live_info);
        }
        // 1秒毎の更新
        const self = this;
        this.updateInterval = setInterval(() =>
        {
          // 属性更新各種
          const isActive = self.isAttrActive();
          if( isActive || self.prevAttrInfoActive ){
              // ブースター更新
              self.$store.dispatch('live/updateLiveAttrInfo', _.cloneDeep(self.$store.state.live.liveAttributeInfo) );
          }
          self.prevAttrInfoActive = isActive;
        }, 1000);
      } catch(e) {
        console.log(e);
      }
    }
    this.buyItemList = _.chain(this.$store.state.live.liveClubfunForUI).map(v => {
      const amountList = []
      for (const key in v.numList) {
        const num = parseInt(v.numList[key]);
        if( num <= parseInt(10 - v.bought_count) ){
          amountList.push(num)
        }
      }
      return {
        type: v.type,
        amount: 0,
        amountList,
        name: v.plan_name,
        c1: v.gold_price,
        c2: v.cash_price,
        bought_count: v.bought_count,
        icon: v.icon,
        send_post: v.send_post,
        select_box_a: v.select_box_a,
        select_box_b: v.select_box_b,
        select_box_c: v.select_box_c,
        options_a: v.options_a,
        options_b: v.options_b,
        options_c: v.options_c,
        a_value: '',
        b_value: '',
        c_value: '',
      }
    }).value();
    if (this.isLiveClubfun) {
      this.setPanelBuy()
    } else {
      this.setPanelGift()
    }

    if (this.$route.query.pay_success) {
      this.buyItemListSuccess = JSON.parse(decodeURIComponent(this.$route.query.pay_success))
      this.ui.panelType = PANEL_TYPE.BUY_COIN
      this.ui.panelbuycoin.confirm=true
      this.ui.panelTypeLast = PANEL_TYPE.BUY
    }
    this.selectedGift.giftAmount = this.giftAmountList[0].value;
    this.getTimeDuration(this.$store.state.live.info.started_at);
    if(this.isLiveNormal || this.isLiveEvent){
      this.clubFunHeartGetTimer = setTimeout(this.getHeartPresent, 20 * 1000)
    }
    if (this.isLiveEvent) {
      this.isLiveEventData();
    }
    if (this.isLivePaid) {
      this.setPenlightGiftDataList();
    }
    // 拡散テキスト設定
    if(this.$store.state.live.info.room_info && this.$store.state.live.info.room_info.twitter_text) {
      this.shareText = this.$store.state.live.info.room_info.twitter_text + "\n";
    }
    this.shareText += this.$store.state.live.info.room_info.name + "\n";
    this.shareUrl = location.href;

    // 初期化が終わったら後はメッセージ受信経由になる
    this.firstFlag = false
  },
  beforeUpdate() {
  },
  updated() {},
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.updateInterval);
    clearTimeout(this.giftTimer);
    clearInterval(this.timerSyncLive)
    clearTimeout(this.clubFunHeartGetTimer);
    clearTimeout(this.boosterTitleHideTimer);
    clearTimeout(this.clubfunTitleHideTimer);
    clearTimeout(this.boosterTitleShowTimer);
  },
  methods: {
    isNotSelf(id){
      return this.$store.state.user.profile_id !== id
    },
    async init() {
      this.audio = new audioPlayer();
      let audioFiles = {};
      if( this.isLiveNormal || this.isLiveEvent ){
        audioFiles = this.audioFiles.normal;
      }
      if( this.isLiveClubfun ){
        audioFiles = this.audioFiles.funds;
      }
      this.audio.InitPath(audioFiles);
      // this.audio.LoadAll();
      this.initParticles();
      this.$store.dispatch('user/syncAttribute');
      const success = await this.$store.dispatch('live/liveInit', {
        id: this.$route.params.id,
        live_item_amount: this.$store.state.system.config.payment_limit_list.item_amount,
      });
      if(!this.isBuyPay && this.isLivePaid){
        this.setPanelBuyPay();
      }
      if (success) {
        try {
          await this.$store.dispatch('live/syncLiveURL');
          this.syncLiveInfoForTimeUpdate();
        } catch(e) {
          console.log(e);
        }
        if(!this.isLiveOver){
          this.$refs.videoRect.playVideo();
        }
        await this.$refs.comment.init();
        return true;
      } else {
        // this.setPanelBuyPay();
        return false;
      }
    },

    async initParticles() {
      if( !this.isLiveNormal && !this.isLiveEvent  ){
        return;
      }
      // 炎
      const effectFireTextures = [
        PIXI.Texture.from("/pixi/particles/fire/fire_01.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_02.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_03.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_04.png")
      ];
      let res = undefined;
      try{
        // 演出用json取得
        res = await getFireEffect();
      } catch(e){
        console.log(e);
      }
      this.effectFireOption = res.data;
      this.effectFireEmmiter = new Emitter(
        this.particleContainer,
        effectFireTextures,
        this.effectFireOption,
      );
      this.effectFireEmmiter.emit = false;
      // 雪
      const effectSnowTextures = [
        PIXI.Texture.from("/pixi/particles/snow/snow_01.png")
      ];
      res = undefined;
      try{
        // 演出用json取得
        res = await getSnowEffect();
      } catch(e){
        console.log(e);
      }
      this.effectSnowOption = res.data;
      this.effectSnowEmmiter = new Emitter(
        this.particleContainer,
        effectSnowTextures,
        this.effectSnowOption,
      );
      this.effectSnowEmmiter.emit = false;
      // 風
      const effectWindAirTextures = [
        PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_01.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_02.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png")
      ];
      const effectWindFlowerTextures = [
        PIXI.Texture.from("/pixi/particles/wind/wind_flower/wind_flower_01.png")
      ];
      let res1 = undefined;
      let res2 = undefined;
      let res3 = undefined;
      try{
        // 演出用json取得
        res1 = await getWindAirEffect();
        res2 = await getWindFlowerEffect();
        res3 = await getWindFlower2Effect();
      } catch(e){
        console.log(e);
      }
      this.effectWindAirOption = res1.data;
      this.effectWindFlowerOption = res2.data;
      this.effectWindFlower2Option = res3.data;
      this.effectWindAirEmmiter = new Emitter(
        this.particleContainer,
        effectWindAirTextures,
        this.effectWindAirOption,
      );
      this.effectWindAirEmmiter.emit = false;
      this.effectWindFlowerEmmiter1 = new Emitter(
        this.particleContainer,
        effectWindFlowerTextures,
        this.effectWindFlowerOption,
      );
      this.effectWindFlowerEmmiter1.emit = false;
      this.effectWindFlowerEmmiter2 = new Emitter(
        this.particleContainer,
        effectWindFlowerTextures,
        this.effectWindFlower2Option,
      );
      this.effectWindFlowerEmmiter2.emit = false;
    },

    resizeEffect() {
      this.particleContainer.scale.x = this.particleContainer.scale.y = 1;
      if (window.innerWidth < 800) {

        if (window.innerWidth < window.innerHeight) {
          this.particleContainer.scale.x = this.particleContainer.scale.y = 0.5;
        }
      }
    },

    playFireEffect(call_next = false) {
      this.effectFireEmmiter.cleanup();
      if( this.effectFireEmmiter.emit ){
        return;
      }
      this.resizeEffect();
      this.effectFireEmmiter.emit = true;
      setTimeout(() => {
        this.effectFireEmmiter.emit = false;
        if (call_next) {
          setTimeout(() => {
            this.onAnimComplete();
          }
            // 2秒
            ,this.effectWaitDuration
          )

        }
        }
        // 5秒
        ,this.effectDuration
      )
      // 炎の音
      this.audio.Play("fire");
    },

    playSnowEffect(call_next = false, duration = 0) {
      this.effectSnowEmmiter.cleanup();
      if( this.effectSnowEmmiter.emit ){
        return;
      }
      this.resizeEffect();
      this.effectSnowEmmiter.emit = true;
      setTimeout(() => {
        this.effectSnowEmmiter.emit = false;
        if (call_next) {

          setTimeout(() => {
              this.onAnimComplete();
            }
              // 2秒
              ,this.effectWaitDuration
            )
          }
        }
        // 5秒
        ,duration > 0 ? duration: this.effectDuration
      )
      // 雪の音
      this.audio.Play("snow");
    },

    playWindEffect(call_next = false, is_short = false) {
      let wait1 = 500
      let wait2 = 2500
      let wait3 = 2000
      if (is_short) {
        wait1 = 200
        wait2 = 1500
        wait3 = 1200
      }
      this.effectWindAirEmmiter.cleanup();
      this.effectWindFlowerEmmiter1.cleanup();
      this.effectWindFlowerEmmiter2.cleanup();
      // if( this.effectWindAirEmmiter.emit ){
      //   return;
      // }
      this.resizeEffect();
      this.effectWindAirEmmiter.emit = true;
      setTimeout(() => {
        this.effectWindFlowerEmmiter1.cleanup();
        this.effectWindFlowerEmmiter1.emit = true;
        // 風の音
        this.audio.Play("wind");
        setTimeout(() => {
          this.effectWindFlowerEmmiter2.cleanup();
          this.effectWindFlowerEmmiter2.emit = true;
          setTimeout(() => {
            this.effectWindAirEmmiter.emit = false;
            this.effectWindFlowerEmmiter1.emit = false;
            this.effectWindFlowerEmmiter2.emit = false;
            if (call_next) {
              setTimeout(() => {
                this.onAnimComplete();
              }
                ,this.effectWaitDuration
              );
            }
          }
            ,wait3
          );
        }
          ,wait2
        );
      }
        ,wait1
      );
    },
    linkPersonalInfo() {
      let link = this.$router.resolve({ name: 'TopTermsPersonalInfo' });
      window.open(link.href,'_blank');
    },
    linkProfileAddress() {
      let link = this.$router.resolve({ name: 'TopProfileAddress' });
      window.open(link.href,'_blank');
    },
  async playEarthEffect() {
    this.playByKey('Tiger_back')
  },
  async playZeus(index) {
    this.playByKey('Zeus',index)
  },
  async playBall() {
    this.playByKey('Ball')
  },
  async playMeerkat() {
    this.playByKey('Meerkat')
  },
  async playGoddess(index) {
    this.playByKey('NvShen',index)
  },
  async playPhoenix() {
    this.playByKey('Phoenix');
  },
  async playPinkShit() {
    this.playByKey('PinkShit')
  },
  async playIceDragon() {
    this.playByKey('IceDragon');
  },
  async playDolphin() {
    this.playByKey('Dolphin')
  },
  async playWhale() {
    this.playByKey('Whale')
  },
  async playWildPig() {
    this.playByKey('WildPig')
  },
  async playTiger() {
    this.playByKey('Tiger')
  },
  async playFlyHorse() {
    this.playByKey('FlyHorse');
  },
  async playHanabi1() {
    this.playByKey('yanhua_a');
  },
  async playHanabi2() {
    this.playByKey('yanhua_b');
  },
  async playByKey(key,allGiftBonus,index = 0){
    this.playQ.push({key,allGiftBonus,index})
    if(this.playQ.length === 1){
      this.playAllAnims(key,allGiftBonus, index);
    }
  },
  playEffect(key) {
    switch(key) {
      case 'IceDragon':
        this.playSnowEffect(false, 3000);
        break
      case 'Phoenix':
        this.playFireEffect();
        break
      case 'FlyHorse':
        this.playWindEffect();
        break
      default:
        break;
    }
  },
  playAllAnims(key,allGiftBonus,index) {
    // ミッションコンプリート演出
    let time = 1;
    if( allGiftBonus > 0 ){
      const self = this;
      // 魔獣判定
      if( allGiftBonus >= 20000 ){
        this.isMissionCompleteForBeast = true;
        time = 3;
      }
      setTimeout(() => {
        this.isPlayingMissionComplete = true;
        setTimeout(()=>{
          self.isMissionCompleteForBeast = false
          self.isPlayingMissionComplete = false
        }, 5 * 1000)
      }, time * 1000);
    }
    switch(key) {
      case 'Whale':
        setTimeout(() => {  playSpineAnimation(key); }, 2000);
        break
      case 'PinkShit':
        setTimeout(() => {  playSpineAnimation(key); }, 800);
        break
      case 'Snow':
        this.playSnowEffect(true, 0);
        return;
      case 'Fire':
        this.playFireEffect(true);
        return;
      case 'Wind':
        this.playWindEffect(true, true);
        return;
      default:
        playSpineAnimation(key);
        break;
    }
    this.showBackground(key)
    this.playMusic(key,index)
    this.playEffect(key)
  },
  onAnimComplete(){
    this.playQ.shift()
    // check if the playQ have not play anim
    this.backgroundImgShow = false;
    if(this.playQ.length > 0){
      this.playAllAnims(this.playQ[0].key, this.playQ[0].allGiftBonus , this.playQ[0].index)
    }
  },
    updateFrame(){
        const newNow = Date.now();
        let updateCounter = (newNow - this.notTimeStamp) * 0.001
        // 炎
        if( this.effectFireEmmiter ){
          this.effectFireEmmiter.update(updateCounter);
        }
        // 雪
        if( this.effectSnowEmmiter ){
          this.effectSnowEmmiter.update(updateCounter);
        }
        // 風
        if ( this.effectWindAirEmmiter ){
          this.effectWindAirEmmiter.update(updateCounter);
          this.effectWindFlowerEmmiter1.update(updateCounter);
          this.effectWindFlowerEmmiter2.update(updateCounter);
        }

        this.notTimeStamp = newNow;
    },

    setPanelGift() {
      if (this.ui.panelType === PANEL_TYPE.GIFT) {
        return;
      }
      this.ui.panelTypeLast = this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.GIFT;
    },

    setPanelBuy() {
      if (this.ui.panelType === PANEL_TYPE.BUY) {
        return;
      }
      this.ui.panelTypeLast = this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.BUY;
    },

    async setPanelTarget() {
      if (this.ui.panelType === PANEL_TYPE.TARGET) {
        return;
      }
      if (!this.$store.state.live.info.target.id) {
        window.alert('現在のターゲットはありません。')
        return;
      }
      if (this.ui.Expansion === true) {
        const streamer_id = this.$store.state.live.info.streamer_id
        const live_id = this.$store.state.live.info.id
        let routeData = this.$router.resolve({
          name: 'TargetView',
          params: { streamer_id, live_id },
          query: { streamer_id, live_id },
        });
        window.open(routeData.href, "_blank");
        return
      }
      await this.$store.dispatch('live/syncTarget');
      this.ui.panelTypeLast = this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.TARGET;
    },

    setPanelAddress() {
      if (this.ui.panelType === PANEL_TYPE.ADDRESS) {
        return;
      }
      this.ui.panelTypeLast = this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.ADDRESS;
    },

    setPanelBuyPay() {
      if (this.ui.panelType === PANEL_TYPE.BUY_PAY) {
        return;
      }
      this.ui.panelTypeLast = this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.BUY_PAY;
    },

    setPenlightGiftDataList() {
      this.$store.state.user.gift_pay.forEach(element => {
        const isPenlight = this.penLightList.includes(element.id)
        if(isPenlight) {
          this.penlightGiftDataList.push(element);
        } else {
          this.paidGiftListExceptPenlight.push(element);
        }
      });
    },

    cancelBuyCoin(){
      this.ui.panelTypeLast = this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.BUY;
    },

    async setPanelBuyCoin() {
      this.tmpFundBuy.remarks = this.clubfunBuy.remarks;
      if (this.ui.panelType === PANEL_TYPE.BUY_COIN) {
        return;
      }
      if (this.ui.fundDeliveryRadio == 'done') {
        Object.assign(this.clubfunBuy, this.tmpFundBuy);
      }

      if (!this.clubfunBuy.family_name) {
        this.ui.clubfunBuyErrorText = '※[苗字]を入力してください';
        this.ui.clubfunBuyError = true;
        return;
      } else if (this.clubfunBuy.family_name) {
        if (!validStringCount(this.clubfunBuy.family_name, 16)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[苗字]は全角8文字・半角16文字以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (!this.clubfunBuy.given_name) {
        this.ui.clubfunBuyErrorText = '※[名前]を入力してください';
        this.ui.clubfunBuyError = true;
        return;
      } else if(this.clubfunBuy.given_name) {
        if (!validStringCount(this.clubfunBuy.given_name, 16)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[名前]は全角8文字・半角16文字以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (!this.clubfunBuy.phone_number1 || !this.clubfunBuy.phone_number2 || !this.clubfunBuy.phone_number3) {
        this.ui.clubfunBuyErrorText = '※[電話番号]を入力してください';
        this.ui.clubfunBuyError = true;
        return;
      }else if(this.clubfunBuy.phone_number1 || this.clubfunBuy.phone_number2 || this.clubfunBuy.phone_number3){
        if (!validStringCount(this.clubfunBuy.phone_number1, 4)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText ='※[電話番号1]は各欄、半角数字4桁以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }else if (!validStringCount(this.clubfunBuy.phone_number2, 4)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText ='※[電話番号2]は各欄、半角数字4桁以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }else if (!validStringCount(this.clubfunBuy.phone_number3, 4)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText ='※[電話番号3]は各欄、半角数字4桁以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (!this.clubfunBuy.postal_code1 || !this.clubfunBuy.postal_code2) {
        this.ui.clubfunBuyErrorText = '※[郵便番号]を入力してください';
        this.ui.clubfunBuyError = true;
        return;
      }else if (this.clubfunBuy.postal_code1) {
        if (stringCount(this.clubfunBuy.postal_code1) != 3) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[郵便番号1]の前半は半角数字3文字、後半は半角数字4文字以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }else if (stringCount(this.clubfunBuy.postal_code2) != 4) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[郵便番号2]の前半は半角数字3文字、後半は半角数字4文字以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (!this.clubfunBuy.prefecture) {
        this.ui.clubfunBuyErrorText = '※[都道府県]を選択してください';
        this.ui.clubfunBuyError = true;
        return;
      }
      if (!this.clubfunBuy.area) {
        this.ui.clubfunBuyErrorText = '※[市区町村]を入力してください';
        this.ui.clubfunBuyError = true;
        return;
      }else if (this.clubfunBuy.area) {
        if (!validStringCount(this.clubfunBuy.area, 24)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[市区町村]は全角12文字・半角24文字以内で入力してください';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (!this.clubfunBuy.address) {
        this.ui.clubfunBuyErrorText = '※[住所]を入力してください';
        this.ui.clubfunBuyError = true;
        return;
      }else if(this.clubfunBuy.address){
        if (!validStringCount(this.clubfunBuy.address, 20)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[住所]は全角10文字・半角20文字以内で入力してください';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (this.clubfunBuy.house_number1) {
        if (!validStringCount(this.clubfunBuy.house_number1, 2)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※番地1は半角数字2文字以内で入力してください';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (this.clubfunBuy.house_number2) {
        if (!validStringCount(this.clubfunBuy.house_number2, 5)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※番地2は半角数字5文字以内で入力してください';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if (this.clubfunBuy.house_number3 && this.clubfunBuy.house_number3.length > 3) {
        if (!validStringCount(this.clubfunBuy.house_number3, 3)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※番地3は半角数字3文字以内で入力してください';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if(this.clubfunBuy.mansion_name) {
        if (!validStringCount(this.clubfunBuy.mansion_name, 24)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[マンション名]は全角12文字・半角24文字以内で入力してください';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if(this.clubfunBuy.room_number) {
        if (!validStringCount(this.clubfunBuy.room_number, 4)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[部屋番号]は半角数字4文字以内で入力してください（記号は使えません）';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      if(this.clubfunBuy.company_name) {
        if (!validStringCount(this.clubfunBuy.company_name, 50)) {
          this.ui.clubfunBuyError = true;
          this.ui.clubfunBuyErrorText = '※[会社名・部門名]は全角25文字・半角50文字以内で入力してください';
          this.ui.clubfunBuyError = true;
          return;
        }
      }
      const infoValidateResults = buyCoinInfoValidation(this.clubfunBuy)
      if (infoValidateResults) {
        this.ui.clubfunBuyErrorText = infoValidateResults;
        this.ui.clubfunBuyError = true;
        return;
      }

      try {
        await createDeliveryInfoHistory({
          "broadcast_fund_id" : this.$store.state.live.info.liveable_id,
          "family_name": this.clubfunBuy.family_name,
          "given_name": this.clubfunBuy.given_name,
          "phone_number": [this.clubfunBuy.phone_number1,this.clubfunBuy.phone_number2,this.clubfunBuy.phone_number3].filter(v=>v).join('-'),
          "postal_code": this.clubfunBuy.postal_code1+this.clubfunBuy.postal_code2,
          "prefecture": this.clubfunBuy.prefecture,
          "area": this.clubfunBuy.area,
          "address": this.clubfunBuy.address,
          "house_number": [this.clubfunBuy.house_number1,this.clubfunBuy.house_number2,this.clubfunBuy.house_number3].filter(v=>v).join('-'),
          "mansion_name": this.clubfunBuy.mansion_name,
          "room_number": this.clubfunBuy.room_number,
          "company_name": this.clubfunBuy.company_name,
          "remarks": this.clubfunBuy.remarks,
        })
      } catch (err) {
        if (err.response.status === 422) {
          this.$alert(err.response.data.message, {
            confirmButtonText: '確認',
          })
          return;
        }
      }

      this.ui.panelTypeLast = this.ui.panelType === PANEL_TYPE.PAYMENT ? PANEL_TYPE.ADDRESS : this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.BUY_COIN;
    },

    onPanelClose() {
      if(this.ui.panelTypeLast) {
        this.ui.panelType = this.ui.panelTypeLast;
        if (this.ui.panelTypeLast === PANEL_TYPE.ADDRESS) {
          this.ui.panelTypeLast = PANEL_TYPE.BUY
        }else if(this.ui.panelTypeLast === PANEL_TYPE.TARGET) {
          this.ui.panelTypeLast = PANEL_TYPE.GIFT
        }
      }
    },

    onClubfunDetail(liveable_id) {
      let routeData = this.$router.resolve({
        name: 'TopFundDetail',
        params: { liveable_id },
      });
      window.open(routeData.href, '_blank');
    },

    onEventDetailClick(liveable_id) {
      let routeData = this.$router.resolve({
        name: 'TopEventDetail',
        params: { liveable_id } ,
      });
      window.open(routeData.href, "_blank");
    },

    onAvatarClick(profile_id) {
      let routeData = this.$router.resolve({
        name: "TopProfileStyle2",
        params: { profile_id },
      });
      window.open(routeData.href, '_blank');
    },

    showUserProfile(profile_id) {
      if (this.ui.panelType === PANEL_TYPE.PROFILE) {
        this.otherProfile_id = profile_id
        return;
      }
      this.otherProfile_id = profile_id
      this.ui.panelTypeLast = this.ui.panelType;
      this.ui.panelType = PANEL_TYPE.PROFILE;
    },
    copyFundBuy() {
      Object.assign(this.tmpFundBuy, this.clubfunBuy);
    },
    clearFundBuy() {
      this.clubfunBuy.family_name = "";
      this.clubfunBuy.given_name = "";
      this.clubfunBuy.phone_number1 = "";
      this.clubfunBuy.phone_number2 = "";
      this.clubfunBuy.phone_number3 = "";
      this.clubfunBuy.postal_code1 = "";
      this.clubfunBuy.postal_code2 = "";
      this.clubfunBuy.prefecture = "";
      this.clubfunBuy.area = "";
      this.clubfunBuy.address = "";
      this.clubfunBuy.house_number1 = "";
      this.clubfunBuy.house_number2 = "";
      this.clubfunBuy.house_number3 = "";
      this.clubfunBuy.mansion_name = "";
      this.clubfunBuy.room_number = "";
      this.clubfunBuy.company_name = "";
    },

    async onClubfunBuy() {
      const buyItems = this.buyItemList.filter(v => v.amount > 0)
      if(this.isLiveOver) {
        this.$alert('既に配信が終了しています');
        return;
      }

      if (!buyItems.length > 0) {
        this.$alert('選択してください');
        return;
      }

      this.ui.panelbuycoin.confirm = false;
      this.ui.ExpansionCoin = false;
      this.ui.Expansion = false;

      if (buyItems.filter(v => v.send_post == true).length === 0) {
        this.ui.panelType = PANEL_TYPE.BUY_COIN;
        this.ui.panelTypeLast = PANEL_TYPE.BUY;
        return;
      }

      const { data } = await getDeliveryInfo();
      if (data.family_name) {
        this.clubfunBuy.family_name = data.family_name;
        this.clubfunBuy.given_name = data.given_name;
        this.clubfunBuy.phone_number1 = data.phone_number.split('-')[0];
        this.clubfunBuy.phone_number2 = data.phone_number.split('-')[1];
        this.clubfunBuy.phone_number3 = data.phone_number.split('-')[2];
        this.clubfunBuy.postal_code1 = data.postal_code.toString().substr(0, 3);
        this.clubfunBuy.postal_code2 = data.postal_code.toString().substr(3, 7);
        this.clubfunBuy.prefecture = data.prefecture;
        this.clubfunBuy.area = data.area;
        this.clubfunBuy.address = data.address;
        this.clubfunBuy.house_number1 = data.house_number.split('-')[0];
        this.clubfunBuy.house_number2 = data.house_number.split('-')[1];
        this.clubfunBuy.house_number3 = data.house_number.split('-')[2];
        this.clubfunBuy.mansion_name = data.mansion_name;
        this.clubfunBuy.room_number = data.room_number;
        this.clubfunBuy.company_name = data.company_name;
        this.clubfunBuy.remarks = '';
        this.ui.fundDeliveryRadio = 'done';
        this.ui.fundDeliveryAddress = true;
        this.copyFundBuy();
      }
      this.setPanelAddress();
    },

    onAlert() {
      this.$alert('同意をチェックしてください');
    },

    onGold() {
      if (!this.checkOptionsValidation()) {
        this.$alert('選択してください');
        return;
      }
      this.$confirm('Gで購入しますか？',{
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          center: true
      }).then(() => {
        this.goldBuy()
      }).catch(() => {
        return
      });
    },
    async goldBuy() {
      this.publishableKey = this.$store.state.system.config.public_key.stripe
      const buyList = this.buyItemList.filter(v => v.amount > 0).map(v => ({
        type: v.type,
        amount: v.amount,
        icon: v.icon,
        options: this.setCoinOptions(v),
      }))
      // const successURL = window.location.href.split('?')[0] + '?pay_success=' + encodeURIComponent(JSON.stringify(this.buyItemList.filter(v => v.amount > 0)))
      let giftGold = {};
      let giftgroup = [];
      const apiBuyList = buyList.map(v => ({
        type: v.type,
        amount: v.amount,
        options: v.options,
      }))
      let totalPrice = 0;
      this.buyItemList.forEach(element => {
        totalPrice += element.c1 * element.amount
      });
      if(this.$store.state.user.c1 < totalPrice){
        this.$confirm('Ｇが不足しています', {
            confirmButtonText: 'G購入',
            cancelButtonText: '閉じる',
            center: true
        }).then(() => {
          let link = this.$router.resolve({ name: 'TopProfileBuyHistory' });
          window.open(link.href,'_blank');
        }).catch(() => {
          return
        });
        return
      }
      try {
        giftGold = await buyClubfunGiftGold(this.$store.state.live.info.liveable_id, this.$store.state.live.info.id, apiBuyList)
      } catch(err) {
        if (err.response.data.code === this.$store.state.system.enum_list.response_code_list.SERVICE_LIVE_NOT_AVAILABLE) {
          this.$alert("この配信ではご利用できない状態になっています。")
          return;
        }
      }
      // ゴールドの更新
      await this.$store.dispatch('user/syncCoupon');
      // window.location.href = successURL
      giftgroup = giftGold.data;
      // 購入情報のクリア
      for (const key in this.buyItemList) {
        this.buyItemList[key].amount = 0;
        this.buyItemList[key].a_value = '';
        this.buyItemList[key].b_value = '';
        this.buyItemList[key].c_value = '';
      }
      for(let i = 0;i < Object.keys(giftgroup).length;i++) {
          try {
          // 購入数の更新
          const type = giftgroup[i]["type"];
          const item = _.find( this.buyItemList, (value) =>{
            if( type ){
              return value.type == type
            }
            return false
          });
          item.bought_count = giftgroup[i].bought_count
          const amountList = []
          for (const key in item.amountList) {
            const num = parseInt(item.amountList[key]);
            if( num <= parseInt(10 - item.bought_count) ){
              amountList.push(num)
            }
          }
          item.amountList = amountList;
          giftgroup[i]["current_percentage"] = giftgroup.broadcast_fund.current_percentage
          giftgroup[i]["is_goal_effect"] = giftgroup.broadcast_fund.is_goal_effect
          giftgroup[i]["is_goal_effect_a"] = giftgroup.broadcast_fund.is_goal_effect_a
          giftgroup[i]["is_goal_effect_b"] = giftgroup.broadcast_fund.is_goal_effect_b
          await this.$store.dispatch('live/timSendMessageGift', {
            text: '',
            GIFT_ID: '',
            RAW: giftgroup[i],
            avatar: this.$store.state.user.profile_avatar_store_path,
            giftIcon: buyList[i].icon,
          });
          this.ui.panelType = PANEL_TYPE.BUY
        } catch(err) {
          this.ui.isGiftItemComment = false;
          this.ui.panelType = PANEL_TYPE.BUY
          return false;
        }
      }
      // Gold使用時のスター獲得チェック
      if ( giftGold.data.star_present ){
        this.$alert( giftGold.data.star_present + 'スター獲得', 'G累計使用ボーナス', {
          confirmButtonText: 'OK',
          showClose: false,
        })
      }
      for (const key in this.buyItemList) {
        this.buyItemList[key].amount = 0;
        this.buyItemList[key].a_value = '';
        this.buyItemList[key].b_value = '';
        this.buyItemList[key].c_value = '';
      }
    },

    async onTicketBuy() {
      this.ui.panelbuypay.confirm = false;
      const {status} = await buyTicket(this.$store.state.live.ticket.liveable_id)
      if(status == "success"){
        window.location.reload(false);
        this.ui.panelbuypay.paid = true;
      }
      // await this.$store.dispatch('live/syncLiveInfo',this.$store.state.live.info.id)
      // if (this.isLiving) {

      // }

    },

    onCash() {
      if (!this.checkOptionsValidation()) {
        this.$alert('選択してください');
        return;
      }
      this.ui.panelTypeLast = PANEL_TYPE.BUY_COIN
      this.ui.panelType = PANEL_TYPE.PAYMENT
      // 決済apiのtype = ex) coinA("1-17-1")
      const apiBuyList = this.buyItemList.map(v => ({
        type: v.type,
        amount: v.amount,
        cash_price: v.c2,
        options: this.setCoinOptions(v),
      })).filter(v =>{ return v.amount > 0; })
      const successURL = window.location.href.split('?')[0] + '?pay_success=' + encodeURIComponent(JSON.stringify(this.buyItemList.filter(v => v.amount > 0)))
      const cancelURL = window.location.href.split('?')[0]
      this.clubfunPaymentData = {
        buyList: apiBuyList,
        buyType: 'gold',
        successUrl: successURL,
        cancellUrl: cancelURL
      }
    },

    async onCashDone(ret) {
      setTimeout(() => {
        this.ui.panelTypeLast = this.ui.panelType;
        this.ui.panelType = PANEL_TYPE.BUY;
        for (const key in this.buyItemList) {
          this.buyItemList[key].amount = 0;
          this.buyItemList[key].a_value = '';
          this.buyItemList[key].b_value = '';
          this.buyItemList[key].c_value = '';
        }
      }, 2000)
      //this.publishableKey = this.$store.state.system.config.public_key.stripe
      const buyList = this.buyItemList.filter(v => v.amount > 0).map(v => ({
        type: v.type,
        amount: v.amount,
        icon: v.icon,
      }))

      let boughtDataMap = {};
      for (const key in ret.items) {
        const item = ret.items[key];
        boughtDataMap[item.type] = item;
      }
      let isGoalEffect = false;
      let isGoalEffectA = false;
      let isGoalEffectB = false;
      for(let i = 0;i < buyList.length;i++) {
        try {
          // 最後の要素だけ達成したかどうかを入れる
          if( i == buyList.length -1 ){
            isGoalEffect = ret.is_goal_effect;
            isGoalEffectA = ret.is_goal_effect_a;
            isGoalEffectB = ret.is_goal_effect_b;
          }
          // 購入数の更新
          const item = _.find( this.buyItemList,['type', buyList[i].type])
          item.bought_count = boughtDataMap[buyList[i].type].bought_count
          const amountList = []
          for (const key in item.amountList) {
            const num = parseInt(item.amountList[key]);
            if( num <= parseInt(10 - item.bought_count) ){
              amountList.push(num)
            }
          }
          item.amountList = amountList;

          await this.$store.dispatch('live/timSendMessageGift', {
            text: '',
            GIFT_ID: '',
            // ゴールドでの購入レスポンスをシミュレート
            RAW: {
              live_id: ret.live_id,
              streamer_id: this.$store.state.live.info.streamer_id,
              viewer_id: ret.viewer_id,
              type: buyList[i].type,
              amount: buyList[i].amount,
              is_goal_effect: isGoalEffect,
              is_goal_effect_a: isGoalEffectA,
              is_goal_effect_b: isGoalEffectB,
              current_percentage: ret.current_percentage,
              bought_count: boughtDataMap[buyList[i].type].bought_count,
              token_time: boughtDataMap[buyList[i].type].token_time,
              token_random: boughtDataMap[buyList[i].type].token_random,
              token_hash: boughtDataMap[buyList[i].type].token_hash
            },
            avatar: this.$store.state.user.profile_avatar_store_path,
            giftIcon: buyList[i].icon,
          });
        } catch(err) {
          this.ui.isGiftItemComment = false;
          return false;
        }
      }
    },

    onBuy() {
      let link = this.$router.resolve({ name: 'TopProfileBuy' });
      window.open(link.href,'_blank');
    },

    async onFollow(id) {
      await this.$store.dispatch("user/addFollowing", id);
      await this.$store.dispatch('live/syncTarget');
    },
    async onFollowCancel(id) {
      await this.$store.dispatch("user/removeFollowers", id);
      await this.$store.dispatch('live/syncTarget');
    },

    async onGiftSend(data, giftPayType, giftAmount, inputText) {
      // 連打防止
      if(this.isClick) {
        this.isClick = false;
        setTimeout(() => {
          this.isClick = true;
        }, 2000)
      }else {
        return
      }
      data.coupon = giftPayType ? giftPayType : data.coupon;
      data.amount = giftAmount;
      //- ブースターのデフォルト数を１にする
      if (this.boosterIdList.indexOf(data.id) !== -1) {
        data.amount = 1;
      }
      // ギフトバリデーション
      if(!this.giftValidation(data)){
        return
      }
      // 購入資格バリデーション
      if(!this.giftBuyValidation(data)){
        return
      }
      let ret = {};
      try {
        ret = await this.$store.dispatch('user/sendGift', data);
        delete ret.data.id;
      } catch(err) {
        this.$alert('ギフト送信失敗しました', {
          confirmButtonText: '確認',
          showClose: false,
        });
        this.ui.isGiftItemComment = false;
        return false;
      }
      // ギフトの副作用の確認
      try {
        if( ret.data.live_info ){
        await this.$store.dispatch('live/timSendMessageEvent', {
          EVENT_TYPE: 'LIVE_INFO',
          RAW: ret.data.live_info,
          avatar: this.$store.state.user.profile_avatar_store_path,
        });
        }
        if ( ret.data.star_present ){
          this.$alert( ret.data.star_present + 'スター獲得', 'G累計使用ボーナス', {
            confirmButtonText: 'OK',
            showClose: false,
          })
        }
      } catch(err) {
        this.ui.isGiftItemComment = false;
        return false;
      }
      // ギフト確認パネルを閉じる
      this.ui.isGiftItemComment = false;
      this.onClosePenLight()
      this.selectedGift.giftComment = '';
      try {
        await this.$store.dispatch('live/timSendMessageGift', {
          text: inputText,
          GIFT_ID: data.name,
          RAW: ret.data,
          avatar: this.$store.state.user.profile_avatar_store_path,
          giftIcon: data.icon,
        });
      } catch(err) {
        this.ui.isGiftItemComment = false;
        return false;
      }

      this.$store.commit('live/SET_LIVE_POINT', ret.data.live_point);

      // 投下音
      // if (data.c1 && (!(data.c1 < 5000) && data.c1 < 50000)) {
      //   this.audio.src = require("../../../../assets/voice/over5000.wav");
      //   this.audio.play();
      // } else if (data.c1 && !(data.c1 < 50000)) {
      //   this.audio.src = require("../../../../assets/voice/over50000.wav");
      //   this.audio.play();
      // }
      this.ui.isGiftItemComment = false;
      this.selectedGift.giftComment = '';
    },
    handleClose(done) {
      done();
    },
    onGiftItemComment(Gift, giftPayType, choiceFlag) {
      this.selectedGift.giftData = Gift;
      this.selectedGift.giftPayType = giftPayType;
      this.ui.isGiftItemComment = true;
      this.selectedGift.choiceFlag = choiceFlag ? true : false;
    },
    onClose() {
      this.ui.isGiftItemComment = false;
      Object.assign(this.selectedGift, this.$options.data().selectedGift)
    },
    onClosePenLight() {
      this.ui.isPenLight = false;
      Object.assign(this.selectedGift, this.$options.data().selectedGift)
    },
    selectedPenlight() {
      this.selectedGift.giftData = this.penlightGiftDataList.filter(penData => penData.id === this.penlightCode)[0];
    },
    getTimeDuration(startTime) {
      const self = this;
      this.timer = setInterval(() => {
        self.elapsedTime = getLiveDurationStr(startTime)
      },1 * 1000);
    },
    syncLiveInfoForTimeUpdate() {
      this.timerSyncLive = setInterval(async() => {
        await this.$store.dispatch('live/syncLiveInfoForTimeUpdate')
      },30 * 1000);
    },
    setGiftItem(item) {
      const isPenlight = this.penLightList.includes(item.id)
      const lessThan1000 = item.c1 < 1000 || item.c2 || item.c3
      return lessThan1000 && !isPenlight
    },
    async getHeartPresent() {
      const { data } = await updateCouponHeart(this.$route.params.id);
      if(data[0]){
        await this.$store.dispatch('user/syncCoupon');
        this.$alert('10ハート獲得', 'ボーナス', {
          confirmButtonText: 'OK',
          showClose: false,
        })
      }
    },
    beastPhoenixImage(){
      return this.isBeastPhoenix? "/img/z-phoenix.png" : "/img/z-phoenix_n.png";
    },
    beastDragonImage(){
      return this.isBeastDragon? "/img/z-dragon.png" : "/img/z-dragon_n.png";
    },
    beastPegasusImage(){
      return this.isBeastPegasus? "/img/z-pegasus.png" : "/img/z-pegasus_n.png";
    },
    beastWhiteTigerImage(){
      return this.isBeastWhiteTiger? "/img/z-white-tiger.png" : "/img/z-white-tiger_n.png";
    },
    attributeFireImage(){
      return this.isAttributeFire? "/img/z-fire.png" : "/img/z-fire_n.png";
    },
    attributeIceImage(){
      return this.isAttributeIce? "/img/z-ice.png" : "/img/z-ice_n.png";
    },
    attributeWindImage(){
      return this.isAttributeWind? "/img/z-wind.png" : "/img/z-wind_n.png";
    },
    attributeEarthImage(){
      return this.isAttributeEarth? "/img/z-earth.png" : "/img/z-earth_n.png";
    },
    playMotion(gift){
      let allGiftBonus = 0;
      // ミッションコンプリート演出用
      if( gift.RAW.all_gift_bonus > 0 ){
        allGiftBonus = gift.RAW.all_gift_bonus;
      }
      for(let i=0; i<gift.RAW.amount; i++) {
        // ギフト演出
        let key = '';
        let index = 0;
        switch( gift.RAW.type ){
          case this.$store.state.system.enum_list.gift_type_list.SHOW_FIRE:
            key = 'Fire'
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_ICE:
            key = 'Snow'
            // this.playSnowEffect();
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_WIND:
            key = 'Wind'
            // this.playWindEffect(true);
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_EARTH:
            key = 'Tiger_back'
            break;
          case this.$store.state.system.enum_list.gift_type_list.SUPERLATIVE_GODDESS:
            key = 'NvShen'
            index = i % 3
            break;
          case this.$store.state.system.enum_list.gift_type_list.SUPERLATIVE_ZEUS:
            key = 'Zeus'
            index = i % 3
            break;
          case this.$store.state.system.enum_list.gift_type_list.BALLOON_GROUP:
            key = 'Ball'
            break;
          case this.$store.state.system.enum_list.gift_type_list.MEERKAT_3:
            key = 'Meerkat'
            break;
          case this.$store.state.system.enum_list.gift_type_list.POOP_PINK:
            key = 'PinkShit'
            break;
          case this.$store.state.system.enum_list.gift_type_list.DOLPHIN_3:
            key = 'Dolphin'
            break;
          case this.$store.state.system.enum_list.gift_type_list.WHALE:
            key = 'Whale'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_PEGASUS:
            key = 'FlyHorse'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_PHOENIX:
            key = 'Phoenix'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_DRAGON:
            key = 'IceDragon'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BOAR_GROUP:
            key = 'WildPig'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_WHITETIGER:
            key = 'Tiger'
            break;
          default:
            break;
        }
        if( key ){
          this.playByKey(key,allGiftBonus,index);
        }
      }
    },
    onPenlightGiftItem() {
      this.ui.isPenLight = true;
      this.selectedPenlight()
    },
    videoGiftHide() {
      this.videoGiftShow = false;
    },
    Expansion() {
      this.onPanelClose();
      this.$nextTick(() => {
      this.$nextTick(() => {
      this.$nextTick(() => {
      this.$nextTick(() => {
      this.$nextTick(() => {
        resizePixi();
      })
      })
      })
      })
      })
      this.ui.Expansion = true;
    },
    ExpansionClose() {
      this.ui.Expansion = false;
      this.$nextTick(() => {
      this.$nextTick(() => {
      this.$nextTick(() => {
      this.$nextTick(() => {
      this.$nextTick(() => {
        resizePixi();
      })
      })
      })
      })
      })
    },
    ExpansionGift() {
      this.onPanelClose()
      this.ui.ExpansionGift = true;
    },
    ExpansionGiftClose() {
      this.ui.ExpansionGift = false;
    },
    ExpansionCoin() {
      this.onPanelClose()
      this.ui.ExpansionCoin = true;
    },
    ExpansionCoinClose() {
      this.ui.ExpansionCoin = false;
    },
    async isLiveEventData() {
      const { data } = await getLiveListEventDetail(this.$store.state.live.info.liveable_id);
      this.eventInfo = data;
    },
    getAttribute(item){
      return item.profile.attribute
    },
    giftValidation(giftData) {
      if (this.$store.getters.enum_list.live_status_list.ENDED == this.$store.state.live.info.status) {
        this.$alert('既に配信が終了しています', {
          confirmButtonText: '確認',
          showClose: false,
        });
        return false;
      }
      if (!giftData.id) {
        this.$alert('ギフトを選択してください', {
          confirmButtonText: '確認',
        });
        return false;
      }
      if (!giftData.amount) {
        this.$alert('ギフト数を選択してください', {
          confirmButtonText: '確認',
        });
        return false;
      }
      if (giftData.coupon == 'c1' && this.$store.state.user.c1 < giftData.c1*giftData.amount) {
        this.$alert('ゴールドが不足しています', {
          confirmButtonText: '確認',
        });
        return false;
      }
      if (giftData.coupon == 'c2' && this.$store.state.user.c2 < giftData.c2*giftData.amount) {
        this.$alert('スターが不足しています', {
          confirmButtonText: '確認',
        });
        return false;
      }
      if (giftData.coupon == 'c3' && this.$store.state.user.c3 < giftData.c3*giftData.amount) {
        this.$alert('ハートが不足しています', {
          confirmButtonText: '確認',
        });
        return false;
      }
      return true;
    },
    giftBuyValidation(giftData) {
      // 各属性演出
      if(AtrributeList.indexOf(giftData.id) !== -1) {
        if (giftData.coupon == "c1" && !this.$store.state.user.profile_profileable_titles.includes('t1')) {
          this.$alert('G購入は「称号：マスター」獲得者のみ可能');
          return false;
        }
      }
      // 各属性ブースター
      if(AtrributeBoosterIdList.indexOf(giftData.id) !== -1) {
        if (giftData.coupon == "c1" && !this.$store.state.user.profile_profileable_titles.includes('t1')) {
          this.$alert('G購入は「称号：マスター」獲得者のみ可能');
          return false;
        }
      }
      // 各魔獣
      if(BeastList.indexOf(giftData.id) !== -1) {
        if (!this.$store.state.user.profile_profileable_titles.includes('t1')) {
          this.$alert('G購入は「称号：マスター」獲得者のみ可能');
          return false;
        }
      }
      // ゼウス
      if(giftData.id=="7-1-1") {
        if (!this.$store.state.user.profile_profileable_titles.includes('t2-1')) {
          this.dispNeedZeus();
        }
      }
      // 女神
      if(giftData.id=="7-2-1") {
        if (!this.$store.state.user.profile_profileable_titles.includes('t2-2')) {
          return this.dispNeedGoddess();
        }
      }
      // 全属性ブースター
      if(giftData.id == "1-18-0") {
        if (!this.$store.state.user.profile_profileable_titles.includes('t2-1') && !this.$store.state.user.profile_profileable_titles.includes('t2-2')) {
          return this.dispNeedGod();
        }
      }
      return true;
    },
    isSendableZeus(){
      return this.$store.state.user.profile_profileable_titles.includes('t2-1') &&
        this.$store.state.user.profile_profileable_current_title == 't2-1'
    },
    isSendableGoddess(){
      return this.$store.state.user.profile_profileable_titles.includes('t2-2') &&
        this.$store.state.user.profile_profileable_current_title == 't2-2'
    },
    dispNeedZeus(){
        this.$alert('「称号：ゼウス」獲得者のみ購入可能');
        return false;
    },
    dispNeedMaster(){
      this.$alert('「称号：マスター」獲得者のみ購入可能');
        return false;
    },
    dispNeedGoddess(){
      this.$alert('「称号：女神」獲得者のみ購入可能');
      return false;
    },
    dispNeedGod(){
      this.$alert('ゴッドのみ購入可能');
      return false;
    },
    linkProfile() {
      let link = this.$router.resolve({ name: 'TopProfileIndex' });
      window.open(link.href,'_blank');
    },
    crowdFundPage() {
      let link = this.$router.resolve({ name: 'TopTermsFundTerms' });
      window.open(link.href,'_blank');
    },
    privacypage() {
      let link = this.$router.resolve({ name: 'TopTermsPrivacy' });
      window.open(link.href,'_blank');
    },
    // ギフトのポップアップの表示位置を取得する関数
    // 引数：設定対象のtab-boxのclass
    // 戻り値：中央に表示されるTop値
    setGiftPopUpTop(targetSelector) {
      const targetTabBox = document.querySelector(targetSelector);

      let criteriaTop;
      const innerWidth = window.innerWidth;
      if(innerWidth < 376) {
        // SP縦の場合
        criteriaTop = 20;
        //SP縦且つ拡大鏡ONの場合
        if(this.ui.Expansion){
          // ポップアップのTop値がすでに設定されていた場合、既存のスクロール量をそのまま設定して返す
          if(this.ui.GiftPopUpScrollTop > 0){
            return this.ui.GiftPopUpScrollTop + 'px';
          }
        }
      } else if(668 > innerWidth && innerWidth > 375){
        // SP横の場合
        criteriaTop = 20;
      } else if(window.innerWidth > 667){
        // PCの場合
        if (this.ui.Expansion) {
          criteriaTop = 20;
        } else {
          criteriaTop = (targetTabBox.clientHeight / 2) - 132;
        }
      }

      const setTopValue = (criteriaTop + targetTabBox.scrollTop);
      // メンバ変数のポップアップ表示位置にも値を設定して保持させておく
      this.ui.GiftPopUpScrollTop = setTopValue;
      return setTopValue + 'px';
    },
    // スクロールを禁止にする関数
    disableScroll(event) {
      event.preventDefault();
    },
    // ギフトのポップアップ表示中はtab-boxのスクロールを無効にする関数
    // 戻り値：ui.isGiftItemComment
    preventScrollGiftWindow(){
      const targetElementList = document.querySelectorAll('.tab-box');
      if(this.ui.isGiftItemComment){
        // SP縦持ち且つ拡大鏡ONの場合はスクロール可能にする。（ポップアップの全体が見えるようにするため）
        if(376 > window.innerWidth && this.ui.Expansion){
          return this.ui.isGiftItemComment;
        }
        targetElementList.forEach((targetElement)=>{
          //ポップアップが表示されている場合
          targetElement.addEventListener('touchmove', this.disableScroll, { passive: false });
          targetElement.addEventListener('mousewheel', this.disableScroll, { passive: false });
        });
      } else {
        targetElementList.forEach((targetElement)=>{
          //ポップアップが非表示の場合
          targetElement.removeEventListener('touchmove', this.disableScroll, { passive: false });
          targetElement.removeEventListener('mousewheel', this.disableScroll, { passive: false });
        })
        // メンバ変数のポップアップ表示位置もクリアする。
        this.ui.GiftPopUpScrollTop = 0;
      }
      return this.ui.isGiftItemComment;
    },
    playMusic(type,index = 0) {
      switch(type) {
        case 'Ball':
          this.audio.Play("Ball");
          break;
        case 'Whale':
          this.audio.Play("Whale");
          break;
        case 'Dolphin':
          this.audio.Play("Dolphin");
          break;
        case 'Phoenix':
          this.audio.Play("Phoenix");
          break;
        case 'WildPig':
          this.audio.Play("WildPig");
          break;
        case 'Meerkat':
          this.audio.Play("Meerkat");
          break;
        case 'NvShen':
          this.audio.Play(`NvShen_${index + 1}`);
          break;
        case 'Zeus':
          this.audio.Play(`Zeus_${index + 1}`);
          break;
        case 'PinkShit':
          this.audio.Play("PinkShit");
          break;
        case 'FlyHorse':
          this.audio.Play("FlyHorse");
          break;
        case 'IceDragon':
          this.audio.Play("IceDragon");
          break;
        case 'Tiger_back':
          this.audio.Play("earth");
          break;
        case 'Tiger':
          this.audio.Play("Tiger");
          break;
        default:
          break;
      }
    },
    showBackground(type){
      this.giftType = type;
      this.backgroundImgShow = true;
    },
    getClubfunPercentageTensCoinImg() {
      return '/img/clubfun_coin_par/' + this.$store.state.live.current_percentage.tens + '.png'
    },
    getClubfunPercentageOnesImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.ones + '.png'
    },
    getClubfunPercentageTensImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.tens + '.png'
    },
    getClubfunPercentageHundredsImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.hundreds + '.png'
    },
    getClubfunPercentageThousandsImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.thousands + '.png'
    },
    isAttrActive(){
      const attrInfo = this.$store.state.live.liveAttributeInfo
      if( !attrInfo ){
        return false;
      }
      const now = moment().unix();
      const arr = [
        now,
        moment(attrInfo.booster_time??0).unix(),
        moment(attrInfo.all_gift_booster_time??0).unix(),
        moment(attrInfo.ice_time??0).unix(),
        moment(attrInfo.wind_time??0).unix(),
        moment(attrInfo.earth_time??0).unix(),
        moment(attrInfo.phoenix_time??0).unix(),
        moment(attrInfo.dragon_time??0).unix(),
        moment(attrInfo.pegasus_time??0).unix(),
        moment(attrInfo.phoenix_time??0).unix(),
      ]
      return Math.max(...arr) != now;
    },
    playClubfunTitle() {
      const Process = Math.floor(this.$store.state.live.liveClubfunProcess/50) * 50
      this.scrollClubfunMessage = [{'title': Process}]
      let _this = this;
      _this.clubfunTitleShow = true
      let showFunc = () => {
        _this.clubfunTitleShow = true
        this.clubfunTitleHideTimer = setTimeout(()=>{
          _this.clubfunTitleShow = false
        }, 12 * 1000)
      }
      const repeatCount = 5
      for (let index = 0; index < repeatCount; index++) {
        if ( index == 0 ){
          showFunc();
          continue;
        }
        this.clubfunTitleHideTimer = setTimeout(()=>{
          showFunc()
        }, (30 *index) * 1000)
      }
    },
    optionsList(list) {
      return list.split(','); 
    },
    setCoinOptions(item) {
      if (!item.a_value){ return '' }
      if (!item.b_value){ return item.a_value }
      if (!item.c_value){ 
        return item.a_value + ',' + item.b_value
      }
      return item.a_value + ',' + item.b_value + ',' + item.c_value
    },
    checkOptionsValidation() {
      const buyItems = this.buyItemList.filter(v => v.amount > 0)
      for (let i = 0; i < buyItems.length; i++) {
        if (buyItems[i].select_box_a && buyItems[i].a_value === '') {
          return false;
        }
        if (buyItems[i].select_box_b && buyItems[i].b_value === '') {
          return false;
        }
        if (buyItems[i].select_box_c && buyItems[i].c_value === '') {
          return false;
        }
      }
      return true;
    }
  }
};
</script>