<template lang="pug">
.root
  component-header-login
  .login-center
    .breadCrumbs ログイン >>
      sapn(class="display-title") パスワードを忘れた方はこちら
    .leftContainer
      div パスワードを変更するとサイトから自動ログアウトされますので、変更したパスワードで再度ログインしてください。
      p 変更したパスワードは必ずメモなどして忘れないようにしてください。

      div ＜パスワードに関する注意事項＞
      div アカウント名＋誕生日など、個人情報から簡単に推測できるパスワードは大変危険です。
      div また、Aライブがメール等でパスワードを質問することは絶対にありません。
      div パスワードは第三者に教えないよう十分ご注意下さい。
    .rightContainer
      .loginBox
        .pink-label パスワード変更する
        .right-content-border
          div(class="m-b") メールアドレス
            input(v-model="email" class="input-g-w-border" autocapitalize="off")
          div(class="m-b") メールアドレス[確認用]
            input(v-model="emailConfirm" class="input-g-w-border" autocapitalize="off")
          .buttonArea
            .errorMessageArea
            .errorMessage(v-show="ui.showError", style="color: red") {{ ui.errorMessage }}
            div(style="padding: 8px 0;") ※ 本サイトに登録されている メールアドレスを 入力してください
            img(@click="onSend()" class="pointer" src="/img/button/sousin.png")

  component-footer-login
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { resetByEmail } from "@/api/user";

export default {
  name: "LoginResetIndex",
  components: {},
  data() {
    return {
      ui: {
        showError: false,
        errorMessage: "",
      },
      email: "",
      emailConfirm: "",
    };
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    async onSend() {
      if (this.email && this.emailConfirm !== this.email) {
        this.email = ''
        this.emailConfirm = ''
        this.ui.showError = true;
        this.ui.errorMessage = "※確認用のメールアドレスが一致していません";
        return;
      }
      try {
        await resetByEmail(this.email);
        this.$router.push({ name: "LoginResetNext" });
      } catch (err) {
        this.ui.showError = true;
        if (err.response.status === 404) {
          this.ui.errorMessage = "メールアドレスを入力してください";
        } else {
          this.ui.errorMessage = "※メールアドレスが正しい形式ではありません";
        }
      }
    },
  },
};
</script>
