<template lang="pug">
  .root
    component-header-index
    <div class="">
      div(class="container-flex")
        div(:class="ui.tab !== 'delivery' ? 'leftContainer dn-sp' : 'leftContainer'")
          div(v-if="ui.tab !== 'profile'")
            <div class="steam-social-app">
              div
                <li v-if="data.sns_infos"><img src="/img/socil_twitter.png" class="pointer" v-if="data.sns_infos.twitter" @click="onSNS(data.sns_infos.twitter)"></li>
                <li v-if="data.sns_infos"><img src="/img/socil_instagram.png" class="pointer" v-if="data.sns_infos.instagram" @click="onSNS(data.sns_infos.instagram)"></li>
                <li v-if="data.sns_infos"><img src="/img/socil_youtube.png" class="pointer" v-if="data.sns_infos.youtube" @click="onSNS(data.sns_infos.youtube)"></li>
              div
                div(style="display:none;")
                  share-link(ref="shareLink" :text="shareText" :url="shareUrl")
                <li><img @click="kakusan(share)" src="/img/kakusan.png"></li>
                <li><img @click="changeRankTab()"  src="/img/jyuni.png"></li>
            </div>
            <div class="profile">
              div(class="prof-img")
                <pan-thumb :avatar="data.avatar_store_path" :attribute="data.attribute" :current_title="getCurrentTitle(data)" :isStreamer="true" size="100px"/>
              <p class="t-c m-b"><span class="" aria-hidden="true"></span><b> {{ data.account_name }}</b></p>
              <div class="t-c m-b">
                button(v-if="data.is_following" @click="onStreamerFollowCancel()" class="gray-button")
                button(v-else @click="onStreamerFollow()" class="pink-button")
              </div>
              <div class="t-c">
                <li @click="changeFollowerTab" class="pointer" >
                  <span class="glyphicon glyphicon-user" aria-hidden="true"style="color:#00afec;"></span> {{ data.follower_total }}
                </li>
              </div>
              <div class="t-c m-b">
                <li @click="changeFollowerTab" class="pointer" style="font-size:14px;">フォロワー</li>
              </div>
              <div class="introduction">
                <p style="color:black;">{{ data.introduction }}</p>
              </div>
            </div>
            div(v-if="surveyData.title" class="left-item-m")
              <div class="anketitle">
                <li><div class="pink-label">アンケート</div></li>
                <li> <b>※{{ surveyData.end_time | moment("YYYY/M/DD/ HH:mm") }}締め切り</b></li>
              </div>
              <div class="question" v-if="surveyData.title">
                <p><b style="color:pink">質問</b></p>
                <p><b style="color:#1d9bf0">{{ surveyData.title }}</b></p>
              </div>
              <div class="answer" v-if="surveyData.title">
                <p><b style="color:pink">回答</b></p>
                <select v-bind:disabled="nonactivation" v-model="answer" @change="onAnswerChange">
                  <option  value="" disabled></option>
                  <option v-for="(item,index) in surveyData.options" v-bind:value="index" v-bind:label="item" style="padding:10px">{{ item }}</option>
                </select>
                <div class="answer-img">
                  <img v-if="!answerbutton" src="/img/kaitou.png" onmouseout="this.src='/img/kaitou.png'" alt="" style="margin-left:20px" v-on:click="onAnswerClick">
                  <img v-else src='/img/kaitou_g.png' style="margin-left:20px">
                </div>
              </div>

            //- 配信予約受付中
            div( v-if="reserveListColumns.length" class="left-item-m")
              <div class="reserve-title">
                <li><div class="pink-label">配信予約受付中</div></li>
                <hr style="margin: 0px">
              </div>
                div(class="flex-col" v-for="column in reserveListColumns")
                  div(class="flex-col-item" v-for="item in column")
                    div(@click="onReserveDetails(item)")
                      live-schedule-item(:data="item")
              <div v-if="reserveShowMoreButton" class="show-more">
                <a><b @click="ReserveShowMore()" style="color:black">>> 続きを表示</b></a>
              </div>

            //- ニュース
            div(v-if="showNewsList.length" class="left-item-m")
              <div class="news-title">
                <li><div class="pink-label">ニュース</div></li>
                <hr style="margin: 0px">
              </div>
                <div class="flex-col" v-for="(item,index) in showNewsList">
                  div(class="flex-col-item" @click="onNewsDetails(item)")
                    news-item(:data="item")
                </div>
              <div v-if="newsShowMoreButton" class="show-more">
                <a><b @click="NewsShowMore()" style="color:black">>> 続きを表示</b></a>
              </div>

        div(v-if="ui.tab=='delivery'" class="rightContainer")
          //- 現在配信中
          <div v-if="streamingLiveList.length" class="coorperList">
            <div style="width: 290px;">
              <img src="/img/living.png" alt="">
            </div>
              <div class="side-content">
                <div class="live-room" v-for="(item,index) in streamingLiveList">
                  live-item-other(:interceptClickFunc="payLiveIntercept" :data="item")
                </div>
              </div>
          </div>
          //- 有料配信アーカイブ
          <div v-if="payArchiveList.length" class="coorperList">
            <div style="width: 290px;">
              <img src="/img/paidlive.png" alt="">
            </div>
            <div style="border: 1px solid #ddd;">
              <div v-for="item in payArchiveList" class="live-room">
                live-item-other(:interceptClickFunc="payLiveIntercept" :data="item")
              </div>
              <div v-if="isPayShowMore()" class="show-more">
                <a><b @click="onPayShowMore()" style="color:black">>> 続きを表示 </b></a>
              </div>
            </div>
          </div>
          //- 配信アーカイブ
          <div v-if="archiveList.length" class="coorperList">
            <div style="width: 290px;">
              <img src="/img/freelive.png" alt="">
            </div>
            <div class="side-content">
              <div v-for="item in archiveList">
                live-item-other(:data="item")
              </div>
              <div v-if="isShowMore()" class="show-more">
                <a><b @click="onShowMore()" style="color:black">>> 続きを表示 </b></a>
              </div>
            </div>
              
          </div>

        div(v-if="ui.tab=='rank'" class="rightContainer")
          <div class="pink-label">貢献度ランキング</div>
          div(class="right-content-border")
            button(@click="changeRankTab()" class="close-button close-button-a") X
            div(class="accountList")
              div(v-for="(item, index) in rankList" class="accountList-item")
                  div(class="container")
                    div(class="img-thumbanail" @click="onAvatarClick(item.profile.id)")
                      <pan-thumb :avatar="item.profile.avatar_store_path" :attribute="item.profile.attribute" :current_title="item.profile.profileable.current_title"   size="70px"/>
                    div(v-if="index < 3" class="topleft")
                      img(:src="index == 0 ? '/img/badge_gold.png' : index == 1 ? '/img/badge_silver.png' : index == 2 ? '/img/badge_copper.png' : ''")
                      span(class="rank-badge") {{index + 1}}

                  p(class="rank-name")
                    span(aria-hidden="true") {{ item.profile.account_name }}
                  div(class="account-point")
                    div(class="point-icon")
                    <img src="/img/P_pink_icon.svg" class="P_icon">
                    span {{ Number(item.rank_point).toLocaleString() }}
                  div(class="rank-button")
                    button(class="gray-button" v-if="item.is_following && isNotSelf(item.profile.id)"  @click="onFollowCancelRank(item.profile.id)")
                    button(class="pink-button" v-else-if="isNotSelf(item.profile.id)"  @click="onFollowRank(item.profile.id)")
            <div v-if="rankingShowMore" class="show-more">
              <a><b @click="putOtherRankingUser()" style="color:##0099FF">>> もっと見る </b></a>
            </div>
        div(v-if="ui.tab=='follower'" class="rightContainer")
          <div class="pink-label">フォロワー</div>
          div(class="right-content-border")
            button(@click="changeFollowerTab()" class="close-button close-button-a") X
            div(class="accountList")
              div(v-for="(item, index) in followerList" class="accountList-item")
                  div(class="container")
                    div(class="img-thumbanail" @click="onAvatarClick(item.id)")
                      <pan-thumb :avatar="item.avatar_store_path" :attribute="item.attribute" :current_title="item.profileable.current_title" :isStreamer="isStreamer(item)" size="70px"/>
                  p(class="rank-name")
                    span(aria-hidden="true") {{ item.account_name }}
                  div(class="rank-button")
                    button(class="gray-button" v-if="item.is_following && isNotSelf(item.id)"  @click="onFollowCancel(item.id)")
                    button(class="pink-button" v-else-if="isNotSelf(item.id)"  @click="onFollow(item.id)")
            <div v-if="followerShowMore" class="show-more">
              <a><b @click="getMoreFollower()" style="color:##0099FF">>> もっと見る </b></a>
            </div>
        // フォローニュース
        div(v-if="ui.tab=='news'" class="right-content-border")
          button(@click="onClose()" class="close-button build-new-room-content-close") X
          div(class="select-news-info")
            div {{ oneNewsInfo.public_time | moment("YYYY/MM/DD HH:mm") }}
            div {{ oneNewsInfo.title }}
          <div class="select-news-img">
            <img v-if="oneNewsInfo.banner_store_path" :src="getBannerUrl(oneNewsInfo.banner_store_path)" class="rounded img-list" alt="">
          </div>
          div(class="popup-content" v-html="oneNewsInfo.describe")

        // 予約配信
        div(v-if="ui.tab=='reserves'" class="right-content-border")
          div(v-if="is_reserved")
            button(@click="onClose()" class="close-button build-new-room-content-close") X
            div 下記配信をキャンセルしますか？
            br
            div(class="select-news-info")
              div {{ oneReservesInfo.date | moment("YYYY/MM/DD HH:mm") }}
              div {{ oneReservesInfo.title }}
            <div class="select-news-img">
              <img v-if="oneReservesInfo.banner" :src="getBannerUrl(oneReservesInfo.banner)" class="rounded img-list" alt="">
            </div>
            div(class="prof-change-confirm-button")
              img(@click="onDestroyScheduleLive(oneReservesInfo.live_id)" class="pointer" src="/img/button/yes.png")
              img(@click="onClose()" class="pointer" src="/img/button/no.png")
          div(v-else)
            button(@click="onClose()" class="close-button build-new-room-content-close") X
            div 下記配信を予約しますか？
            br
            div(class="select-news-info")
              div {{ oneReservesInfo.date | moment("YYYY/MM/DD HH:mm") }}
              div {{ oneReservesInfo.title }}
            <div class="select-news-img">
              <img v-if="oneReservesInfo.banner" :src="getBannerUrl(oneReservesInfo.banner)" class="rounded img-list" alt="">
            </div>
            div(class="prof-change-confirm-button")
              img(@click="onSetScheduleLive(oneReservesInfo.live_id)" src="/img/button/yes.png")
              img(@click="onClose()" class="pointer" src="/img/button/no.png")

        div(v-if="ui.tab=='profile'" class="pos-r")
          component-profile-index(:profile_id="otherProfile_id" @close="onClose")
        PaidBuyPanel(ref="PaidBuyPanel" :showPanel="paidBuyPanelShow" @showPanelChange="visibleChange")

    </div>
    div(v-if="isDevelop()")
      p デバッグ
      span {{ `streamer_id: ${ streamer_id }` }}
      br/
      span {{ `profile_id: ${ profile_id }` }}

    component-footer-index
</template>

<style src="./Style2.scss" lang="scss" scoped>
</style>

<script>
import { getProfile, getFollowers, getFollowings, addFollowing, removeFollowers, getRankList, getArchiveLive, getListArchiveLive, getListPayArchiveLive, getStreamingLiveByProfileId, getQuestionnaire, setQuestionnaire} from '@/api/profile'
import { setScheduleLive, showScheduleLive, destroyScheduleLive, getReservableLive } from '@/api/live'
import { getImageUrl } from '@/utils/tools'
import PanThumb from '@/components/PanThumb'
import ShareLink from '@/components/ShareLink'
import LiveItemOther from './LiveItemOther'
import LiveScheduleItem from './LiveScheduleItem'
import NewsItem from './NewsItem'
import moment from 'moment'
import PaidBuyPanel from '@/components/PaidBuyPanel'
import { isDevelop } from '@/utils/tools'

export default {
  name: "LoginProfileStyle2",
  components: {
    PanThumb,
    ShareLink,
    LiveItemOther,
    LiveScheduleItem,
    PaidBuyPanel,
    NewsItem
  },
  data() {
    return {
      ui: {
        tab: "",
        backTab: "",
      },
      share: '',
      shareText: '',
      shareUrl: '',
      profile_id:0,
      streamer_id:0,
      cols:2,
      data: {},
      rankList: [],
      otherRankList: [],
      otherProfile_id: '',
      payArchiveList:[],
      archiveList:[],
      archiveListData:[],
      streamingLiveList: [],
      futureLive: [],
      reservableLiveList:[],
      showReservableLiveList: [],
      newsList: [],
      showNewsList: [],
      surveyData:{},
      answer:0,
      defaultRoomBanner:'/img/titletest.png',
      listQuery: {
        page: 1,
        limit: 9,
      },
      followerPagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 1,
      },
      archiveListQuery: {
        page: 1,
        limit: 5,
      },
      archivePayListQuery: {
        page: 1,
        limit: 2,
      },
      archivePagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 1,
      },
      archivePayPagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 1,
      },
      oneReservesInfo: {},
      oneNewsInfo: {},
      followerList: [],
      is_reserved: false,
      answerindex: '',
      nonactivation : false,
      answerbutton: false,
      paidBuyPanelShow: false,
      visibleLeft: true,
    }
  },
  props: {},
  computed: {
    isStreamering(){
      return this.streamingLiveList.length > 0
    },
    reserveListColumns () {
      let columns = []
      let col = 0;
      for(let i = 0 ; i < this.showReservableLiveList.length ; i++){
        col = i % this.cols;
        if(columns[col] == undefined){
          columns[col] = []
        }
        columns[col].push(this.showReservableLiveList[i])
      }
      return columns
    },
    reserveShowMoreButton() {
      return this.showReservableLiveList.length < this.reservableLiveList.length
    },
    newsShowMoreButton() {
      return this.showNewsList.length < this.newsList.length
    },
    keysFollowing() {
      return this.$store.state.user.keysFollowing;
    },
    rankingShowMore() {
      return this.otherRankList.length > 0 && this.rankList.length < 13
    },
    followerShowMore() {
      return this.followerPagination.current_page < this.followerPagination.total_pages
    },
  },
  watch: {
    '$route' (to) {
      this.init(to.params.id)
    }
  },
  mounted() {},
  async created() {
    this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    isDevelop() {
      return isDevelop();
    },
    isNotSelf(id){
      return this.$store.state.user.profile_id !== id
    },
    isStreamer(item){
      return item.profileable_type === 'STREAMER';
    },
    getCurrentTitle(data){
      if (data.profileable) {
        return data.profileable.current_title;
      }
      return '';
    },
    formatTime(date) {
      return moment(date, "YYYYMMDDHHmmss").format('YYYY/MM/DD HH:mm');
    },
    async getFollow() {
      const ret1 = (await getFollowings(this.$store.state.user.profile_id,this.listQuery)).data;
      let listId = ret1.meta.following_id_list;
      await this.$store.dispatch("user/setFollowingId",listId);
    },
    async getProfileData() {
      const { data } = await getProfile(this.profile_id);
      if (data.status === 'private') {
        this.$message.error('このタレントのアカウントは非表示になっています。')
        return;
      }
      this.data = data;
    },
    async getFollowerListData() {
      this.listQuery.page = 1
      const retFollower = await getFollowers(this.profile_id,this.listQuery);
      this.followerList = retFollower.data.data;
      this.followerPagination = retFollower.data.meta.pagination
    },

    isShowMore() {
      if(this.archivePagination.total > this.archiveList.length) {
        return true;
      }
      return false;
    },
    async onShowMore(){
      this.archivePagination.current_page += 1
      this.streamer_id = this.data.profileable.streamer_id
      const ret = await getListArchiveLive(this.streamer_id, this.archiveListQuery.limit, this.archivePagination.current_page);
      if(ret.data.data){
        this.archiveListData = ret.data.data.map(liveArchive=>{
          return this.convertArchiveLive(liveArchive);
        }).filter(e => e)
        this.archiveListData?.forEach((element) => {
          this.archiveList.push(element);
        })
      }
    },
    isPayShowMore(){
      if(this.archivePayPagination.total > this.payArchiveList.length) {
        return true;
      }
      return false;
    },
    async onPayShowMore(){
      this.archivePayPagination.current_page += 1
      this.streamer_id = this.data.profileable.streamer_id
      const ret = await getListPayArchiveLive(this.streamer_id, this.archivePayListQuery.limit, this.archivePayPagination.current_page);
      if(ret.data.data){
        this.archiveListData = ret.data.data.map(liveArchive=>{
          return this.convertPayArchiveLive(liveArchive);
        }).filter(e => e)
        this.archiveListData?.forEach((element) => {
          this.payArchiveList.push(element);
        })
      }
    },

    async init() {
      this.showReservableLiveList = [];
      this.showNewsList = [];

      const profile_id = this.$route.params.profile_id;
      this.profile_id = profile_id;
      await this.getProfileData()
      // 拡散テキスト
      this.shareText = this.data.account_name + "\n";
      this.shareUrl = location.href;
      this.newsList = this.data.follow_news;
      this.$nextTick(function() {
        this.share = this.$refs.shareLink.createSnsUrl();
      });
      this.newsList?.forEach((element,index) => {
        if (index < 2) {
          this.showNewsList.push(element);
        }
      })

      this.getFollow()
      const streamer_id = this.data.profileable ? this.data.profileable.streamer_id : '';
      if (!streamer_id) {
        return;
      }
      this.streamer_id = streamer_id;

      const retRankList = await getRankList(streamer_id)
      const rankList = []
      const otherRankList = []
      retRankList.data.data.forEach((element, index) => {
        if (index < 12) {
          rankList.push(element)
        }else {
          otherRankList.push(element)
        }
      });
      this.rankList = rankList;
      this.otherRankList = otherRankList;

      // this.rankList = (await getRankList(streamer_id)).data.data;
      const ret2 = await getArchiveLive(streamer_id, this.archiveListQuery.limit, this.archivePayListQuery.limit);
      const arhiveList = ret2.data.archive;
      const payArchiveList = ret2.data.pay_archive;
      if( arhiveList.meta ){
        this.archivePagination = arhiveList.meta.pagination
      }
      if( arhiveList.data ){
        this.archiveList = arhiveList.data.map(liveArchive=>{
          return this.convertArchiveLive(liveArchive);
        }).filter(e => e)
      }
      if( payArchiveList.meta ){
        this.archivePayPagination = payArchiveList.meta.pagination
      }
      if( payArchiveList.data ){
        this.payArchiveList = payArchiveList.data.map(liveArchive=>{
          return this.convertPayArchiveLive(liveArchive);
        }).filter(e => e)
      }

      if (this.$route.params.rankflag) {
        this.ui.tab = "rank";
      } else {
        this.ui.tab = "delivery"
      }
      const ret3 = await getStreamingLiveByProfileId(profile_id)
      this.streamingLiveList = ret3.data.data.map(live=>{
        return {
          streamer:live.profile ? live.profile.account_name : 'profileがない',
          title:live.room_info ? live.room_info.name : 'room_infoがない',
          status:live.status ? live.status : this.$store.getters.enum_list.live_status_list.SCHEDULE,
          banner:live.room_info ? live.room_info.banner_store_path:'',
          date:live.started_at  ? this.formatTime(live.started_at) :'日時情報がない',
          type:live.liveable_type ? live.liveable_type:'NORMAL',
          profile_id:live.profile_id,
          liveable_id:live.liveable_id,
          live_id:live.id,
          isArchive:false
        }
      })
      this.onReservableLive(streamer_id);

      const ret6 = await getQuestionnaire(streamer_id)
      this.surveyData = ret6.data
      if(this.surveyData.answer !== null){
        this.answerbutton = true
        this.nonactivation = true
      }
    },
    convertArchiveLive(liveArchive){
      const live = liveArchive.live;
      return {
        streamer:live.profile ? live.profile.account_name : 'profileがない',
        title:live.room_info ? live.room_info.name : 'room_infoがない',
        status:live.status ? live.status : this.$store.getters.enum_list.live_status_list.SCHEDULE,
        banner:live.room_info ? live.room_info.banner_store_path:'',
        date:live.started_at  ? this.formatTime(live.started_at) :'日時情報がない',
        type:live.liveable_type ? live.liveable_type:'NORMAL',
        profile_id:live.profile_id,
        liveable_id:live.liveable_id,
        live_id:live.id,
        isArchive:true
      }
    },
    convertPayArchiveLive(liveArchive){
      const live = liveArchive.live;
      return {
        streamer:live.profile ? live.profile.account_name : 'profileがない',
        title:live.room_info ? live.room_info.name : 'room_infoがない',
        status:live.status ? live.status : this.$store.getters.enum_list.live_status_list.SCHEDULE,
        banner:live.room_info ? live.room_info.banner_store_path:'',
        date:live.expire_time  ? this.formatTime(live.expire_time) : '日時情報がない',
        type:live.liveable_type ? live.liveable_type:'NORMAL',
        profile_id:live.profile_id,
        liveable_id:live.liveable_id,
        live_id:live.id,
        isArchive:true
      }
    },
    async onFollowRank(profile_id) {
      try {
        await addFollowing(profile_id);
        this.rankList.forEach((rank) => {
          if (rank.profile.id === profile_id) {
            rank.is_following = true;
          }
        })
      } catch(e) {
        return false;
      }
    },
    async onFollowCancelRank(profile_id) {
      try {
        await removeFollowers(profile_id);
        this.rankList.forEach((rank) => {
          if (rank.profile.id === profile_id) {
            rank.is_following = false;
          }
        })
      } catch(e) {
        return false;
      }
    },
    async onFollow(profile_id) {
      try {
        await addFollowing(profile_id);
        this.followerList.forEach((follower) => {
          if (follower.id === profile_id) {
            follower.is_following = true;
          }
        })
      } catch(e) {
        return false;
      }
    },
    async onFollowCancel(profile_id) {
      try {
        await removeFollowers(profile_id);
        this.followerList.forEach((follower) => {
          if (follower.id === profile_id) {
            follower.is_following = false;
          }
        })
      } catch(e) {
        return false;
      }
    },
    async onStreamerFollow() {
      await addFollowing(this.data.id);
      this.data.is_following = true;
      this.getProfileData()
      if(this.ui.tab == "follower") {
        await this.getFollowerListData()
      }
    },
    async onStreamerFollowCancel() {
      await removeFollowers(this.data.id);
      this.data.is_following = true;
      this.getProfileData()
      if(this.ui.tab == "follower") {
        await this.getFollowerListData()
      }
    },
    async onReservableLive(streamer_id) {
      const ret = await getReservableLive(streamer_id)
      for(let i = 0; i < ret.data.data.length; i++){
        if(ret.data.data[i].liveable_type == "NORMAL" ){
          this.futureLive[i] = ret.data.data[i]
        } else if (ret.data.data[i].public_time <= moment().format("YYYY-MM-DD HH:mm:ss")){
          this.futureLive[i] = ret.data.data[i]
        }
      }
      if(this.futureLive){
        this.reservableLiveList = this.futureLive.map(live => {
          if (live.schedule_start_time) {
            return {
              streamer:live.profile ? live.profile.account_name : 'profileがない',
              title:live.room_info ? live.room_info.name : 'room_infoがない',
              status:live.status ? live.status : this.$store.getters.enum_list.live_status_list.SCHEDULE,
              banner:live.room_info ? live.room_info.banner_store_path:'',
              date:live.schedule_start_time  ? this.formatTime(live.schedule_start_time) : '日時情報がない',
              type:live.liveable_type ? live.liveable_type:'NORMAL',
              profile_id:live.profile_id,
              liveable_id:live.liveable_id,
              live_id:live.id,
              isArchive: false
            }
          }
        }).filter(e => e)
        this.reservableLiveList?.forEach((element,index) => {
        if (index < 2) {
          this.showReservableLiveList.push(element);
        }
      })
      }
    },
    changeRankTab() {
      this.ui.tab = this.ui.tab == "rank" ? "delivery" : "rank";
      if(this.ui.tab == "rank"){
        this.ui.backTab = "rank";
      }
    },
    async changeFollowerTab() {
      if(this.ui.tab == "follower") {
        this.ui.tab = "delivery"
        return
      }
      await this.getFollowerListData()
      this.ui.tab = "follower";
      this.ui.backTab = "follower"
    },
    onAvatarClick(profile_id) {
      this.otherProfile_id = profile_id;
      this.ui.tab = "profile";
    },
    onClose() {
      if(this.ui.backTab === "follower") {
        this.ui.tab = "follower"
      } else if (this.ui.tab === 'reserves' || this.ui.tab === 'news') {
        this.ui.tab = "delivery"
      } else {
        this.syncRankList()
        this.ui.tab = "rank"
      }
    },
    async syncRankList(){
      const retRankList = await getRankList(this.streamer_id)
      const rankList = []
      const otherRankList = []
      retRankList.data.data.forEach((element, index) => {
        if (index < 12) {
          rankList.push(element)
        }else {
          otherRankList.push(element)
        }
      });
      this.rankList = rankList;
      this.otherRankList = otherRankList;
    },
    onArchiveClick(id) {
      let liveType = "";
      liveType = "ArchiveLiveNormal";
      this.$router.push({ name: liveType, params: { id } });
    },
    onAnswerChange(index){
      this.answerindex = index.target.value
    },
    async onAnswerClick() {
      this.answerbutton = true
      this.nonactivation = true
      await setQuestionnaire(this.surveyData.streamer_id , this.answer)
    },
    async onReserveDetails(item) {
      // クラファンは詳細へ
      if ( item.type == "FUND" ){
          this.$router.push({
          name: 'TopFundDetail',
          params: { liveable_id: item.liveable_id },
        });
        return;
      }
      if ( item.type === "PAY" ) {
        // 有料配信
        await this.$store.dispatch('live/syncLiveInfo', item.live_id).then(() => {
          this.$store.dispatch('live/syncLiveTicketInfo', item.liveable_id).then(()=>{
            // 購入状態に関わらずポップアップ
            // if(!this.$store.state.live.info.is_buy_pay)
            this.paidBuyPanelShow = true;
            this.ui.tab = 'pay';
            return;
          });
        }).catch((error)=>{
          console.error(error);
        });
        return;
      }
      // 購入ポップアップだけemitの関係で1フレーム遅らせる
      if( this.paidBuyPanelShow ){
        this.paidBuyPanelShow  = false;
        const _this = this;
        this.$nextTick( () =>{
          _this.ui.tab = 'reserves';
        });
      } else {
        this.ui.tab = 'reserves'
      }
      this.oneReservesInfo = item
      const { data } = await showScheduleLive(item.live_id);
      if (data && data.id) {
        this.is_reserved = true
      }
    },
    async onSetScheduleLive(live_id) {
      await setScheduleLive(live_id)
      this.onClose()
    },
    async onDestroyScheduleLive(live_id) {
      await destroyScheduleLive(live_id)
      this.onClose()
    },
    async onNewsDetails(item) {
      this.oneNewsInfo = item
      this.ui.tab = 'news'
      await this.$store.dispatch('news/readFollowNews',item.id)
      this.$store.dispatch('news/updateFollowReadedIdList',item.id)
    },
    ReserveShowMore() {
      const lengthNow = this.showReservableLiveList.length
      this.reservableLiveList.forEach((element,index) => {
        if (index > (lengthNow - 1) && index < (lengthNow + 2)) {
          this.showReservableLiveList.push(element);
        }
      });
    },
    NewsShowMore() {
      const lengthNow = this.showNewsList.length
      this.newsList.forEach((element,index) => {
        if (index > (lengthNow - 1) && index < (lengthNow + 2)) {
          this.showNewsList.push(element);
        }
      });
    },
    kakusan(link){
      window.open(link,'_blank');
    },
    onSNS(link) {
      window.open(link,'_blank');
    },
    getBannerUrl(url){
      return getImageUrl(url)
    },
    //プロフィール文のボックスを可変にする関数
    calcIntroductionAreaHeight(){
      //規定の高さ
      const baseHeight = 100;
      const criteriaNum = 4;
      let newLineCounts = 0;
      let heightToBeAdded = 0;

      // --改行が存在した場合、その分だけ高さを伸ばす
      const newLines = (this.$store.state.user.profile_introduction.split(/\r?\n/g) || []);
      //分割した文字列のそれぞれの長さが21文字以上か調べる。そうして折り返した回数を調べる。
      newLines.forEach((str)=>{newLineCounts += Math.floor(str.length / 32) });

      //純粋な改行数も加算する。
      newLineCounts += newLines.length;

      //基準の回数より多くの改行数があった場合は、その分だけ高さを加算する。
      if(newLineCounts > criteriaNum){
        heightToBeAdded = (newLineCounts - criteriaNum) * 24;
      }

      return baseHeight + heightToBeAdded + 'px';
    },
    putOtherRankingUser() {
      this.otherRankList.forEach(element=>{
        this.rankList.push(element);
      })
    },
    async getMoreFollower() {
      this.listQuery.page = this.listQuery.page + 1
      const retFollow = await getFollowers(this.profile_id,this.listQuery);
      retFollow.data.data.forEach(element => {
        this.followerList.push(element)
      });
      this.followerPagination = retFollow.data.meta.pagination
    },
    visibleChange(value) {
      this.paidBuyPanelShow = value;
      if( !value ){
        this.ui.tab = 'delivery';
      }
    },
    async payLiveIntercept(liveItemOther){
      // 有料配信だけ割り込む
      if( !liveItemOther.isPay ) {
        liveItemOther.onClickBanner();
        return;
      }
      let _this = this;
      await this.$store.dispatch('live/syncLiveInfo', liveItemOther.data.live_id).then(() => {
        this.$store.dispatch('live/syncLiveTicketInfo', liveItemOther.data.liveable_id).then(()=>{
          // 購入済み且つ配信中は配信視聴画面へ遷移
          if( _this.$store.state.live.info.is_buy_pay &&
            _this.$store.getters.enum_list.live_status_list.ONGOING == _this.$store.state.live.info.status ){
            let routeData = _this.$router.resolve({
              name: "TopLivePaid",
              params: { id: _this.$store.state.live.info.id },
            });
            window.open(routeData.href, '_blank');
            return;
          }
          _this.paidBuyPanelShow = true;
          _this.ui.tab = 'pay';
          return;
        });
      });
    },
  }
};
</script>
