import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/auth',
        method: 'post',
        data
    })
}

export function refresh() {
    return request({
        url: '/auth',
        method: 'put'
    })
}

export function getInfoFromAuth() {
    return request({
        url: '/auth',
        method: 'get'
    })
}

export function logout() {
    return request({
        url: '/auth',
        method: 'delete'
    })
}

export function registerByEmail(email, key) {
    return request({
        url: '/users',
        method: 'post',
        data: {
            email,
            'g-recaptcha-response': key
        }
    })
}

export function resetByEmail(email) {
    return request({
        url: `/users/${email}/edit`,
        method: 'get'
    })
}

export function reset(password, query) {
    return request({
        url: `/users/${query.id}?token=${query.token}&id=${query.id}&expire_time=${query.expire_time}`,
        method: 'put',
        data: {
            password
        }
    })
}

export function initUserinfo(form, query) {
    return request({
        url: `/user_infos?token=${query.token}&id=${query.id}&expire_time=${query.expire_time}`,
        method: 'post',
        data: form
    })
}

export function validateToken(query) {
    return request({
        url: `/user_infos/check?token=${query.token}&id=${query.id}&expire_time=${query.expire_time}`,
        method: 'post',
        data: undefined
    })
}

export function getUserinfoById(id) {
    return request({
        url: `/user_infos/${id}`,
        method: 'get',
    })
}

export function setUserInfo(data) {
    if(!data.profile.background_store_path) {
        delete data.profile.background_store_path;
    }
    if(!data.profile.avatar_store_path){
        delete data.profile.avatar_store_path;
    }
    return request({
        url: `/user_info`,
        method: 'put',
        data,
    })
}


export function uploadBackground(form) {
    return request.post('background', form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function uploadAvatar(form) {
    return request.post('avatar', form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function setPassword(pwd) {
    return request({
        url: `user/password`,
        method: 'put',
        data: {
            "new_password": pwd,
        }
    })
}

export function sendEmailCode(email) {
    return request({
        url: `user/email/${email}`,
        method: 'get'
    })
}

export function setEmail(email) {
    return request({
        url: `user/email`,
        method: 'put',
        data: {
            "email": email,
        }
    })
}

export function deleteUser() {
    return request({
        url: `/users`,
        method: 'delete'
    })
}

export function getLoginBonusInfo() {
    return request({
        url: 'users/check',
        method: 'get'
    })
}
