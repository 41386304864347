<template lang="pug">
  .root
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "RedirectIndex",
  components: {
  },
  data() {
    return {
      ui: {
      },
      link: undefined,
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    const encodedLink = this.$route.params.link;
    try{
      this.link = decodeURI(encodedLink)
    } catch(error) {
      console.log(error)
    }
    if( !this.link ){
      this.$router.push({
        name: 'TopIndex'
      })
      return;
    }
    window.location.href = this.link
  },
  updated() {},
  destroyed() {},
  methods: {
  }
};
</script>
