<template lang="pug">
  .root
    ArchiveBase(:liveType="liveType")

</template>

<style src="./ArchiveEvent.scss" lang="scss" scoped>

</style>

<script>
import ArchiveBase from './components/ArchiveBase'
import {LIVE_TYPE} from './helper/def'

export default {
  name: "ArchiveLiveEvent",
  components: {
    ArchiveBase
  },
  data() {
    return {
      liveType: LIVE_TYPE.EVENT
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  async destroyed() {},
  methods: {}
};
</script>
