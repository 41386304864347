import request from '@/utils/request'

export function getViewHistory(){
  return request({
    url: `viewer_histories`,
    method: 'get'
  })
}

export function getViewHistoryList(query){
  return request({
    url: `viewer_histories/list`,
    method: 'get',
    params: query
  })
}

export function getPayArchiveList(limit,page) {
  return request({
    url: `viewer_histories/pay_list?limit=${limit}&page=${page}`,
    method: 'get',
  })
}
