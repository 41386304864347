
export default class {

  // audio instance map
  // this.audioInstMap[]
  // audio path map
  // this.pathMap[]

  // constructor
  constructor(arg) {
    this.arg = arg;
    this.audioInstMap = {};
    this.pathMap = {};
  }

  // パス登録
  InitPath(pathMap) {
    this.pathMap = pathMap;
  }

  // 一括読み込み
  LoadAll( ){
    // 全ての要素をループしパスのリソースをロードする
    for (const key in this.pathMap) {
      const path = this.pathMap[key];
      const tempAudio = new Audio();
      tempAudio.src = path;
      tempAudio.load();
      this.audioInstMap[key] = tempAudio;
    }
  }

  // 再生
  Play(key) {
    if( !this.audioInstMap[key] ) {
      return;
    }
    if( this.audioInstMap[key].paused ){
      this.audioInstMap[key].play();
    }
    else{
      this.audioInstMap[key].currentTime = 0;
    }
  }
}