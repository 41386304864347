<template lang="pug">
  .root
    <div class="liveStatus">
      <li v-if="isStreaming"><img class="isStreaming" src="/img/haishinchu.gif"></li>
      <li v-if="isPay"><img src="/img/yuryo.png"></li>
      <li v-if="isFund"><img src="/img/crfun.png"></li>
    </div>
    div(class="side-content")
      div(class="side-img")
        <img @click="interceptClick" :src="roomBanner">
      div(class="side-message")
        <div class="side-text-box"><b class="side-text-title"> {{roomName}} </b></div>
        <div class="side-text-box"><b class="side-text-date"> {{roomPeriod  | moment("YYYY/MM/DD HH:mm") }} </b>
        </div>
</template>

<script>
import ListItemComputed from './component/ListItemComputed'
import ListItemMethod from './component/ListItemMethod'

export default {
  name: 'LiveItemOther',
  mixins: [ListItemComputed, ListItemMethod],
  props: {
    data:{
      type:Object,
      required:true
    },
    interceptClickFunc:{
      type: Function,
      default: null,
    }
  },
  created(){
  },
  methods: {
    onStreamerNameClick() {
      const profile_id = this.data.profile_id
      this.$router.push({ name: 'TopProfileStyle2', params: { profile_id } });
    },
    interceptClick(){
      if( this.interceptClickFunc )
      {
        this.interceptClickFunc(this);
        return;
      }
      this.onClickBanner();
    }
  },
}
</script>

<style src="./LiveItemOther.scss" lang="scss" scoped>
</style>
