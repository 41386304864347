import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'


import { getToken } from '@/utils/auth'
import jwt_decode from "jwt-decode";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    console.log(err)
  })
}

Vue.use(VueRouter);

const routes = [
  // Home
  {
    path: "/",
    name: "Home",
    redirect: { name: "LoginIndex" },
  },

  // Login
  {
    path: "/login/index",
    name: "LoginIndex",
    component: () => import("@/views/Login/Index.vue"),
  },
  // LoginResetIndex
  {
    path: "/login/reset/index",
    name: "LoginResetIndex",
    component: () => import("@/views/Login/Reset/Index.vue"),
  },
  // LoginResetNext
  {
    path: "/login/reset/next",
    name: "LoginResetNext",
    component: () => import("@/views/Login/Reset/Next.vue"),
  },
  // LoginResetBack
  {
    path: "/login/reset/back",
    name: "LoginResetBack",
    component: () => import("@/views/Login/Reset/Back.vue"),
  },
  // LoginResetEmail
  {
    path: "/login/reset/email",
    name: "LoginResetEmail",
    component: () => import("@/views/Login/Reset/Email.vue"),
  },
  // LoginResetSuccess
  {
    path: "/login/reset/success",
    name: "LoginResetSuccess",
    component: () => import("@/views/Login/Reset/Success.vue"),
  },
  // LoginRegisterIndex
  {
    path: "/login/register/index",
    name: "LoginRegisterIndex",
    component: () => import("@/views/Login/Register/Index.vue"),
  },
  // LoginRegisterSuccess
  {
    path: "/login/register/success",
    name: "LoginRegisterSuccess",
    component: () => import("@/views/Login/Register/Success.vue"),
  },
  // LoginContactIndex
  {
    path: "/login/contact/index",
    name: "LoginContactIndex",
    component: () => import("@/views/Login/Contact/Index.vue"),
  },
  // LoginInitIndex
  {
    path: "/login/init/index",
    name: "LoginInitIndex",
    component: () => import("@/views/Login/Init/Index.vue"),
  },
  // LoginInitNext
  {
    path: "/login/init/next",
    name: "LoginInitNext",
    component: () => import("@/views/Login/Init/Next.vue"),
  },
  // LoginInitSelect
  {
    path: "/login/init/select",
    name: "LoginInitSelect",
    component: () => import("@/views/Login/Init/Select.vue"),
  },

  // TopIndex
  {
    path: "/top/index",
    name: "TopIndex",
    component: () => import("@/views/Top/Top/Index.vue"),
  },
  
  // TopHotIndex
  {
    path: "/top/hot/index",
    name: "TopHotIndex",
    component: () => import("@/views/Top/Hot/Index.vue"),
  },

  // TopNewIndex
  {
    path: "/top/new/index",
    name: "TopNewIndex",
    component: () => import("@/views/Top/New/Index.vue"),
  },

  // TopEventIndex
  {
    path: "/top/event/index",
    name: "TopEventIndex",
    component: () => import("@/views/Top/Event/Index.vue"),
  },

  // TopEventDetail
  {
    path: "/top/event/detail/:liveable_id",
    name: "TopEventDetail",
    component: () => import("@/views/Top/Event/Detail.vue"),
  },

  // TopFundIndex
  {
    path: "/top/fund/index",
    name: "TopFundIndex",
    component: () => import("@/views/Top/Fund/Index.vue"),
  },
  // TopFundDetail
  {
    path: "/top/fund/detail/:liveable_id",
    name: "TopFundDetail",
    component: () => import("@/views/Top/Fund/Detail.vue"),
  },

  // TopProfileIndex
  {
    path: "/top/profile/index",
    name: "TopProfileIndex",
    component: () => import("@/views/Top/Profile/Index.vue"),
  },
  // TopProfileViewerProfile
  {
    path: "/top/profile/ViewerProfile/:profile_id",
    name: "TopProfileViewerProfile",
    component: () => import("@/views/Top/Profile/ViewerProfile.vue"),
  },
  // TopProfileStyle2
  {
    path: "/top/profile/style2/:profile_id",
    name: "TopProfileStyle2",
    component: () => import("@/views/Top/Profile/Style2.vue"),
  },
  // TopProfileBuy
  {
    path: "/top/profile/buy",
    name: "TopProfileBuy",
    component: () => import("@/views/Top/Profile/Buy.vue"),
  },
  // TopProfileSelectPayment
  {
    path: "/top/profile/selectpayment",
    name: "TopProfileSelectPayment",
    component: () => import("@/views/Top/Profile/SelectPayment.vue"),
  },
  // TopProfilePaymentFormCVS
  {
    path: "/top/profile/paymentformcvs",
    name: "TopProfilePaymentFormCVS",
    component: () => import("@/views/Top/Profile/PaymentFormCVS.vue"),
  },
  // TopProfilePaymentFormCVSConfirm
  {
    path: "/top/profile/paymentformcvs-confirm",
    name: "TopProfilePaymentFormCVSConfirm",
    component: () => import("@/views/Top/Profile/PaymentFormCVSConfirm.vue"),
  },
  // TopProfilePaymentFormCVSResult
  {
    path: "/top/profile/paymentformcvsresult",
    name: "TopProfilePaymentFormCVSResult",
    component: () => import("@/views/Top/Profile/PaymentFormCVSResult.vue"),
  },
  // TopProfilePaymentFormPayeasy
  {
    path: "/top/profile/paymentformpayeasy",
    name: "TopProfilePaymentFormPayeasy",
    component: () => import("@/views/Top/Profile/PaymentFormPayeasy.vue"),
  },
  // TopProfilePaymentFormPayeasyConfirm
  {
    path: "/top/profile/paymentformPayeasy-confirm",
    name: "TopProfilePaymentFormPayeasyConfirm",
    component: () => import("@/views/Top/Profile/PaymentFormPayeasyConfirm.vue"),
  },
  // TopProfilePaymentFormPayeasyResult
  {
    path: "/top/profile/paymentformPayeasyresult",
    name: "TopProfilePaymentFormPayeasyResult",
    component: () => import("@/views/Top/Profile/PaymentFormPayeasyResult.vue"),
  },
  // TopProfilePaymentFormStripe
  {
    path: "/top/profile/paymentformStripe",
    name: "TopProfilePaymentFormStripe",
    component: () => import("@/views/Top/Profile/PaymentFormStripe.vue"),
  },
  // TopProfilePaymentFormStripeResult
  {
    path: "/top/profile/paymentformStripeResult",
    name: "TopProfilePaymentFormStripeResult",
    component: () => import("@/views/Top/Profile/PaymentFormStripeResult.vue"),
  },
  // TopProfilePaymentLoading
  {
    path: "/top/profile/paymentLoading",
    name: "TopProfilePaymentLoading",
    component: () => import("@/views/Top/Profile/PaymentLoading.vue"),
  },
  // TopProfilePaymentLoadingResult
  {
    path: "/top/profile/paymentLoadingResult",
    name: "TopProfilePaymentLoadingResult",
    component: () => import("@/views/Top/Profile/PaymentLoadingResult.vue"),
  },
  // TopProfileBuyHistory
  {
    path: "/top/profile/buyhistory",
    name: "TopProfileBuyHistory",
    component: () => import("@/views/Top/Profile/BuyHistory.vue"),
  },
  // TopProfileChangePassword
  {
    path: "/top/profile/changePassword",
    name: "TopProfileChangePassword",
    component: () => import("@/views/Top/Profile/ChangePassword.vue"),
  },
  // TopProfileChangeMail
  {
    path: "/top/profile/changeMail",
    name: "TopProfileChangeMail",
    component: () => import("@/views/Top/Profile/ChangeMail.vue"),
  },
  // TopProfileChange
  {
    path: "/top/profile/change",
    name: "TopProfileChange",
    component: () => import("@/views/Top/Profile/Change.vue"),
  },
  // TopProfileFinish
  {
    path: "/top/profile/finish",
    name: "TopProfileFinish",
    component: () => import("@/views/Top/Profile/Finish.vue"),
  },
  // TopProfileFollow
  {
    path: "/top/profile/follow",
    name: "TopProfileFollow",
    component: () => import("@/views/Top/Profile/Follow.vue"),
  },
  // TopProfileAddress
  {
    path: "/top/profile/address",
    name: "TopProfileAddress",
    component: () => import("@/views/Top/Profile/Address.vue"),
  },
  // TopProfileRoom
  {
    path: "/top/profile/room",
    name: "TopProfileRoom",
    component: () => import("@/views/Top/Profile/Room.vue"),
  },
  // TopProfileRoom
  {
    path: "/top/profile/attribute",
    name: "TopProfileAttribute",
    component: () => import("@/views/Top/Profile/Attribute.vue"),
  },
  // TopBroadcastIndex
  {
    path: "/top/broadcast/index",
    name: "TopBroadcastIndex",
    component: () => import("@/views/Top/Broadcast/Index.vue"),
  },
  // TopSearch
  {
    path: "/top/search/index",
    name: "TopSearchIndex",
    component: () => import("@/views/Top/Search/Index.vue"),
  },

  // TopLiveNormal
  {
    path: "/top/live/normal/:id",
    name: "TopLiveNormal",
    component: () => import("@/views/Top/Live/Normal.vue"),
  },

  // TopLiveEvent
  {
    path: "/top/live/event/:id",
    name: "TopLiveEvent",
    component: () => import("@/views/Top/Live/Event.vue"),
  },

  // TopLiveFund
  {
    path: "/top/live/fund/:id",
    name: "TopLiveFund",
    component: () => import("@/views/Top/Live/Fund.vue"),
  },

  // TopLivePaid
  {
    path: "/top/live/paid/:id",
    name: "TopLivePaid",
    component: () => import("@/views/Top/Live/Paid.vue"),
  },

  // TopTermsHowTo
  {
    path: "/top/terms/howto",
    name: "TopTermsHowTo",
    component: () => import("@/views/Top/Terms/HowTo.vue"),
  },

  // TopTermsManual
  {
    path: "/top/terms/manual",
    name: "TopTermsManual",
    component: () => import("@/views/Top/Terms/Manual.vue"),
  },

  // TopTermsPrivacy
  {
    path: "/top/terms/privacy",
    name: "TopTermsPrivacy",
    component: () => import("@/views/Top/Terms/Privacy.vue"),
  },

  // TopTermsTerms
  {
    path: "/top/terms/terms",
    name: "TopTermsTerms",
    component: () => import("@/views/Top/Terms/Terms.vue"),
  },

  // TopTermsFundTerm
  {
    path: "/top/terms/fund-terms",
    name: "TopTermsFundTerms",
    component: () => import("@/views/Top/Terms/FundTerms.vue"),
  },

  // TopTermsPersonalInfo
  {
    path: "/top/terms/personalpnfo",
    name: "TopTermsPersonalInfo",
    component: () => import("@/views/Top/Terms/PersonalInfo.vue"),
  },

  // TopTermsTokushoho
  {
    path: "/top/terms/tokushoho",
    name: "TopTermsTokushoho",
    component: () => import("@/views/Top/Terms/tokushoho.vue"),
  },

  // TopTermsUnregister
  {
    path: "/top/terms/unregister",
    name: "TopTermsUnregister",
    component: () => import("@/views/Top/Terms/Unregister.vue"),
  },
  // ArchiveLiveNormal
  {
    path: "/top/profile/archive/normal/:id",
    name: "ArchiveLiveNormal",
    component: () => import("@/views/Top/Profile/Archive/ArchiveNormal.vue"),
  },

  // ArchiveLiveEvent
  {
    path: "/top/profile/archive/event/:id",
    name: "ArchiveLiveEvent",
    component: () => import("@/views/Top/Profile/Archive/ArchiveEvent.vue"),
  },

  // ArchiveLivePaid
  {
    path: "/top/profile/archive/paid/:id",
    name: "ArchiveLivePaid",
    component: () => import("@/views/Top/Profile/Archive/ArchivePaid.vue"),
  },

  // ArchiveLiveFund
  {
    path: "/top/profile/archive/fund/:id",
    name: "ArchiveLiveFund",
    component: () => import("@/views/Top/Profile/Archive/ArchiveFund.vue"),
  },

  // Refund
  {
    path: "/refund/index",
    name: "RefundIndex",
    component: () => import("@/views/Refund/Index.vue"),
  },

  // Redirect
  {
    path: "/redirect/:link",
    name: "RedirectIndex",
    component: () => import("@/views/Redirect/Index.vue"),
  },
  // TargetView
  {
    path: "/top/live/targetView",
    name: "TargetView",
    component: () => import("@/views/Top/Live/TargetView.vue"),
  },

  { path: "*", redirect: { name: "LoginIndex" }, hidden: true },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

const whiteList = [
  '/login/index',
  '/login/reset/index',
  '/login/reset/next',
  '/login/reset/back',
  '/login/reset/success',
  '/login/register/index',
  '/login/register/success',
  '/login/contact/index',
  '/login/init/index',
  "/top/terms/howto",
  "/top/terms/manual",
  "/top/terms/privacy",
  "/top/terms/terms",
  "/top/terms/fund-terms",
  "/top/terms/tokushoho",
  '/redirect/index',
]

let tokenCheckFlag = false;

router.beforeEach( async (to, from, next) => {

  // determine whether the user has logged in
  const token = getToken()

  if (token) {
    store.commit('user/SET_ID', `${jwt_decode(token).sub}`);
    store.commit('user/SET_MODULE', `${jwt_decode(token).module}`);

    // Refresh SystemInfo
    await store.dispatch('system/syncSystemInfo');
    await store.dispatch('system/syncSystemTime')

    // Refresh UserInfo On First Loading.
    if (!tokenCheckFlag) {
      await store.dispatch('user/refresh');
      // await store.dispatch('profile/syncProfileSelf');
      tokenCheckFlag = true;
    }

    if (to.path === '/login/index') {
      // if is logged in, redirect to the home page
      next({ path: '/top/index' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login/index`)
    }
  }
})

export default router;
