<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(v-if="!changeCreditCard" class="content-paymentformstripe")

        div クレジットカードの確認

        div(class="stripeForm")
          h5 名前
          p {{ cardData.holder_name }}
          h5 カード番号
          p {{ cardData.card_no }}
          h5 有効期限
          p {{ cardData.ex_date }}
          h5 支払い合計金額
          p {{ totalPrice.toLocaleString() }}円

        .buttonArea
          .errorMessageArea
            .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}
          button.pink-button.onNextButton(@click="onPaymentCredit()" v-preventReClick="3000") 購入する
        
        div(class="coin-buy-footer-button")
          button(@click="changeCreditCard = true" class="pink-button" style="margin-bottom:10px") 変更する
        //div ※ここで購入したGはAライブ内の全サービスでご利用いただけます。
        //div >>資金決済法に基づく表示
        //div(@click="onClick1()") >>特定商取引法の表記
        div(class="coin-buy-footer-button")
          button(@click="onBack()" class="pink-button") 戻る
      
      div(v-if="changeCreditCard")
        div(class="right-content-border")
          button(@click="onChangeBack()" class="close-button") <i class="fas fa-times"></i>
          div(class="m-b") クレジットカードの登録
            div(class="m-b")
              div(class="credit-input-item") 名前
                input(v-model="creditData.name" class="input-g-w-border")
              div(class="credit-input-item") カード番号
                input(v-model="creditData.code" class="input-g-w-border")
              div(class="credit-input-item expiration-date") 有効期限
                div
                  span(style="margin-right: 9%;") 月
                  span(style="margin: 0 8px;font-weight: bold;font-size:15px;") /
                  span 年(西暦下二桁)
                div
                  input(v-model="creditData.expiration_date_month" class="input-g-w-border expiration-date-month")
                  span(style="margin: 0 8px;font-weight: bold;font-size:16px;") /
                  input(v-model="creditData.expiration_date_year" class="input-g-w-border expiration-date-year")
                span(style="color: red;") ※月/年は0を含める
              div(class="credit-input-item") セキュリティコード
                input(v-model="creditData.security_code" class="input-g-w-border")
            div(class="coin-buy-footer-button" style="margin-bottom:10px" )
              button(@click="onCreateCredit()" class="pink-button") 変更
            div(class="coin-buy-footer-button")
              button(@click="onChangeBack()" class="pink-button") 戻る
              h5 サイト内にクレジットカード情報は保持されません。
      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormStripeResult.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import { getStripeData, createPaymentCredit, createPaymentCard, DeleteCredit } from '@/api/payment';
import { isPrd } from '@/utils/tools';

export default {
  name: "TopProfilePaymentFormStripe",
  components: {
    PRBanner,
  },
  data() {
    return {
      ui: {
        errorMessage: "Error",
        showError: false,
      },
      cardData: {
        card_name: '',
        card_no: '',
        ex_date: '',
        card_seq: '',
      },
      buyItemList: [],
      buyType: '',
      successUrl: '',
      cancellUrl: '',
      changeCreditCard: false,
      creditData: {
        name: '',
        code: '',
        expiration_date_month: '',
        expiration_date_year: '',
        security_code: '',
      },
    }
  },
  mounted(){
    // TODO : 外だし対応必要
    this.shopId = 'tshop00050484';
    let url = 'https://stg.static.mul-pay.jp/ext/js/token.js';
    // 本番はurlとshopIdが違う
    if ( isPrd() ){
      url = 'https://static.mul-pay.jp/ext/js/token.js';
      this.shopId = '9200003409720';
    }
    let cardTokenScript = document.createElement('script');
    cardTokenScript.setAttribute('src', url);
    document.head.appendChild(cardTokenScript);
  },
  async created() {
    this.init()
  },
  computed: {
    totalPrice(){
      let price = 0;
      for(var key in this.buyItemList){
        price += (Number(this.buyItemList[key].type) * Number(this.buyItemList[key].amount));
      }
      return price;
    },
  },
  methods: {
    async init() {
      const card_data = await getStripeData();
      this.cardData.holder_name = card_data.data.HolderName;
      this.cardData.card_no = card_data.data.CardNo;
      this.cardData.ex_date = card_data.data.Expire;
      this.cardData.card_seq = card_data.data.CardSeq;
      this.buyItemList = this.$route.params.buyList;
      if( !this.buyItemList ){
        this.$alert('商品が正しく選択されていません。')
        this.$router.push({ name: 'TopProfileBuy'});
        return;
      }
    },
    async onPaymentCredit() {
      let ret = {data:null};
      try{
        ret = await createPaymentCredit(this.cardData.card_seq,this.buyItemList);
      } catch( error ){
        this.$alert("決済エラーが発生しました。このカードは現在お取り扱いできません。")
        return;
      }
      const resultData = ret.data;
      const payChoice = 'card';
      this.$router.push({ name: 'TopProfilePaymentLoading', params: {
        resultData,
        payChoice,
      } });
    },

    onBack() {
      this.$router.push({
        name: 'TopProfileBuy'
      })
    },

    // onClick1() {
    //   this.$router.push({ name: 'TopTermsTokushoho' });
    // },

    onChangeBack() {
      this.changeCreditCard = false
    },

    async onCreateCredit() {
      if(
        !this.creditData.name ||
        !this.creditData.code ||
        !this.creditData.expiration_date_month ||
        !this.creditData.expiration_date_year ||
        !this.creditData.security_code
        ) {
        this.$alert("未入力箇所があります。必ず入力してください。")
        return;
      }
      this.onDeleteCredit()
      // token 取得システム初期化
      window.Multipayment.init(this.shopId);
      // コールバック関数の設定
      const self_ = this;
      window.PaymentTokenCallbackTunnel = function PaymentTokenCallbackTunnel(result){
        self_.onTokenCallback(result);
      }
      // トークン変換
      window.Multipayment.getToken(
        {
          cardno: this.creditData.code,
          expire: this.creditData.expiration_date_year + this.creditData.expiration_date_month,
          securitycode: this.creditData.security_code,
          holdername: this.creditData.name,
        },
        window.PaymentTokenCallbackTunnel);
    },
    async onTokenCallback(result){
      if( result.resultCode != '000' ){
        this.$alert('クレジットカードの認証に失敗しました。入力情報をお確かめください。')
        return;
      }
      await createPaymentCard(result.tokenObject.token).then(() => {
        this.$alert('変更完了しました。')
        this.init();
        this.changeCreditCard = false
      }).catch(() => {
        this.$alert("決済エラーが発生しました。このカードは現在お取り扱いできません。")
        return;
      });
    },

    async onDeleteCredit() {
      await DeleteCredit().catch(() => {
        this.$alert('決済エラーが発生しました。このカードは現在お取り扱いできません。')
      });
    },
  }
}
</script>
