<template lang="pug">
  .root
    component-header-login
    .login-center
      .leftContainer
        component-login-index
      
      .rightContainer
        button(@click="onClose()" class="close-button") X
        div(v-if="!ui.expired")
          p {{ showText }}
        div(v-if="ui.expired")
          .right-content-border
            div(class="t-c m-b") 有効期限切れです。
        div(class="t-c")
          button.pink-button(@click="onClose()") ログインページに戻る

    component-footer-login
</template>

<style src="./Email.scss" lang="scss" scoped>
</style>

<script>
import ComponentLoginIndex from "@/components/Login/index.vue";
import { setEmail } from '@/api/user'
import { removeToken } from '@/utils/auth'


export default {
  name: "LoginResetEmail",
  components: {
    ComponentLoginIndex
  },
  data() {
    return {
      ui: {
        expired: false,
      },
      email: '',
      updateSuccese:false,
    }
  },
  props: {},
  computed: {
    showText() {
      const text = this.updateSuccese ? "メールアドレスの変更が完了しました。" : "エラーの原因で、メールアドレス更新に失敗しました。"
      return text
    } 
  },
  watch: {},
  mounted() {
    if (parseInt(this.$route.query.expire_time) - new Date().valueOf()/1000 < 0) {
      this.ui.expired = true;
    }
    if(this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  async created() {
    this.init()
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      this.mail = this.$route.query.email.toString()
      const { data } = await setEmail(this.mail);
      this.updateSuccese = data[0]
      if (this.updateSuccese) {
        this.$alert('メール変更に成功しました')
      } else {
        this.$alert('メール変更に失敗しました')
      }
    },
    async onClose() {
      removeToken();
      this.$router.push({ name: 'LoginIndex' })
    }
  }
};
</script>
