<template lang="pug">
  .root(v-if="eventInfo.title")
    component-header-index
    <div class="container-flex">
      <div class="leftContainer">
        <div class="banner">
          <img :src="eventBanner" />
        </div>
        <div class="support-stream-title">{{ eventInfo.title }}</div>
        <div class="support-stream-user-name" @click="onStreamerNameClick()"><img src="/img/profile_icon_pink.svg">{{ eventInfo.streamer.profile.account_name }}</div>
        <div class="support-achievement-conditions" >
          <img src="/img/book.svg"> 達成条件：
          div(class="support-achievement-conditions-item" v-if="eventInfo.target_cash")
            <img :src="getGoldIcon()" class="gold-icon">{{ Number(eventInfo.target_cash).toLocaleString() || 0 }} (
            <img :src="getYenIcon()" class="gold-icon">{{ parseInt(eventInfo.target_cash * 1.1).toLocaleString() || 0 }})
          div(class="support-achievement-conditions-item" v-else-if="eventInfo.target_count") {{ eventInfo.target_count }}{{ eventInfo.target_count_unit }}
          div(v-else) なし
        </div>
        <div v-if="liveEnded" class="support-achievement-conditions"><img src="/img/book.svg"> 達成結果：
          div(v-if="eventInfo.target_cash")
            <img :src="getGoldIcon()" class="gold-icon">{{ Number(eventInfo.current_gold).toLocaleString() || 0 }} (
            <img :src="getYenIcon()" class="gold-icon">{{ parseInt(eventInfo.current_gold * 1.1).toLocaleString() || 0 }})／達成率: {{ eventInfo.current_percentage || 0}}%
          div(v-if="eventInfo.current_count") {{ eventInfo.current_count }}{{ eventInfo.target_count_unit }}／達成率: {{ eventInfo.current_percentage || 0}}%
        </div>
        <div class="support-scheduled-time">
          <img src="/img/clock_icon_green.svg"> {{ eventInfo.start_time  | moment("M月DD日HH:mm") }}~{{ eventInfo.end_time | moment("M月DD日HH:mm") | postfix(liveEnded ? '':'（予定）') }}
        </div>
        <div v-if="!liveEnded && liveOnGoing" class="support-scheduled-time">( {{ eventInfo.start_time  | moment("M月DD日HH:mm")| postfix('から配信中') }} )</div>

        <div v-html="eventInfo.describe" class="support-comment"></div>
        <div class="support-stream-terms pointer">
          a(@click="crowdFundPage") >>クラファン配信利用規約はこちら
        </div>
        <div class="support-stream-reservation-pc pointer">
          div(v-if="liveValid && this.eventInfo.live.status === 's3'" type="submit" @click="onLive(eventInfo.live.id)") <img class="img-button" src="/img/button/watch.png">
          div(v-else-if="isAvailableReservation")
            div(v-if="!isReserved" type="submit" @click="onReserve()" v-preventReClick="3000") <img class="img-button" src="/img/button/resave.png">
            div(v-else type="submit" @click="onCancelReserve()" v-preventReClick="3000") <img class="img-button" src="/img/button/resaved.png">
        </div>
      </div>
      <div class="support-stream-reservation-sp">
        //-<div class="support-stream-reservation-sp"><button type="submit" class="pink-button">配信を予約する</button></div>
        div(v-if="liveValid && this.eventInfo.live.status === 's3'" type="submit" @click="onLive(eventInfo.live.id)") <img class="img-button" src="/img/button/watch.png">
        div(v-else-if="isAvailableReservation")
          div(v-if="!isReserved" type="submit" @click="onReserve()" v-preventReClick="3000") <img class="img-button" src="/img/button/resave.png">
          div(v-else type="submit" @click="onCancelReserve()" v-preventReClick="3000") <img class="img-button" src="/img/button/resaved.png">
      </div>
      <div class="rightContainer">
        <div class="pink-label">特典詳細</div>
        <div class="right-content-border">
          <div class="support-plan" v-for="(plan,index) in plans">
            <img :src="plan.coin_icon">
            <div class="gold-point"><img :src="plan.gold_icon" class="gold-icon">{{ Number(plan.gold_price).toLocaleString() }}</div>
            <div class="gold-point"><img :src="getYenIcon()" class="gold-icon">{{ Number(plan.cash_price).toLocaleString() }}</div>
            <div class="gold-point-plan-name">{{ plan.plan_name }}</div>
            <div class="gold-point-describe">{{ plan.describe }}</div>
          </div>
        </div>
      </div>
    </div>
    component-footer-index
</template>

<style src="./Detail.scss" lang="scss" scoped>
</style>

<script>

import { getLiveClubfun, setScheduleLive, destroyScheduleLive } from '@/api/live';
import { getImageUrl } from '@/utils/tools';
// TODO find a better way to get the coin icon
import { getDefaultGiftList } from '@/store/modules/system'

export default {
  name: "TopFundDetail",
  components: {
  },
  filters:{
    postfix(str,postStr){
      return str + postStr
    }
  },
  data() {
    return {
      defaultGiftList:{},
      plans:[],
      eventInfo: {
        live: {
          profile: {
            account_name:''
          }
        }
      },
    }
  },
  props: {},
  computed: {
    liveValid(){
      return this.eventInfo.live && this.eventInfo.live.id > 0
    },
    liveOnGoing(){
      return this.eventInfo.live && this.eventInfo.live.status === this.$store.getters.enum_list.live_status_list.ONGOING
    },
    liveEnded(){
      return this.eventInfo.live && this.eventInfo.live.status === this.$store.getters.enum_list.live_status_list.ENDED
    },
    eventBanner(){
      return getImageUrl(this.eventInfo.banner_store_path)
    },
    isAvailableReservation(){
      return this.eventInfo.live && this.eventInfo.live.status === 's1'
    },
    isReserved(){
      return this.eventInfo.is_reserved
    },
  },
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const { data } = await getLiveClubfun(this.$route.params.liveable_id);
      if (data.claim !== 'c3' || new Date() < new Date(data.public_time)  || !data.is_show) {
        this.$router.push({ name: 'TopFundIndex' })
        return;
      }
      this.eventInfo = data;
      this.defaultGiftList = getDefaultGiftList();
      this.parsePlan();
    },
    parsePlan(){
      this.plans =[]
      Object.keys(this.eventInfo).forEach(key=>{
        if(key.startsWith('coin_infos_')){
          const coinInfo = this.eventInfo[key]
          const coinId = key.replace('coin_infos_','')
          if (coinInfo.gold_price || coinInfo.cash_price) {
            this.plans.push({
              coin_icon: this.getCoinIcon(coinId),
              gold_icon: this.getGoldIcon(),
              // cash_icon: this.getCashIcon(),
              gold_price: coinInfo.gold_price,
              cash_price: coinInfo.cash_price,
              describe: coinInfo.describe,
              plan_name: coinInfo.plan_name
            })
          }
        }
      })
      this.plans.sort((a,b)=>parseInt(b.gold_price) - parseInt(a.gold_price))
    },
    getCoinIcon(coinId){
      return require('@/assets/gift-icon/'+this.defaultGiftList[coinId].icon)
    },
    getGoldIcon(){
      return '/img/gold-icon.png'
    },
    getYenIcon() {
      return '/img/yen-icon.png'
    },
    // getCashIcon(){
    //   return '/img/cash-icon.png'
    // },
    onLive(id) {
      this.$router.push({
        name: 'TopLiveFund',
        params: {
          id
        }
      })
    },
    crowdFundPage() {
      let link = this.$router.resolve({ name: 'TopTermsFundTerms' });
      window.open(link.href,'_blank');
    },
    onStreamerNameClick() {
      const profile_id = this.eventInfo.streamer.profile.id
      let link = this.$router.resolve({ name: 'TopProfileStyle2', params: { profile_id } })
      window.open(link.href,'_blank');
    },
    async onReserve(){
      // do the reservation
      await setScheduleLive(this.eventInfo.live.id)
      this.eventInfo.is_reserved = true;
    },
    async onCancelReserve(){
      await destroyScheduleLive(this.eventInfo.live.id)
      this.eventInfo.is_reserved = false;
    },
  }
};
</script>
