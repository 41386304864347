<template lang="pug">
.root
  component-header-login

  .login-center
    .breadCrumbs ログイン >>
      span(class="display-title") 登録する
    div(class="container-flex")
      .leftContainer
        div(class="img-content-pc") <img src="/img/Alive_email_pc-01.jpg">
        div(class="img-content-sp") <img src="/img/Alive_email_sp-01.jpg">
      .rightContainer
        .loginBox
          .pink-label 新規登録
          .right-content-border
            div(class="m-b") メールアドレス
              input(v-model="email"  class="input-g-w-border" autocapitalize="off")
            div(class="terms")
              label
                input(type="checkbox" v-model="isAgreeTerms")
                span 利用規約に同意
                p ※利用規約は
                  a(@click="onTermsClick()" class="link-color") こちら
            div(class="no-robot")
              vue-recaptcha(ref="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="getSiteKey()")
            .buttonArea
              .errorMessageArea
                .errorMessage(v-show="ui.showError", style="color: red") {{ ui.errorMessage }}
              div(style="color: red; margin-bottom: 4px") ※ 登録はまだ済んでいません
              button.big-pink-button(@click="onSend()" v-bind:disabled="!isAgreeTerms || !isReCaptcha") 本登録URLを発行する

  component-footer-login
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { registerByEmail } from "@/api/user";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "LoginRegisterIndex",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      ui: {
        showError: false,
        errorMessage: "",
      },
      email: "",
      isAgreeTerms: false,
      isReCaptcha: false
    };
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    async onSend() {
      if (!this.checkMail()) {
        return;
      }
      if (this.email && this.recaptcha) {
        try {
          await registerByEmail(this.email, this.recaptcha);
          this.$router.push({ name: "LoginRegisterSuccess" });
        } catch (err) {
          this.ui.showError = true;
          this.ui.errorMessage = "エラーが発生しました";
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            if (err.response.status === 422) {
              this.ui.errorMessage = "※メールアドレスが正しい形式ではありません";
            }
          }
        }
      }
    },
    checkMail() {
      // メールアドレス正規表現
      let re = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      if (
        this.email.includes(' ') || this.email.includes('　')
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = '※スペースは入れずにご入力ください';
        return false;
      } else if (
        !this.email
      ){
        this.ui.showError = true;
        this.ui.errorMessage = '※メールアドレスが入力されていません';
        return false;
      } else if (
        !re.test(this.email)
      ){
        this.ui.showError = true;
        this.ui.errorMessage = '※メールアドレスが正しい形式ではありません';
        return false;
      }
      return true;
    },
    onVerify(recaptcha) {
      this.recaptcha = recaptcha;
      this.isReCaptcha = true
    },
    onExpired() {
      this.resetRecaptcha()
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset()
      this.isReCaptcha = false
    },

    getSiteKey() {
      return process.env.VUE_APP_ENV === `DEV` ? `6Legtq8aAAAAAFoVMWr9rl7-TnyJYiNJOip0D4HO` : `6LccCbcaAAAAALOM0Kdw_muzKpg2UZ16GEC6scXV`
    },

    onTermsClick() {
      let routeData = this.$router.resolve({
        name: 'TopTermsTerms'
      });
      window.open(routeData.href, '_blank');
    }
  },
};
</script>
