<template lang="pug">
  .root
    img.video-sub(@click="onPlay()" src="/img/play_button.png" v-if="videoStatus==='PREPARED'") 
    button(v-if="videoStatus==='PREPARING' || videoStatus === 'LOADING'") Loading
    video.video-rect(controls playsinline autoplay ref="hls-player" @timeupdate="timeupdate" @loadeddata="getDuration"
      webkit-playsinline  disablePictureInPicture="true" controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
      defaultPlaybackRate="1"
      )

</template>

<style src="./ArchiveVideoRect.scss" lang="scss" scoped>

</style>

<script>
import Hls from 'hls.js';
import {getUrlCode} from '@/api/tools';
import * as Bluebird from "bluebird";

const VIDEO_STATUS = {
  PAUSED : 'PAUSED',
  PREPARING : 'PREPARING',
  PREPARED : 'PREPARED',
  LOADING : 'LOADING',
  PLAYING : 'PLAYING',
}

export default {
  name: "ArchiveLiveComponentVideoRect",
  components: {},
  data() {
    return {
      insHLS: undefined,
      videoStatus: VIDEO_STATUS.PAUSED,
      timer: undefined,
      timer2: undefined,
      videoTag: undefined,
      motionList:[],
      motinoTimeList: [],
      getVideoCurrentTimeFlag: true,
      bluebirdPromiseClose: false,
      streamerCommentList: [],
      streamerCommentTimeList: [],
    }
  },
  props: {},
  computed: {
    commentList() {
      return this.$store.state.live.commentList;
    },
  },
  watch: {},
  mounted() {},
  async created() {
    this.syncM3U8();
    // this.timer = setInterval(this.syncM3U8, 3 * 1000);
    // this.timer2 = setInterval(this.syncTarget, 3 * 1000);
  },
  beforeDestroy() {
    this.bluebirdPromiseClose = true;
    clearInterval(this.timer);
    clearInterval(this.timer2);
    this.stopVideo();
    Bluebird.cancel();
  },
  updated() {},
  methods: {
    async onPlay() {
      this.videoStatus = VIDEO_STATUS.LOADING;
      await this.playVideo();
      this.videoTag.play();
      this.videoStatus = VIDEO_STATUS.PLAYING;
    },
    async playVideo() {
      this.videoStatus = VIDEO_STATUS.PREPARING;
      this.videoTag = this.$refs['hls-player'];
      let _this = this
      this.videoTag.onplay = function() {
        _this.videoStatus = VIDEO_STATUS.PLAYING
      };
      
      let lastRetData = null;
      let count = 10;
      for(let i = 0; i != -1 ; i++) {
        const ret = await getUrlCode(this.$store.state.live.liveURL);
        if (i === 0 && ret){
          break;
        }
        if (lastRetData === null && ret) {
          lastRetData = ret;
        }
        if(this.bluebirdPromiseClose){
          break
        }
        if (count === 0 && !ret && !this.bluebirdPromiseClose) {
          this.$alert("配信URL取得に失敗しました")
          break;
        } 
        if (!ret) {
          await Bluebird.delay(5000);
          continue;
        }
        if (lastRetData === ret) {
          await Bluebird.delay(5000);
          continue;
        } else {
          count = count - 1;
          lastRetData = null;
        }
        i = 1;
      }
      // await Bluebird.delay(20000);
      
      this.videoStatus = VIDEO_STATUS.PREPARED;

      if (Hls.isSupported()) {
        this.insHLS = new Hls({
          autoStartLoad: true,
          startFragPrefetch: true,
          liveDurationInfinity: true,
          liveSyncDuration: 3,
          enableWorker: true,
          manifestLoadingTimeOut: 2000,
          levelLoadingTimeOut: 2000,
          levelLoadingMaxRetry: 10,
          levelLoadingRetryDelay: 50,
          levelLoadingMaxRetryTimeout: 30 * 1000,
          fragLoadingTimeOut: 2000,
          fragLoadingMaxRetry: 10,
          fragLoadingRetryDelay: 50,
          fragLoadingMaxRetryTimeout: 30 * 1000,
          xhrSetup: (xhr, url) => {
            if(url && url.split('?')[0].endsWith('.m3u8')) {
              xhr.open('GET', this.$store.state.live.liveURL, true);
            }
          },
        });
        this.insHLS.attachMedia(this.videoTag);
        this.insHLS.on(Hls.Events.MEDIA_ATTACHED,  () => {
          this.insHLS.loadSource(this.$store.state.live.liveURL)
          this.videoTag.play();
        });
        this.insHLS.on(Hls.Events.MANIFEST_PARSED,  () => {
          });
        this.insHLS.on(Hls.Events.ERROR, async (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                this.insHLS.startLoad();
                break;
              case 'mediaError':
                this.insHLS.recoverMediaError();
                break;
              default:
                this.insHLS.startLoad();
                break;
            }
          } else {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                if (data.response && data.response.code === 404) {
                  this.insHLS.destroy();
                }
                break;
              default:
                break;
            }
          }
        });        
      } else if (this.videoTag.canPlayType('application/vnd.apple.mpegurl')) {
        this.videoTag.src = this.$store.state.live.liveURL;
        this.videoTag.addEventListener("canplay", () => {
          this.videoTag.play();
        });
      }

      this.motionSetup();
    },
    async iosPlayVideo(){
      if( !(this.videoTag.currentTime > 0 && !this.videoTag.paused && !this.videoTag.ended && this.videoTag.readyState > 2) ){
        await this.videoTag.play();
        this.videoStatus = VIDEO_STATUS.PLAYING;
      }
    },

    motionSetup() {
      const LiveStartTime = new Date(this.$store.state.live.info.started_at);
      this.motionList = this.$store.state.live.commentList.filter(v => v && v.payload && v.payload.data === 'gift').map( v => {
        const motionItem = {};
        motionItem.showTime = Math.floor((v.created_at - LiveStartTime)/1000)
        motionItem.motionId = v.payload.Ext.RAW.type
        motionItem.amount = v.payload.Ext.RAW.amount
        return motionItem
      })
      this.motionList.forEach(element => {
        this.motinoTimeList.push(element.showTime)
      });

      this.streamerCommentList = this.$store.state.live.commentList.filter(n => {
        const extension = JSON.parse(n.payload.extension);
        const isStreamerMessage = extension.PROFILEABLE_TYPE === 'streamer'
        return isStreamerMessage;
      }).map( v => {
        const streamerCommentItem = {};
        streamerCommentItem.showTime = Math.floor((v.created_at - LiveStartTime)/1000)
        streamerCommentItem.description = v.payload.description
        return streamerCommentItem
      })
      this.streamerCommentList.forEach(element => {
        this.streamerCommentTimeList.push(element.showTime)
      });
    },

    // ギフトモーション表示リスナー
    timeupdate(e) {
      // 毎秒に一回アーカイブ再生時間取得
      if (!this.getVideoCurrentTimeFlag) {
        return;
      }
      this.getVideoCurrentTimeFlag = false;
      setTimeout(()=>{
        this.getVideoCurrentTimeFlag = true;
      }, 1000);
      // 親画面にギフトモーション表示させる
      if(this.motinoTimeList.indexOf(parseInt(e.target.currentTime)) !== -1){
        this.motionList.forEach(element => {
          if(element.showTime == parseInt(e.target.currentTime)) {
            this.$emit('playMotion', {motionId : element.motionId, amount: element.amount})
          }
        });
      }
      // 親画面に配信者コメントを表示させる
      if(this.streamerCommentTimeList.indexOf(parseInt(e.target.currentTime)) !== -1){
        this.streamerCommentList.forEach(element => {
          if(element.showTime == parseInt(e.target.currentTime)) {
            this.$emit('playStreamerMessage', element.description)
          }
        });
      }
    },

    stopVideo() {
      if(this.insHLS) {
        this.insHLS.destroy();
      }
    },

    syncM3U8() {
      if (this.$store.state.live.info.id) {
        this.$store.dispatch('live/syncArchiveLiveURL');
      }
    },
    // syncTarget() {
    //   if (this.$store.state.live.id) {
    //     this.$store.dispatch('live/syncTarget');
    //   }
    // }
    getDuration(e) {
      this.$emit('getDuration', e.target.duration)
    },
  }
};
</script>
