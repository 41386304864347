<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-buy")
        div(class="content-buy-button-content") 購入/使用履歴>>
          div(class="content-buy-button")
            button(@click="onTabClick('BuyHistory')" class="buyhistory-menu-button" v-bind:class="{ 'tab-active':isActiveGold }") Ｇ購入履歴
            button(@click="onTabClick('UseHistory')" class="buyhistory-menu-button" v-bind:class="{ 'tab-active':isActiveGift }") G★♥使用履歴
            button(@click="onTabClick('CashHistory')" class="buyhistory-menu-button" v-bind:class="{ 'tab-active':isActiveCash }") 即時決済履歴

        div(class="money-possession")
          div(v-if="!birthdayCheck")
            div(class="title") 現在の所持数
            div(class="money-list")
              div <img src="/img/gold-icon.png" class="gold-icon">{{ $store.state.user.c1 }}
              div <img src="/img/star-icon.png" class="star-icon">{{ $store.state.user.c2 }}
              div <img src="/img/heart-icon.png" class="heart-icon">{{ $store.state.user.c3 }}
            button(class="pink-button" @click="onBuy()")
            div(class="credit-card-button")
                a(@click="onCreditClick()") >>登録したクレジットカードの確認/削除
          div(class="m-t" v-else)
            span 満18歳未満の方は<br>
            span ゴールドをご購入いただけません

        table(v-if="ui.tab === 'BuyHistory'" class="history-list")
          tr(class="history-list-item history-list-item-g" v-for="item in $store.state.cost.gold_list")
            td {{ item.created_at | moment("YYYY/MM/DD HH:mm") }}
            td <img src="/img/gold-icon.png" class="gold-icon">{{ item.amount }}

        table(v-if="ui.tab === 'UseHistory'" class="history-list")
          tr(class="history-list-item" v-for="item in $store.state.cost.gift_list")
            td {{ item.created_at | moment("YYYY/MM/DD HH:mm") }}
            td(v-if="item.coupon === 'c1'" text-align="center" vertical-align="middle")
              <img src="/img/gold-icon.png" class="gold-icon">{{ item.amount * -1 }}
            td(v-if="item.coupon === 'c2'" text-align="center" vertical-align="middle")
              <img src="/img/star-icon.png" class="star-icon">{{ item.amount * -1 }}
            td(v-if="item.coupon === 'c3'" text-align="center" vertical-align="middle")
              <img src="/img/heart-icon.png" class="heart-icon">{{ item.amount * -1 }}
            td
              span(v-if="item.remark === 'rc1'" class="gift") ギフト
              span(v-if="item.remark === 'rc2'" class="paid-stream") 有料配信
              span(v-if="item.remark === 'rc3'" class="support-stream") ｸﾗﾌｧﾝ配信
              span(v-if="item.remark === 'rc4'")
                <img class="delete-img" src="/img/shoumetsu.png">

        table(v-if="ui.tab === 'CashHistory'" class="history-list")
          tr(class="history-list-item" v-for="item in $store.state.cost.cash_list")
            td {{ item.created_at | moment("YYYY/MM/DD HH:mm") }}
            td {{ item.amount*-1 }}円
            td
              span(class="support-stream") ｸﾗﾌｧﾝ配信

        a(v-if="haveMore" class="next-view" @click="onContinue()") >>続きを表示

      div(v-if="rightTap == 'PRBanner'" class="pr-list")
        PRBanner
      div(v-if="rightTap == 'CreditCard'" class="credit-card-box")
        credit-card(@onCreditClose="defaultRightTap")
    component-footer-index
</template>

<style src="./BuyHistory.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import CreditCard from '@/components/CreditCard'
import { isOverEighteen } from '@/utils/tools'

export default {
  name: "TopProfileBuyHistory",
  components: {
    PRBanner,
    CreditCard
  },
  data() {
    return {
      ui: {
        tab: 'BuyHistory',
      },
      isActiveGold: true,
      isActiveGift: false,
      isActiveCash: false,
      birthdayCheck: false,
      rightTap: 'PRBanner'
    }
  },
  async created() {
    await this.$store.dispatch('user/syncCoupon');
    await this.$store.dispatch('cost/syncListGold', { init: true });
  },
  computed:{
    haveMore(){
      if(this.isActiveGift){
        return this.$store.state.cost.gift_next_page !== -1
      }else if (this.isActiveGold){
        return this.$store.state.cost.gold_next_page !== -1
      }else if (this.isActiveCash){
        return this.$store.state.cost.cash_next_page !== -1
      }else{
        return false
      }
    }
  },
  methods: {
    async onTabClick(tabName) {
      this.ui.tab = tabName
      if (this.ui.tab  == "BuyHistory") {
        this.isActiveGold = true;
        this.isActiveGift = false;
        this.isActiveCash = false;

        await this.$store.dispatch('cost/syncListGold', { init: true });
      } else if (this.ui.tab  == "UseHistory") {
        this.isActiveGold = false;
        this.isActiveGift = true;
        this.isActiveCash = false;

        await this.$store.dispatch('cost/syncListGift', { init: true });
      } else if (this.ui.tab  == "CashHistory") {
        this.isActiveGold = false;
        this.isActiveGift = false;
        this.isActiveCash = true;

        await this.$store.dispatch('cost/syncListCash', { init: true });
      }
    },
    onBuy() {
      if (!isOverEighteen(this.$store.state.user.profile_birthday)) {
        this.birthdayCheck = true;
        return;
      }
      this.$router.push({
        name: 'TopProfileBuy'
      })
    },
    async onContinue() {
      if (this.isActiveGold) {
        await this.onContinueGold();
      } else if (this.isActiveGift) {
        await this.onContinueGift();
      } else if (this.isActiveCash) {
        await this.onContinueCash();
      }
    },
    async onContinueGold() {
      await this.$store.dispatch('cost/syncListGold');
    },
    async onContinueGift() {
      await this.$store.dispatch('cost/syncListGift');
    },
    async onContinueCash() {
      await this.$store.dispatch('cost/syncListCash');
    },
    onCreditClick() {
      this.rightTap = 'CreditCard';
    },
    defaultRightTap() {
      this.rightTap = 'PRBanner';
    }
  }
}
</script>
