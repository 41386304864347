<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="leftContainer")
        <div class="aki-common-event-info">
          <div class="aki-common-event-banner">
            <img class="event-detail-banner" :src="eventBanner" />
          </div>
          <div class="aki-common-event-title">{{ eventInfo.name }}</div>
        </div>
        <div class="support-scheduled-time">
            <img src="/img/clock_icon_green.svg">{{ eventInfo.start_time  | moment("M月DD日HH:mm") }}~{{ eventInfo.end_time | moment("M月DD日HH:mm")}}
        </div>
        <div v-html="eventInfo.describe" class="aki-common-event-describe"></div>
        <div v-html="eventNotice"></div>

      div(class="rightContainer" v-if="!showViewerProfile")
        div(class="event-label-pc")
          div(:disabled="isEventEnd" @click="isEventEnd?()=>{}:tabClick('Participant')" class="pink-label" v-bind:class="{ 'tab-active':isActive2 }") 参加者
          div(:disabled="!isEventStarted" @click="!isEventStarted?()=>{}:tabClick('Rank')" class="pink-label" v-bind:class="{ 'tab-active':isActive3}") ランキング
        div(class="event-label-sp") {{ eventName }} >>
          button(@click="tabClick('EventInfo')" class="" v-bind:class="{ 'tab-active':isActive1 }") 詳細
          button(:disabled="isEventEnd" @click="isEventEnd?()=>{}:tabClick('Participant')" class="" v-bind:class="{ 'tab-active':isActive2 }") 参加者
          button(:disabled="!isEventStarted" @click="!isEventStarted?()=>{}:tabClick('Rank')" class="" v-bind:class="{ 'tab-active':isActive3 }") ランキング

        div(class="right-content-border")
          div(v-if="ui.tab === 'EventInfo' ")
            div(class="aki-common-event-right-banner")
              <img :src="eventBanner" style="margin-bottom: 16px;" />
              div(class="aki-common-event-title") {{ eventInfo.name }}
              <div v-if="!isEventStarted || isEventRecruiting" class="support-scheduled-time">
                <span class="glyphicon glyphicon-time" aria-hidden="true" style="width:20px;height:20px;margin-right:4px;"></span>{{ eventInfo.start_time  | moment("M月DD日HH:mm") }}~{{ eventInfo.end_time | moment("M月DD日HH:mm")}}
              </div>
              <div v-html="eventInfo.describe" class="aki-common-event-sp"></div>

          div(v-if="ui.tab === 'Participant' ")
            div(class="live-img-grid")
              div(class="live-img-item" v-for="item in streamingLives")
                div(v-if="isLiving(item)")
                  div
                    img(class="room-tag" src="/img/haishin.png")
                  div(class="live-img-sp")
                    img(class="room-banner" :src="getRoomBannerUrl(item)" @click="onClickRoomBanner(item.liveable_id,item.id)")
            div(class="participant-list")
              div(class="accountList")
                div(v-for="item in entryStreamers" class="accountList-item")
                  div(class="container")
                    div(class="img-thumbanail" @click="onStreamerAvatarClick(item.streamer_info.profile.id)")
                      <pan-thumb :avatar="getStreamerAvatarUrl(item)" :attribute="getStreamerAttribute(item)" :isStreamer="true" size="70px"/>
                    p(class="accountList-name")
                      span(aria-hidden="true") {{ item.streamer_info.profile.account_name }}
                    div(class="account-point")
                      div(class="point-icon")
                      <img src="/img/P_pink_icon.svg" class="P_icon">
                      span {{ Number(item.rank_point).toLocaleString() }}
                    button(:class="item.is_following ? 'gray-button' : 'pink-button'" @click="item.is_following ? onFollowCancel(item.streamer_info.profile.id, 'entry'): onFollow(item.streamer_info.profile.id, 'entry')") {{ item.is_following ? 'フォロー済み' : 'フォローする' }}

          div(v-if="ui.tab === 'Rank'")
            div(v-if="!showViewerRank && !showViewerProfile" class="participant-list")
              div(class="accountList")
                div(v-for="(item, index) in rankingStreamers" class="accountList-item")
                    div(class="container")
                      div(class="img-thumbanail" @click="onStreamerAvatarClick(item.streamer_info.profile.id)")
                        <pan-thumb :avatar="getStreamerAvatarUrl(item)" :attribute="getStreamerAttribute(item)" :isStreamer="true" size="70px"/>
                      div(class="topleft" v-if="index < 3")
                        img(:src="index == 0 ? '/img/badge_gold.png' : index == 1 ? '/img/badge_silver.png' : index == 2 ? '/img/badge_copper.png' : ''")
                        span(class="rank-badge") {{index + 1}}
                      p(class="accountList-name")
                        span(aria-hidden="true") {{ item.streamer_info.profile.account_name }}
                      div(class="account-point")
                        div(class="point-icon")
                        <img src="/img/P_pink_icon.svg" class="P_icon">
                        span {{ Number(item.rank_point).toLocaleString() }}
                      div(class="rank-button")
                        button(class="gray-button" v-if="item.is_following" @click="onFollowCancel(item.streamer_info.profile.id, 'event_ranking')")
                        button(class="pink-button" v-else @click="onFollow(item.streamer_info.profile.id, 'event_ranking')")
                        button(class="pink-button support-ranking" @click="showRank(item)")
              a(v-if="rankingShowMore" class="goondisplay" style="cursor:pointer" @click="putOtherRankingUser()") >>もっと見る
            div(v-if="showViewerRank")
              button(@click="onCloseViewerRankList" class="close-button") <i class="fas fa-times"></i>
              div(class="t-c")
                h5 {{ eventInfo.name }}
                h5 {{ showingStreamer.profile.account_name }}
                h5 応援ランキング
              div(class="viewer-list-grid")
                div(v-for="(item, index) in listViewers" class="viewer-img-item")
                  div(class="container")
                    div(class="img-thumbanail" @click="onViewerAvatarClick(item.profile.id)")
                      <pan-thumb :avatar="getViewerAvatarUrl(item)" :attribute="getViewerAttribute(item)" :current_title="item.viewer_info.current_title" size="70px"/>
                    div(class="topleft" v-if="index < 3")
                      img(v-bind:src="index == 0 ? '/img/badge_gold.png' : index == 1 ? '/img/badge_silver.png' : index == 2 ? '/img/badge_copper.png' : ''")
                      span(class="rank-badge") {{ index + 1 }}
                    span(class="accountList-name")
                      span(aria-hidden="true") {{ item.profile.account_name }}
                    div(class="account-point")
                      div(class="point-icon")
                      <img src="/img/P_pink_icon.svg" class="P_icon">
                      span {{  item.total_points ?  item.total_points.toLocaleString() : 0 }}
                    div(class="rank-button" v-if="isNotSelf(item.profile.id)")
                      button(:class="item.is_following ? 'gray-button' : 'pink-button'" @click="item.is_following ? onFollowCancel(item.profile.id, 'ranking'): onFollow(item.profile.id, 'ranking')") 
              a(v-if="viewerRankingShowMore" style="cursor: pointer" @click="putOtherViewerRankingUser()") >>もっと見る
      div(v-if="showViewerProfile" class="pos-r")
        component-profile-index( :profile_id="showingViewerProfileId" @close="onCloseViewerProfile")


    component-footer-index
</template>

<style src="./Detail.scss" lang="scss" scoped>
</style>

<script>

import { getLiveListEventDetail, getEventLiveViewerRankList, getLiveListEventEntryStreamer, getLiveListEventRanking } from '@/api/live'
import { getEventNotice } from '@/api/system'
import PanThumb from '@/components/PanThumb'
import { getImageUrl } from '@/utils/tools'
import moment from 'moment'

export default {
  name: "TopEventDetail",
  components: {
    PanThumb
  },
  data() {
    return {
      ui: {
        tab: window.innerWidth > 375 ? 'Participant' : 'EventInfo'
      },
      isActive1: window.innerWidth < 376,
      isActive2: window.innerWidth > 375,
      isActive3: false,
      showViewerRank:false,
      showingStreamer:undefined,
      eventInfo: {
        streamers: [
        ],
        lives:[]
      },
      eventNotice:'',
      listViewers:[],
      listOtherViewers:[],
      entryStreamers: [],
      rankingStreamers: [],
      otherRankingStreamers: [],
      followingViewerList:[],
      showViewerProfile:false,
      showingViewerProfileId:-1,
    }
  },
  props: {},
  computed: {
    eventBanner(){
      return getImageUrl(this.eventInfo.banner_store_path);
    },
    eventName(){
      let str = this.eventInfo.name;
      let eventNameOverflow = "";
      if (!str) return "";
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
        if (len > 20) {
          eventNameOverflow = eventNameOverflow + "…";
          break;
        }
        eventNameOverflow = eventNameOverflow + str[i];
      }
      return eventNameOverflow;
    },
    streamingLives(){
      return this.eventInfo.lives.data?.filter(item =>{
        return item.status === this.$store.getters.enum_list.live_status_list.ONGOING
      })
    },
    isEventStarted(){
      return moment(this.eventInfo.start_time).isBefore(moment())
    },
    isEventEnd(){
      return moment(this.eventInfo.end_time).isBefore(moment())
    },
    isEventRecruiting(){
      return moment(this.eventInfo.recruitment_start_time).isBefore(moment()) && moment(this.eventInfo.recruitment_end_time).isAfter(moment())
    },
    rankingShowMore() {
      return this.otherRankingStreamers.length > 0 && this.rankingStreamers.length < 13
    },
    viewerRankingShowMore() {
      return this.listOtherViewers.length > 0 && this.listViewers.length < 13
    },
  },
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      try {
        const { data } = await getLiveListEventDetail(this.$route.params.liveable_id);
        if (!data.is_show) {
          this.$router.push({ name: 'TopEventIndex' })
          return;
        }
        this.eventInfo = data;      
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: 'TopEventIndex' })
        }
        return;
      }
      if(this.isEventEnd){
        this.tabClick("Rank")
      }
      const res = await getEventNotice()
      this.eventNotice = res.event_note.content.describe
      this.fetchEntryStreamer();
    },
    onStreamerAvatarClick(profile_id) {
      let link = this.$router.resolve({ name: 'TopProfileStyle2', params: { profile_id } })
      window.open(link.href,'_blank');
    },
    onViewerAvatarClick(profile_id){
      this.showingViewerProfileId = profile_id
      this.showViewerRank = false
      this.showViewerProfile = true
    },
    onCloseViewerProfile(){
      this.syncRankList()
      this.showingViewerProfileId = -1
      this.showViewerRank = true
      this.showViewerProfile = false
    },
    tabClick: function(tab_name){
      this.ui.tab = tab_name
      if (this.ui.tab  == "EventInfo") {this.isActive1 = true;this.isActive2 = false;this.isActive3 = false}
      if (this.ui.tab  == "Participant") {this.isActive1 = false;this.isActive2 = true;this.isActive3 = false}
      if (this.ui.tab  == "Rank") {
        this.isActive1 = false;this.isActive2 = false;this.isActive3 = true;
        this.syncEventRanking();
      }
    },
    async onFollow(id, link) {
      await this.$store.dispatch("user/addFollowing", id);
      switch(link) {
        case 'entry':
          await this.fetchEntryStreamer()
          break;
        case 'ranking':
          await this.syncRankList()
          break;
        case 'event_ranking':
          await this.syncEventRanking()
          break;
      }
    },
    async onFollowCancel(id, link) {
      await this.$store.dispatch("user/removeFollowers", id);
      switch(link) {
        case 'entry':
          await this.fetchEntryStreamer()
          break;
        case 'ranking':
          await this.syncRankList()
          break;
        case 'event_ranking':
          await this.syncEventRanking()
          break;
      }
    },
    async showRank(item) {
      this.showingStreamer = item.streamer_info
      await this.syncRankList()
      this.showViewerRank = true
    },
    async fetchEntryStreamer() {
      const ret = await getLiveListEventEntryStreamer(this.eventInfo.id);
      this.entryStreamers = ret.data.data;
    },
    // イベントランキング
    async syncEventRanking(){
      const { data } = await getLiveListEventRanking(this.eventInfo.id);
      const rankList = []
      const otherRankList = []
      data.data.forEach((element, index) => {
        if( index< 12) {
          rankList.push(element)
        }else {
          otherRankList.push(element)
        }
      });
      this.rankingStreamers = rankList;
      this.otherRankingStreamers = otherRankList;
    },
    // 応援ランキング
    async syncRankList(){
      const { data, following_id_list } = await getEventLiveViewerRankList(this.eventInfo.id, this.showingStreamer.streamer_id);
      const rankList = []
      const otherRankList = []
      data.forEach((element, index) => {
        if (index < 12) {
          rankList.push(element)
        } else {
          otherRankList.push(element)
        }
      });
      this.listViewers = rankList;
      this.listOtherViewers = otherRankList;
      this.followingViewerList = following_id_list
    },
    onCloseViewerRankList(){
      this.showingStreamer = undefined
      this.showViewerRank = false
    },
    getStreamerAvatarUrl(item){
      return item.streamer_info.profile.avatar_store_path
    },
    getStreamerAttribute(item){
      return item.streamer_info.profile.attribute
    },
    getViewerAvatarUrl(viewer){
      return viewer.profile?.avatar_store_path
    },
    getViewerAttribute(viewer){
      return viewer.profile?.attribute
    },
    getRoomBannerUrl(liveItem){
      return getImageUrl(liveItem.room_info.banner_store_path)
    },
    onClickRoomBanner(liveable_id,live_id){
      let link = this.$router.resolve({ name: 'TopLiveEvent', params: { liveable_id, id: live_id }})
      window.open(link.href,'_blank');
    },
    isLiving(liveItem){
      return liveItem.status === this.$store.getters.enum_list.live_status_list.ONGOING
    },
    isNotSelf(id){
      return this.$store.state.user.profile_id !== id
    },
    putOtherRankingUser() {
      this.otherRankingStreamers.forEach(element=>{
        this.rankingStreamers.push(element);
      })
    },
    putOtherViewerRankingUser() {
      this.listOtherViewers.forEach(element=>{
        this.listViewers.push(element);
      })
    }
  }
};
</script>
