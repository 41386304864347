<template lang="pug">
  <div v-if="mode == 'avaterUpload'">
    <input id="avatarFileUpload" type="file" accept="image/*" v-show="false" @change="onAvatarUpload">
    <label @click="chooseAvatarFile()">
      <i class="fas fa-file-upload"></i>
    </label>
  </div>

  <div v-else-if="mode == 'backgroundUpload'">
    <input id="backgroundFileUpload" type="file" v-show="false" @change="onBackgroundUpload">
    <li><a><u @click="chooseBackgroundFile()">>> 背景画像を変更</u></a></li>
  </div>
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<style lang="scss" scoped>
li {
  font-size: 15px;
  margin-bottom: 5px;
  list-style-type: none;
  background-color: rgba(255,255,255,0.4);
  @include sp{
    font-size: 12px;
  }
}
</style>
<script>
import { uploadAvatar } from '@/api/user'
import { uploadBackground } from '@/api/user'
import Compressor from 'compressorjs';

export default {
  name: "ImageUpload",
  components: {
  },
  filters:{
  },
  data() {
    return {
    }
  },
  props: {
    fatherInit: {
      type: Function,
      default: null
    },
    mode: {
      type: String,
      default: ""
    },
  },
  computed: {
  },
  watch: {},
  mounted() {},
  async created() {
  },
  updated() {},
  async beforeDestroy() {
  },
  methods: {

    chooseAvatarFile() {
      document.getElementById("avatarFileUpload").click();
    },

    chooseBackgroundFile() {
      document.getElementById("backgroundFileUpload").click();
    },

    async onAvatarUpload(event) {
      this.file = event.target.files[0];
      if (!this.file) {
        return;
      }
      let _this = this;
      new Compressor(this.file, {
        strict: true,
        maxWidth: 120,
        maxHeight: 120,
        convertSize: 100000, // 100KB
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        async success(result) {
          var formData = new FormData();
          formData.append("file", result);
          // var formData = new FormData();
          // formData.append('file', result, result.name);
          if (formData) {
            const { data } = await uploadAvatar(formData);
            await _this.$store.dispatch('user/setProfileAvator',data.store_path);
            // const ret1 = await getUserinfoById(_this.$store.state.user.profile_profileable_id);
            // ret1.data.profile.avatar_store_path = data.store_path;
            // await setUserInfo(ret1.data);
            // await _this.$store.dispatch('user/syncProfile');
            // await _this.fatherInit();
          }
      },
      error(err) {
        console.log(err.message);
      }
      })
    },

    async onBackgroundUpload(event) {
      this.file = event.target.files[0];
      if (!this.file) {
        return;
      }
      let imagSize = this.file.size;
      if(imagSize < 1024 * 1024 * 3) {
        var formData = new FormData();
        formData.append("file", event.target.files[0]);

        const { data } =await uploadBackground(formData);
        await this.$store.dispatch('user/setProfileBackground',data.store_path);
        // const ret1 = await getUserinfoById(this.$store.state.user.profile_profileable_id);
        // ret1.data.profile.background_store_path = data.store_path;
        // await setUserInfo(ret1.data);
        // await this.$store.dispatch('user/syncProfile');
      } else {
        let _this = this;
        new Compressor(this.file, {
        convertSize: 3000000, // 3MB
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        async success(result) {
          var formData = new FormData();
          formData.append("file", result);
          // var formData = new FormData();
          // formData.append('file', result, result.name);
          if (formData) {
            const { data } =await uploadBackground(formData);
            await _this.$store.dispatch('user/setProfileBackground',data.store_path);
            // const ret1 = await getUserinfoById(_this.$store.state.user.profile_profileable_id);
            // ret1.data.profile.background_store_path = data.store_path;
            // await setUserInfo(ret1.data);
            // await _this.$store.dispatch('user/syncProfile');
          }
        },
        error(err) {
          console.log(err.message);
        }
        })
      }
    },

  }
};
</script>