<template lang="pug">
  .root
    component-header-index(v-if="$store.state.user.email")
    component-header-login(v-if="!$store.state.user.email")
    div(class="container-flex")
      div(class="leftContainer")
        div(class="img-content-pc")
          div(v-show="buttonHide" class="bread-list") >>
            span(class="display-title") もう一度初期解説を見る
          div(v-show="!buttonHide" class="img-content-img") 
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-01.jpg")
          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-02.jpg")
          div(v-show="buttonHide") 
            div(class="youtubeText") ～クラファン配信とは～
            ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeWhatIsClubFun")
          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-03.jpg")
          div(v-show="buttonHide")
            div(class="youtubeText") ～オーディションでの応援方法～
            ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeHowToSupportEvent")
          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-04.jpg")
          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-05.jpg")
          div(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-06.jpg")
          div(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-07.jpg")
          ddiv(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-08.jpg")
          div(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_pc-09.jpg")

          a(v-show="buttonHide" @click="$router.push({ name: 'TopTermsManual' })") ※詳しくはヘルプページにてご確認ください

        div(class="img-content-sp")
          div(v-show="buttonHide" class="bread-list") >>
            span(class="display-title") もう一度初期解説を見る

          div(v-show="!buttonHide" class="img-content-img") 
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-01.jpg")

          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-02.jpg")
          div(v-show="buttonHide") 
            div(class="youtubeText") ～クラファン配信とは～
            ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeWhatIsClubFun")
          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-03.jpg")
          div(v-show="buttonHide")
            div(class="youtubeText") ～オーディションでの応援方法～
            ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeHowToSupportEvent")
          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-04.jpg")
          div(v-show="buttonHide" class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-05.jpg")
          div(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-06.jpg")
          div(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-07.jpg")
          div(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-08.jpg")
          div(class="img-content-img")
            img(class="attr-img" src="/img/Alive-after/Alive_after_sp-09.jpg")

          a(v-show="buttonHide" @click="$router.push({ name: 'TopTermsManual' })") ※詳しくはヘルプページにてご確認ください

        div(v-show="!buttonHide" class="select-button")
          button(@click="onNext()" class="pink-button") 属性を登録
          button(@click="onSkip()" class="pink-button") スキップ

    component-footer-login
</template>

<style src="./Next.scss" lang="scss" scoped>
</style>

<script>
import VideoPlayer from "@/components/VideoPlayer/index.vue";
import ComponentYoutubePlayer from "@/components/VideoPlayer/youtubePlayer";

export default {
  name: "LoginInitNext",
  components: {
    VideoPlayer,
    ComponentYoutubePlayer
  },
  data() {
    return {
      buttonHide: false,
      movie: {
        youtubeWhatIsClubFun: "PLiXxjUe-Po",
        youtubeHowToSupportEvent: "mJTM01L0C2c"
      },
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    this.buttonHide = localStorage.getItem('buttonHide') ? true: false
  },
  updated() {},
  destroyed() {},
  methods: {
    onNext() {
      this.$router.push({ name: 'LoginInitSelect' })
    },
    async onSkip() {
      await this.$store.dispatch('user/setAttribute', this.$store.state.system.enum_list.attribute_list.NONE);
      this.$router.push({ name: 'TopIndex' })
    }
  }
};
</script>
