import { getImageUrl,isInvalidResImageUrl } from '@/utils/tools'

export default {
  computed:{
    isNormal(){
      return this.roomType === "NORMAL"
    },
    isPay(){
      return this.roomType === "PAY"
    },
    isEvent(){
      return this.roomType === "EVENT"
    },
    isFund(){
      return this.roomType === "FUND"
    },
    isArchive(){
      return this.data.isArchive
    },
    roomType(){
      return this.data.type ? this.data.type : "NORMAL"
    },
    roomName(){
      return this.data.title === '' ? '未設定':this.data.title
    },
    roomPeriod(){
      return this.data.date
    },
    roomHost(){
      return this.data.streamer
    },
    canPlay(){
      return this.isStreaming || this.isEnded
    },
    isStreaming(){
      return this.data.status === this.$store.getters.enum_list.live_status_list.ONGOING
    },
    isEnded(){
      return this.data.status === this.$store.getters.enum_list.live_status_list.ENDED
    },
    roomBanner(){
      if(!isInvalidResImageUrl(this.data.banner)){
        return getImageUrl(this.data.banner)
      }else{
        return '/img/test.png';
      }
    },
  }
}
