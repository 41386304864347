<template lang="pug">
  .root
    <div class="profileright">
      button(@click="$emit('close')" class="close-button") <i class="fas fa-times"></i>
      div(class="prof-bg-img")
        <img v-if="data.background_store_path" :src="$store.state.system.config.storage_addr_list.public + data.background_store_path">
      <div class="socilappright">
        <li v-if="data.sns_infos.twitter"><img src="/img/socil_twitter.png" class="pointer" v-if="data.sns_infos.twitter" @click="onSNS(data.sns_infos.twitter)"></li>
        <li v-if="data.sns_infos"><img src="/img/socil_instagram.png" class="pointer" v-if="data.sns_infos.instagram" @click="onSNS(data.sns_infos.instagram)"></li>
      </div>
      <div class="profilecenterright">
        div(class="user-icon")
          <pan-thumb :avatar="data.avatar_store_path" :attribute="data.attribute" :current_title="current_title" :isStreamer="isStreamer(data)" size="90px"/>
        <div class="profilebk" v-bind:style="{ background: 'url(' + bk_url +') center no-repeat' }">
          div(class="user-name" v-bind:style="{ color: this.checkBackgroundColor() }") {{ data.account_name }}
          div(class="follow-content")
            div(class="follow-content-f" v-bind:style="{ color: this.checkBackgroundColor() }")
              div
                <img src="/img/profile_icon_pink.svg" @click="linkFollow('follow')">
                span(@click="linkFollow('follow')") {{ data.following_total }}
              div(@click="linkFollow('follow')" style="line-height: 12px; padding-bottom: 8px;") フォロー
            div(class="follow-content-f" v-bind:style="{ color: this.checkBackgroundColor() }")
              div
                <img src="/img/profile_icon_blue.svg" @click="linkFollow('follower')">
                span(@click="linkFollow('follower')") {{ data.follower_total }}
              div(@click="linkFollow('follower')" style="line-height: 12px; padding-bottom: 8px;") フォロワー
          <div class="introductionright">
            <p><b style="color:black;">{{ data.introduction }}</b></p>
          </div>
          div(class="follow-button")
            div(v-if="keysFollowing && keysFollowing.includes(data.id) && isNotSelf(data.id) && data.status !== 'private'")
              button(@click="onFollowCancel(data.id)" class="gray-button")
            div(v-else)
              button(v-show="isNotSelf(data.id) && data.status !== 'private'" @click="onFollow(data.id)" class="pink-button")
            //- button(class="pink-button" v-if="data.id && isNotSelf(data.id)" @click="data.is_following ? onFollowCancel(data.id): onFollow(data.id)") {{ data.is_following ? 'フォロー済み' : 'フォローする' }}
        </div>
      </div>
    </div>

</template>

<style src="./index.scss" lang="scss" scoped>
</style>


<script>
import { getProfile, getFollowings } from '@/api/profile';
import { getImageUrl } from '@/utils/tools'
import { getBackgroundColorImage } from '@/utils/image'
import PanThumb from '@/components/PanThumb'

export default {
  name: "ComponentsProfileIndex",
  components: {
    PanThumb
  },
  data() {
    return {
      data: {
        sns_infos: {
          twitter: '',
          instagram: '',
        },
      },
      bk_url: '',
      current_title: ''
    }
  },
  props: {
    profile_id: {
      type: Number,
      default: -1
    }
  },
  async created() {
    await this.init();
  },
  computed: {
    keysFollowing() {
      return this.$store.state.user.keysFollowing;
    }
  },
  methods: {
    isNotSelf(id){
      return this.$store.state.user.profile_id !== id
    },
    isStreamer(item){
      return item.profileable_type === 'STREAMER';
    },
    setBackgroudColor() {
      const attribute_list = this.$store.state.system.enum_list.attribute_list;
      this.bk_url = getBackgroundColorImage(this.data.profileable.current_title, this.data.attribute, attribute_list);
    },
    onSNS(link) {
      window.open(link,'_blank');
    },
    async init() {
      const { data } = await getProfile(this.profile_id);
      if (!data.sns_infos) {
        data.sns_infos = {
          twitter: '',
          instagram: '',
        }
      }
      this.data = data;
      this.current_title = data.profileable.current_title;
      const retFollowings = (await getFollowings(this.$store.state.user.profile_id))
      await this.$store.dispatch("user/setFollowingId",retFollowings.data.meta.following_id_list);
      this.setBackgroudColor();
    },
    async onFollow(id) {
      await this.$store.dispatch("user/addFollowing", id);
      let listId = this.keysFollowing;
      listId.push(id);
      await this.$store.dispatch("user/setFollowingId",listId);
      this.init()
    },
    async onFollowCancel(id) {
      await this.$store.dispatch("user/removeFollowers", id);
      await this.$store.dispatch("user/setFollowingId",this.keysFollowing.filter(item => item != id));
      this.init()
    },
    getAvatar(avatar_store_path){
      return getImageUrl(avatar_store_path)
    },
    linkFollow(type) {
      let tab = '';
      type == 'follow' ? tab = 'follow' : tab = 'follower';
      let link = this.$router.resolve({ name: 'TopProfileFollow', query: { profile: this.profile_id, tab: tab }});
      window.open(link.href,'_blank');
    },
    checkBackgroundColor() {
      return this.bk_url.includes('white') ? '#666666' : 'white';
    }
  },
}
</script>
