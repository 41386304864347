export default {
  methods: {
    async onClickBanner(){
      let liveable_id = this.data.liveable_id ? this.data.liveable_id : -1
      if (liveable_id === -1) {
        this.$alert("エラーが発生しました。時間を置いて再度アクセスしてください。");
        return;
      }
      let liveType = this.getLiveType();
      if(liveType === ""){
        this.$alert("エラーが発生しました。時間を置いて再度アクセスしてください。")
        return;
      }
      if(!this.canPlay){
        //alert('配信は開始されておりません。')
        // 予約配信
        // クラファン
        if( this.isFund ){
          let link = this.$router.resolve({ name: liveType, params: { liveable_id: this.data.liveable_id }});
          window.open(link.href,'_blank');
          return;
        }
        return;
      }
      if(this.isLiveLink(liveType)) {
        let link = this.$router.resolve({ name: liveType, params: { id: this.data.live_id }, query: { liveable_id: this.data.liveable_id } });
        window.open(link.href,'_blank');
      } else {
        let link = this.$router.resolve({ name: liveType, params: { liveable_id } });
        window.open(link.href,'_blank');
      }
    },
    isLiveLink(liveType) {
      switch (liveType) {
        case "TopLiveNormal":
        case "TopLiveEvent":
        case "ArchiveLiveNormal":
        case "ArchiveLiveEvent":
        case "TopLivePaid":
        case "ArchiveLivePaid":
          return true;
        default:
          return false;
      }
    },
    getLiveType() {
      let liveType = '';
      // アーカイブ
      if (this.isArchive) {
        if(this.isNormal){
          liveType = "ArchiveLiveNormal";
        } else if(this.isEvent){
          liveType = "ArchiveLiveEvent"
        } else if(this.isFund){
          liveType = "TopFundDetail"
        } else if(this.isPay){
          liveType = "ArchiveLivePaid"
        }
      } else {
        if(this.isNormal){
          liveType = "TopLiveNormal";
        } else if(this.isEvent){
          liveType = "TopLiveEvent"
        } else if(this.isFund){
          liveType = "TopFundDetail"
        } else if(this.isPay){
          liveType = "TopLivePaid"
        }
      }
      return liveType;
    }
  },
}
