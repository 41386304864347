import request from '@/utils/request'

export function getDeliveryInfoHistory(id) {
    return request({
        url: `/delivery_information/${id}`,
        method: 'get',
    })
}

export function createDeliveryInfoHistory(data) {
    return request({
        url: `/delivery_information_history`,
        method: 'post',
        data: data
    })
}