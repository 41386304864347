<template lang="pug">
  .root
    button(@click="$emit('close')" class="close-button") X
    div(class="t-c m-b target-center-item") 現在累計{{ $store.state.live.info.target.current_point || 0 }}Pt 獲得 {{ $store.state.live.info.target.percentString || 0 }}％達成
    //- div(class="target-center-item rank-title") ターゲット名:
    div.rank-title {{ $store.state.live.info.target.name }}
    div(class="target-img")
      img(:src="$store.state.system.config.storage_addr_list.public + $store.state.live.info.target.banner_store_path")
    div(class="rank-title") 目標ギフトポイント:{{ $store.state.live.info.target.target_point }}pt
      //- div {{ $store.state.live.info.target.current_point }}
    //- div(class="rank-title") チャレンジ内容:
    div.rank-content {{ $store.state.live.info.target.content }}
    div(v-if="$store.state.live.info.target.ranking.length >0")
      div.rank-title ランキング
      div(class="live-user-rank")
        div.rank-item(v-for="(item,index) in $store.state.live.info.target.ranking")
          //- img(:src="$store.state.system.config.storage_addr_list.public + item.profile.avatar_store_path" @click="onAvatarClick(item.profile.id)")
          div.account-img(@click="onAvatarClick(item.profile.id)")
            <pan-thumb :avatar="item.profile.avatar_store_path" :attribute="getAttribute(item)" :current_title="item.viewer_info.current_title"  size="70px"/>
            div(v-if="index < 3" class="rank-top-three")
              img(:src="index == 0 ? '/img/badge_gold.png' : index == 1 ? '/img/badge_silver.png' : index == 2 ? '/img/badge_copper.png' : ''")
              span(class="rank-badge") {{index + 1}}
          div.rank-item-name {{ item.profile.account_name }}
          div.rank-item-point
            <span class="badge badge-pink badge-pill" style="color: #fff"> P </span> {{ item.total_points }}
          button(:class="item.is_following ? 'gray-button-rank' : 'pink-button-rank'" v-if="item.profile.id && isNotSelf(item.profile.id) && item.profile.status !== 'private'" @click="item.is_following ? onFollowCancel(item.profile.id): onFollow(item.profile.id)") {{ item.is_following ? 'フォロー済み' : 'フォローする' }}
        a(v-if="rankingShowMore" style="cursor: pointer" @click="putOtherRankingUser()") >>もっと見る
</template>

<style src="./index.scss" lang="scss" scoped>
</style>

<script>
import PanThumb from '@/components/PanThumb'


export default {
  name: "ComponentsTargetViewIndex",
  components: {
    PanThumb
  },
  data() {
    return {
      otherProfile_id: '',
    }
  },
  props: {},
  computed: {
    rankingShowMore() {
      return this.$store.state.live.info.target.otherRanking.length > 0 && this.$store.state.live.info.target.ranking.length < 13
    }
  },
  watch: {},
  mounted() {},
  async created() {
    this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
    },
    onClose() {
      window.close();
    },
    async putOtherRankingUser() {
      await this.$store.dispatch('live/putOtherRankingUser')
    },
    onAvatarClick(profile_id) {
      this.$emit('onRankAvatarClick', profile_id)
    },
    getAttribute(item){
      return item.profile.attribute
    },
    isNotSelf(id){
      return this.$store.state.user.profile_id !== id
    },
    async onFollow(id) {
      await this.$store.dispatch("user/addFollowing", id);
      await this.$store.dispatch('live/syncTarget');
    },
    async onFollowCancel(id) {
      await this.$store.dispatch("user/removeFollowers", id);
      await this.$store.dispatch('live/syncTarget');
    },
  }
};
</script>
