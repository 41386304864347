<template lang="pug">
div(style="margin: 0 auto; text-align: center")
  <!-- div(class="explanation-video") ↓ Aライブの楽しみ方はこちら ↓

  video-player(src="/video/test.mp4")

  div.use-flow 画像[諸説明] 
    p 属性やｸﾗﾌｧﾝ配信があることからアーティスト支援の流れ -->
    
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import VideoPlayer from "@/components/VideoPlayer/index.vue";
export default {
  name: "ComponentsLoginIndex",
  components: {
    VideoPlayer,
  },
  data() {
    return {
      player: null,
    };
  },
};
</script>
