<template lang="pug">
  div.youtube
    <iframe v-bind:src="`https://www.youtube.com/embed/${srcKey}`"></iframe>
</template>

<style src="./youtubePlayer.scss" lang="scss" scoped>
</style>

<script>
export default {
  name: "ComponentYoutubePlayer",
  props: {
    srcKey: {
      type: String,
      required: true
    },
  },
}
</script>
