import axios from 'axios'

export function getFireEffect() {
    // 非同期処理を記述
    return axios.get('/pixi/particles/fire/fire.json');
}

export function getSnowEffect() {
    // 非同期処理を記述
    return axios.get('/pixi/particles/snow/snow.json');
}

export function getWindAirEffect() {
    // 非同期処理を記述
    return axios.get('/pixi/particles/wind/wind_air/wind_air.json');
}

export function getWindFlowerEffect() {
    // 非同期処理を記述
    return axios.get('/pixi/particles/wind/wind_flower/wind_flower.json');
}

export function getWindFlower2Effect() {
    // 非同期処理を記述
    return axios.get('/pixi/particles/wind/wind_flower/wind_flower2.json');
}