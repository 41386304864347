<template lang="pug">
  .root
    <div class="animation-target">
      <img v-if="isBeast" src="/img/mission-complete/misson_complete_20000.gif" alt=""/>
      <img v-else src="/img/mission-complete/misson_complete_2000.gif" alt=""/>
    </div>
</template>

<style src="./MissionComplete.scss" lang="scss" scoped>

</style>

<script>
export default {
  name: "LiveComponentMissionComplete",
  components: {},
  data() {
    return {
    }
  },
  props: {
    isBeast: {
      type: Boolean,
      default: false
    },
  },
  computed: {},
  watch: {},
  mounted() {},
  async created() {
  },
  beforeDestroy() {
  },
  updated() {},
  methods: {
  }
};


</script>
