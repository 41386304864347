import * as PIXI from 'pixi.js';
window.PIXI = PIXI;
import "pixi-spine";
// const Spine = require('pixi-spine') ;
let pixiApp = null;
let particleContainer = null;
let effects = {};
let parentrect = null;
let animCompleteCallback = ()=>{};
// let isInited = false;

export function initPixi(devicePixelRatio,rectPixi,updateFunc,animCompleteFunc){
  pixiApp = Object.seal(
    new window.PIXI.Application({
      autoResize: true,
      resolution: devicePixelRatio || 1,
      antialias: true,
      transparent: true,
      backgroundColor: 0x000000,
      resizeTo: rectPixi
    })
  );
  pixiApp.renderer.plugins.interaction.autoPreventDefault = false
  pixiApp.renderer.view.style.touchAction = 'auto'
  rectPixi.appendChild(pixiApp.view)
  const container = new window.PIXI.Container();
  pixiApp.stage.addChild(container);
  particleContainer = container;
  pixiApp.ticker.add(updateFunc);
  animCompleteCallback = animCompleteFunc;
  parentrect = rectPixi;
  return particleContainer
  // isInited = true;
  // pixiApp.loader.add('Zeus', '/pixi/spine/Zeus/Zeus.json')
  //   pixiApp.loader.add('Ball', '/pixi/spine/Ball/Ball.json')
  //   pixiApp.loader.add('Meerkat', '/pixi/spine/Meerkat/Meerkat.json')
  //   pixiApp.loader.add('NvShen', '/pixi/spine/NvShen/NvShen.json')
  //   pixiApp.loader.add('Phoenix', '/pixi/spine/Phoenix/Phoenix.json')
  //   pixiApp.loader.add('PinkShit', '/pixi/spine/PinkShit/PinkShit.json')
}

export function resizePixi() {
  pixiApp.renderer.resize(parentrect.offsetWidth, parentrect.offsetHeight);
}

export async function playSpineAnimation(resString){
  console.log('start play ' + resString)
  if(effects[resString]){
    
    resizeObject(resString, effects[resString])
    effects[resString].visible = true;
    effects[resString].state.setAnimation(0, 'animation', false)
    // effects[resString].state.onComplete = function(trackIndex,loopCount){
    //   console.log('track1111'+trackIndex +'completed1111'+loopCount)
    // }
    return
  }
  await load(resString, true);
  pixiApp.start()
}
function resizeObject(resString, animation) {
    switch(resString) {
      case 'Zeus':
        animation.x = 360
        animation.y = 350
        animation.scale.x = animation.scale.y = 1;
        break
      case 'NvShen':
        animation.x = 360
        animation.y = 900
        animation.scale.x = animation.scale.y = 1.6;
        break
      case 'Phoenix':
        animation.x = 370
        animation.y = 490
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Dolphin':
        animation.x = 165
        animation.y = 830
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Whale':
        animation.x = 290
        animation.y = 420
        animation.scale.x = animation.scale.y = 1;
        break
      case 'FlyHorse':
        animation.x = 360
        animation.y = 460
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Tiger':
        animation.x = 370
        animation.y = 395
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Tiger_back':
        animation.x = 360
        animation.y = 425
        animation.scale.x = animation.scale.y = 1;
        break
      case 'IceDragon':
        animation.x = 360
        animation.y = 540
        animation.scale.x = animation.scale.y = 1;
        break
      case 'PinkShit':
        animation.x = 360
        animation.y = 400
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Meerkat':
        animation.x = 360
        animation.y = 300
        animation.scale.x = animation.scale.y = 1.2;
        break;
      case 'yanhua_a':
        animation.x = 360
        animation.y = 240
        animation.scale.x = animation.scale.y = 0.7;
        break;
      case 'yanhua_b':
        animation.x = 360
        animation.y = 240
        animation.scale.x = animation.scale.y = 0.7;
        break;
      default:
        animation.x = 360
        animation.y = 300
        animation.scale.x = animation.scale.y = 1;
        break;
    }
    if (window.innerWidth < 800) {
        if (window.innerWidth < window.innerHeight) {
          animation.scale.x = animation.scale.y = animation.scale.x / 2;

          animation.x = animation.x / 2;
          animation.y = animation.y / 2;
        }
    }

}

export async function preparationPixi(types) {
  for (const type of types || []) {
    await load(type, false);
  }
}

function load(type, run = false) {
  if (effects[type]) {
    return;
  }

  return new Promise((resolve) => {
    pixiApp.loader.add(type, `/pixi/spine/${type}/${type}.json`).load((loader,resources)=>{
      let animation = null;
      if (effects[type]) {
        animation = effects[type];
        animation.visible = run ? true : animation.visible;
      } else {
        // TODO try to optimize this
        animation = new window.PIXI.spine.Spine(resources[type].spineData)
        animation.visible = !!run;
        animation.state.onComplete = function(trackIndex,loopCount){
          console.log('track'+trackIndex +'completed'+loopCount)
          // pixiApp.stage.removeChild(animation)
          animation.visible = false
          if(animCompleteCallback){
            animCompleteCallback()
          }
        }

        //this part adjusts the ratio:
        // pixiApp.renderer.resize(pixiApp.renderer.view.style.width,pixiApp.renderer.view.style.height);
    
        console.time(type)
        pixiApp.stage.addChild(animation)
        effects[type] = animation
        console.timeEnd(type)
        resizeObject(type, animation)
      }
  
      if (animation.state.hasAnimation('animation')) {
        if (run) {
          // run forever, little boy!
          animation.state.setAnimation(0, 'animation', false)
        }
        // dont run too fast
        animation.state.timeScale = 1
      }
      resolve();
    })
  })
}

export {particleContainer,pixiApp}
