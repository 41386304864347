<template lang="pug">
.root
  component-header-index
  .center
    .spPickUp(:class="PaidBuyPanelShow?'dnsp':''")
      swiper(:options="swiperOption")
        swiper-slide(v-for="(item, index) in $store.state.ad.list" :key="index")
          img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path" @click="onAdClick(item.url)")
        .swiper-pagination(slot="pagination")
        .swiper-button-prev(slot="button-prev")
          <img src="/img/arrow-l.png">
        .swiper-button-next(slot="button-next")
          <img src="/img/arrow-r.png">
    .leftContainer(:class="PaidBuyPanelShow?'dnsp':''")
      ul.accountList
        li(
          v-for="item in ui.list",
          :key="item.id",
          :style="{ 'list-style-type': 'none', background: 'url(' + (item.room_info.banner_store_path ? $store.state.system.config.storage_addr_list.public + item.room_info.banner_store_path : '/img/test.png') + ') center no-repeat' }"
        ) 
          a(@click="onLiveClick(item.id, item.liveable_type, item.liveable_id)")
            img(class="accountList_live_type"
              v-if="item.liveable_type === 'FUND' || item.liveable_type === 'PAY'",
              :src="item.liveable_type === 'PAY' ? '/img/yuryo.png' : '/img/crfun.png'"
            )
            .liveInfo
              div.liveDetail
                p.liveTitle {{ item.room_info.name }}
                div.liveData
                  span
                    <img src="/img/user_icon.svg"> {{ item.viewer_count ? item.viewer_count : 0 }}
                  div &nbsp;&nbsp;
                  span.liveInfo(v-if="item.liveable_type !== 'FUND'" aria-hidden="true") {{ Number(item.point).toLocaleString() }}pt
                  div &nbsp;&nbsp;
                  span
                    <img src="/img/clock_icon.svg"> {{ item.started_at | moment("MM/DD,HH:mm") }}~
      h6.seeMore
        a(v-if="hasMore" @click="showMore()" style="color: black;") >>もっと見る
    .rightContainer(v-if="!PaidBuyPanelShow")
      td.pickUp
        .pr-list
          PRBanner
    .PaidByContainer
      PaidBuyPanel(ref="PaidBuyPanel" :showPanel="PaidBuyPanelShow" @showPanelChange="visibleChange")
  component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getLiveDurationStr } from "@/utils/tools";
import PRBanner from '@/components/PRBanner'
import PaidBuyPanel from '@/components/PaidBuyPanel'

export default {
  name: "LoginNewIndex",
  components: {
    PRBanner,
    PaidBuyPanel
  },
  data() {
    return {
      pageLimit:90,
      currentPage:1,
      list: [],
      ui: {
        list: [],
      },
      swiperOption: {
        autoplay: {
          delay: 3000,
        },
        loop: true,
        slidesPerView: 2,
        spaceBetween: 5,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      paidLive:{
        id:0,
        liveable_id:''
      },
      PaidBuyPanelShow: false,
    };
  },
  props: {},
  computed: {
    hasMore() {
      return this.list.length > this.ui.list.length;
    },
    isBuyPay(){
      return this.$store.state.live.info.is_buy_pay;
    },
  },
  watch: {},
  mounted() {},
  async created() {
    await this.$store.dispatch("live/syncLiveListNew", { limit:this.pageLimit, page:this.currentPage });
    this.list = this.$store.state.live.liveList;
    this.list.forEach((element,index) => {
      if (index < 15) {
        this.ui.list.push(element);
      }
    });
  },
  updated() {},
  destroyed() {},
  methods: {
    onAdClick(URL) {
      window.location.href = URL;
    },
    showMore() {
      let x = this.ui.list.length;
      this.list.forEach((element,index) => {
        if (index > x - 1 && index < x + 15) {
          this.ui.list.push(element);
        }
      });
    },
    async onLiveClick(id, type, liveable_id) {
      if (id === -1) {
        alert("配信Not Found!!!!!");
        return;
      }
      let liveType = "";
      if (type === "FUND") {
        liveType = "TopLiveFund";
      } else if (type === "PAY") {
        liveType = "TopLivePaid";
      } else if (type === "NORMAL") {
        liveType = "TopLiveNormal";
      } else if (type === "EVENT") {
        liveType = "TopLiveEvent";
      }
      if (type === "PAY") {
        await this.$store.dispatch('live/syncLiveInfo', id).then(() => {
          this.$store.dispatch('live/syncLiveTicketInfo', liveable_id).then(()=>{
            if(!this.$store.state.live.info.is_buy_pay){
              this.PaidBuyPanelShow = true
              return
            } else {
              let routeData = this.$router.resolve({
                name: liveType,
                params: { id },
                query: {liveable_id}
              });
              window.open(routeData.href, '_blank');
            }
          });
        }).catch((error)=>{
          // ブロックされた場合エラーメッセージを出す
          if (error.response.data.code === 400114) {
            this.$alert("購入が拒否されました")
          }
          console.error(error);
        });
      } else {
        let routeData = this.$router.resolve({
          name: liveType,
          params: { id },
          query: {liveable_id}
        });
        window.open(routeData.href, '_blank');
      }
    },
    visibleChange(value) {
      this.PaidBuyPanelShow = value;
    },
    getLiveDurationStr(value) {
      return getLiveDurationStr(value);
    },

  },
};
</script>
