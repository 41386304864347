<template lang="pug">
  .root
    component-header-index

    div(class="container-flex")
      div(:class="ui.tab === 'PR' ? 'content-news-info' : 'content-news-info content-news-info-dn'")
        div(class="news-list-breadcrumb")  >>
          el-tabs( v-model="activeName" @tab-click="handleClick" style="margin-left:4px")
            el-tab-pane(label="運営" name="Admin")
            el-tab-pane(label="フォロー" name="Follow")
        div(class="newsList")
          div(v-for="item in showList" class="news-item")
            a(@click="onRead(item.id)")
              i.fas.fa-circle(v-if="!showReadedList.includes(item.id)" style="color: red; position: absolute")
              div(class="news-item-img")
                img(v-if="item.banner_store_path" :src="getBannerUrl(item.banner_store_path)")
              div(class="news-item-detail")
                div(class="news-item-detail-title") {{ item.title }}
                div.news-item-detail-comment {{ToText(item.describe)}}
                div(class="news-item-detail-day")
                  span(v-if="!isAdminList" class="follow-account-name-pc") <img src="/img/profile_icon_pink.svg"> {{ item.account_name }}
                  span(v-if="!isAdminList" class="follow-account-name-sp") <img src="/img/profile_icon_pink.svg"> {{ item.account_name }}
                  div(class="follow-account-name-date pc") 更新日時 {{ new Date(item.public_time).getTime() | moment("YYYY/MM/DD HH:mm") }}
        div(v-if="haveMore" class="request-more")
          a(@click="requestMore") >>もっと見る

      <div v-if="ui.tab === 'PR'">
        PRBanner
      </div>
      <div v-if="ui.tab === 'Detail'">
        div(class="right-content-border")
          button(@click="onClose()" class="close-button") X
          div(class="select-news-info")
            div(v-if="!isAdminList")
              span(class="follow-account-name-pc detail-account-name" @click="onStreamerNameClick(oneNewsInfo.profile_id)") <img src="/img/profile_icon_pink.svg"> {{ oneNewsInfo.account_name }}
              span(class="follow-account-name-sp detail-account-name" @click="onStreamerNameClick(oneNewsInfo.profile_id)") <img src="/img/profile_icon_pink.svg"> {{ oneNewsInfo.account_name }}
            div
              <img src="/img/clock_icon_green.svg"> {{ oneNewsInfo.public_time | moment("YYYY/MM/DD HH:mm") }}
            div {{ oneNewsInfo.title }}
          <div class="select-news-img">
            <img v-if="oneNewsInfo.banner_store_path" :src="getBannerUrl(oneNewsInfo.banner_store_path)" class="rounded img-list" alt="">
          </div>
          div( v-html="oneNewsInfo.describe")
      </div>

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner'
import { getImageUrl } from '@/utils/tools'
import { Tabs, TabPane } from 'element-ui';

export default {
  name: "TopBroadcastIndex",
  components: {
    PRBanner,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
  },
  data() {
    return {
      pageLimit:6,
      followNoticeList:[],
      activeName: 'Admin',
      ui: {
        showPanel: '',
        tab: 'PR',
      },
      oneNewsInfo: {}
    }
  },
  props: {},
  computed: {
    isAdminList(){
      return this.activeName === 'Admin'
    },
    showList(){
      return this.isAdminList ? this.$store.state.news.list : this.$store.state.news.followNewsList
    },
    showReadedList() {
      return this.isAdminList ? this.$store.state.news.readed_id_list : this.$store.state.news.follow_readed_id_list
    },
    haveMore(){
      return this.isAdminList ? this.$store.state.news.adminNum.total > this.showList.length : this.$store.state.news.followNum.total > this.showList.length;
    },
  },
  watch: {},
  mounted() {},
  async created() {
    await this.$store.dispatch('news/getList',this.pageLimit);
    await this.$store.dispatch('news/getFollowNewsList',this.pageLimit);
  },
  updated() {},
  destroyed() {},
  methods: {
    async onRead(id) {
      this.ui.showPanel = true;
      try {
        this.oneNewsInfo = this.isAdminList ? await this.$store.dispatch('news/readNews', id) : await this.$store.dispatch('news/readFollowNews',id);
      }catch(error) {
        this.$message.error('読み済み設定に失敗しました')
        console.log(error)
      }
      // ローカル読み済みお知らせリスト更新
      if(this.isAdminList) {
        await this.$store.dispatch('news/updateReadedIdList', id)
      } else {
        await this.$store.dispatch('news/updateFollowReadedIdList',id)
      }
      this.ui.tab = 'Detail'
    },
    onClose() {
      this.ui.tab = 'PR'
    },
    resetDetailPanel(){
      this.ui.tab = 'PR'
      this.ui.showPanel = false;
      this.oneNewsInfo = {}
    },
    async handleClick(){
      this.resetDetailPanel()
    },
    getBannerUrl(url){
      return getImageUrl(url)
    },
    async requestMore(){
      this.isAdminList ? await this.$store.dispatch('news/getList',this.$store.state.news.adminNum.total):await this.$store.dispatch('news/getFollowNewsList',this.$store.state.news.followNum.total);
    },
    onStreamerNameClick(profile_id) {
      let link = this.$router.resolve({ name: 'TopProfileStyle2', params: { profile_id } })
      window.open(link.href,'_blank');
    },
    ToText(HTML){
      var input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');  
    }
  }
};
</script>
