<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-paymentformcvs")

        div コンビニ決済の必要事項を入力してください。

        div(class="cvsForm")
          h5 お客様の氏名
          input(v-model="customerData.customer_name")
          br
          h5 お客様のフリガナ
          input(v-model="customerData.customer_kana")
          br
          h5 お客様の電話番号
          input(v-model="customerData.tel_no")
          br
          h5 決済を行うコンビニ
          select(v-model="customerData.convenience")
            <option disabled value="">選択してください</option>
            <option v-for="option in convenienceCode" v-bind:value="option.value">
              | {{ option.text }}
            </option>
          br
          h5 お客様のメールアドレス
          input(v-model="customerData.mail" autocapitalize="off")
          br


        .buttonArea
          .errorMessageArea
            .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}
          button.pink-button.onNextButton(@click="onConfirmGmoCvs()") 次へ
        
        //div ※ここで購入したGはAライブ内の全サービスでご利用いただけます。
        //div >>資金決済法に基づく表示
        //div(@click="onClick1()") >>特定商取引法の表記
        div(class="coin-buy-footer-button")
          button(@click="onBack()" class="pink-button") 戻る

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormCVS.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'

export default {
  name: "TopProfilePaymentFormCVS",
  components: {
    PRBanner,
  },
  data() {
    return {
      ui: {
        errorMessage: "Error",
        showError: false,
      },
      customerData: {
        customer_name: '',
        customer_kana: '',
        tel_no: '',
        convenience: '',
        mail: '',
      },
      buyItemList: [],
      buyType: '',
      successUrl: '',
      cancellUrl: '',
      convenienceCode: [
        { text: 'ローソン', value: '10001' },
        { text: 'ファミリーマート', value: '10002' },
        { text: 'ミニストップ', value: '10005' },
        { text: 'セイコーマート', value: '10008' },
      ],
    }
  },
  async created() {
    this.buyItemList = this.$route.params.buyList;
    this.buyType = this.$route.params.buyType;
    this.successUrl = this.$route.params.successUrl;
    this.cancellUrl = this.$route.params.cancellUrl;
    if( !this.buyItemList ){
      this.$alert('商品が正しく選択されていません。')
      this.$router.push({ name: 'TopProfileBuy'});
      return;
    }
  },
  methods: {
    async onConfirmGmoCvs() {
      if( 
        !this.customerData.customer_name ||
        !this.customerData.customer_kana ||
        !this.customerData.tel_no ||
        !this.customerData.convenience ||
        !this.customerData.mail
      ){
        this.ui.showError = true;
        this.ui.errorMessage = "未入力箇所があります。必ず入力してください。";
        return;
      }
      const resultData = this.customerData;
      const buyList = this.buyItemList;
      this.$router.push({ name: 'TopProfilePaymentFormCVSConfirm', params: {
        resultData,
        buyList,
      } });
    },

    onBack() {
      this.$router.push({
        name: 'TopProfileBuy'
      })
    },
  }
}
</script>
