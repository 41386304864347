<template lang="pug">
  .root
    div(v-if="tab === 'RegistCredit'" class="right-content-border")
      button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
      div(class="m-b") クレジットカードの登録
        div(class="m-b")
          div(class="credit-input-item") 名前
            input(v-model="creditData.name" class="input-g-w-border")
          div(class="credit-input-item") カード番号
            input(v-model="creditData.code" class="input-g-w-border")
          div(class="credit-input-item expiration-date") 有効期限
            div
              span(style="margin-right: 9%;") 月
              span(style="margin: 0 8px;font-weight: bold;font-size:15px;") /
              span 年(西暦下二桁)
            div
              input(v-model="creditData.expiration_date_month" class="input-g-w-border expiration-date-month")
              span(style="margin: 0 8px;font-weight: bold;font-size:16px;") /
              input(v-model="creditData.expiration_date_year" class="input-g-w-border expiration-date-year")
            span(style="color: red;") ※月/年は0を含める
          div(class="credit-input-item") セキュリティコード
            input(v-model="creditData.security_code" class="input-g-w-border")
        div(class="t-c")
          button(@click="onCreateCredit()" class="pink-button") 登録する

    div(v-if="tab === 'ChangeCredit'" class="right-content-border")
      button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
      div(class="m-b") 登録されているクレジットカード
        div(class="m-b")
          h5 名前
          p(class="credit-font") {{ this.credit.holderName }}
        div(class="m-b")
          h5 カード番号
          p(class="credit-font") {{ this.credit.cardNo }}
        div(class="m-b")
          h5 有効期限
          p(class="credit-font") {{ this.credit.exDateYear }} / {{ this.credit.exDateMonth }}
      div(class="t-c")
        button(@click="onDeleteCredit()" class="pink-button") 削除する
      h5 サイト内にクレジットカード情報は保持されません。

    div(v-if="tab === 'CreditChanged'" class="right-content-border")
      button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
      div(class="m-b") クレジットカードの登録/削除
        div(class="m-b")
          h5 {{ this.credit.manipulation }}が完了しました。
    
</template>

<style src="./index.scss" lang="scss" scoped>
</style>

<script>
import { getStripeData, createPaymentCard, DeleteCredit } from '@/api/payment';
import { isPrd } from '@/utils/tools';

export default {
  name: "ComponentsCreditCard",
  data() {
    return {
      tab: '',
      credit: {
        holderName: '',
        cardNo: '',
        exDateYear: '',
        exDateMonth: '',
        manipulation: '',
      },
      creditData: {
        name: '',
        code: '',
        expiration_date_month: '',
        expiration_date_year: '',
        security_code: '',
      },
    }
  },
  computed: {
  },
  mounted(){
    // TODO : 外だし対応必要
    this.shopId = 'tshop00050484';
    let url = 'https://stg.static.mul-pay.jp/ext/js/token.js';
    // 本番はurlとshopIdが違う
    if ( isPrd() ){
      url = 'https://static.mul-pay.jp/ext/js/token.js';
      this.shopId = '9200003409720';
    }
    let cardTokenScript = document.createElement('script');
    cardTokenScript.setAttribute('src', url);
    document.head.appendChild(cardTokenScript);
  },
  created() {
    this.init();
  },

  methods: {
    async init() {
      const ret = await getStripeData()
      if(ret.data.ErrCode) {
        this.tab = 'RegistCredit'
      } else {
        this.credit.holderName = ret.data.HolderName;
        this.credit.cardNo = ret.data.CardNo;
        this.credit.exDateYear = ret.data.Expire.slice(2,4);
        this.credit.exDateMonth = ret.data.Expire.slice(0,2);
        this.tab = 'ChangeCredit'
      }
    },

    async onCreateCredit() {
      if(
        !this.creditData.name ||
        !this.creditData.code ||
        !this.creditData.expiration_date_month ||
        !this.creditData.expiration_date_year ||
        !this.creditData.security_code
        ) {
        this.$alert("未入力箇所があります。必ず入力してください。")
        return;
      }
      // token 取得システム初期化
      window.Multipayment.init(this.shopId);
      // コールバック関数の設定
      const self_ = this;
      window.PaymentTokenCallbackTunnel = function PaymentTokenCallbackTunnel(result){
        self_.onTokenCallback(result);
      }
      // トークン変換
      window.Multipayment.getToken(
        {
          cardno: this.creditData.code,
          expire: this.creditData.expiration_date_year + this.creditData.expiration_date_month,
          securitycode: this.creditData.security_code,
          holdername: this.creditData.name,
        },
        window.PaymentTokenCallbackTunnel);
    },

    async onTokenCallback(result){
      if( result.resultCode != '000' ){
        this.$alert('クレジットカードの認証に失敗しました。入力情報をお確かめください。')
        return;
      }
      await createPaymentCard(result.tokenObject.token).then(() => {
        this.credit.manipulation = '登録'
        this.tab = 'CreditChanged'
      }).catch(() => {
        this.$alert("決済エラーが発生しました。このカードは現在お取り扱いできません。")
        return;
      });
    },

    async onDeleteCredit() {
      await DeleteCredit().then(() => {
        this.$alert('削除が完了しました。' , 'クレジットカードの登録/削除')
        this.credit.manipulation = '削除'
        this.tab = 'CreditChanged'
      }).catch(() => {
        this.$alert('決済エラーが発生しました。このカードは現在お取り扱いできません。')
      });
    },

    onClose() {
      this.$emit('onCreditClose')
    }
  }
}
</script>