<template lang="pug">
  .root
    component-header-index(v-if="$store.state.user.email")
    component-header-login(v-if="!$store.state.user.email")
    
    div(class="container-flex")
      div
        div(v-if="$store.state.user.email")
          span プロフィールを変更>>属性解説
          
        img(src="/img/beast1.png")
        div 属性SDｷｬﾗ
        div 属性背景枠
        
        img(src="/img/beast2.png")
        div 属性SDｷｬﾗ
        div 属性背景枠

        img(src="/img/beast3.png")
        div 属性SDｷｬﾗ
        div 属性背景枠

        img(src="/img/beast4.png")
        div 属性SDｷｬﾗ
        div 属性背景枠

        img(src="/img/got1.png")
        div 属性説明補足 ゼウス・女神の説明など
    
    component-footer-login
</template>

<style src="./Attribute.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "TopProfileAttribute",
  components: {},
  data() {
    return {
      form: {
        type: ''
      },
      ui: {
        type: []
      }
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    this.ui.type = [
      { name: '無属性', value: this.$store.state.system.enum_list.attribute_list.NONE },
      { name: '炎属性', value: this.$store.state.system.enum_list.attribute_list.FIRE },
      { name: '氷属性', value: this.$store.state.system.enum_list.attribute_list.ICE },
      { name: '風属性', value: this.$store.state.system.enum_list.attribute_list.WIND },
      { name: '地属性', value: this.$store.state.system.enum_list.attribute_list.EARTH },
    ]
    this.form.type = this.$store.state.user.attribute;
  },
  updated() {},
  destroyed() {},
  methods: {
    async onNext() {
      if (this.form.type !== this.$store.state.system.enum_list.attribute_list.NONE) {
        await this.$store.dispatch('user/setAttribute', this.form.type);
      }
      this.$router.push({ name: 'TopIndex' })
    }
  }
};
</script>
