<template lang="pug">
.root
  .bottomNav
    .bottomNav-img
</template>

<style src="./header.scss" lang="scss" scoped>
</style>

<script>
export default {
  name: "ComponentsHeaderLogin",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>