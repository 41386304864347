import { getSystemInfo,getSystemTimeStamp } from '@/api/system';
import moment from 'moment'

const getDefaultState = () => {
  return {
    lastInitTs: 0,
    timestamp: 0,
    time_diff:0,
    enum_list: {
      "module_list":{
          "HOST":"host",
          "AGENT":"agent",
          "ADMIN":"admin",
          "STREAMER":"streamer",
          "VIEWER":"viewer"
      },
      "broadcast_event_status_list":{
          "ACCESSIBLE":0,
          "CREATED_2_UNPUBLICED":1,
          "PUBLICED_2_UNRECRUITMENT_STARTED":2,
          "RECRUITMENT_STARTED_2_UNRECRUITMENT_ENDED":4,
          "RECRUITMENT_ENDED_2_UNSTARTED":8,
          "STARTED_2_UNENDED":16,
          "ENDED":32,
          "ALL":63
      },
      "broadcast_pay_buy_status_list":{
          "UNBUY":"s1",
          "BUYING":"s2",
          "BUYED":"s3"
      },
      "broadcast_fund_status_lilst":{
          "ACCESSIBLE":0,
          "CREATED_2_UNPUBLICED":1,
          "PUBLICED_2_UNSTARTED":2,
          "STARTED_2_UNENDED":4,
          "ENDED":8,
          "ALL":15
      },
      "broadcast_fund_result_lilst":{
          "NONE":"r0",
          "SUCCEED":"r1",
          "FAILED":"r2"
      },
      "broadcast_fund_achievement_condition_list":{
          "CASH":"a1",
          "COIN":"a2"
      },
      "broadcast_fund_recruitment_method_list":{
          "ALL_IN":"r1",
          "ALL_OR_NOTHING":"r2"
      },
      "live_list_category_list":{
          "POPULAR":"c1",
          "RECOMMEND":"c2"
      },
      "live_status_list":{
          "SCHEDULE":"s1",
          "PREPARATION":"s2",
          "ONGOING":"s3",
          "ENDED":"s4",
          "REPLAYING":"s5"
      },
      "attribute_list":{
          "NONE":"a0",
          "FIRE":"a1",
          "ICE":"a2",
          "WIND":"a3",
          "EARTH":"a4"
      },
      "gift_type_list":{
          "GEM_YELLOW":"1-1-1",
          "GEM_GREEN":"1-1-2",
          "GEM_BLUE":"1-1-3",
          "GEM_RED":"1-1-4",
          "GEM_PURPLE":"1-1-5",
          "RICEBALL_PINK":"1-2-1",
          "STRAWBERRY_RED":"1-3-1",
          "STRAWBERRY_WHITE":"1-3-2",
          "MACARON_YELLOW":"1-4-1",
          "MACARON_WATER":"1-4-2",
          "MACARON_GREEN":"1-4-3",
          "MACARON_PURPLE":"1-4-4",
          "BOUQUET":"1-5-1",
          "HOLECAKE":"1-6-1",
          "CLOVER_PINK":"1-7-1",
          "CRYSTAL":"1-8-1",
          "BEAR_PINK":"1-9-1",
          "BEAR_PURPLE":"1-9-2",
          "PANDA":"1-10-1",
          "CROWN":"1-11-1",
          "TIARA":"1-12-1",
          "UNICORN":"1-13-1",
          "HEART":"1-14-1",
          "HEARTBOX":"1-15-1",
          "STAR":"1-16-1",
          "COIN_A":"1-17-1",
          "COIN_B":"1-17-2",
          "COIN_C":"1-17-3",
          "COIN_D":"1-17-4",
          "COIN_E":"1-17-5",
          "BOOSTER_ALL":"1-18-0",
          "BOOSTER_FIRE":"1-18-1",
          "BOOSTER_ICE":"1-18-2",
          "BOOSTER_WIND":"1-18-3",
          "BOOSTER_EARTH":"1-18-4",
          "FIGHT":"2-1-1",
          "PUSH":"2-2-1",
          "CUTE":"2-3-1",
          "PRECIOUS":"2-4-1",
          "GO":"2-5-1",
          "PENLIGHT_RED":"3-1-1",
          "PENLIGHT_PINK":"3-1-2",
          "PENLIGHT_ORANGE":"3-1-3",
          "PENLIGHT_YELLOW":"3-1-4",
          "PENLIGHT_BLUE":"3-1-5",
          "PENLIGHT_WATER":"3-1-6",
          "PENLIGHT_GREEN":"3-1-7",
          "PENLIGHT_GREENYELLOW":"3-1-8",
          "PENLIGHT_PURPLE":"3-1-9",
          "PENLIGHT_WHITE":"3-1-10",
          "PENLIGHT_BLACK":"3-1-11",
          "APPLAUSE":"3-2-1",
          "TOWEL":"3-3-1",
          "GOLD_TAPE_GLITTER":"3-4-1",
          "SILVER_TAPE_GLITTER":"3-5-1",
          "SIDE_TAPE":"3-6-1",
          "FIREWORK":"3-7-1",
          "MEERKAT_3":"4-1-1",
          "BALLOON_GROUP":"4-2-1",
          "POOP_PINK":"4-3-1",
          "PENGUINS_GROUP":"4-4-1",
          "DOLPHIN_3":"4-5-1",
          "BOAR_GROUP":"4-6-1",
          "WHALE":"4-7-1",
          "SHOW_FIRE":"5-1-1",
          "SHOW_ICE":"5-2-1",
          "SHOW_WIND":"5-3-1",
          "SHOW_EARTH":"5-4-1",
          "BEAST_ALL":"6-0-1",
          "BEAST_PHOENIX":"6-1-1",
          "BEAST_DRAGON":"6-2-1",
          "BEAST_PEGASUS":"6-3-1",
          "BEAST_WHITETIGER":"6-4-1",
          "SUPERLATIVE_ZEUS":"7-1-1",
          "SUPERLATIVE_GODDESS":"7-2-1"
      },
      "coupon_type_list":{
          "CASH":"c0",
          "GOLD":"c1",
          "STAR":"c2",
          "HEART":"c3"
      },
      "exchange_category_list":{
          "PURCHASE":1,
          "CONSUMPTION":2,
          "ALL":3
      },
      "exchange_remark_list":{
          "PAYMENT":"rp1",
          "ADDITION":"rp2",
          "GIFT":"rc1",
          "BROADCAST_PAY":"rc2",
          "BROADCAST_FUND":"rc3"
      },
      "response_code_list":{
          "SERVICE_REGISTER_SUCCESS":200101,
          "SERVICE_LOGIN_SUCCESS":200102,
          "RESOURCE_STORE_SUCCESS":200201,
          "RESOURCE_DESTROY_SUCCESS":200202,
          "RESOURCE_UPDATE_SUCCESS":200203,
          "RESOURCE_SHOW_SUCCESS":200204,
          "RESOURCE_INDEX_SUCCESS":200205,
          "CLIENT_PARAMETER_ERROR":400001,
          "CLIENT_CREATED_ERROR":400002,
          "CLIENT_DELETED_ERROR":400003,
          "SERVICE_ACCOUNT_UNQIUE_ERROR":400101,
          "SERVICE_ACCOUNT_SIGN_ERROR":400102,
          "SERVICE_COUPON_NOT_ENOUGH":400103,
          "SERVICE_LIVE_TYPE_ERROR":400104,
          "SERVICE_GIFT_TYPE_ERROR":400105,
          "SERVICE_COUPON_TYPE_ERROR":400106,
          "SERVICE_BROADCAST_PAY_BUY_ERROR":400107,
          "SERVICE_LIVE_NOT_AVAILABLE":400108,
          "SERVICE_VIEWER_STATUS_ERROR":400109,
          "SERVICE_LIVE_STATUS_CHANGE_ERROR":400110,
          "SERVICE_LIVE_PUBLIC_OUT_OF_TIME":400111,
          "SERVICE_PAYMENT_FAILED":400112,
          "SERVICE_EMAILUPDATE_RANDCODE_ERROR":400113,
          "SERVICE_VIEWER_BLOCKED":400114,
          "SERVICE_STRIPE_WEBHOOK_ERROR":400201,
          "SERVICE_STRIPE_WEBHOOK_VERIFICATION_ERROR":400202,
          "SERVICE_STRIPE_WEBHOOK_PAYLOAD_ERROR":400203,
          "RESOURCE_NOT_FOUND":404000,
          "CLIENT_VALIDATION_ERROR":422001,
          "SYSTEM_ERROR":500001,
          "SYSTEM_UNAVAILABLE":500002,
          "SYSTEM_CACHE_CONFIG_ERROR":500003,
          "SYSTEM_CACHE_MISSED_ERROR":500004,
          "SYSTEM_CONFIG_ERROR":500005,
          "SERVICE_REGISTER_ERROR":500101,
          "SERVICE_LOGIN_ERROR":500102,
          "API_TIM_CONNECT_ERROR":500201,
          "HTTP_CONTINUE":100,
          "HTTP_SWITCHING_PROTOCOLS":101,
          "HTTP_PROCESSING":102,
          "HTTP_EARLY_HINTS":103,
          "HTTP_OK":200,
          "HTTP_CREATED":201,
          "HTTP_ACCEPTED":202,
          "HTTP_NON_AUTHORITATIVE_INFORMATION":203,
          "HTTP_NO_CONTENT":204,
          "HTTP_RESET_CONTENT":205,
          "HTTP_PARTIAL_CONTENT":206,
          "HTTP_MULTI_STATUS":207,
          "HTTP_ALREADY_REPORTED":208,
          "HTTP_IM_USED":226,
          "HTTP_MULTIPLE_CHOICES":300,
          "HTTP_MOVED_PERMANENTLY":301,
          "HTTP_FOUND":302,
          "HTTP_SEE_OTHER":303,
          "HTTP_NOT_MODIFIED":304,
          "HTTP_USE_PROXY":305,
          "HTTP_RESERVED":306,
          "HTTP_TEMPORARY_REDIRECT":307,
          "HTTP_PERMANENTLY_REDIRECT":308,
          "HTTP_BAD_REQUEST":400,
          "HTTP_UNAUTHORIZED":401,
          "HTTP_PAYMENT_REQUIRED":402,
          "HTTP_FORBIDDEN":403,
          "HTTP_NOT_FOUND":404,
          "HTTP_METHOD_NOT_ALLOWED":405,
          "HTTP_NOT_ACCEPTABLE":406,
          "HTTP_PROXY_AUTHENTICATION_REQUIRED":407,
          "HTTP_REQUEST_TIMEOUT":408,
          "HTTP_CONFLICT":409,
          "HTTP_GONE":410,
          "HTTP_LENGTH_REQUIRED":411,
          "HTTP_PRECONDITION_FAILED":412,
          "HTTP_REQUEST_ENTITY_TOO_LARGE":413,
          "HTTP_REQUEST_URI_TOO_LONG":414,
          "HTTP_UNSUPPORTED_MEDIA_TYPE":415,
          "HTTP_REQUESTED_RANGE_NOT_SATISFIABLE":416,
          "HTTP_EXPECTATION_FAILED":417,
          "HTTP_I_AM_A_TEAPOT":418,
          "HTTP_MISDIRECTED_REQUEST":421,
          "HTTP_UNPROCESSABLE_ENTITY":422,
          "HTTP_LOCKED":423,
          "HTTP_FAILED_DEPENDENCY":424,
          "HTTP_TOO_EARLY":425,
          "HTTP_UPGRADE_REQUIRED":426,
          "HTTP_PRECONDITION_REQUIRED":428,
          "HTTP_TOO_MANY_REQUESTS":429,
          "HTTP_REQUEST_HEADER_FIELDS_TOO_LARGE":431,
          "HTTP_UNAVAILABLE_FOR_LEGAL_REASONS":451,
          "HTTP_INTERNAL_SERVER_ERROR":500,
          "HTTP_NOT_IMPLEMENTED":501,
          "HTTP_BAD_GATEWAY":502,
          "HTTP_SERVICE_UNAVAILABLE":503,
          "HTTP_GATEWAY_TIMEOUT":504,
          "HTTP_VERSION_NOT_SUPPORTED":505,
          "HTTP_VARIANT_ALSO_NEGOTIATES_EXPERIMENTAL":506,
          "HTTP_INSUFFICIENT_STORAGE":507,
          "HTTP_LOOP_DETECTED":508,
          "HTTP_NOT_EXTENDED":510,
          "HTTP_NETWORK_AUTHENTICATION_REQUIRED":511
      },
      "system_message_type_list":{
          "OTHER":"t1",
          "MAINTENANCE":"t2"
      },
      "system_message_frequency_list":{
          "AT_00":"00",
          "AT_15":"15",
          "AT_30":"30",
          "AT_45":"45"
      },
      "notice_importance_list":{
          "LOW":"i1",
          "MIDDLE":"i2",
          "HIGH":"i3"
      }
    },
    config: {
      "public_key":{
        "stripe":"pk_test_51IbhcnKpYaJppK1MhK7T3plmlsDsRftAepELNwK48LapAM5cfBEN2Kxx9fLBbhddVpUIwHvo7JB7SlXWgiEKkafA00dR0kJoFr"
     },
     "storage_addr_list":{
        "public":"https://resource.dev.a-live.tokyo/"
     },
     "storage_field_list":[
        "banner_store_path",
        "avatar_store_path",
        "background_store_path"
     ],
     "live":{
        "max_duration":86400,
        "advance_access_duration":3600
     },
     "sig":{
        "max_expire_duration":86400,
        "default_expire_duration":600
     },
     "input_limit":{
        "upload_file_size":{
           "avatar":10240,
           "banner":10240
        },
        "forbidden_word":{
           "count":3000,
           "length":10
        },
        "broadcast_event_streamers_count":100,
        "coupon_addtion":999999
     },
     "query_list_limit":{
        "default":15,
        "target":5,
        "viewer_advert":5
     },
     "viewer_account":{
        "register_callback_uri":"login/init/index",
        "forgotten_password_callback_uri":"login/reset/back",
        "register_expire_duration":7200
     },
     "frontend_addr_list":{
        "viewer":"https://dev.a-live.tokyo/"
     },
     "throttle":{
        "viewer_consultations_store":[
           1000
        ],
        "viewer_live_show_pull_url":[
           20
        ],
        "viewer_user_edit":[
           1000
        ],
        "viewer_user_edit_email":[
           100
        ]
     },
     "violator_condition":{
        "block_count":10,
        "forbidden_word_count":20
     },
     "payment_limit_list":{
        "currency":"jpy",
        "method_types_count":10,
        "method_types_name_length":50,
        "items_count":{
           "t0":14,
           "t1":5
        },
        "item_amount":{
           "t0":1000,
           "t1":10
        }
     },
     "event_list":{
        "t0":"App\\Events\\CommonPaid",
        "t1":"App\\Events\\BroadcastFundCoinPaid"
     },
     "coupon_price_list":{
        "110":{
           "c1":100,
           "c2":10
        },
        "330":{
           "c1":300,
           "c2":30
        },
        "550":{
           "c1":500,
           "c2":50
        },
        "1100":{
           "c1":1000,
           "c2":100
        },
        "2200":{
           "c1":2000,
           "c2":200
        },
        "3300":{
           "c1":3000,
           "c2":300
        },
        "5500":{
           "c1":5000,
           "c2":500
        },
        "7700":{
           "c1":7000,
           "c2":700
        },
        "11000":{
           "c1":10000,
           "c2":1000
        },
        "22000":{
           "c1":20000,
           "c2":2000
        },
        "33000":{
           "c1":30000,
           "c2":3000
        },
        "55000":{
           "c1":50000,
           "c2":5000
        },
        "77000":{
           "c1":70000,
           "c2":7000
        },
        "110000":{
           "c1":100000,
           "c2":10000
        }
     },
     "gift_cost_list":{
     },
     "allowed_gift_list":{
     },
     "gift_special_require_list":{
        "1-18-0":{
           "titles":[
              "t1"
           ]
        },
        "1-18-1":{
           "attribute":"a1"
        },
        "1-18-2":{
           "attribute":"a2"
        },
        "1-18-3":{
           "attribute":"a3"
        },
        "1-18-4":{
           "attribute":"a4"
        },
        "5-1-1":{
           "attribute":"a1"
        },
        "5-2-1":{
           "attribute":"a2"
        },
        "5-3-1":{
           "attribute":"a3"
        },
        "5-4-1":{
           "attribute":"a4"
        },
        "6-0-1":{
           "titles":[
              "t2-1",
              "t2-2"
           ]
        },
        "6-1-1":{
           "attribute":"a1",
           "titles":[
              "t1"
           ]
        },
        "6-2-1":{
           "attribute":"a2",
           "titles":[
              "t1"
           ]
        },
        "6-3-1":{
           "attribute":"a3",
           "titles":[
              "t1"
           ]
        },
        "6-4-1":{
           "attribute":"a4",
           "titles":[
              "t1"
           ]
        },
        "7-1-1":{
           "titles":[
              "t2-1"
           ]
        },
        "7-2-1":{
           "titles":[
              "t2-2"
           ]
        }
      }
    }
  }
}

const state = getDefaultState();

const mutations = {
  UPDATE_SYSTEM_INFO: (state, data) => {
    const keys = Object.keys(data);
    for(let i = 0; i !== keys.length; i++) {
      state[keys[i]] = data[keys[i]];
    }
  },
  SET_SYSTEM_UPDATE_FLAG: (state, value) => {
    state.lastInitTs = value;
  },
  SET_SYSTEM_TIME_DIFF: (state, value) => {
    state.time_diff = value;
  }
}

const actions = {
  async syncSystemInfo({commit, state}) {
    if (new Date().valueOf() - state.lastInitTs > 60 * 60 * 1000) {
      const ret = await getSystemInfo();
      commit('UPDATE_SYSTEM_INFO', ret.data);
      commit('SET_SYSTEM_UPDATE_FLAG', new Date().valueOf());
    }
  },
  async syncSystemTime({commit}){
    const {data} = await getSystemTimeStamp();
    const serverTime = moment(data.timestamp * 1000)
    const now = moment()
    const diff = serverTime.diff(now)
    commit('SET_SYSTEM_TIME_DIFF',diff)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
// giftのアイコンと名前を適応するところ
export function getDefaultGiftList() {
   return {
      // 宝石×5色10G(黄色、緑、青、赤、紫)
       "1-1-1":{
          "c1":10,
          "icon":"gift-01-c1.png",
          "name":"宝石",
       },
       "1-1-2":{
          "c1":10,
          "icon":"gift-01-c2.png",
          "name":"宝石",
       },
       "1-1-3":{
          "c1":10,
          "icon":"gift-01-c3.png",
          "name":"宝石",
       },
       "1-1-4":{
          "c1":10,
          "icon":"gift-01-c4.png",
          "name":"宝石",
       },
       "1-1-5":{
          "c1":10,
          "icon":"gift-01-c5.png",
          "name":"宝石",
       },

       // ピンクのおにぎり30G
       "1-2-1":{
          "c1":30,
          "icon":"gift-02-c1.png",
          "name":"ピンクのおにぎり",
       },

        // 赤いイチゴ、白いイチゴ30G
       "1-3-1":{
          "c1":30,
          "icon":"gift-03-c1.png",
          "name":"赤いイチゴ",
       },
       "1-3-2":{
          "c1":30,
          "icon":"gift-03-c2.png",
          "name":"白いイチゴ",
       },

       // マカロン50G(うす黄色、うす水色、うす緑、うす紫)
       "1-4-1":{
          "c1":50,
          "icon":"gift-04-c1.png",
          "name":"マカロン",
       },
       "1-4-2":{
          "c1":50,
          "icon":"gift-04-c2.png",
          "name":"マカロン",
       },
       "1-4-3":{
          "c1":50,
          "icon":"gift-04-c3.png",
          "name":"マカロン",
       },
       "1-4-4":{
          "c1":50,
          "icon":"gift-04-c4.png",
          "name":"マカロン",
       },

       // 花束100G
       "1-5-1":{
          "c1":100,
          "icon":"gift-05-c1.png",
          "name":"花束",
       },

       // ホールケーキ100G
       "1-6-1":{
          "c1":100,
          "icon":"gift-06-c1.png",
          "name":"ホールケーキ",
       },

       // 四葉のクローバー100G(ピンク)
       "1-7-1":{
          "c1":100,
          "icon":"gift-07-c1.png",
          "name":"四葉のクローバー",
       },

       // クリスタルの塊300G
       "1-8-1":{
          "c1":300,
          "icon":"gift-08-c1.png",
          "name":"クリスタル",
       },

       // うすピンクのクマ、うす紫のクマ500G(ぬいぐるみ)
       "1-9-1":{
          "c1":500,
          "icon":"gift-09-c1.png",
          "name":"クマのぬいぐるみ",
       },
       "1-9-2":{
          "c1":500,
          "icon":"gift-09-c2.png",
          "name":"ぬいぐるみ（うす紫のクマ）",
       },

       // パンダ500G(ぬいぐるみ)
       "1-10-1":{
          "c1":500,
          "icon":"gift-10-c1.png",
          "name":"パンダのぬいぐるみ",
       },

       // 王冠1000G
       "1-11-1":{
          "c1":1000,
          "icon":"gift-11-c1.png",
          "name":"王冠",
       },

       // ティアラ1000G
       "1-12-1":{
          "c1":1000,
          "icon":"gift-12-c1.png",
          "name":"ティアラ",
       },

       // ユニコーン1000G(ぬいぐるみ)
       "1-13-1":{
          "c1":1000,
          "icon":"gift-13-c1.png",
          "name":"ユニコーンのぬいぐるみ",
       },

       // ハート
     //   "1-14-1":{
     //      "c3":1
     //   },

       // ハートBOX
       "1-15-1":{
          "c3":100,
          "icon":"gift-17-c1.png",
          "name":"ハートBOX",
       },

     //   // 星
     //   "1-16-1":{
     //      "c2":1
     //   },

       // ファイト!300G
       "2-1-1":{
          "c1":300,
          "icon":"gift2-01-c1.png",
          "name":"ファイト!",
       },

       // 推し300G
       "2-2-1":{
          "c1":300,
          "icon":"gift2-02-c1.png",
          "name":"推し",
       },

       // かわいい500G
       "2-3-1":{
          "c1":500,
          "icon":"gift2-03-c1.png",
          "name":"かわいい",
       },

       // 尊い500G
       "2-4-1":{
          "c1":500,
          "icon":"gift2-04-c1.png",
          "name":"尊い",
       },

       // よっしゃいくぞ~!1000G
       "2-5-1":{
          "c1":1000,
          "icon":"gift2-05-c1.png",
          "name":"よっしゃいくぞー！",
       },

       // ペンライト100G (赤・ピンク・オレンジ・黄色・青・水色・緑・黄緑・紫・白・黒) 縦振り・横振り・ケチャの3Ver.
      "3-1-10": {
         "c1": 100,
         "icon":"gift3-01-c10.png",
         "name":"ペンライト"
      },
      "3-1-1-1": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c01-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-2": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c02-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-3": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c03-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-4": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c04-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-5": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c05-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-6": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c06-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-7": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c07-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-8": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c08-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-9": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c09-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-10": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c10-t2.png",
            "name":"ペンライト"
      },
      "3-1-1-11": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c11-t2.png",
            "name":"ペンライト"
      },
      "3-1-2-1": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c01-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-2": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c02-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-3": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c03-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-4": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c04-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-5": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c05-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-6": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c06-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-7": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c07-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-8": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c08-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-9": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c09-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-10": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c10-y1.png",
            "name":"ペンライト"
      },
      "3-1-2-11": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c11-y1.png",
            "name":"ペンライト"
      },
      "3-1-3-1": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c01-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-2": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c02-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-3": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c03-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-4": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c04-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-5": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c05-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-6": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c06-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-7": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c07-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-8": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c08-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-9": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c09-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-10": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c10-k1.png",
            "name":"ペンライト"
      },
      "3-1-3-11": {
            "c1": 100,
            "icon":"pen-light/gift4-01-c11-k1.png",
            "name":"ペンライト"
      },
       // 拍手100G ⇒モーション内イラストと同一で良し
       "3-2-1":{
          "c1":100,
          "icon":"gift4-02-c1.png",
          "name":"拍手",
       },

       // タオル100G
       "3-3-1":{
          "c1":100,
          "icon":"gift3-03-c1.png",
          "name":"タオル",
       },

       // 金テープキラキラ2000G
       "3-4-1":{
          "c1":2000,
          "icon":"gift3-04-c1.png",
          "name":"金テープ",
       },

       // 銀テープキラキラ1000G
       "3-5-1":{
         "c1":1000,
         "icon":"gift3-05-c1.png",
         "name":"銀テープ",
      },

       // サイドからテープ5000G ⇒右写真イメージ
       "3-6-1":{
          "c1":5000,
          "icon":"gift3-06-c1.png",
          "name":"キャノン砲",
       },

       // 花打ち上げ花火7発10000G ⇒花火3発
       "3-7-1":{
          "c1":10000,
          "icon":"gift3-07-c1.png",
          "name":"打ち上げ花火",
       },

       // ミーアキャット3匹5000G 走って登場、Vの字に並んでキョロキョロ
       "4-1-1":{
          "c1":5000,
          "icon":"gift5-01-c1.png",
          "name":"ミーアキャット",
       },

       // バルーン群10000G 色とりどりの風船が下から上に上がっていく
       "4-2-1":{
          "c1":10000,
          "icon":"gift5-02-c1.png",
          "name":"バルーン群",
       },

       // ピンクの巨大ウンチ15000G 巨大まきぐそが上からドーン(ボヨヨン感大事)
       "4-3-1":{
          "c1":15000,
          "icon":"gift5-03-c1.png",
          "name":"ピンクの巨大ウンチ",
       },

       // ペンギン群20000G 右から左にペンギンがよちよち歩きで通り過ぎる
       "4-4-1":{
          "c1":20000,
          "icon":"gift5-04-c1.png",
          "name":"ペンギン群",
       },

       // イルカ3匹30000G イルカのショーのように水中から飛び出す
       "4-5-1":{
          "c1":30000,
          "icon":"gift5-05-c1.png",
          "name":"イルカ",
       },

       // イノシシ群50000G 右から左にイノシシが砂埃を立てながら通り過ぎる
       "4-6-1":{
          "c1":50000,
          "icon":"gift5-06-c1.png",
          "name":"イノシシ群",
       },

       // 巨大クジラ100000G 大きなクジラがザパーン(水しぶきが飛び散る)
       "4-7-1":{
          "c1":100000,
          "icon":"gift5-07-c1.png",
          "name":"巨大クジラ",
       },

       // --------- 下記から、属性あり ---------
       // 属性 ブースター(筒に青白い炎 / 5色)
       "1-18-0":{
         "c1":1000,
         "icon":"gift-19-c5.png",
         "name":"全属性対応ブースター",
       },
       "1-18-1":{
           "c1":1000,
           "c2":1000,
           "icon":"attribute/booster1.png",
           "name":"炎属性ブースター",
       },
       "1-18-2":{
           "c1":1000,
           "c2":1000,
           "icon":"attribute/booster2.png",
           "name":"氷属性ブースター",
       },
       "1-18-3":{
           "c1":1000,
           "c2":1000,
           "icon":"attribute/booster3.png",
           "name":"風属性ブースター",
       },
       "1-18-4":{
           "c1":1000,
           "c2":1000,
           "icon":"attribute/booster4.png",
           "name":"地属性ブースター",
       },

       // 火属性 両サイドに火柱(上に向かって燃える) ⇒炎でOK
       "5-1-1":{
          "c1":5000,
          "c2":5000,
          "icon":"attribute/attribute1.png",
          "name":"炎属性演出",
       },

       // 氷属性 雪の結晶が舞い落ちる ⇒雪の結晶・大小2つ(斜めに配置)
       "5-2-1":{
          "c1":5000,
          "c2":5000,
          "icon":"attribute/attribute2.png",
          "name":"氷属性演出",
       },

       // 風属性 ピンクの風に白い花びらが舞う(右上から左下に) ⇒ピンクの風に花びら
       "5-3-1":{
          "c1":5000,
          "c2":5000,
          "icon":"attribute/attribute3.png",
          "name":"風属性演出",
       },

       // 地属性 地響き(両サイドから木が生えてくる、奥に崖) ⇒地面と木(木は揺れている)
       "5-4-1":{
          "c1":5000,
          "c2":5000,
          "icon":"attribute/attribute4.png",
          "name":"地属性演出",
       },

      //  // 全属性ブースター
      //  "6-0-1":{
      //     "c1":100000,
      //     "icon":"attribute/booster1.png",
      //     "name":"全属性ブースター",
      //  },

       // 各属性専用魔獣
       // 火属性→フェニックス／氷属性→ドラゴン／風属性→ペガサス／地属性→白虎
       "6-1-1":{
          "c1":50000,
          "icon":"attribute/beast1.png",
          "name":"フェニックス",
       },
       "6-2-1":{
          "c1":50000,
          "icon":"attribute/beast2.png",
          "name":"ドラゴン",
       },
       "6-3-1":{
          "c1":50000,
          "icon":"attribute/beast3.png",
          "name":"ペガサス",
       },
       "6-4-1":{
          "c1":50000,
          "icon":"attribute/beast4.png",
          "name":"白虎",
       },

       // ◇ゼウスor女神
       "7-1-1":{
          "c1":100000,
          "icon":"god/got1.png",
          "name":"ゼウス",
       },
       "7-2-1":{
          "c1":100000,
          "icon":"god/got2.png",
          "name":"女神",
       },
       "1-17-1":{
          "icon":"gift-clubfun-icon1.png"
       },
       "1-17-2":{
          "icon":"gift-clubfun-icon2.png"
       },
       "1-17-3":{
          "icon":"gift-clubfun-icon3.png"
       },
       "1-17-4":{
          "icon":"gift-clubfun-icon4.png"
       },
       "1-17-5":{
          "icon":"gift-clubfun-icon5.png"
       },
    }
}
