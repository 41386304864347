<template lang="pug">
  .root
    component-header-login
    .login-center
      .leftContainer
        div パスワードを変更するとサイトからログアウトされます。変更したパスワードで再度ログインしてください。
        p 変更したパスワードは必ずメモをして、忘れないようにしてください。

        div [注意] 
        div アカウント名＋誕生日など、個人情報から簡単に推測できるパスワードは大変危険ですのでおやめください。
        div Aライブがメール等の返信でパスワードを要求することはありません。パスワードを第三者に教えないように気を付けて下さい。

        component-login-index
      
      .rightContainer
        div(v-if="!ui.expired")
          .pink-label パスワードを再登録する
          .right-content-border
            div(class="m-b") パスワードを変更するとサイトから自動ログアウトされますので、変更したパスワードで再度ログインしてください。変更したパスワードは必ずメモなどして忘れないようにしてください。
            p
            div(class="m-b") 変更後パスワード
              label(class="input-password-content")
                input(class="input-g-w-border" type="text" v-model="password" v-if="psdDisplay")
                input(class="input-g-w-border" type="password" v-model="password" v-else)
                i(class="fas fa-eye eye-icon" v-show="!psdDisplay" @click="psdDisplay=!psdDisplay")
                i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay" @click="psdDisplay=!psdDisplay")
            div(class="m-b") 変更後パスワード[確認用]
              label(class="input-password-content")
                input(class="input-g-w-border" type="text" v-model="passwordConfirm" v-if="psdDisplay2")
                input(class="input-g-w-border" type="password" v-model="passwordConfirm" v-else)
                i(class="fas fa-eye eye-icon" v-show="!psdDisplay2" @click="psdDisplay2=!psdDisplay2")
                i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay2" @click="psdDisplay2=!psdDisplay2")
            div(style="color: red;") ※ パスワードは英数字混在の8～16桁で設定してください
            div(v-if="ui.showError" style="color: red;") {{ ui.errorMessage }}
            div(class="t-c")
              div( class="change-send_icon" @click="onSend()" ) <img src="/img/henkou_p.png">
        div(v-if="ui.expired")
          .right-content-border
            div(class="t-c m-b") 有効期限切れです。
            div(class="t-c")
              button(@click="$router.push({ name: 'LoginIndex' })") ログインページに戻る

    component-footer-login
</template>

<style src="./Back.scss" lang="scss" scoped>
</style>

<script>
import ComponentLoginIndex from "@/components/Login/index.vue";
import { reset } from '@/api/user'

export default {
  name: "LoginResetBack",
  components: {
    ComponentLoginIndex
  },
  data() {
    return {
       ui: {
        expired: false,
        showError: false,
        errorMessage: 'Error',
      },
      password: '',
      passwordConfirm: '',
      psdDisplay:false,
      psdDisplay2:false,
    }
  },
  props: {},
  computed: {

  },
  watch: {},
  mounted() {
    if (parseInt(this.$route.query.expire_time) - new Date().valueOf()/1000 < 0) {
      this.ui.expired = true;
    }
  },
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    async onSend() {
      const query = this.$route.query;

      const pwdArray = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890'.split('');
      const pwdEnglishArray = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM'.split('');
      const pwdNumberArray = '1234567890'.split('');

      const pwdInputArray = this.password.split('');

      if (this.password && this.passwordConfirm !== this.password) {
        this.ui.showError = true;
        this.ui.errorMessage = '※確認用のパスワードが一致していません。';
        return;
      } else if (
        this.password.includes(' ') || this.password.includes('　')
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = 'スペースは入れずにご入力ください';
        return;
      } else if (
        this.password.length < 8 || 
        this.password.length > 16 || 
        !pwdInputArray.every(v => pwdArray.includes(v)) ||
        !pwdInputArray.some(v => pwdEnglishArray.includes(v)) ||
        !pwdInputArray.some(v => pwdNumberArray.includes(v))
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = '※英数字が混在しているか8～16桁になっているか確認してください';
        return;
      }
      
      try {
        await reset(this.password, query);
        this.$router.push({ name: 'LoginResetSuccess' })
      } catch(err) {
        this.ui.showError = true;
        console.log(err)
      }
    }
  }
};
</script>
