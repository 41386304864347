<template lang="pug">
  .root
    component-header-index(v-if="$store.state.user.email")
    component-header-login(v-if="!$store.state.user.email")
    
    div(class="container-flex")
      div(class="leftContainer")

        div(class="attr-select-bottom")
          select(v-model="form.type" class="input-g-border")
            option(v-for="item in ui.type" :value="item.value") {{ item.name }}
          div
            button(@click="onNext()" class="pink-button") 登録する
            button(@click="onNext()" class="pink-button") スキップ
    
    component-footer-login
</template>

<style src="./Select.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "LoginInitSelect",
  components: {},
  data() {
    return {
      form: {
        type: ''
      },
      ui: {
        type: []
      }
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    this.ui.type = [
      { name: '無属性', value: this.$store.state.system.enum_list.attribute_list.NONE },
      { name: '炎属性', value: this.$store.state.system.enum_list.attribute_list.FIRE },
      { name: '氷属性', value: this.$store.state.system.enum_list.attribute_list.ICE },
      { name: '風属性', value: this.$store.state.system.enum_list.attribute_list.WIND },
      { name: '地属性', value: this.$store.state.system.enum_list.attribute_list.EARTH },
    ]
    this.form.type = this.$store.state.user.attribute;
  },
  updated() {},
  destroyed() {},
  methods: {
    async onNext() {
      if (this.form.type !== this.$store.state.system.enum_list.attribute_list.NONE) {
        await this.$store.dispatch('user/setAttribute', this.form.type);
      }
      this.$router.push({ name: 'TopIndex' })
    }
  }
};
</script>
