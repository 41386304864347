<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-paymentformcvsresult")

        h4 お支払い情報確認

        div(class="cvsResult")
          table
            tr お客様の氏名
            td {{ customerData.customer_name }}
            tr お客様のフリガナ
            td {{ customerData.customer_kana }}
            tr お客様の電話番号
            td {{ customerData.tel_no }}
            tr 決済を行うコンビニ
            td {{ selectedCVSStr }}
            tr お客様のメールアドレス
            td {{ customerData.mail }}
            tr 支払い合計金額
            td {{ totalPrice.toLocaleString() }}円

        div(class="caution") ※注意事項
          div コンビニでのお支払いにつきまして、支払い情報確定後にメールをお送りしております。
          div ご案内メールは、遅くとも翌日中までには送信しております。
          div メールは【system@p01.mul-pay.com】より送信されます。
          div 迷惑メール対策としてドメイン設定サービスをご利用されている場合は、
          div 上記メールアドレス及びドメインを受信可能に設定してください。

        div(class="confirm")
          button.pink-button.onNextButton(@click="onConfirmGmoCvs()" v-preventReClick="3000") 支払い情報確定
        div(class="coin-buy-footer-button")
          button(@click="onBack()" class="pink-button") 戻る

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormCVSConfirm.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import { createCouponGmoCvs } from '@/api/coupon';

export default {
  name: "TopProfilePaymentFormCVSConfirm",
  components: {
    PRBanner,
  },
  data() {
    return {
      customerData: {
        customer_name: '',
        customer_kana: '',
        tel_no: '',
        convenience: '',
        mail: '',
      },
      selectedCVSStr: undefined,
      resultData: {},
      buyItemList: [],
      convenienceData: {
        '00007': {
          name: 'セブンイレブン',
        },
        '00009': {
          name: 'スリーエフ',
        },
        '10001': {
          name: 'ローソン',
        },
        '10002': {
          name: 'ファミリーマート',
        },
        '10003': {
          name: 'サンクス',
        },
        '10004': {
          name: 'サークルK',
        },
        '10005': {
          name: 'ミニストップ',
        },
        '10008': {
          name: 'セイコーマート',
        },
      },
    }
  },
  async created() {
    this.customerData = this.$route.params.resultData;
    this.buyItemList = this.$route.params.buyList;
    if( !this.buyItemList || !this.customerData ){
      this.$alert('商品が正しく選択されていません。')
      this.$router.push({ name: 'TopProfileBuy'});
      return;
    }
    this.selectedCVSStr = this.convenienceData[this.customerData.convenience].name;
  },
  computed: {
    totalPrice(){
      let price = 0;
      for(var key in this.buyItemList){
        price += (Number(this.buyItemList[key].type) * Number(this.buyItemList[key].amount));
      }
      // FIXME 手数料 80 円
      return price + 80;
    },
  },
  methods: {
    async onConfirmGmoCvs() {
      const ret = await createCouponGmoCvs(this.customerData,this.buyItemList);
      const resultData = ret.data;
      const buyList = this.buyItemList;
      this.$router.push({ name: 'TopProfilePaymentFormCVSResult', params: {
        resultData,
        buyList,
      } });
    },

    onBack() {
      const buyList = this.buyItemList;
      this.$router.push({ name: 'TopProfilePaymentFormCVS', params: {
        buyList,
      }});
    },
  }
}
</script>
