<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-event")
        div(class="event-tab-sp") >>
          button(v-if="isShowingListExists" @click="tabclick('Kaisaing')" class="event-menu-button" v-bind:class="{ 'tab-active':isActive1 }") 開催中
          button(v-if="isToshowListExists" @click="tabclick('Near')" class="event-menu-button" v-bind:class="{ 'tab-active':isActive2 }") 近日開催
          button(v-if="isShowedListExists" @click="tabclick('Archive')" class="event-menu-button" v-bind:class="{ 'tab-active':isActive3 }") 過去開催

        //- 開催中
        div(v-if="ui.tab === 'Kaisaing'")
          div(class="living")
            div(class="pink-label-s") 開催中
          div(class="kaisaing")
            div(class="eventnow" v-for="item in showingList")
              a(@click="onEventDetailClick(item.id)")
                div(class="haisinbk" :style="{ 'list-style-type': 'none', background: 'url(' + getImage(item.banner_store_path) + ') no-repeat center' }")
              div(class="accountList")
                div(class="accountList-item" v-if="item.streamers.data.length > 0")
                  a(@click="onAvatarClick(item.streamers.data[0].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(0)" :attribute="item|parseAttribute(0)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_gold.png")
                        span(class="rank-badge") 1
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[0].streamer_info.profile.account_name }}
                div(class="accountList-item" v-if="item.streamers.data.length > 1")
                  a(@click="onAvatarClick(item.streamers.data[1].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(1)" :attribute="item|parseAttribute(1)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_silver.png")
                        span(class="rank-badge") 2
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[1].streamer_info.profile.account_name }}
                div(class="accountList-item" v-if="item.streamers.data.length > 2")
                  a(@click="onAvatarClick(item.streamers.data[2].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(2)" :attribute="item|parseAttribute(2)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_copper.png")
                        span(class="rank-badge") 3
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[2].streamer_info.profile.account_name }}

          <div class="goondisplay">
            <u><button v-if="showingHasMore" @click="showMore('showing')" style="color:black,cursor: pointer" class="show-more-button" v-preventReClick="1000">>>続きを表示</button></u>
          </div>


        //- 近日開催(SP)
        div(class="event-content-sp" v-if="ui.tab === 'Near'")
          div(class="living")
            div(v-if="toshowList" class="pink-label-s") 近日開催
          div(class="kaisaing")
            div(class="eventnow" v-for="item in toshowList")
              a(@click="onEventDetailClick(item.id)")
                div(class="haisinbk" :style="{ 'list-style-type': 'none', background: 'url(' + getImage(item.banner_store_path) + ') no-repeat center' }")
              div(class="accountList")

          <div class="goondisplay">
            <u><button v-if="toshowHasMore" @click="showMore('toshow')" style="color:black,cursor: pointer" class="show-more-button" v-preventReClick="1000">>>続きを表示</button></u>
          </div>

        //- 過去開催(SP)
        div(class="event-content-sp" v-if="ui.tab === 'Archive'")
          div(class="living")
            div(v-if="showedList" class="pink-label-s") 過去開催
          div(class="kaisaing")
            div(class="eventnow" v-for="item in showedList")
              a(@click="onEventDetailClick(item.id)")
                div(class="haisinbk" :style="{ 'list-style-type': 'none', background: 'url(' + getImage(item.banner_store_path) + ') no-repeat center' }")
              div(class="accountList")
                div(class="accountList-item" v-if="item.streamers.data.length > 0")
                  a(@click="onAvatarClick(item.streamers.data[0].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(0)" :attribute="item|parseAttribute(0)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_gold.png")
                        span(class="rank-badge") 1
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[0].streamer_info.profile.account_name }}
                div(class="accountList-item" v-if="item.streamers.data.length > 1")
                  a(@click="onAvatarClick(item.streamers.data[1].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(1)" :attribute="item|parseAttribute(1)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_silver.png")
                        span(class="rank-badge") 2
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[1].streamer_info.profile.account_name }}
                div(class="accountList-item" v-if="item.streamers.data.length > 2")
                  a(@click="onAvatarClick(item.streamers.data[2].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(2)" :attribute="item|parseAttribute(2)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_copper.png")
                        span(class="rank-badge") 3
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[2].streamer_info.profile.account_name }}

          <div class="goondisplay">
            <u><button v-if="showedHasMore" @click="showMore('showed')" style="color:black" class="show-more-button" v-preventReClick="1000">>>続きを表示</button></u>
          </div>

        //- 近日開催(PC)
        div(class="event-tab-pc" v-if="toshowList.length != 0")
          div(class="living")
            div(class="pink-label-s") 近日開催
          div(class="kaisaing")
            div(class="eventnow" v-for="item in toshowList")
              a(@click="onEventDetailClick(item.id)")
                div(class="haisinbk" :style="{ 'list-style-type': 'none', background: 'url(' + getImage(item.banner_store_path) + ') no-repeat center' }")
              div(class="accountList")
          <div class="goondisplay">
            <u><button v-if="toshowHasMore" @click="showMore('toshow')" style="color:black" class="show-more-button" v-preventReClick="1000">>>続きを表示</button></u>
          </div>

        //- 過去開催(pc)
        div(class="event-tab-pc" v-if="showedList.length != 0")
          div(class="living")
            div(v-if="showedList" class="pink-label-s") 過去開催
          div(class="kaisaing")
            div(class="eventnow" v-for="item in showedList")
              a(@click="onEventDetailClick(item.id)")
                div(class="haisinbk" :style="{ 'list-style-type': 'none', background: 'url(' + getImage(item.banner_store_path) + ') no-repeat center' }")
              div(class="accountList")
                div(class="accountList-item" v-if="item.streamers.data.length > 0")
                  a(@click="onAvatarClick(item.streamers.data[0].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(0)" :attribute="item|parseAttribute(0)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_gold.png")
                        span(class="rank-badge") 1
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[0].streamer_info.profile.account_name }}
                div(class="accountList-item" v-if="item.streamers.data.length > 1")
                  a(@click="onAvatarClick(item.streamers.data[1].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(1)" :attribute="item|parseAttribute(1)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_silver.png")
                        span(class="rank-badge") 2
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[1].streamer_info.profile.account_name }}
                div(class="accountList-item" v-if="item.streamers.data.length > 2")
                  a(@click="onAvatarClick(item.streamers.data[2].streamer_info.profile.id)")
                    div(class="container")
                      div(class="img-thumbanail")
                        pan-thumb(:avatar="item|parseAvatar(2)" :attribute="item|parseAttribute(2)" :isStreamer="true" width="80px" height="80px" frameWidth="82px" frameHeight="82px")
                      div(class="topleft")
                        img(src="/img/badge_copper.png")
                        span(class="rank-badge") 3
                    p
                      span(class="accountList-name" aria-hidden="true") {{ item.streamers.data[2].streamer_info.profile.account_name }}

          <div class="goondisplay">
            <u><button v-if="showedHasMore" @click="showMore('showed')" style="color:black" class="show-more-button" v-preventReClick="1000">>>続きを表示 </button></u>
          </div>

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner'
import Block from './component/Block'
import { getImageUrl } from '@/utils/tools'
import PanThumb from '@/components/PanThumb'
// import LiveItemCommon from '@/components/LiveItemCommon/Index'

export default {
  name: "TopEventIndex",
  components: {
    Block,
    PRBanner,
    PanThumb,
  },
  filters:{
    parseAvatar(item,index){
      return item.streamers.data[index].streamer_info.profile.avatar_store_path
    },
    parseAttribute(item,index){
      return (item.streamers.data[index].streamer_info.profile.attribute)
    }
  },
  data() {
    return {
      ui: {
        tab: null,
      },
      isActive1: false,
      isActive2: false,
      isActive3: false,
      pageParma:{
        limit:3,
        page:1,
      }
    }
  },
  props: {},
  computed: {
    showingList() {
      return this.$store.state.live.liveListEvent.showing.data;
    },
    toshowList() {
      return this.$store.state.live.liveListEvent.toshow.data;
    },
    showedList() {
      return this.$store.state.live.liveListEvent.showed.data;
    },
    showingHasMore() {
        if(this.$store.state.live.liveListEvent.showing.meta.pagination.current_page < this.$store.state.live.liveListEvent.showing.meta.pagination.total_pages){
          return true
        }
      return false
    },
    toshowHasMore() {
        if(this.$store.state.live.liveListEvent.toshow.meta.pagination.current_page < this.$store.state.live.liveListEvent.toshow.meta.pagination.total_pages){
          return true
        }
      return false
    },
    showedHasMore() {
      if(this.$store.state.live.liveListEvent.showed.meta.pagination.current_page < this.$store.state.live.liveListEvent.showed.meta.pagination.total_pages){
        return true
      }
      return false
    },
    isShowingListExists() {
      return this.showingList && this.showingList.length > 0;
    },
    isToshowListExists() {
      return this.toshowList && this.toshowList.length > 0;
    },
    isShowedListExists() {
      return this.showedList && this.showedList.length > 0;
    },

  },
  watch: {},
  mounted() {},
  async created() {
    await this.$store.dispatch("live/syncLiveListEvent", {
      limit: this.pageParma.limit,
      page: this.pageParma.page}
    );
    if (this.isShowingListExists) {
      this.tabclick('Kaisaing');
    } else if (this.isToshowListExists) {
      this.tabclick('Near');
    } else if (this.isShowedListExists) {
      this.tabclick('Archive');
    }
  },
  updated() {},
  destroyed() {},
  methods: {
    getImage(url){
      return url ? getImageUrl(url) : '/img/test.png';
    },
    onAdClick() {

    },
    async showMore(liveType) {
      if(liveType == "showing") {
        const nextPage = this.$store.state.live.liveListEvent.showing.meta.pagination.current_page + 1
        await this.$store.dispatch("live/showMoreEventShowing", {
          limit:this.pageParma.limit,
          page:nextPage
        });
      }
      if(liveType == "toshow") {
        const nextPage = this.$store.state.live.liveListEvent.toshow.meta.pagination.current_page + 1
        await this.$store.dispatch("live/showMoreEventToShow", {
          limit: this.pageParma.limit,
          page: nextPage
        });
      }
      if(liveType == "showed") {
        const nextPage = this.$store.state.live.liveListEvent.showed.meta.pagination.current_page + 1
        await this.$store.dispatch("live/showMoreEventShowed", {
          limit: this.pageParma.limit,
          page: nextPage
        });
      }
    },
    onAvatarClick(profile_id) {
      this.$router.push({
        name: 'TopProfileStyle2',
        params: { profile_id },
      });
    },
    onEventDetailClick(liveable_id) {
      this.$router.push({
        name: 'TopEventDetail',
        params: { liveable_id },
      });
    },
    tabclick: function(tabnm){
      this.ui.tab = tabnm
      if (this.ui.tab  == "Kaisaing") {this.isActive1 = true;this.isActive2 = false;this.isActive3 = false}
      if (this.ui.tab  == "Near") {this.isActive1 = false;this.isActive2 = true;this.isActive3 = false}
      if (this.ui.tab  == "Archive") {this.isActive1 = false;this.isActive2 = false;this.isActive3 = true}
    },
  }
};
</script>
