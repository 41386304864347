import Vue from "vue";

import "./styles/quasar.sass";
import "quasar/dist/quasar.ie.polyfills";
import lang from "quasar/lang/ja.js";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import Quasar from 'quasar/src/vue-plugin.js';import QLayout from 'quasar/src/components/layout/QLayout.js';import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import QPage from 'quasar/src/components/page/QPage.js';import QHeader from 'quasar/src/components/header/QHeader.js';import QFooter from 'quasar/src/components/footer/QFooter.js';import QDrawer from 'quasar/src/components/drawer/QDrawer.js';import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';import QPageScroller from 'quasar/src/components/page-scroller/QPageScroller.js';;

Vue.use(Quasar, {
  config: {},
  plugins: {},
  lang: lang,
  components: {
    QLayout,
    QPageContainer,
    QPage,
    QHeader,
    QFooter,
    QDrawer,
    QPageSticky,
    QPageScroller
  }
});
