import { render, staticRenderFns } from "./BuyHistory.vue?vue&type=template&id=21990976&scoped=true&lang=pug&"
import script from "./BuyHistory.vue?vue&type=script&lang=js&"
export * from "./BuyHistory.vue?vue&type=script&lang=js&"
import style0 from "./BuyHistory.scss?vue&type=style&index=0&id=21990976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21990976",
  null
  
)

export default component.exports