<template lang="pug">
  .root
    div(class="content-selectpayment")

      div お支払い方法を選んでください。

      div(class="paymentList")
        <input type="radio" id="card" value="card" v-model="selectedPaymentType">
        <label for="card">カード決済</label>
        div(class="paymentList-detail")
          div(class="card-img")
            <img src="/img/logo_visa.jpg">
            <img src="/img/logo_master_card.png">
            <img src="/img/logo_jcb.gif">
            <img style="width: 60px;height: 60px;" src="/img/logo_amex.jpg">
            <img style="width: 60px;height: 60px;" src="/img/logo_diners.jpg">
            

        <input type="radio" id="cvs" value="cvs" v-model="selectedPaymentType">
        <label for="cvs">コンビニ決済</label>
        div(class="paymentList-detail")
          div 手数料:80円
          div <<ご利用可能店舗>>
          div ローソン、ファミリーマート、ミニストップ、セイコーマート

        <input type="radio" id="payeasy" value="payeasy" v-model="selectedPaymentType">
        <label for="payeasy">ペイジー決済（Pay-easy）</label>
        div(class="paymentList-detail")
          div 手数料:80円
          div 銀行ATMでのお支払い、各金融機関でのネットバンキング・モバイルバンキングのお支払いがご利用いただける決済サービスです。
          div 購入者の支払情報が金融機関の画面にそのまま引き継がれるため、支払い忘れや入力ミスがなく、安心・確実に決済手続きが行えます。
          div <<ご利用可能な金融機関>>
          div ゆうちょ銀行・みずほ銀行・三井住友銀行・三菱UFJ銀行・りそな銀行・楽天銀行 他

        //- <input type="radio" id="cvs-ap" value="cvs-ap" v-model="selectedPaymentType">
        //- <label for="cvs-ap">コンビニ後払い（こんど払い byGMO）</label>
        //- div(class="paymentList-detail")
        //-   div 手数料:220円
        //-   div ひと月分のご利用金額をまとめて翌月中の好きなタイミングでコンビニにて支払うことができる、後払い決済サービスです。
        //-   div お支払い時に別途こんど払いシステム利用料が発生いたします。
        //-   div ※こんど払いシステム利用料：手数料を含む1か月分のご利用金額合計 5万円未満：330円、5万円以上：660円（いずれも税込）
        //-   a(href="https://condo-pay.com/") 詳しくはこちら

      div(class="paymentNext")
        button.pink-button.onNextButton(@click="onPaymentNext()" v-preventReClick="3000") 次へ
      div(class="coin-buy-footer-button")
        button.pink-button.onNextButton(@click="onBack()" class="pink-button") 戻る

</template>

<style src="./index.scss" lang="scss" scoped>
</style>


<script>
import { createCouponGmoAp } from '@/api/coupon';
import { getStripeData } from '@/api/payment';

export default {
  name: "ComponentsPayment",
  components: {
  },
  data() {
    return {
      selectedPaymentType: '',
      buyItemList: [],
      buyType: '',
      successUrl: '',
      cancellUrl: '',
      sessionId: '',
    }
  },
  async created() {
    this.buyItemList = this.$route.params.buyList;
    this.buyType = this.$route.params.buyType;
    this.successUrl = this.$route.params.successUrl;
    this.cancellUrl = this.$route.params.cancellUrl;
  },
  methods: {
    async onConfirmStripe() {
      const buyType = this.buyType;
      const buyList = this.buyItemList;
      const successUrl = this.successUrl;
      const cancellUrl = this.cancellUrl;

      const ret = await getStripeData()
      if(ret['data']['ErrCode']) {
        this.$router.push({ name: 'TopProfilePaymentFormStripe', params: {
          buyType,
          buyList,
          successUrl,
          cancellUrl,
        } });
      } else {
        this.$router.push({ name: 'TopProfilePaymentFormStripeResult', params: {
          buyType,
          buyList,
          successUrl,
          cancellUrl,
        } });
      }
    },
    async onConfirmGmoAp() {
      const buyList = this.buyItemList;
      const ret = await createCouponGmoAp(buyList)
      const resultData = ret.data;
      const payChoice = 'condo';
      this.$router.push({ name: 'TopProfilePaymentLoading', params: {
        resultData,
        payChoice,
      } });
    },
    async onConfirmGmoCvs() {
      const buyType = this.buyType;
      const buyList = this.buyItemList;
      const successUrl = this.successUrl;
      const cancellUrl = this.cancellUrl;
      this.$router.push({ name: 'TopProfilePaymentFormCVS', params: {
        buyType,
        buyList,
        successUrl,
        cancellUrl,
      } });
    },
    async onConfirmGmoPayeasy() {
      const buyType = this.buyType;
      const buyList = this.buyItemList;
      const successUrl = this.successUrl;
      const cancellUrl = this.cancellUrl;
      this.$router.push({ name: 'TopProfilePaymentFormPayeasy', params: {
        buyType,
        buyList,
        successUrl,
        cancellUrl,
      } });
    },
    async onPaymentNext() {
      switch(this.selectedPaymentType){
        case 'card':
          await this.onConfirmStripe();
          break;
        case 'cvs-ap':
          await this.onConfirmGmoAp();
          break;
        case 'cvs':
          await this.onConfirmGmoCvs();
          break;
        case 'payeasy':
          await this.onConfirmGmoPayeasy();
          break;
      }
    },

    onBack() {
      this.$router.push({
        name: 'TopProfileBuy'
      })
    },

    onClick1() {
      this.$router.push({ name: 'TopTermsTokushoho' });
    }
  }
}
</script>