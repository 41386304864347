<template lang="pug">
.root
  div(class="img-content-pc")
  component-header-login
  .login-center
    .topContainer(v-if="this.isPcLayout()")
      div(class="img-content-img-top-pc") <img src="/img/Alive-fun/Alive_fun_top_pc-01.jpg">
      div(class="img-content-img-top-sp") <img src="/img/Alive-fun/Alive_fun_top_sp-01.jpg">
    div(class="login-content")
      .leftContainer
        component-login-index
        div
          div(class="img-content-pc")
            div(class="img-content-img" v-if="!this.isPcLayout()") <img src="/img/Alive-fun/Alive_fun_pc-01.jpg">
            div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_pc-01.jpg">
            div(class="youtubeText") ～クラファン配信とは～
            ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeWhatIsClubFun")
            div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_pc-02.jpg">
            div(class="youtubeText") ～オーディションでの応援方法～
            ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeHowToSupportEvent")
            div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_pc-03.jpg">
            div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_pc-04.jpg">
            div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_pc-05.jpg">
            div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_pc-06.jpg">
          div(class="img-content-sp")
            div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_sp-01.jpg">

      div(class="xbutton-content" v-if="!ui.resultClose && $route.query.from_email")
        button(@click="ui.resultClose = true") X
          div メールアドレスの変更が完了しました。

      div(class="xbutton-content" v-if="!ui.resultClose && $route.query.from_password")
        button(@click="ui.resultClose = true") X
        div(class="change-pass-message")
          div パスワードの変更が
          div 完了しました。

      div(class="xbutton-content" v-if="!ui.resultClose && $route.query.from_unregister")
        button(@click="ui.resultClose = true") X
        div(class="change-pass-message")
          div 退会が完了しました。

      .rightContainer(v-if="ui.resultClose || (!$route.query.from_password && !$route.query.from_email && !$route.query.from_unregister)")
        .loginBox
          .pink-label ログインの方はこちら
          .right-content-border(style="text-align: center")
            div(class="login-input-content")
              div(style="font-size: 13px; margin-bottom: 12px")
                input(placeholder="メールアドレス" v-model="form.email" autocapitalize="off")
            div(class="login-input-content")
              div(style="font-size: 13px; margin-bottom: 12px;")
                span(class="pass-title")
                label(class="input-password-content")
                  input(type="text" placeholder="パスワード" v-model="form.password" @input="no_fullwidth_password" v-if="psdDisplay")
                  input(type="password" placeholder="パスワード" v-model="form.password" @input="no_fullwidth_password" v-else)
                  i(class="fas fa-eye eye-icon" v-show="!psdDisplay" @click="psdDisplay=!psdDisplay")
                  i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay" @click="psdDisplay=!psdDisplay")

            div(v-if="ui.loginfailed", style="color: red; font-size: 13px;") ※IDまたはパスワードが正しくありません
            div( class="Login_icon" @click="onLogin()" ) <img src="/img/login_p.png">
            div(style="margin: 12px 0px") ログイン情報を記録する
              input(type="checkbox", v-model="form.isSave")
            div ※パスワードを忘れた方は
              router-link(:to="{ name: 'LoginResetIndex' }") こちら
        .newAcountBox(style="margin-top: 12px")
          .pink-label 新規登録者の方はこちら
          .right-content-border(style="text-align: center")
            .buttonArea
              button.pink-button(@click="onRegister()") 登録する
            div ※ 配信者登録などの
            div お問い合わせは
              router-link(:to="{ name: 'LoginContactIndex' }") こちら
        .prBox(style="margin-top: 12px")
          PRBanner

      div(v-if="ui.resultClose || (!$route.query.from_password && !$route.query.from_email && !$route.query.from_unregister)" class="img-content-sp")
        div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_sp-02.jpg">
        div(class="youtubeText") ～クラファン配信とは～
        ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeWhatIsClubFun")
        div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_sp-03.jpg">
        div(class="youtubeText") ～オーディションでの応援方法～
        ComponentYoutubePlayer(v-bind:srcKey="movie.youtubeHowToSupportEvent")
        div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_sp-04.jpg">
        div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_sp-05.jpg">
        div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_sp-06.jpg">
        div(class="img-content-img") <img src="/img/Alive-fun/Alive_fun_sp-07.jpg">

        .rightContainer
          .loginBox
            .pink-label ログインの方はこちら
            .right-content-border(style="text-align: center")
              div(class="login-input-content")
                div(style="font-size: 13px; margin-bottom: 12px")
                  input(placeholder="メールアドレス" v-model="form.email" autocapitalize="off")
              div(class="login-input-content")
                div(style="font-size: 13px; margin-bottom: 12px;")
                  span(class="pass-title")
                  label(class="input-password-content")
                    input(type="text" placeholder="パスワード" v-model="form.password" @input="no_fullwidth_password" v-if="psdDisplay")
                    input(type="password" placeholder="パスワード" v-model="form.password" @input="no_fullwidth_password" v-else)
                    i(class="fas fa-eye eye-icon" v-show="!psdDisplay" @click="psdDisplay=!psdDisplay")
                    i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay" @click="psdDisplay=!psdDisplay")

              div(v-if="ui.loginfailed", style="color: red; font-size: 13px;") ※IDまたはパスワードが正しくありません
              div( class="Login_icon" @click="onLogin()" ) <img src="/img/login_p.png">
              div(style="margin: 12px 0px") ログイン情報を記録する
                input(type="checkbox", v-model="form.isSave")
              div ※パスワードを忘れた方は
                router-link(:to="{ name: 'LoginResetIndex' }") こちら
          .newAcountBox(style="margin-top: 12px")
            .pink-label 新規登録者の方はこちら
            .right-content-border(style="text-align: center")
              .buttonArea
                button.pink-button(@click="onRegister()") 登録する
              div ※ 配信者登録などの
              div お問い合わせは
                router-link(:to="{ name: 'LoginContactIndex' }") こちら

  component-footer-login
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import ComponentLoginIndex from "@/components/Login/index.vue";
import { getEmailSaved } from "@/utils/auth";
import PRBanner from '@/components/PRBanner'
import ComponentYoutubePlayer from "@/components/VideoPlayer/youtubePlayer";

export default {
  name: "LoginIndex",
  components: {
    ComponentLoginIndex,
    ComponentYoutubePlayer,
    PRBanner,
  },
  data() {
    return {
      movie: {
        youtubeWhatIsClubFun: "PLiXxjUe-Po",
        youtubeHowToSupportEvent: "mJTM01L0C2c"
      },
      ui: {
        loginfailed: false,
        resultClose: false,
      },
      form: {
        email: undefined,
        password: undefined,
        isSave: true,
      },
      psdDisplay:false,
    };
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    const emailSaved = getEmailSaved();

    if (process.env.VUE_APP_ENV === "DEV") {
      if (process.env.VUE_APP_DEV_MAIL) {
        this.form.email = process.env.VUE_APP_DEV_MAIL;
      } else {
        this.form.email = "viewer8@akipro.com";
      }
      this.form.password = "password";
    } else if (emailSaved) {
      this.form.email = emailSaved;
    }
    await this.$store.dispatch('system/syncSystemInfo');
  },
  updated() {},
  destroyed() {},
  methods: {
    async onLogin() {
      try {
        await this.$store.dispatch("user/login", this.form);
        await this.$store.dispatch('user/syncUserInfo');
        this.$router.push({ name: "TopIndex" });
      } catch(err) {
        console.log(err);
        this.form.email = ''
        this.form.password = ''
        this.ui.loginfailed = true;
      }
    },
    onRegister() {
      this.$router.push({ name: "LoginRegisterIndex" });
    },
    isPcLayout() {
      return window.innerWidth >= 429;
    },
    no_fullwidth_password() {
      let tmp_password = '';
      for (let i = 0; i < this.form.password.length; i++) {
        if (this.form.password[i].match(/[ -~]/)) { tmp_password += this.form.password[i] }
      }
      this.form.password = tmp_password
    },
  },
};
</script>
