<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-paymentformpayeasyresult")

        h4 お支払い情報確認

        div(class="payeasyResult")
          table
            tr お客様の氏名
            td {{ customerData.customer_name }}
            tr お客様のフリガナ
            td {{ customerData.customer_kana }}
            tr お客様の電話番号
            td {{ customerData.tel_no }}
            tr お客様のメールアドレス
            td {{ customerData.mail }}
            tr 支払い合計金額
            td {{ totalPrice.toLocaleString() }}円

        div(class="caution") ※注意事項
          div payeasyでのお支払いにつきまして、支払い情報確定後にメールをお送りしております。
          div ご案内メールは、遅くとも翌日中までには送信しております。
          div メールは【system@p01.mul-pay.com】より送信されます。
          div 迷惑メール対策としてドメイン設定サービスをご利用されている場合は、
          div 上記メールアドレス及びドメインを受信可能に設定してください。

        div(class="confirm")
          button.pink-button.onNextButton(@click="onPaymentConfirm()" v-preventReClick="3000") 支払い情報確定

        div(class="coin-buy-footer-button")
          button(@click="onBack()" class="pink-button") 戻る

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormPayeasyConfirm.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import { createCouponGmoPayeasy } from '@/api/coupon';

export default {
  name: "TopProfilePaymentFormPayeasyConfirm",
  components: {
    PRBanner,
  },
  data() {
    return {
      customerData: {
        customer_name: '',
        customer_kana: '',
        tel_no: '',
        mail: '',
      },
      buyItemList: [],
      resultData: {},
    }
  },
  async created() {
    this.buyItemList = this.$route.params.buyList;
    this.customerData = this.$route.params.customerData;
    if( !this.buyItemList || !this.customerData ){
      this.$alert('商品が正しく選択されていません。')
      this.$router.push({ name: 'TopProfileBuy'});
      return;
    }
  },
  computed: {
    totalPrice(){
      let price = 0;
      for(var key in this.buyItemList){
        price += (Number(this.buyItemList[key].type) * Number(this.buyItemList[key].amount));
      }
      // FIXME 手数料 80 円
      return price + 80;
    },
  },
  methods: {
    onBack() {
      const buyList = this.buyItemList;
      this.$router.push({ name: 'TopProfilePaymentFormPayeasy', params: {
        buyList,
      }});
    },

    async onPaymentConfirm() {
      const ret = await createCouponGmoPayeasy(this.customerData,this.buyItemList);
      const resultData = ret.data;
      const buyList = this.buyItemList;
      this.$router.push({ name: 'TopProfilePaymentFormPayeasyResult', params: {
        resultData,
        buyList,
      } });
    },
  }
}
</script>
