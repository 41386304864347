<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")

      h5 {{ resultText }}

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentLoadingResult.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner';

export default {
  name: "TopProfilePaymentLoadingResult",
  components: {
    PRBanner,
  },
  data() {
    return {
      resultText: '',
    }
  },
  async created() {
    this.payment = this.$route.params.pay;
    if(this.payment == 'done') {
      this.resultText = '支払いが完了しました。'
      setTimeout(this.PaymentSucceed, 5000);
    }
  },
  methods: {
    PaymentSucceed:function() {
      this.$router.push({ name: 'TopProfileBuy' });
    }
  }
}
</script>
