<template lang="pug">
.root
  img.video-sub(
    v-if="videoStatus === 'PREPARED'"
    @click='onPlay()'
    src='/img/play_button.png'
  )
  button(v-if='videoStatus === "PREPARING"') Loading
  video.video-rect(
    playsinline,
    ref='hls-player',
    autoplay,
    webkit-playsinline,
    type='application/x-mpegURL'
  )
</template>

<style src="./VideoRect.scss" lang="scss" scoped>
</style>

<script>
  import Hls from 'hls.js'
  import { getUrlCode } from '@/api/tools'
  // import * as Bluebird from 'bluebird'

  const VIDEO_STATUS = {
    PAUSED: 'PAUSED',
    PREPARING: 'PREPARING',
    PREPARED: 'PREPARED',
    LOADING: 'LOADING',
    PLAYING: 'PLAYING'
  }

  export default {
    name: 'LiveComponentVideoRect',
    components: {
    },
    data() {
      return {
        insHLS: undefined,
        videoStatus: VIDEO_STATUS.PAUSED,
        timerUrl: undefined,
        timerTarget: undefined,
        timerRank: undefined,
        videoTag: undefined,
        bluebirdPromise: '',
        bluebirdPromiseClose: false
      }
    },
    props: {},
    computed: {},
    watch: {
    },
    mounted() {},
    async created() {
    this.bluebirdPromise = require("bluebird");
    // Configure webpack and browserify for development/debugging
    this.bluebirdPromise.config({
      cancellation: true,
    })

      // this.timerUrl = setInterval(this.syncM3U8, 3 * 1000)
      this.syncM3U8();
    },
    beforeDestroy() {
      // this.bluebirdPromise.cancel();
      this.bluebirdPromiseClose = true;
      clearInterval(this.timerUrl)
      clearInterval(this.timerTarget)
      clearInterval(this.timerRank)
      this.stopVideo()
    },
    updated() {},
    methods: {
      async onPlay() {
        this.videoStatus = VIDEO_STATUS.LOADING
        await this.$store.dispatch('live/syncLiveURL');
        this.playVideo()
      },
      async playVideo() {
        this.videoStatus = VIDEO_STATUS.PREPARING
        this.videoTag = this.$refs['hls-player']
        let _this = this
        this.videoTag.onplay = function() {
          _this.videoStatus = VIDEO_STATUS.PLAYING
        };

        let lastRetData = null
        let count = 2
        for (let i = 0; i != -1; i++) {
          const ret = await getUrlCode(this.$store.state.live.liveURL)
          if (i === 0 && ret) {
            break
          }
          if (lastRetData === null && ret) {
            lastRetData = ret
          }
          if(this.bluebirdPromiseClose || this.$store.getters.enum_list.live_status_list.ENDED === this.$store.state.live.info.status){
            break
          }
          if (!ret) {
            await this.bluebirdPromise.delay(5000)
            continue
          }
          if(lastRetData === ret) {
            await this.bluebirdPromise.delay(5000)
            continue
          }
          if (count === 0) {
            break
          } else {
            count = count - 1
            lastRetData = null
          }
          i = 1
        }
        // await Bluebird.delay(20000);

        this.videoStatus = VIDEO_STATUS.PREPARED        
        if (Hls.isSupported()) {
          this.insHLS = new Hls({
            autoStartLoad: true,
            startFragPrefetch: true,
            liveDurationInfinity: true,
            liveSyncDuration: 3,
            enableWorker: true,
            manifestLoadingTimeOut: 2000,
            levelLoadingTimeOut: 2000,
            levelLoadingMaxRetry: 10,
            levelLoadingRetryDelay: 50,
            levelLoadingMaxRetryTimeout: 30 * 1000,
            fragLoadingTimeOut: 2000,
            fragLoadingMaxRetry: 10,
            fragLoadingRetryDelay: 50,
            fragLoadingMaxRetryTimeout: 30 * 1000,
            xhrSetup: async (xhr, url) => {
              if (url && url.split('?')[0].endsWith('.m3u8')) {
                xhr.open('GET', this.$store.state.live.liveURL, true)
              }
            }
          })
          this.insHLS.attachMedia(this.videoTag)
          this.insHLS.on(Hls.Events.MEDIA_ATTACHED, () => {
            this.insHLS.loadSource(this.$store.state.live.liveURL)
            this.videoTag.play()
          })
          this.insHLS.on(Hls.Events.MANIFEST_PARSED, () => {})
          this.insHLS.on(Hls.Events.ERROR, async (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  this.insHLS.startLoad()
                  break
                case 'mediaError':
                  this.insHLS.recoverMediaError()
                  break
                default:
                  this.insHLS.startLoad()
                  break
              }
            } else {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  if (data.response && data.response.code === 404) {
                    this.insHLS.destroy()
                  }
                  break
                default:
                  break
              }
            }
          })
        } else if (this.videoTag.canPlayType('application/vnd.apple.mpegurl')) {
          this.videoTag.src = this.$store.state.live.liveURL
          this.videoTag.addEventListener('canplay', () => {
            this.videoTag.play()
          })
          if (this.videoTag.readyState > 3) {
            this.asyncPlay()
          }
        }
      },
      async asyncPlay() {
        await this.videoTag.play()
        this.videoStatus = VIDEO_STATUS.PLAYING
      },

      stopVideo() {
        if (this.insHLS) {
          this.insHLS.destroy()
        }
      },
      syncM3U8() {
        if (this.$store.state.live.info.id) {
          this.$store.dispatch('live/syncLiveURL')
        }
      },
    }
  }
</script>
