<template lang="pug">
  .root
    div(class="logo-sp")
      a(@click="$router.push({ name: 'TopIndex' })")
        img(src="/img/logo.png")
    div(class ="topNav")
      div(class="logo")
        a(@click="$router.push({ name: 'TopIndex' })")
          img(src="/img/logo.png")
      ul
        Li(class="user-icon-sp")
          a(@click="$router.push({ name: 'TopProfileIndex' })")
            img(src="/img/profile_icon.svg")
        Li(v-bind:class="{ active: isActive1 }")
          a(@click="$router.push({ name: 'TopIndex' })") TOP
        Li(v-bind:class="{ active: isActive2 }")
          a(@click="$router.push({ name: 'TopHotIndex' })") 配信一覧
        Li(v-bind:class="{ active: isActive3 }")
          a(@click="$router.push({ name: 'TopEventIndex' })") オーディション
        Li(v-bind:class="{ active: isActive4 }")
          a(@click="$router.push({ name: 'TopFundIndex' })") クラファン配信
        Li(v-bind:class="{ active: isActive5 }").style-head-font-pc
          a(@click="$router.push({ name: 'TopProfileIndex' })") マイプロフィール
        Li(v-bind:class="{ active: isActive6 }").style-head-font-pc
          a(@click="$router.push({ name: 'TopBroadcastIndex' })") お知らせ
            <sup v-if="$store.state.news.num.new > 0"><span data="" class="badge badge-danger badge-pill">{{ $store.state.news.num.new }}</span></sup>
        Li.style-head-font-pc
          <span class="search_container">
            <input v-model="ui.input" type="text" size="20" class="placeholder" placeholder="サイト内を検索" @keyup.enter="onSearch(ui.input)">
            <button type="submit" class="find-ico" @click="onSearch(ui.input)">
              img(src="/img/header_serch_icon.png")
            </button>
          </span>
        Li.style-head-font-sp
          a(@click="$router.push({ name: 'TopBroadcastIndex' })" class="annouce-ico-sp")
            img(src="/img/notice.svg")
            <sup v-if="$store.state.news.num.new > 0"><span data="" class="badge badge-danger badge-pill">{{ $store.state.news.num.new }}</span></sup>
        Li.style-head-font-sp
          img(src="/img/serch_icon.svg" class="find-ico-sp" @click="spSearch()")
    <div class="search-sp-container" v-bind:style="{ display:searchActive}">
      <span class="search_container-sp">
        <input v-model="ui.inputSP" type="text" size="20" class="placeholder" placeholder="サイト内を検索" @keyup.enter="onSearch(ui.input)">
        <button type="submit" class="find-ico" @click="onSearch(ui.inputSP)">
          img(src="/img/header_serch_icon.png")
        </button>           
      </span>
    </div>
</template>

<style src="./header.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "ComponentsHeaderIndex",
  components: {
  },
  data() {
    return {
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
      isActive6: false,
      searchActive:'none',
      ui: {
        input: '',
        inputSP: '',
      }
    }
  },
  mounted() {},
  async created() {
    let $url = this.$route.path;
    const Rex = /top\/event\/detail/
    const Rexfund = /top\/fund\/detail/
    const Rexbuyhistory = /top\/profile\/buyhistory/
    const Rexprofchange = /top\/profile\/change/
    const Rexprofadd = /top\/profile\/address/

    if($url  == "/top/index"){this.isActive1 = true}
    if($url  == "/top/hot/index"){this.isActive2 = true}
    if( ($url  == "/top/event/index")|| (Rex.test($url)) ){this.isActive3 = true}
    if( ($url  == "/top/fund/index") || (Rexfund.test($url)) ){this.isActive4 = true}
    if( ($url  == "/top/profile/index") || (Rexbuyhistory.test($url)) || (Rexprofchange.test($url)) || (Rexprofadd.test($url)) ){this.isActive5 = true}
    if($url  == "/top/broadcast/index"){this.isActive6 = true}

    await this.$store.dispatch('news/getNewsStatus');
  },
  methods: {
    onSearch(content) {
      if (content) {
        this.$router.push({ name: 'TopSearchIndex', query: { content } })
      }
    },

    spSearch(){
      if(this.searchActive=='none'){
        this.searchActive='block'
      }
      else{
        this.searchActive='none'
      }
    },

    onLogout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push({ name: 'LoginIndex' })
      }).catch((e) => {
        console.log(e)
      })
    },
  }
}


</script>