<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="prof-change")
        div(class="bread-list") >>
          span(class="display-title") パスワードを変更

        div(class="content-border")
          div(class="m-b") パスワードを変更するとサイトから自動ログアウトされますので、変更したパスワードで再度ログインしてください。変更したパスワードは必ずメモなどして忘れないようにしてください。
          div(class="m-b") [注意]
          div(class="m-b") アカウント名＋誕生日など、個人情報から簡単に推測できるパスワードは大変危険です。Aライブがメール等でパスワードを質問することは絶対にありません。パスワードは第三者に教えないように気を付けてください。

          div(class="m-b") 変更後パスワード
            label(class="input-content")
              input(v-if="psdDisplay" type="text" v-model="password" class="input-g-w-border")
              input(v-else type="password" v-model="password" class="input-g-w-border")
              i(class="fas fa-eye eye-icon" v-show="!psdDisplay" @click="psdDisplay=!psdDisplay")
              i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay" @click="psdDisplay=!psdDisplay")
          div(class="m-b") 変更後パスワード[確認用]
            label(class="input-content")
              input(v-if="psdDisplay2" type="text" v-model="passwordConfirm" class="input-g-w-border")
              input(v-else type="password" v-model="passwordConfirm" class="input-g-w-border")
              i(class="fas fa-eye eye-icon" v-show="!psdDisplay2" @click="psdDisplay2=!psdDisplay2")
              i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay2" @click="psdDisplay2=!psdDisplay2")
          div(style="color: red;") ※パスワードは英数字混在の8～16桁で設定してください
          div(style="color: red;") {{ ui.errorMessage }}
          div(class="right-content-button")
            div(@click="onChangePasswordConfirm()" ) <img src="/img/henkou_p.png">

    component-footer-index
</template>

<style src="./ChangePassword.scss" lang="scss" scoped>

</style>

<script>
import { setPassword } from '@/api/user'

export default {
  name: "TopProfileChangePassword",
  components: {
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      psdDisplay:false,
      psdDisplay2:false,
      ui: {
        errorMessage: '',
        showError: false,
      }
    }
  },
  computed: {
  },
  async created() {
    await this.init();
  },
  mounted() {},
  methods: {
    async init() {
    },

    async onChangePasswordConfirm() {
      const pwdArray = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890'.split('');
      const pwdEnglishArray = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM'.split('');
      const pwdNumberArray = '1234567890'.split('');

      const pwdInputArray = this.password.split('');

      if (this.password && this.passwordConfirm !== this.password) {
        this.ui.showError = true;
        this.ui.errorMessage = '※確認用のパスワードが一致していません。';
        return;
      } else if (
        this.password.includes(' ') || this.password.includes('　')
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = 'スペースは入れずにご入力ください';
        return;
      } else if(
        this.password.length === 0
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = '※パスワードが入力されていません';
        return;
      } else if (
        this.password.length < 8 ||
        this.password.length > 16 ||
        !pwdInputArray.every(v => pwdArray.includes(v)) ||
        !pwdInputArray.some(v => pwdEnglishArray.includes(v)) ||
        !pwdInputArray.some(v => pwdNumberArray.includes(v))
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = '※英数字が混在しているか8～16桁になっているか確認してください';
        return;
      }

      await setPassword(this.password);
      await this.$store.dispatch('user/logout').then(() => {
        this.$router.push({ name: 'LoginIndex', query: { from_password: true } })
      })
    },
  }
}
</script>
