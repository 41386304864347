const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  enum_list: state=>state.system.enum_list,
  fund_condition_list:state=>state.system.enum_list.broadcast_fund_achievement_condition_list,
  room_invited_status_list:state=>state.system.enum_list.profile_invited_room_mail_status_list,
  system_timestamp:state =>state.system.timestamp,
  time_diff:state =>state.system.time_diff,
}
export default getters
