<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-clubfun")
        div(v-if="isShowingListExists && !isShowedListExists " class="clubfun-tab-sp") >>
          button(v-model="ui.tab = 'Kaisaing'" @click="tabclick('Kaisaing');" class="clubfun-menu-button" v-bind:class="{ 'tab-active':isActive1 }" :disabled="!isShowingListExists") 開催情報
        div(v-else-if="!isShowingListExists && isShowedListExists" class="clubfun-tab-sp" ) >>
          button(v-model="ui.tab = 'Archive'" @click="tabclick('Archive');" class="clubfun-menu-button" v-bind:class="{ 'tab-active':isActive2 ='true' }" :disabled="!isShowedListExists" ) 過去開催
        div(v-else-if="isShowingListExists && isShowedListExists " class="clubfun-tab-sp") >>
          button(@click="tabclick('Kaisaing');" class="clubfun-menu-button" v-bind:class="{ 'tab-active':isActive1 }" :disabled="!isShowingListExists") 開催情報
          button(@click="tabclick('Archive');" class="clubfun-menu-button" v-bind:class="{ 'tab-active':isActive2}" :disabled="!isShowedListExists" ) 過去開催

        //- 開催情報
        div(v-if="ui.tab === 'Kaisaing' && isShowingListExists" )
          div(class="living")
            div(class="pink-label-s") 開催情報
          div(class="kaisaing")
            div(class="eventnow" v-for="item in ui.showingList")
              a(@click="onClubfunDetailClick(item.id)")
                div(class="haisinbk")
                  div.videoTag(v-if="item.live && item.live.id && item.live.status === $store.getters.enum_list.live_status_list.ONGOING")  配信中
                  img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path")

          div(class="goondisplay")
            a(v-if="showingHasMore" @click="showMore('showing')") >> 続きを表示

        //- 過去開催（SP）
        div(class="clubfun-event-sp" v-if="ui.tab === 'Archive' && isShowedListExists " )
          div(class="living")
            div(class="pink-label-s") 過去開催
          div(class="archive")
            div(class="eventnow" v-for="item in ui.showedList")
              a(@click="onClubfunDetailClick(item.id)")
                div(class="haisinbk")
                  img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path")

          div(class="goondisplay")
            a(v-if="showedHasMore" @click="showMore('showed')") >> 続きを表示

        //- 過去開催(PC)
        div(class="clubfun-tab-pc" v-if="isShowedListExists")
          div(class="living")
            div(class="pink-label-s") 過去開催
          div(class="kaisaing")
            div(class="eventnow" v-for="item in ui.showedList")
              a(@click="onClubfunDetailClick(item.id)")
                div(class="haisinbk")
                  img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path")

          div(class="goondisplay")
            a(v-if="showedHasMore" @click="showMore('showed')") >> 続きを表示

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner'

export default {
  name: "TopFundIndex",
  components: {
    PRBanner
  },
  data() {
    return {
      initPage:1,
      showingLoadPage: 2,
      showedLoadPage: 2,
      perIndex:3,
      ui: {
        tab: 'Kaisaing',
        showingList: [],
        showedList: [],
        showingHasMore: false,
        showedHasMore: false
      },
      isActive1: true,
      isActive2: false,
    }
  },
  props: {},
  computed: {
    showingList() {
      return this.$store.state.live.liveListClubfun.showing.data;
    },
    showedList() {
      return this.$store.state.live.liveListClubfun.showed.data;
    },
    showingHasMore() {
      if(this.ui.showingList.length < this.$store.state.live.liveListClubfun.showing.meta.pagination.total){
        return true
      }
      return false
    },
    showedHasMore() {
      if(this.ui.showedList.length < this.$store.state.live.liveListClubfun.showed.meta.pagination.total){
        return true
      }
      return false
    },
    isShowingListExists() {
      return this.ui.showingList.length > 0
    },
    isShowedListExists() {
      return this.ui.showedList.length > 0
    },

  },
  watch: {},
  mounted() {},
  async created() {
    await this.$store.dispatch("live/syncLiveListClubfun", {index:this.perIndex, page:this.initPage});

    this.showingList.forEach((element) => {
      this.ui.showingList.push(element);
    });

    this.showedList.forEach((element) => {
      this.ui.showedList.push(element);
    });
  },
  updated() {},
  destroyed() {},
  methods: {
    onClubfunDetailClick(liveable_id) {
      this.$router.push({
        name: 'TopFundDetail',
        params: { liveable_id },
      });
    },
    async showMore(value) {
      if (value == 'showing') {
        let x = this.ui.showingList.length;
        if (this.showingList.length < x + this.perIndex && 
          this.showingList.length < this.$store.state.live.liveListClubfun.showing.meta.pagination.total) {
          await this.$store.dispatch("live/showMoreClubfunShowing", {
            index:this.perIndex,
            page:this.showingLoadPage
          });
          this.showingLoadPage += 1;
        }
        this.showingList.forEach((element, index) => {
          if(index > x - 1 && index < x + this.perIndex){
            this.ui.showingList.push(element);
          }
        });
      } else if (value == 'showed') {
        let x = this.ui.showedList.length;
        if (this.showedList.length < x + this.perIndex &&
          this.showedList.length < this.$store.state.live.liveListClubfun.showed.meta.pagination.total) {
          await this.$store.dispatch("live/showMoreClubfunShowed", {
            index:this.perIndex,
            page:this.showedLoadPage
          });
          this.showedLoadPage += 1;
        }
        this.showedList.forEach((element, index) => {
          if(index > x - 1 && index < x + this.perIndex){
            this.ui.showedList.push(element);
          }
        });
      }
    },
    tabclick: function(tabnm){
      this.ui.tab = tabnm
      if (this.ui.tab  == "Kaisaing") {this.isActive1 = true;this.isActive2 = false;}
      if (this.ui.tab  == "Archive") {this.isActive1 = false;this.isActive2 = true;}
    }
  }
};
</script>
