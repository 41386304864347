import request from '@/utils/request'

export function getNewsList(pageLimit) {
    return request({
        url: `/notices?orderBy=order;public_time&sortedBy=asc;desc&limit=`+pageLimit,
        method: 'get',
    })
}
export function getFollowNewsList(pageLimit) {
    return request({
        url: `/follow_news?limit=`+pageLimit,
        method: 'get',
    })
}

export function readOneNews(id) {
    return request({
        url: `/notices/${id}`,
        method: 'get',
    })
}
export function readOneFollowNews(id) {
    return request({
        url: `/follow_news/${id}`,
        method: 'get',
    })
}

export function getNewsStatus() {
    return request({
        url: `/notices/statistic`,
        method: 'get',
    })
}

export function getMaintenance() {
    return request({
        url: `/system_messages?type=t2`,
        method: 'get',
    })
}
