<template lang="pug">
  div(class ="bottomNav")
    div(class ="bottomNav-img")
      a(@click="navigateToTopOrScroll")
        img(src="/img/logo.png")
    ul
      Li
        a(@click="$router.push({ name: 'TopTermsManual' })") ヘルプ
      Li
        a(@click="$router.push({ name: 'LoginContactIndex' })") お問い合わせ
      Li
        a(@click="$router.push({ name: 'TopTermsTerms' })") 利用規約
      Li
        a(@click="$router.push({ name: 'TopTermsPrivacy' })") プライバシーポリシー
      Li
        a(@click="$router.push({ name: 'TopTermsTokushoho' })") 特定商取引法に基づく表記
      Li(class ="bottomNav-twitter-img")
        img(src="/img/twitter.png" @click="onTwitter()")
    <div class="copyright">Copyright © 2021 Aライブ All Rights Reseved.</div>

    div(v-if="isDevelop()")
      p デバッグ
      span {{ `viewer_id: ${ $store.state.user.id }` }}
      br/
      span {{ `profile_id: ${ $store.state.user.profile_id }` }}
      br/
      span {{ `account_name : ${ $store.state.user.profile_account_name }` }}

</template>

<style src="./footer.scss" lang="scss" scoped>
</style>

<script>
import { isDevelop } from '@/utils/tools'

export default {
  name: "ComponentsFooterIndex",
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    onTwitter() {
      window.open('https://twitter.com/a_live_info', '_blank');
    },
    isDevelop() {
      return isDevelop();
    },
    navigateToTopOrScroll() {
      if (this.$route.name === 'TopIndex') {
        // トップページにいる場合はスクロール
        window.scrollTo({ top: 0, behavior: 'auto' });
      } else {
        // トップページ以外の場合はリダイレクト
        this.$router.push({ name: 'TopIndex' });
      }
    },
  }
}
</script>