<template lang="pug">
  .root
    component-header-login
    .login-center
      .leftContainer
        div ログイン >>
          span(style="color: #da2672;") 登録する
        br
        div(class="container-flex")
          .leftContainer
            div(class="img-content-pc") <img src="/img/Alive_email_pc-01.jpg">
            div(class="img-content-sp") <img src="/img/Alive_email_sp-01.jpg">
          .rightContainer
            .right-content-border
              div URLの発行が完了しました。
              div 入力したメールアドレスにメールをお送りしましたのでご確認ください。
              button(@click="onReturn()" class="pink-button" style="width: 100%; margin-top: 4px;") ログインページへ戻る
    component-footer-login
</template>

<style src="./Success.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "LoginRegisterSuccess",
  components: {},
  data() {
    return {}
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    onReturn() {
      this.$router.push({ name: 'LoginIndex' })
    }
  }
};
</script>
