<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="prof-change")
        div(class="bread-list") >>
          span(class="display-title") メールアドレスの変更

        div(class="content-border")
          div 入力されたメールアドレスへ「メールアドレス変更 URL」を記載したメールをお送りします。
          div この URL をクリックし、メールアドレスの変更を完了させてください。
          div(class="m-b") メールアドレスを変更するとサイトからログアウトされます。変更したメールアドレスで再度ログインしてください。
          div [注意]
          div 届いたメールのドメインが「@a-live.tokyo」であることを確認してください。
          div 本ドメイン以外のメールは偽物ですので、URL 等は絶対に開かないようにしてください。
          div 変更前のメールアドレスではログインできなくなります。
          div Aライブがメール等の返信で登録しているメールアドレスを要求することはありません。
          div(class="m-b") 変更後メールアドレス
            input(v-model="mail" class="input-g-w-border" autocapitalize="off")
          div(class="m-b") 変更後メールアドレス[確認用]
            input(v-model="mailConfirm" class="input-g-w-border" autocapitalize="off")
          div(style="color: red;") {{ ui.errorMessage }}
          div(class="t-c")
            button(@click="onChangeEmailSendCode()" class="pink-button m-b") 変更URLを発行

    component-footer-index
</template>

<style src="./ChangeMail.scss" lang="scss" scoped>

</style>

<script>
import { sendEmailCode } from '@/api/user'

export default {
  name: "TopProfileChangeMail",
  components: {
  },
  data() {
    return {
      mail: '',
      mailConfirm: '',
      ui: {
        errorMessage: '',
        showError: false,
      },
    }
  },
  computed: {},
  async created() {},
  mounted(){},
  methods: {
    checkMail() {
      // メールアドレス正規表現
      let re = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      if (!this.mail) {
        this.ui.showError = true;
        this.ui.errorMessage = '※メールアドレスが入力されていません';
        return false;
      } else if (this.mail && this.mailConfirm !== this.mail) {
        this.ui.showError = true;
        this.ui.errorMessage = '※確認用のメールアドレスが一致していません';
        return false;
      } else if (
        this.mail.includes(' ') || this.mail.includes('　')
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = 'スペースは入れずにご入力ください';
        return false;
      } else if (
        !re.test(this.mail)
      ){
        this.ui.showError = true;
        this.ui.errorMessage = '※メールアドレスが正しい形式ではありません';
        return false;
      }
      return true;
    },

    async onChangeEmailSendCode() {
      if (!this.checkMail()) {
        return;
      }
      try{
        const { data } = await sendEmailCode(this.mail);
        this.ui.showError = true;
        if (data[0]) {
          this.ui.errorMessage = '確認URLの発行が完了しました。';
        } else {
          this.ui.errorMessage = 'エラーが発生しました。';
        }
      } catch(e) {
        this.ui.errorMessage = 'エラーが発生しました。';
      }
    },
  }
}
</script>
