<template lang="pug">
  .root
    component-header-login
    .login-center
      .leftContainer
        component-login-index
      .rightContainer
        .right-content-border
          div(class="t-c m-b") パスワードの変更が完了しました
          div(class="t-c")
            button(@click="onSend()" class="pink-button" style="width: 100%;") ログインページへ戻る
    
    component-footer-login  
</template>

<style src="./Success.scss" lang="scss" scoped>
</style>

<script>
import ComponentLoginIndex from "@/components/Login/index.vue";

export default {
  name: "LoginResetSuccess",
  components: {
    ComponentLoginIndex
  },
  data() {
    return {}
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    onSend() {
      this.$router.push({ name: 'LoginIndex' })
    }
  }
};
</script>
