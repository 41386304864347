<template lang="pug">
  .root
    component-header-index
    <div class="base-window">
        div(v-if="!profileTab" class="right-content-border")
          component-target-view-index(@onRankAvatarClick='onShowUserProfile' @close="onClose")
        div(v-if="profileTab")
          component-profile-index(:profile_id="otherProfile_id" @close="onPanelClose()")
    </div>

    component-footer-index
</template>

<style src="./TargetView.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "TargetView",
  components: {
  },
  data() {
    return {
      profileTab: false,
      otherProfile_id: '',
    }
  },
  props: {},
  computed: {

  },
  watch: {},
  mounted() {},
  async created() {
    this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      await this.$store.dispatch('live/syncLiveInfo', this.$route.query.live_id)
      await this.$store.dispatch('live/syncTarget', this.$route.query.streamer_id)
    },

    onClose() {
      window.close();
    },

    onShowUserProfile(profile_id) {
      this.otherProfile_id = profile_id
      this.profileTab = true
    },

    onPanelClose() {
      this.profileTab = false
    }
  }
};
</script>
