<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="prof-change")
        div(class="prof-change-title") プロフィールを変更する>>
          span(class="display-title") 住所の登録/変更

        div 登録した住所はクラファン配信の特典送付にのみ利用されます。
        div このページで事前登録をしておくと、クラファン配信でコインを購入した際に住所を入力せずに済み、時間短縮が可能となります。

        div(class="prof-information")
          a(@click="linkPersonalInfo") >>個人情報取り扱いについて
        div(class="prof-crowd-funding-information")
          a(@click="TopTermsCrowdfundTerms") >>クラファン配信利用規約はこちら
        div(class="prof-change-address")
          ValidationObserver(ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit()")

            div(class="prof-change-address-content")
              h5 苗字
                span(style="color: red;") *
                span(class="m-0") (全角8文字・半角16文字以内)
              input(class="input-size" v-model="form.name1" @input="validateCount('familyName')" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''")

            div(class="prof-change-address-content")
              h5 名前
                span(style="color: red;") *
                span(class="m-0") (全角8文字・半角16文字以内)
              input(class="input-size" v-model="form.name2" @input="validateCount('givenName')" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''")

            div(class="prof-change-address-content")
              h5 電話番号
                span(style="color: red;") *
                span(class="m-0") (半角数字のみ)
              div(class="prof-change-address-box")
                input(v-model="phone1" oninput="if(value.length > 4)value = value.slice(0, 4)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" style="width: 35%")
                span －
                input(v-model="phone2" oninput="if(value.length > 4)value = value.slice(0, 4)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" style="width: 35%")
                span －
                input(v-model="phone3" oninput="if(value.length > 4)value = value.slice(0, 4)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" style="width: 35%")

            div(class="prof-change-address-content")
              h5 郵便番号
                span(style="color: red;") *
                span(class="m-0") (半角数字のみ)
              div(class="prof-change-address-box")
                span(style="margin: 0 5px") 〒
                span
                input(v-model="postcode1" oninput="if(value.length > 3)value = value.slice(0, 3)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" class="first-box")
                span －
                input(v-model="postcode2" oninput="if(value.length > 4)value = value.slice(0, 4)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" class="second-box")

            div(class="prof-change-address-content")
              h5 都道府県
                span(style="color: red;") *
              select(v-model="form.province" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" class='prof-change-address-province' style="width: 32%")
                option(v-for="option in ui.province" :value="option") {{ option }}

            div(class="prof-change-address-content")
              h5 市区町村
                span(style="color: red;") *
                span(class="m-0") (全角12文字・半角24文字以内)
              input(class="input-size" v-model="form.area" @input="validateCount('municipality')" onkeydown="return event.keyCode !== 32 && event.keyCode !== 12288" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''")

            div(class="prof-change-address-content")
              h5 住所
                span(style="color: red;") *
                span(class="m-0") (全角10文字・半角20文字以内)
              input(id="form.address" class="input-size" v-model.trim="form.address" @input="validateCount('address')" onkeydown="return event.keyCode !== 32 && event.keyCode !== 12288" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''")

            div(class="prof-change-address-content")
              h5 番地
                span(class="m-0") (半角数字のみ)
              div(class="prof-change-address-box")
                input(v-model="banchi1" oninput="if(value.length > 2)value = value.slice(0, 2)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" style="width: 35%")
                span －
                input(v-model="banchi2" oninput="if(value.length > 5)value = value.slice(0, 5)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" style="width: 35%")
                span －
                input(v-model="banchi3" oninput="if(value.length > 3)value = value.slice(0, 3)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" style="width: 35%")

            div(class="prof-change-address-content")
              h5 マンション名
                span(class="m-0") (全角12文字・半角24文字以内)
              input(class="input-size" v-model.trim="form.building" @input="validateCount('building')" onkeydown="return event.keyCode !== 32 && event.keyCode !== 12288" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''")

            div(class="prof-change-address-content")
              h5 部屋番号
                span(class="m-0") (半角数字のみ)
              input(v-model="form.room" oninput="if(value.length > 4)value = value.slice(0, 4)" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''" onkeydown="return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" style="width: 32%")
              span 号室

            div(class="prof-change-address-content")
              h5 会社名・部門名
                span(class="m-0") (全角25文字・半角50文字以内)
              div ※会社宛の場合は会社名および部門名を入力してください
              input(class="input-size-company" v-model="form.company" @input="validateCount('company')" onkeydown="return event.keyCode !== 32 && event.keyCode !== 12288" :disabled="ui.showConfirm" :style="ui.showConfirm ? 'background-color: gray;':''")

            div(style="color: red;") ※＊は必須項目です。英数字は半角、スペースは入れずにご入力ください。
            br/

            div(v-show="ui.showError" style="color: red;") {{ ui.errorMessage }}

            div(class="prof-change-address-button")
              div(@click="onOK()" :disabled="ui.showConfirm") <img src="/img/kakunin.png">

      div(v-if="ui.showConfirm" class="prof-change-confirm")
        div(class="right-content-border" style="height: fit-content;")
          div(class="prof-change-confirm-item")
            div 苗字: {{ form.name1 }}
            div 名前: {{ form.name2 }}
            div 電話番号: {{ form.phone }}
            div 郵便番号: {{ form.postcode }}
            div 都道府県: {{ form.province }}
            div 市区町村: {{ form.area }}
            div 住所: {{ form.address }}
            div 番地: {{ form.banchi }}
            div マンション名: {{ form.building }}
            div 部屋番号: {{ form.room }}
            div 会社名・部門名: {{ form.company }}

          div(class="prof-change-confirm-bottom")
            div この内容でよろしいですか？
            div(class="prof-change-confirm-button")
              button(@click="onConfirm()" class="pink-button") はい
              button(@click="onCancel()" class="pink-button") いいえ

    component-footer-index
</template>

<style src="./Address.scss" lang="scss" scoped>
</style>

<script>
import { setDeliveryInfo, getDeliveryInfo } from '@/api/delivery_information';
import { validStringCount, stringCount, sliceString } from '@/utils/inputLimit';
import { ValidationObserver,extend,localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import ja from 'vee-validate/dist/locale/ja.json';
import { countLength } from '@/utils/tools'


export default {
  name: "TopProfileAddress",
  components: {
    ValidationObserver
  },
  data() {
    return {
      ruleObject:{ required: true, email: true },
      rules:{
        email:{ required: true, email: true },
        address:{ required:true, max: 20, regex:/^([0-9]+)$/ }
        // address:{ required:true, max: 10, regex:/[^ぁ-んァ-ンa-zA-Z一-龠]$/ }
      },
      value:'',
      phone1: undefined,
      phone2: undefined,
      phone3: undefined,
      postcode1: undefined,
      postcode2: undefined,
      banchi1: undefined,
      banchi2: undefined,
      banchi3: undefined,

      form: {
        name1: undefined,
        name2: undefined,
        phone: undefined,
        postcode: undefined,
        province: undefined,
        area: undefined,
        address: undefined,
        banchi: undefined,

        building: "",
        room: "",
        company: "",
      },

      ui: {
        showConfirm: false,
        errorMessage: '',
        showError: false,
        province: ["", "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"],
      },

      clubfunBuy: {
          "family_name": "",
          "given_name": "",
          "phone_number1": "",
          "phone_number2": "",
          "phone_number3": "",
          "postal_code1": "",
          "postal_code2": "",
          "prefecture": "",
          "area": "",
          "address": "",
          "house_number1": "",
          "house_number2": "",
          "house_number3": "",
          "mansion_name": "",
          "room_number": "",
          "company_name": ""
      }
    }
  },
  watch: {
    'phone1' () {
      this.form.phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3;
    },
    'phone2' () {
      this.form.phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3;
    },
    'phone3' () {
      this.form.phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3;
    },
    'banchi1' () {
      this.form.banchi = [this.banchi1,this.banchi2,this.banchi3].filter(v=>v).join('-');
    },
    'banchi2' () {
      this.form.banchi = [this.banchi1,this.banchi2,this.banchi3].filter(v=>v).join('-');
    },
    'banchi3' () {
      this.form.banchi = [this.banchi1,this.banchi2,this.banchi3].filter(v=>v).join('-');
    },
    'postcode1' () {
      this.form.postcode = this.postcode1 + '-' + this.postcode2;
    },
    'postcode2' () {
      this.form.postcode = this.postcode1 + '-' + this.postcode2;
    },
  },

  async created() {
    const { data } = await getDeliveryInfo();
    if (data.family_name) {
      this.form.name1 = data.family_name;
      this.form.name2 = data.given_name;
      this.phone1 = data.phone_number.split('-')[0];
      this.phone2 = data.phone_number.split('-')[1];
      this.phone3 = data.phone_number.split('-')[2];
      this.postcode1 = data.postal_code.toString().slice(0, 3);
      this.postcode2 = data.postal_code.toString().slice(3);
      this.form.province = data.prefecture;
      this.form.area = data.area;
      this.form.address = data.address;
      this.banchi1 = data.house_number.split('-')[0];
      this.banchi2 = data.house_number.split('-')[1];
      this.banchi3 = data.house_number.split('-')[2] || '';
      this.form.building = data.mansion_name;
      this.form.room = data.room_number;
      this.form.company = data.company_name;
    }
    // install rules and localization
    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule]);
    });
    localize('ja', ja);
    extend('odd', value => {
      return value % 2 !== 0;
    })
    extend('jp_char', {
      validate(value){
        return (/^[ぁ-んァ-ンa-zA-Z一-龠]*$/g).test(String(value))
      },
      message:'無効な文字を含まれています、英数字は半角で入力してください'
    })
    extend('no_space', {
      validate(value){
        // eslint-disable-next-line
        const pattern = /^[^ 　]+$/g
        return pattern.test(String(value))
      },
      message:'スペースが入力されています'
    })
    extend('jp_address', {
      validate(value){
        // eslint-disable-next-line
        const pattern = /東京都|北海道|(?:京都|大阪)府|.{6,9}県/g
        return pattern.test(String(value))
      },
      message:'正しい住所を入力してください'
    })
    extend('no_fullwidth_number', {
      validate(value){
        const pattern = /[\uFF10-\uFF19]/g
        return !pattern.test(String(value))
      },
      message:'数字は半角で入力してください'
    })
    extend('no_fullwidth_alpha', {
      validate(value){
        const pattern = /[Ａ-Ｚａ-ｚ]+/g
        return !pattern.test(String(value))
      },
      message:'英字は半角で入力してください'
    })
  },
  methods: {
    async submit () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        // ABORT!!
      }
      // 🐿 ship it
    },
    async onOK() {
      this.ui.errorMessage = '';
      if ( !this.form.name1 ){
        this.ui.showError = true;
        this.ui.errorMessage = '※[苗字]を入力してください';
        return;
      }
      if ( !this.form.name2 ){
        this.ui.showError = true;
        this.ui.errorMessage = '※[名前]を入力してください';
        return;
      }
      if ( !this.phone1 || !this.phone2 || !this.phone3){
        this.ui.showError = true;
        this.ui.errorMessage = '※[電話番号]を入力してください';
        return;
      }
      if ( !this.postcode1 || !this.postcode2){
        this.ui.showError = true;
        this.ui.errorMessage = '※[郵便番号]を入力してください';
        return;
      }
      if ( !this.form.province ){
        this.ui.showError = true;
        this.ui.errorMessage = '※[都道府県]を選択してください';
        return;
      }
      if ( !this.form.area ){
        this.ui.showError = true;
        this.ui.errorMessage = '※[市区町村]を入力してください';
        return;
      }
      if ( !this.form.address ){
        this.ui.showError = true;
        this.ui.errorMessage = '※[住所]を入力してください';
        return;
      }
      // if ( !this.banchi1 ){
      //   this.ui.showError = true;
      //   this.ui.errorMessage = '番地1を入力してください。';
      //   return;
      // } else 
      if (countLength(this.banchi1) > 2){
        this.ui.showError = true;
        this.ui.errorMessage = '※前半は半角数字2文字以内で入力してください';
        return;
      }
      // if ( !this.banchi2 ){
      //   this.ui.showError = true;
      //   this.ui.errorMessage = '番地2を入力してください。';
      //   return;
      // } else
      if (countLength(this.banchi2) > 5){
        this.ui.showError = true;
        this.ui.errorMessage = '※中央は半角数字5文字以内で入力してください';
        return;
      }
      // if ( !this.banchi3 ){
      //   this.ui.showError = true;
      //   this.ui.errorMessage = '番地3を入力してください。';
      //   return;
      // } else
      if (countLength(this.banchi3) > 3){
        this.ui.showError = true;
        this.ui.errorMessage = '※後半は半角数字3文字以内で入力してください';
        return;
      }
      if ( this.form.room ){
        const roomNumber = this.form.room;
        if( isNaN( Number( roomNumber ) ) ){
          this.ui.showError = true;
          this.ui.errorMessage = '※部屋番号は半角数字のみ入力可能です';
          return;
        }else{
          this.ui.showError = false;
        }
      }

      // 文字入力
      if (!validStringCount(this.form.name1, 16)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[苗字]は全角8文字・半角16文字以内で入力してください（記号は使えません）';
        return;
      }
      if (!validStringCount(this.form.name2, 16)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[名前]は全角8文字・半角16文字以内で入力してください（記号は使えません）';
        return;
      }
      if (!validStringCount(this.phone1, 4) || !validStringCount(this.phone2, 4) || !validStringCount(this.phone3, 4)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[電話番号]は各欄、半角数字4桁以内で入力してください（記号は使えません）';
        return;
      }
      if (stringCount(this.postcode1) != 3 || stringCount(this.postcode2) != 4) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[郵便番号]の前半は半角数字3文字、後半は半角数字4文字以内で入力してください（記号は使えません）';
        return;
      }
      if (!validStringCount(this.form.area, 24)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[市区町村]は全角12文字・半角24文字以内で入力してください';
        return;
      }
      if (!validStringCount(this.form.address, 20)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[住所]は全角10文字・半角20文字以内で入力してください';
        return;
      }
      if (!validStringCount(this.form.building, 24)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[マンション名]は全角12文字・半角24文字以内で入力してください';
        return;
      }
      if (!validStringCount(this.form.room, 4)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[部屋番号]は半角数字4文字以内で入力してください（記号は使えません）';
        return;
      }
      if (!validStringCount(this.form.company, 50)) {
        this.ui.showError = true;
        this.ui.errorMessage = '※[会社名・部門名]は全角25文字・半角50文字以内で入力してください';
        return;
      }
      //TODO all validation should deal with vee-validate
      else if (
        this.form.name1.includes(' ') || this.form.name2.includes('　') ||
        this.form.phone.includes(' ') || this.form.postcode.includes('　') ||
        this.form.province.includes(' ') || this.form.area.includes('　') ||
        this.form.address.includes(' ') || this.form.banchi.includes('　')
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = 'スペースは入れずにご入力ください';
        return;
      }
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.ui.showError = true;
        this.ui.errorMessage = this.getFirstVeeValidateError();
        return
      }
      this.ui.showConfirm = true;
    },
    async onConfirm() {
      try {
        await setDeliveryInfo({
          "family_name": this.form.name1,
          "given_name": this.form.name2,
          "phone_number": this.form.phone,
          "postal_code": this.form.postcode.replace('-', ''),
          "prefecture": this.form.province,
          "area": this.form.area,
          "address": this.form.address,
          "house_number": this.form.banchi,
          "mansion_name": this.form.building,
          "room_number": this.form.room,
          "company_name": this.form.company,
        })
      } catch (err) {
        if (err.response.status === 422) {
          this.$alert(err.response.data.message, {
            confirmButtonText: '確認',
          })
          this.ui.showConfirm = false;
          return;
        }
      }

      this.$router.push({ name: 'TopProfileChange' })
    },
    onCancel() {
      this.ui.showConfirm = false;
    },
    validateCount(element) {
      switch(element) {
        case 'familyName':
          this.form.name1 = sliceString(this.form.name1, 16)
          break;
        case 'givenName':
          this.form.name2 = sliceString(this.form.name2, 16)
          break;
        case 'municipality':
          this.form.area = sliceString(this.form.area, 24)
          break;
        case 'address':
          this.form.address = sliceString(this.form.address, 20)
          break;
        case 'building':
          this.form.building = sliceString(this.form.building, 24)
          break;
        case 'company':
          this.form.company = sliceString(this.form.company, 50)
          break;
      }
    },
    linkPersonalInfo() {
      let link = this.$router.resolve({ name: 'TopTermsPersonalInfo' });
      window.open(link.href,'_blank');
    },
    getFirstVeeValidateError(){
      const errorObj = this.$refs.observer.errors
      // console.log(obj)
      for(var key in errorObj){
        // console.log(`${key}:${errorObj[key]}`)
        if(errorObj[key].length > 0){
          return `「${key}」${errorObj[key][0]}`
          // return errorObj[key][0]
        }
      }
    },
    TopTermsCrowdfundTerms() {
      let link = this.$router.resolve({ name: 'TopTermsFundTerms' });
      window.open(link.href,'_blank');
    },
  }
}
</script>
