<template lang="pug">
.root
  component-header-login

  .container
    .formContainer
      div(v-if="!ui.showConfirm")
        div(v-if="!isNamePassCheck" style="color:red;margin-top:20px") NGワードが含まれています
        p.item
          span.itemTitle アカウント名
          span.asterisk ＊
        input.inputArea(v-model="form.accountName" @input="checkNameInput()")
        br/
        div.attentionMsg ※ご登録後、生年月日は変更できません
        p.item
          span.itemTitle 生年月日
          span.asterisk ＊
        select.selectArea.yearForm(v-model="form.year")
          option(v-for="option in ui.year", :value="option") {{ option }}
        select.selectArea.monthForm(v-model="form.month", :disabled="!form.year")
          option(v-for="option in ui.month", :value="option") {{ option }}
        select.selectArea.dayForm(
          v-model="form.day",
          :disabled="!form.year || !form.month"
        )
          option(v-for="option in ui.day", :value="option") {{ option }}
        br/
        p.item
          span.itemTitle 住んでいる国
          span.asterisk ＊
        select.selectArea.livingCountry(v-model="form.country")
          option(v-for="option in ui.country", :value="option") {{ option }}
        br/
        div(v-if="form.country === '日本'")
          p.item
            span.itemTitle 住んでいる都道府県
            span.asterisk ＊
          select.selectArea.livingPrefecture(
            v-model="form.province",
            :disabled="form.country !== '日本'"
          )
            option(v-for="option in ui.province", :value="option") {{ option }}
          br/
        p.item
          span.itemTitle 秘密の質問
          span.asterisk ＊
        select.selectArea.secretAnswerForm(v-model="form.secretQuestion")
          option(v-for="option in ui.secretQuestion", :value="option") {{ option }}
        br/
        p.item
          span.itemTitle 秘密の答え
          span.asterisk ＊
        input.inputArea(v-model="form.secretAnswer") 
        br/
        div.attentionMsg ※ パスワードは英数字混在の8～16桁で設定してください
        p.item
          span.itemTitle パスワード
          span.asterisk ＊
        label(class="input-password-content")
          input.inputArea(type="text" v-model="form.password" v-if="psdDisplay")
          input.inputArea(v-model="form.password" type="password" v-else) 
          i(class="fas fa-eye eye-icon" v-show="!psdDisplay" @click="psdDisplay=!psdDisplay")
          i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay" @click="psdDisplay=!psdDisplay")
        br/
        p.item
          span.itemTitle パスワード[確認用]
          span.asterisk ＊
        label(class="input-password-content")
          input.inputArea(type="text" v-model="passwordConfirm" v-if="psdDisplay2")
          input.inputArea(v-model="passwordConfirm", type="password" v-else)
          i(class="fas fa-eye eye-icon" v-show="!psdDisplay2" @click="psdDisplay2=!psdDisplay2")
          i(class="fas fa-eye-slash eye-icon" v-show="psdDisplay2" @click="psdDisplay2=!psdDisplay2")
        br/
        .asteriskExplanation 
          span.asterisk ＊
          span.attentionMsg は必須項目です。スペースは入れずにご入力ください。

        div(class="no-robot")
          vue-recaptcha(ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            :sitekey="getSiteKey()")

        .buttonArea
          .errorMessageArea
            .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}
          div(@click="onOK()") <img src="/img/kakunin.png">

      div(v-if="ui.showConfirm")
        .confirmContainer
          p.item
            span.itemTitle アカウント名
          p.inputArea {{ form.accountName }}
          br/
          p.item
            span.itemTitle 生年月日
          p.inputArea {{ form.year }} 年 {{ form.month }} 月 {{ form.day }} 日
          br/
          p.item
            span.itemTitle 住んでいる国
          p.inputArea {{ form.country }}
          br/
          div(v-if="form.country === '日本'")
            p.item
              span.itemTitle 住んでいる都道府県
            p.inputArea {{ form.province }}
            br/
          p.item
            span.itemTitle 秘密の質問
          p.inputArea {{ form.secretQuestion }}
          br/
          p.item
            span.itemTitle 秘密の答え
          p.inputArea {{ form.secretAnswer }}
          br/
          p.item
            span.itemTitle パスワード
          p.inputArea {{ pwdStar }}
          br/
          p.submit この内容でよろしいですか？
          .buttonArea
            img(@click="onConfirm()" class="leftButton" class="pointer" src="/img/soushin.png")
            img(@click="onCancel()" class="rightButton" class="pointer" src="/img/modoru.png")

  component-footer-login
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { repeat } from "lodash";
import { validateToken } from '@/api/user'
import VueRecaptcha from "vue-recaptcha";
import { getSystemNgWord } from '@/api/system';
import { countLength } from '@/utils/tools'
import Mint from 'mint-filter';

export default {
  name: "LoginInitIndex",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      ui: {
        errorMessage: "Error",
        showConfirm: false,
        showError: false,
        year: Array.from(
          { length: new Date().getFullYear() },
          (_, i) => i + 1
        ).filter((v) => v >= 1900),
        month: [''].concat(Array.from({ length: 12 }, (_, i) => i + 1)),
        day: [],
        country: [
          "",
          "日本",
          "アフガニスタン",
          "アルバニア",
          "アルジェリア",
          "アンドラ",
          "アンゴラ",
          "アンティグア・バーブーダ",
          "アルゼンチン",
          "アルメニア",
          "オーストラリア",
          "オーストリア",
          "バハマ",
          "バーレーン",
          "バングラデシュ",
          "バルバドス",
          "ベラルーシ",
          "ベルギー",
          "ベリーズ",
          "ベニン",
          "ブータン",
          "ボリビア",
          "ボスニア・ヘルツェゴビナ",
          "ボツワナ",
          "ブラジル",
          "ブルネイ",
          "ブルガリア",
          "ブルキナファソ",
          "ブルンジ",
          "カーボベルデ",
          "カンボジア",
          "カメルーン",
          "カナダ",
          "中央アフリカ共和国",
          "チャド",
          "チリ",
          "中国",
          "コロンビア",
          "コモロ",
          "コンゴ",
          "コスタリカ",
          "コートジボアール",
          "クロアチア",
          "キューバ",
          "キプロス",
          "チェコ",
          "朝鮮民主主義人民共和国",
          "コンゴ民主共和国",
          "デンマーク",
          "ジブチ",
          "ドミニカ",
          "ドミニカ共和国",
          "エクアドル",
          "エジプト",
          "エルサルバドル",
          "赤道ギニア",
          "エリトリア",
          "エストニア",
          "エスワティニ",
          "エチオピア",
          "フィジ.",
          "フィンランド",
          "フランス",
          "ガボン",
          "ガンビア",
          "ジョージア",
          "ドイツ",
          "ガーナ",
          "ギリシャ",
          "グレナダ",
          "グアテマラ",
          "ギニア",
          "ギニアビサウ",
          "ガイアナ",
          "ハイチ",
          "ホンジュラス",
          "ハンガリ. ",
          "アイスランド",
          "インド",
          "インドネシア",
          "イラン",
          "イラク",
          "アイルランド",
          "イスラエル",
          "イタリア",
          "ジャマイカ",
          "ヨルダン",
          "カザフスタン",
          "ケニア",
          "キリバス",
          "クウェート",
          "キルギスタン",
          "ラオス人民民主共和国",
          "ラトビア",
          "レバノン",
          "レソト",
          "リベリア",
          "リビア",
          "リヒテンシュタイン",
          "リトアニア",
          "ルクセンブルク",
          "マダガスカル ",
          "マラウィ",
          "マレーシア",
          "モルディブ",
          "マリ ",
          "マルタ",
          "マーシャル諸島",
          "モーリタニア",
          "モーリシャス",
          "メキシコ",
          "ミクロネシア連邦",
          "モナコ",
          "モンゴル",
          "モンテネグロ",
          "モロッコ",
          "モザンビーク",
          "ミャンマー",
          "ナミビア",
          "ナウル",
          "ネパール",
          "オランダ",
          "ニュージーランド",
          "ニカラグア",
          "ニジェール",
          "ナイジェリア",
          "北マケドニア",
          "ノルウェー",
          "オマーン",
          "パキスタン",
          "パラオ",
          "パナマ",
          "パプア・ニューギニア",
          "パラグアイ",
          "ペルー",
          "フィリピン",
          "ポーランド",
          "ポルトガル",
          "カタール",
          "韓国",
          "モルドバ",
          "ルーマニア",
          "ロシア連邦",
          "ルワンダ",
          "セントクリストファー・ネイビス",
          "セントルシア",
          "セントビンセントおよびグレナディーン諸島",
          "サモア",
          "サンマリノ",
          "サントメ・プリンシペ",
          "サウジアラビア",
          "セネガル",
          "セルビア",
          "セイシェル",
          "シエラレオネ",
          "シンガポール",
          "スロバキア",
          "スロベニア",
          "ソロモン諸島",
          "ソマリア",
          "南アフリカ",
          "南スーダン",
          "スペイン",
          "スリランカ",
          "スーダン",
          "スリナム",
          "スウェーデン",
          "スイス",
          "シリア",
          "タジキスタン",
          "タイ",
          "東ティモール",
          "トーゴ",
          "トンガ",
          "トリニダード・トバゴ ",
          "チュニジア",
          "トルコ",
          "トルクメニスタン",
          "ツバル",
          "ウガンダ",
          "ウクライナ",
          "アラブ首長国連邦",
          "英国",
          "タンザニア",
          "米国",
          "ウルグアイ",
          "ウズベキスタン",
          "バヌアツ",
          "ベネズエラ",
          "ベトナム",
          "イエメン",
          "ザンビア",
          "ジンバブエ",
        ],
        province: ["", "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"],
        secretQuestion: [
          "",
          "母親の旧姓は？",
          "好きな食べ物は？",
          "出生地は？",
          "思い出の場所は？",
          "小学校の担任の先生の名前は？",
        ],
      },
      form: {
        accountName: "",
        year: '1970',
        month: "",
        day: "",
        country: "",
        province: "",
        secretQuestion: "",
        secretAnswer: "",
        password: "",
      },
      psdDisplay:false,
      psdDisplay2:false,
      passwordConfirm: "",
      recaptcha: false,
      NgWordList: [],
      mint: {},
      isMintInited: false,
      isNamePassCheck: true,
    };
  },
  props: {},
  computed: {
    pwdStar() {
      return repeat("＊", this.form.password.length);
    },
  },
  watch: {
    "form.year"(val) {
      if (val && this.form.month) {
        this.ui.day = [''].concat(Array.from(
          { length: this.calcDay(val, this.form.month) },
          (_, i) => i + 1
        ));
      } else {
        this.ui.day = [];
      }
    },
    "form.month"(val) {
      if (val && this.form.year) {
        this.ui.day = [''].concat(Array.from(
          { length: this.calcDay(this.form.year, val) },
          (_, i) => i + 1
        ));
      } else {
        this.ui.day = [];
      }
    },
  },
  mounted() {},
  async created() {
    // トークンの有効期限の検証
    const query = this.$route.query;
    try {
      const { code } = await validateToken(query);
      if( !code || code != 200 ){
        this.$alert('有効期限が経過しました。再度新規登録をしてください')
        this.$router.push({ name: 'LoginIndex'});
        return;
      }
    } catch( error ) {
      console.log(error);
      this.$alert('有効期限が経過しました。再度新規登録をしてください')
      this.$router.push({ name: 'LoginIndex'});
      return;
    }
    this.initMint();
  },
  updated() {},
  destroyed() {},
  methods: {
    async onOK() {
      const pwdArray = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890".split("");
      const pwdEnglishArray = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM".split("");
      const pwdNumberArray = "1234567890".split("");

      const pwdInputArray = this.form.password.split("");
      this.checkNameInput()
      if (!this.isNamePassCheck) {
        this.ui.showError = true;
        this.ui.errorMessage = "NGワードが含まれています";
        return;
      }
      if (
        !this.form.accountName ||
        !this.form.year ||
        !this.form.month ||
        !this.form.day ||
        !this.form.country ||
        !this.form.secretQuestion ||
        !this.form.secretAnswer ||
        !this.form.password
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = "＊は必須項目です、必ず入力してください。";
        return;
      } else if (
        this.form.password &&
        this.passwordConfirm !== this.form.password
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = "※確認用のパスワードが一致していません。";
        return;
      } else if (
        this.form.accountName.includes(" ") ||
        this.form.accountName.includes("　") ||
        this.form.secretAnswer.includes(" ") ||
        this.form.secretAnswer.includes("　") ||
        this.form.password.includes(" ") ||
        this.form.password.includes("　")
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = "スペースは入れずにご入力ください";
        return;
      } else if (
        countLength(this.form.accountName) > 40
      ) {
        this.ui.showError = true;
        this.ui.errorMessage = "アカウント名は全角20文字、半角40文字以内で入力してください";
        return;
      } else if (
        this.form.password.length < 8 ||
        this.form.password.length > 16 ||
        !pwdInputArray.every((v) => pwdArray.includes(v)) ||
        !pwdInputArray.some((v) => pwdEnglishArray.includes(v)) ||
        !pwdInputArray.some((v) => pwdNumberArray.includes(v))
      ) {
        this.ui.showError = true;
        this.ui.errorMessage =
          "※パスワードに英数字が混在しているか8～16桁になっているか確認してください";
        return;
      }
      if (!this.recaptcha) {
        this.ui.showError = true;
        this.ui.errorMessage = "※「私はロボットではありません」にチェックを入れてください";
        return;
      }

      this.ui.showConfirm = true;
    },
    async onConfirm() {
      const form = {
        password: this.form.password,
        country: this.form.country,
        province: this.form.country === '日本' ? this.form.province : "",
        secret_question: this.form.secretQuestion,
        secret_answer: this.form.secretAnswer,
        profile: {
          account_name: this.form.accountName,
          birthday: this.formatDate(
            `${this.form.year}`,
            `${this.form.month}`,
            `${this.form.day}`
          ),
        },
      };

      const query = this.$route.query;

      try {
        await this.$store.dispatch("user/initByData", { form, query });
        localStorage.removeItem('buttonHide');
        this.$router.push({ name: "LoginInitNext" });
      } catch (e) {
        console.log(e);
        this.ui.errorMessage = "エラーが発生しました";
        this.ui.showError = true;
        this.ui.showConfirm = false;
      }
    },
    onCancel() {
      this.ui.showError = false;
      this.ui.showConfirm = false;
      this.recaptcha =  false;
    },
    calcDay(year, month) {
      if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
        return 31;
      } else if ([4, 6, 9, 11].includes(month)) {
        return 30;
      } else {
        if (year % 4 === 0) {
          return 28;
        } else {
          return 29;
        }
      }
    },
    formatDate(year, month, day) {
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    onVerify(recaptcha) {
      this.recaptcha = recaptcha;
    },
    onExpired() {
      this.recaptcha = false;
    },
    getSiteKey() {
      return process.env.VUE_APP_ENV === `DEV` ? `6Legtq8aAAAAAFoVMWr9rl7-TnyJYiNJOip0D4HO` : `6LccCbcaAAAAALOM0Kdw_muzKpg2UZ16GEC6scXV`
    },
    // NGワードチャックInit
    async initMint(){
      this.isMintInited = false
      const { data } = await getSystemNgWord()
      this.NgWordList = data
      this.mint = new Mint(this.NgWordList)
      this.isMintInited = true
    },
    // 入力のタイミングでNGワードチャック name
    checkNameInput(){
      const regMail = /[\w\-._]+@[\w\-._]+\.[A-Za-z]+/g
      const regUrl = /https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/g
      this.isNamePassCheck = this.mint.validator(this.form.accountName) && !regMail.test(this.form.accountName) && !regUrl.test(this.form.accountName)
    },
  },
};
</script>
