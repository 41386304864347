<template lang="pug">
  .root
    component-header-index

    stripe-checkout(
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    )

    div(:class="ui.Expansion === true ? 'stream-bg' : ''")
    <div class="center">
      div(class="container-flex")
        div(:class="ui.Expansion === true ? '' : 'content-live'")
            <div class="liveStayus">
              <div class="liveStayus-item">
                <div @click="onAvatarClick($store.state.live.info.profile.id)" style="list-style-type:none; width: 40px;margin-left: 0px;margin-top: 0px;">
                  <a @click="">
                    //- <img :src="liverAvatar" style="border-radius: 100%; object-fit:cover;">
                    <pan-thumb :avatar="liverAvatar" :attribute="$store.state.live.info.profile.attribute" :isStreamer="true" size="40px"/>
                  </a>
                </div>
                <div v-if="isLiveNormal || isLivePaid"><p><span class="stream_name">{{ $store.state.live.info.room_info.name }}</span></p></div>
                <div v-if="isLiveEvent"><p><span class="stream_name">{{ $store.state.live.info.room_info.name }}</span></p></div>
                <div v-if="isLiveFund"><p><span class="stream_name">{{ $store.state.live.info.room_info.name }}</span></p></div>
              </div>
              <div class="liveStayus-item">
                <div><p><img src="/img/clock_icon_gray.svg">{{ duration }}</p></div>
                <div><p><img src="/img/profile_icon_gray.svg"> {{ $store.state.live.onlineCount }}</p></div>
                share-link(:text="shareText" :url="shareUrl")
              </div>
            </div>

            <div :class="ui.Expansion === true ? 'stream-container-ex' : 'stream-container'">
                ArchiveVideoRect(ref="videoRect" @playMotion="playMotion" @getDuration="getDuration" @playStreamerMessage="playStreamerMessage")
                .pixi.absolute(ref="rectPixi")
                div(v-if="backgroundImgShow" v-bind:class="motionBackground")
                <div>
                  <div v-if="titleMessage" class="topleft"><p style="text-align: center;"><b v-html="titleMessage"></b></p></div>
                  <div class="topright">
                    <p>
                      <span data="" class="badge badge-black badge-pill">P</span>
                      <b> {{ infoPoint }} </b>
                    </p></div>
                </div>
            </div>

            div(v-if="isDev")
              button(@click="playFireEffect()") 炎テスト
              button(@click="playSnowEffect()") 雪テスト
              button(@click="playWindEffect()") 風テスト
              button(@click="playZeus()") Zeusテスト
              button(@click="playBall()") Ballテスト
              button(@click="playMeerkat()") Meerkatテスト
              button(@click="playGoddess()") Goddessテスト
              button(@click="playPhoenix()") Phoenixテスト
              button(@click="playPinkShit()") PinkShitテスト
              button(@click="playIceDragon()") IceDragonテスト
              button(@click="playDolphin()") Dolphinテスト
              button(@click="playWhale()") Whaleテスト
              button(@click="playWildPig()") WildPigテスト
              button(@click="playTiger()") Tigerテスト

            ArchiveComment(ref="comment")
    </div>
    component-footer-index
</template>

<style src="./ArchiveBase.scss" lang="scss" scoped>

</style>

<script>
import ArchiveComment from './ArchiveComment'
import ArchiveVideoRect from './ArchiveVideoRect'
import { getLiveListEventDetail } from '@/api/live';
import { getFireEffect, getSnowEffect, getWindAirEffect, getWindFlowerEffect, getWindFlower2Effect } from '@/api/particles';
// import _ from 'lodash'
import moment from 'moment'

import { LIVE_TYPE } from '../helper/def'
// import { getLiveDurationStr } from '@/utils/tools'
import { StripeCheckout } from '@vue-stripe/vue-stripe';


import PanThumb from '@/components/PanThumb'
import ShareLink from '@/components/ShareLink'

import * as PIXI from 'pixi.js'
import 'pixi-spine';
import { initPixi,playSpineAnimation } from '@/utils/spine'
window.PIXI = PIXI
import {Emitter} from 'pixi-particles';
// import { getImageUrl } from '@/utils/tools'

export default {
  name: "ArchiveLiveComponentBase",
  components: {
    ArchiveComment,
    ArchiveVideoRect,
    StripeCheckout,
    PanThumb,
    ShareLink,
    TemplateVars: {
      functional: true,
      render: (h, {props, scopedSlots}) => h('div', scopedSlots.default(props)),
    },
  },
  data() {
    return {
      shareText: '',
      shareUrl: '',
      playQ:[],
      audio: new Audio(),
      attributeAudio: new Audio(),
      motionAudio: new Audio(),
      ui: {
        Expansion: false,

      },
      publishableKey: '',
      sessionId: '',
      eventInfo: {},
      elapsedTime: '',
      giftType: '',

      particleContainer: null,
      effectFireEmmiter: undefined,
      effectFireOption: undefined,
      effectSnowEmmiter: undefined,
      effectSnowOption: undefined,
      effectWindAirEmmiter: undefined,
      effectWindAirOption: undefined,
      effectWindFlowerEmmiter1: undefined,
      effectWindFlowerEmmiter2: undefined,
      effectWindFlowerOption: undefined,
      effectWindFlower2Option: undefined,
      effectDuration:5000,
      effectWaitDuration:3000,

      duration: '',
      titleMessage: '',
    }
  },
  props: {
    liveType: {
      type: Symbol,
      default: LIVE_TYPE.NORMAL
    }
  },
  mounted() {
    const rectPixi = this.$refs['rectPixi'];
    this.particleContainer = Object.seal(initPixi(window.devicePixelRatio,rectPixi,this.updateFrame,this.onAnimComplete))

    if (window.innerWidth < 800) {
        this.particleContainer.scale.x = this.particleContainer.scale.y = 0.5;

        this.particleContainer.x = this.particleContainer.x / 2;
        this.particleContainer.y = this.particleContainer.y / 2;
    }
  },
  computed: {
    liverAvatar(){
      return (this.$store.state.live.info.profile.avatar_store_path)
    },
    isLiveNormal() {
      return this.liveType === LIVE_TYPE.NORMAL
    },
    isLiveEvent() {
      return this.liveType === LIVE_TYPE.EVENT
    },
    isLiveFund() {
      return this.liveType === LIVE_TYPE.FUND
    },
    isLivePaid() {
      return this.liveType === LIVE_TYPE.PAID
    },

    infoPoint() {
      return this.$store.state.live.info.point;
    },
    isDev() {
      return process.env.VUE_APP_ENV === 'DEV'
    },
  },
  watch: {},
  beforeMount() {
    this.$store.dispatch('live/reset');
  },
  async created() {
    await this.init();
    // 有料配信、購入なし
    if (this.$store.state.live.ticket.id) {
      return;
    }

    // 拡散テキスト設定
    if(this.$store.state.live.info.room_info && this.$store.state.live.info.room_info.twitter_text) {
      this.shareText = this.$store.state.live.info.room_info.twitter_text + "\n";
    }
    this.shareText += this.$store.state.live.info.room_info.name + "\n";
    this.shareUrl = location.href;
    //this.getTimeDuration(this.$store.state.live.info.started_at);
    //this.getTimeDuration(this.$refs.videoRect.$refs['hls-player'].currentTime);
    if (this.isLiveEvent) {
      this.isLiveEventData();
    }
  },
  beforeUpdate() {
  },
  updated() {},
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async init() {
      this.initParticles();
      this.$store.dispatch('user/syncAttribute');
      const success = await this.$store.dispatch('live/archiveLiveInit', {
        id: this.$route.params.id,
        live_item_amount: this.$store.state.system.config.payment_limit_list.item_amount,
        limit: 100
      });
      if (success) {
        try {
          await this.$store.dispatch('live/syncArchiveLiveURL');
        } catch(e) {
          console.log(e);
        }
        await this.$refs.comment.init();
        this.$refs.videoRect.playVideo();

        return true;
      } else {
        return false;
      }
    },
    getDuration(duration){
      duration = parseInt(duration)
      this.duration = moment.unix(duration).utc().format('HH:mm:ss')
    },

    onAvatarClick(profile_id) {
      let routeData = this.$router.resolve({
        name: 'TopProfileStyle2',
        params: { profile_id }
      });
      window.open(routeData.href, '_blank');
    },
    Expansion() {
      this.ui.Expansion = true;
    },
    ExpansionClose() {
      this.ui.Expansion = false;
    },
    async isLiveEventData() {
      const { data } = await getLiveListEventDetail(this.$store.state.live.info.liveable_id);
      this.eventInfo = data;
    },
    async playEarthEffect() {
      this.playByKey('Tiger_back')
    },
    async playZeus(index) {
      this.playByKey('Zeus', index)
    },
    async playBall() {
      this.playByKey('Ball')
    },
    async playMeerkat() {
      this.playByKey('Meerkat')
    },
    async playGoddess(index) {
      this.playByKey('NvShen', index)
    },
    async playPhoenix() {
      this.playByKey('Phoenix')
    },
    async playPinkShit() {
      this.playByKey('PinkShit')
    },
    async playIceDragon() {
      this.playByKey('IceDragon');
    },
    async playDolphin() {
      this.playByKey('Dolphin')
    },
    async playWhale() {
      this.playByKey('Whale')
    },
    async playWildPig() {
      this.playByKey('WildPig')
    },
    async playTiger() {
      this.playByKey('Tiger')
    },
    async playFlyHorse() {
      this.playByKey('FlyHorse');
    },
    async playHanabi1() {
      this.playByKey('yanhua_a');
    },
    async playHanabi2() {
      this.playByKey('yanhua_b');
    },
    async playByKey(key,index = 0){
      this.playQ.push({key,index})
      if(this.playQ.length === 1){
        this.playAllAnims(key, index)
      }
    },
    playEffect(key) {
      switch(key) {
        case 'IceDragon':
          this.playSnowEffect(false, 3000);
          break
        case 'Phoenix':
          this.playFireEffect();
          break
        case 'FlyHorse':
          this.playWindEffect();
          break
        default:
          break;
      }

    },
    playAllAnims(key, index) {
      switch(key) {
        case 'Whale':
          setTimeout(() => {  playSpineAnimation(key); }, 2000);
          break
        case 'PinkShit':
          setTimeout(() => {  playSpineAnimation(key); }, 1000);
          break
      case 'Snow':
        this.playSnowEffect(true, 0);
        return;
      case 'Fire':
        this.playFireEffect(true);
        return;
      case 'Wind':
        this.playWindEffect(true, true);
        return;
        default:
          playSpineAnimation(key);
          break;
      }
      this.showBackground(key)
      this.playMusic(key,index)
      this.playEffect(key);
    },
    onAnimComplete(){
      // console.log(999999999)
      this.playQ.shift()
      // check if the playQ have not play anim
      if(this.playQ.length > 0){
        this.playAllAnims(this.playQ[0].key, this.playQ[0].index)
      }
    },
    updateFrame(){
        const newNow = Date.now();
        let updateCounter = (newNow - this.notTimeStamp) * 0.001
        // 炎
        if( this.effectFireEmmiter ){
          this.effectFireEmmiter.update(updateCounter);
        }
        // 雪
        if( this.effectSnowEmmiter ){
          this.effectSnowEmmiter.update(updateCounter);
        }
        // 風
        if ( this.effectWindAirEmmiter ){
          this.effectWindAirEmmiter.update(updateCounter);
          this.effectWindFlowerEmmiter1.update(updateCounter);
          this.effectWindFlowerEmmiter2.update(updateCounter);
        }

        this.notTimeStamp = newNow;
    },
    async initParticles() {
      if( !this.isLiveNormal && !this.isLiveEvent  ){
        return;
      }
      // 炎
      const effectFireTextures = [
        PIXI.Texture.from("/pixi/particles/fire/fire_01.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_02.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_03.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_04.png")
      ];
      let res = undefined;
      try{
        // 演出用json取得
        res = await getFireEffect();
      } catch(e){
        console.log(e);
      }
      this.effectFireOption = res.data;
      this.effectFireEmmiter = new Emitter(
        this.particleContainer,
        effectFireTextures,
        this.effectFireOption,
      );
      this.effectFireEmmiter.emit = false;
      // 雪
      const effectSnowTextures = [
        PIXI.Texture.from("/pixi/particles/snow/snow_01.png")
      ];
      res = undefined;
      try{
        // 演出用json取得
        res = await getSnowEffect();
      } catch(e){
        console.log(e);
      }
      this.effectSnowOption = res.data;
      this.effectSnowEmmiter = new Emitter(
        this.particleContainer,
        effectSnowTextures,
        this.effectSnowOption,
      );
      this.effectSnowEmmiter.emit = false;
      // 風
      const effectWindAirTextures = [
        PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_01.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_02.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png")
      ];
      const effectWindFlowerTextures = [
        PIXI.Texture.from("/pixi/particles/wind/wind_flower/wind_flower_01.png")
      ];
      let res1 = undefined;
      let res2 = undefined;
      let res3 = undefined;
      try{
        // 演出用json取得
        res1 = await getWindAirEffect();
        res2 = await getWindFlowerEffect();
        res3 = await getWindFlower2Effect();
      } catch(e){
        console.log(e);
      }
      this.effectWindAirOption = res1.data;
      this.effectWindFlowerOption = res2.data;
      this.effectWindFlower2Option = res3.data;
      this.effectWindAirEmmiter = new Emitter(
        this.particleContainer,
        effectWindAirTextures,
        this.effectWindAirOption,
      );
      this.effectWindAirEmmiter.emit = false;
      this.effectWindFlowerEmmiter1 = new Emitter(
        this.particleContainer,
        effectWindFlowerTextures,
        this.effectWindFlowerOption,
      );
      this.effectWindFlowerEmmiter1.emit = false;
      this.effectWindFlowerEmmiter2 = new Emitter(
        this.particleContainer,
        effectWindFlowerTextures,
        this.effectWindFlower2Option,
      );
      this.effectWindFlowerEmmiter2.emit = false;
    },

    playFireEffect(call_next = false) {
      this.effectFireEmmiter.cleanup();
      if( this.effectFireEmmiter.emit ){
        return;
      }
      this.effectFireEmmiter.emit = true;
      setTimeout(() => {
        this.effectFireEmmiter.emit = false;
        this.effectFireEmmiter.emit = false;
        if (call_next) {
          setTimeout(() => {
            this.onAnimComplete();
          }
            // 2秒
            ,this.effectWaitDuration
          )

        }
      }
        // 5秒
        ,this.effectDuration
      )
      
      // 炎の音
      this.attributeAudio.src = require("../../../../../assets/voice/fire.mp3");
      this.attributeAudio.play();
    },

    playSnowEffect(call_next = false, duration = 0) {
      this.effectSnowEmmiter.cleanup();
      if( this.effectSnowEmmiter.emit ){
        return;
      }
      this.effectSnowEmmiter.emit = true;
      setTimeout(() => {
        this.effectSnowEmmiter.emit = false;
        if (call_next) {

          setTimeout(() => {
              this.onAnimComplete();
            }
              // 2秒
              ,this.effectWaitDuration
            )
          }

      }
        // 5秒
        ,duration > 0 ? duration: this.effectDuration
      )
      // 雪の音
      this.attributeAudio.src = require("../../../../../assets/voice/snow.mp3");
      this.attributeAudio.play();
    },

    playWindEffect(call_next = false, is_short = false) {
      let wait1 = 500
      let wait2 = 2500
      let wait3 = 2000
      if (is_short) {
        wait1 = 100
        wait2 = 1200
        wait3 = 1000
      }
      this.effectWindAirEmmiter.cleanup();
      this.effectWindFlowerEmmiter1.cleanup();
      this.effectWindFlowerEmmiter2.cleanup();
      if( this.effectWindAirEmmiter.emit ){
        return;
      }
      this.effectWindAirEmmiter.emit = true;
      setTimeout(() => {
        this.effectWindFlowerEmmiter1.cleanup();
        this.effectWindFlowerEmmiter1.emit = true;
        // 風の音
        this.attributeAudio.src = require("../../../../../assets/voice/wind.mp3");
        this.attributeAudio.play();
        setTimeout(() => {
          this.effectWindFlowerEmmiter2.cleanup();
          this.effectWindFlowerEmmiter2.emit = true;
          setTimeout(() => {
            this.effectWindAirEmmiter.emit = false;
            this.effectWindFlowerEmmiter1.emit = false;
            this.effectWindFlowerEmmiter2.emit = false;
            if (call_next) {
              setTimeout(() => {
                this.onAnimComplete();
              }
                ,this.effectWaitDuration
              );
            }

          }
            ,wait3
          );
        }
          ,wait2
        );
      }
        ,wait1
      );
    },
    playMotion(gift){
      for(let i=0; i<gift.amount; i++) {
        // ギフト演出
        switch( gift.motionId ){
          case this.$store.state.system.enum_list.gift_type_list.SHOW_FIRE:
            this.playByKey('Fire');
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_ICE:
            this.playByKey('Snow');
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_WIND:
            this.playByKey('Wind');
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_EARTH:
            this.playEarthEffect();
            break;
          case this.$store.state.system.enum_list.gift_type_list.SUPERLATIVE_GODDESS:
            this.playGoddess(i % 3)
            break;
          case this.$store.state.system.enum_list.gift_type_list.SUPERLATIVE_ZEUS:
            this.playZeus(i % 3)
            break;
          case this.$store.state.system.enum_list.gift_type_list.BALLOON_GROUP:
            this.playBall()
            break;
          case this.$store.state.system.enum_list.gift_type_list.MEERKAT_3:
            this.playMeerkat()
            break;
          case this.$store.state.system.enum_list.gift_type_list.POOP_PINK:
            this.playPinkShit()
            break;
          case this.$store.state.system.enum_list.gift_type_list.DOLPHIN_3:
            this.playDolphin()
            break;
          case this.$store.state.system.enum_list.gift_type_list.WHALE:
            this.playWhale()
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_PEGASUS:
            this.playFlyHorse()
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_PHOENIX:
            this.playPhoenix()
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_DRAGON:
            this.playIceDragon()
            break;
          case this.$store.state.system.enum_list.gift_type_list.BOAR_GROUP:
            this.playWildPig()
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_WHITETIGER:
            this.playTiger()
            break;
          default:
            break;
        }
      }
    },
    playMusic(type,index = 0) {
      switch(type) {
        case 'Ball':
          this.motionAudio.src = require("../../../../../assets/voice/Ball.mp3");
          break;
        case 'Whale':
          this.motionAudio.src = require("../../../../../assets/voice/Whale.mp3");
          break;
        case 'Dolphin':
          this.motionAudio.src = require("../../../../../assets/voice/Dolphin.mp3");
          break;
        case 'Phoenix':
          this.motionAudio.src = require("../../../../../assets/voice/Phoenix.mp3");
          break;
        case 'WildPig':
          this.motionAudio.src = require("../../../../../assets/voice/WildPig.mp3");
          break;
        case 'Meerkat':
          this.motionAudio.src = require("../../../../../assets/voice/Meerkat.mp3");
          break;
        case 'NvShen':
          this.motionAudio.src = require(`../../../../../assets/voice/NvShen_${index + 1}.mp3`);
          break;
        case 'Zeus':
          this.motionAudio.src = require(`../../../../../assets/voice/Zeus_${index + 1}.mp3`);
          break;
        case 'PinkShit':
          this.motionAudio.src = require("../../../../../assets/voice/PinkShit.mp3");
          break;
        case 'FlyHorse':
          this.motionAudio.src = require("../../../../../assets/voice/FlyHorse.mp3");
          break;
        case 'IceDragon':
          this.motionAudio.src = require("../../../../../assets/voice/IceDragon.mp3");
          break;
        case 'Tiger_back':
          this.motionAudio.src = require("../../../../../assets/voice/earth.mp3");
          break;
        case 'Tiger':
          this.motionAudio.src = require("../../../../../assets/voice/Tiger.mp3");
          break;
        default:
          this.motionAudio = new Audio()
          break;
      }
      this.motionAudio.play();
    },
    showBackground(type){
      this.giftType = type;
      this.backgroundImgShow = true;
    },
    motionBackground() {
      switch(this.giftType) {
        case 'NvShen':
        return {
            backgroundGoddess: true,
          }
        case 'Whale':
          return {
            backgroundSkyblue: true,
          }
        default:
          return {
            backgroundNon: true,
          }
      }
    },
    playStreamerMessage(description) {
      this.titleMessage = description
    }
  }
};
</script>
