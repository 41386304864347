import { getNewsList, readOneNews, getNewsStatus,getFollowNewsList,readOneFollowNews,getMaintenance } from '@/api/notice';

const getDefaultState = () => {
  return {
    list: [],
    followNewsList:[],
    num: {
      total: 0,
      readed: 0,
      new: 0,
    },
    adminNum:{
      total:0
    },
    followNum: {
      total:0
    },
    readed_id_list: [],
    follow_readed_id_list: [],
    maintenance: 0
  }
}

const state = getDefaultState()

const mutations = {
  SET_LIST: (state, data) => {
    state.list = data
  },
  SET_NUM: (state, data) => {
    state.num.total = data.total
    state.num.readed = data.readed_total
    state.num.new = data.total - data.readed_total
  },
  SET_FOLLOW_NEWS_LIST: (state, data) => {
    state.followNewsList = data
  },
  SET_FOLLOW_NUM: (state,pagination) => {
    state.followNum.total = pagination.total
  },
  SET_ADMIN_NUM: (state,meta) => {
    state.adminNum.total = meta.pagination.total
  },
  SET_READED_ID_LIST: (state,meta) => {
    state.readed_id_list = meta.readed_id_list
  },
  SET_FOLLOW_READED_ID_LIST: (state,readed) => {
    state.follow_readed_id_list = readed.readed_id_list
  },
  UPDATE_READED_ID_LIST: (state,id) => {
    if(state.readed_id_list.indexOf(id) == -1){
      state.readed_id_list.push(id)
    }
  },
  UPDATE_FOLLOW_READED_ID_LIST: (state,id) => {
    if(state.follow_readed_id_list.indexOf(id) == -1){
      state.follow_readed_id_list.push(id)
    }
  },
  SET_MAINTEN: (state, data) => {
    state.maintenance = data
  },
}

const actions = {
  async getList({ commit },pageLimit) {
    const { data } = await getNewsList(pageLimit);
    commit('SET_ADMIN_NUM', data.meta);
    commit('SET_READED_ID_LIST', data.meta);
    commit('SET_LIST', data.data);
  },
  async getNewsStatus({commit}) {
    const { data } = await getNewsStatus();
    commit('SET_NUM', data);
  },
  async readNews({ dispatch }, id) {
    const { data } = await readOneNews(id);
    await dispatch('getNewsStatus')
    return data;
  },
  async getFollowNewsList({ commit }, limit){
    const { data } = await getFollowNewsList(limit);
    commit('SET_FOLLOW_NUM', data.meta.pagination);
    if (data.readed) {
      commit('SET_FOLLOW_READED_ID_LIST', data.readed);
    }
    commit('SET_FOLLOW_NEWS_LIST', data.data);
  },
  async readFollowNews({ dispatch },id) {
    const { data } = await readOneFollowNews(id);
    await dispatch('getNewsStatus')
    return data;
  },
  async updateReadedIdList({ commit }, id) {
    commit('UPDATE_READED_ID_LIST', id);
  },
  async updateFollowReadedIdList({ commit }, id) {
    commit('UPDATE_FOLLOW_READED_ID_LIST', id);
  },
  async syncMaintenance({ commit }) {
    const { data } = await getMaintenance();
    if (data.data.length > 0) {
      commit('SET_MAINTEN', data.data[0].effective_time);
    } else {
      commit('SET_MAINTEN', 0);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

