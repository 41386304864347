<template lang="pug">
.root
  component-header-index
  .center
    .spPickUp(:class="PaidBuyPanelShow?'dnsp':''")
      swiper(:options="swiperOption")
        swiper-slide(v-for="(item, index) in $store.state.ad.list" :key="index")
          img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path" @click="onAdClick(item.url)")
        .swiper-pagination(slot="pagination")
        .swiper-button-prev(slot="button-prev")
          <img src="/img/arrow-l.png">
        .swiper-button-next(slot="button-next")
          <img src="/img/arrow-r.png">
    .leftContainer(:class="PaidBuyPanelShow?'dnsp':''")
      ul.liveAccountList
        li(v-for="item in $store.state.live.liveListRecommend")
          a(@click="onLiveClick(item)")
            .paidLive(v-if="item.liveable_type === 'PAY'")
              img(src="/img/yuryo.png")
            .paidLive(v-if="item.liveable_type === 'FUND'")
              img(src="/img/crfun.png")
            //- .account-img <img :src="item.profile.avatar_store_path ? $store.state.system.config.storage_addr_list.public + item.profile.avatar_store_path : '/img/default_avatar.png'">
            pan-thumb(:avatar="item.profile.avatar_store_path" :attribute="item.profile.attribute" :isStreamer="true")
            .account-name(aria-hidden="true") {{ item.profile.account_name }}
        //- li
        //-   a(@click="onAvatarClick(item.profile.id)")
        //-     .cloudFundingLive クラファン配信
        //-     .account-img <img src="/img/account_test02.png">
        //-     .glyphicon.glyphicon-heart-empty.account-name(aria-hidden="true") アカウント名2

      ul.accountList
        li(
          v-for="item in ui.list",
          :key="item.id",
          :style="{ 'list-style-type': 'none', background: 'url(' + (item.room_info.banner_store_path ? $store.state.system.config.storage_addr_list.public + item.room_info.banner_store_path : '/img/test.png') + ') center no-repeat' }"
        )
          a(@click="onLiveClick(item)" )
            img.streaming(v-if="!item.is_archive && (item.liveable_type == 'NORMAL' || item.liveable_type == 'EVENT')"
              src="/img/haishinchu.gif"
            )
            img(class="accountList_live_type"
              v-if="item.liveable_type === 'FUND' || item.liveable_type === 'PAY'",
              :src="item.liveable_type === 'PAY' ? '/img/yuryo.png' : '/img/crfun.png'"
            )

            div.liveDetail
              p.liveTitle {{ item.room_info.name }}
              div.liveData
                span
                  <img src="/img/user_icon.svg"> {{ item.viewer_count ? item.viewer_count : 0 }}
                div &nbsp;&nbsp;
                span.liveInfo(v-if="item.liveable_type !== 'FUND'" aria-hidden="true") {{ Number(item.point).toLocaleString() }}pt
                div &nbsp;&nbsp;
                span
                  <img src="/img/clock_icon.svg"> {{ item.started_at | moment("MM/DD,HH:mm") }}~
      h6.seeMore
        a(v-if="hasMore" @click="showMore()") >>もっと見る
    .rightContainer(v-if="!PaidBuyPanelShow")
      td.pickUp
        .pr-list
          PRBanner
    .PaidByContainer
      PaidBuyPanel(ref="PaidBuyPanel" :showPanel="PaidBuyPanelShow" @showPanelChange="visibleChange")
  component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner'
import PaidBuyPanel from '@/components/PaidBuyPanel'
import PanThumb from '@/components/PanThumb'

export default {
  name: "LoginHotIndex",
  components: {
    PRBanner,
    PaidBuyPanel,
    PanThumb
  },
  data() {
    return {
      list: [],
      pageLimit:90,
      currentPage:1,
      ui: {
        list: [],
      },
      swiperOption: {
        autoplay: {
          delay: 3000,
        },
        loop: true,
        slidesPerView: 2,
        spaceBetween: 5,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      paidLive:{
        id:0,
        liveable_id:''
      },
      PaidBuyPanelShow: false,
    };
  },
  props: {},
  computed: {
    hasMore() {
      // あらかじめ沢山取っておく
      //return this.list.length > this.$store.state.live.liveList.meta.pagination.total;
      return this.list.length > this.ui.list.length;
    },
    isBuyPay(){
      return this.$store.state.live.info.is_buy_pay;
    },
  },
  watch: {},
  mounted() {},
  async created() {
    await this.$store.dispatch("live/syncLiveListHot", { limit:this.pageLimit, page:this.currentPage });
    this.list = this.$store.state.live.liveList.data;
    this.list.forEach((element,index) => {
      if (index < 15) {
        this.ui.list.push(element);
      }
    });
    await this.$store.dispatch("live/syncLiveListHotRecommend");
  },
  updated() {},
  destroyed() {},
  methods: {
    onAdClick(URL) {
      window.open(URL, '_blank');
    },
    showMore() {
      // あらかじめ沢山取っておく
      //this.currentPage += 1;
      //await this.$store.dispatch("live/syncLiveListHot",this.pageLimit,this.currentPage);
      //this.list = this.$store.state.live.liveList.data;
      let x = this.ui.list.length;
      this.list.forEach((element,index) => {
        if (index > x - 1 && index < x + 15) {
          this.ui.list.push(element);
        }
      });
    },
    // onAvatarClick(item) {
    //   const profile_id = item.profile.id
    //   this.$router.push({ name: "TopProfileStyle2", params: { profile_id } });
    // },
    async onLiveClick(liveData) {
      if (liveData.id === -1) {
        alert("配信Not Found!!!!!");
        return;
      }
      // アーカイブ
      if( liveData.is_archive ){
          let liveType = "";
          if (liveData.liveable_type === "FUND") {
            liveType = "ArchiveLiveFund";
          } else if (liveData.liveable_type === "PAY") {
            liveType = "ArchiveLivePaid";
          } else if (liveData.liveable_type === "NORMAL") {
            liveType = "ArchiveLiveNormal";
          } else if (liveData.liveable_type === "EVENT") {
            liveType = "ArchiveLiveEvent";
          }
          if (liveData.liveable_type === "PAY") {
            await this.$store.dispatch('live/syncLiveInfo', liveData.id).then(() => {
              this.$store.dispatch('live/syncLiveTicketInfo', liveData.liveable_id).then(()=>{
                if(!this.$store.state.live.info.is_buy_pay){
                  this.PaidBuyPanelShow = true
                  return
                } else {
                  let routeData = this.$router.resolve({
                    name: liveType,
                    params: { id: liveData.id },
                    query: { liveable_id: liveData.liveable_id}
                  });
                  window.open(routeData.href, '_blank');
                }
              });
            }).catch((error)=>{
              console.error(error);
            });
          } else {
            let routeData = this.$router.resolve({
              name: liveType,
              params: { id: liveData.id },
              query: { liveable_id: liveData.liveable_id }
            });
            window.open(routeData.href, '_blank');
          }
      }
      else {
          let liveType = "";
          if (liveData.liveable_type === "FUND") {
            liveType = "TopLiveFund";
          } else if (liveData.liveable_type === "PAY") {
            liveType = "TopLivePaid";
          } else if (liveData.liveable_type === "NORMAL") {
            liveType = "TopLiveNormal";
          } else if (liveData.liveable_type === "EVENT") {
            liveType = "TopLiveEvent";
          }
          if (liveData.liveable_type === "PAY") {
            await this.$store.dispatch('live/syncLiveInfo', liveData.id).then(() => {
              this.$store.dispatch('live/syncLiveTicketInfo', liveData.liveable_id).then(()=>{
                if(!this.$store.state.live.info.is_buy_pay){
                  this.PaidBuyPanelShow = true
                  return
                } else {
                  let routeData = this.$router.resolve({
                    name: liveType,
                    params: { id: liveData.id },
                    query: { liveable_id: liveData.liveable_id}
                  });
                  window.open(routeData.href, '_blank');
                }
              });
            }).catch((error)=>{
              console.error(error);
            });
          } else {
            let routeData = this.$router.resolve({
              name: liveType,
              params: { id: liveData.id },
              query: { liveable_id: liveData.liveable_id }
            });
            window.open(routeData.href, '_blank');
          }
      }
    },
    visibleChange(value) {
      this.PaidBuyPanelShow = value;
    },
  },
};
</script>
