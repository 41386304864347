<template lang="pug">
  .root
    div(class="pr-list")
      div(class="pink-label") PICK UP
      div(class="right-content-border")
        div(v-for="(item, index) in $store.state.ad.list" class="pr-item")
          img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path" @click="onAdClick(item.url)")
</template>

<script>
export default {
  name: "ComponentsPRBannner",
  data() {
    return {
      adList: []
    }
  },
  async created() {
    await this.$store.dispatch('ad/getList');
  },
  methods: {
    onAdClick(url) {
      window.open(url, '_blank')
    }
  }
}
</script>