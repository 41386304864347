import request from '@/utils/request'

export function getM3u8Url(id) {
    return request({
        url: `/lives/${id}/pull_url`,
        method: 'get',
    })
}

export function getArchiveM3u8Url(id) {
    return request({
        url: `/lives/${id}/archive_url`,
        method: 'get',
    })
}

export function getLiveListHot(limit, page) {
    return request({
        url: `/lives?category=c1&limit=${limit}&page=${page}`,
        method: 'get',
    })
}
export function getLiveListHotRecommend() {
    return request({
        url: `/lives?category=c2`,
        method: 'get',
    })
}

export function getLiveListNew(limit,page) {
    return request({
        url: `/lives?search=status:s3&orderBy=started_at&sortedBy=desc&limit=${limit}&page=${page}`,
        method: 'get',
    })
}

export function getLiveHistoryComments(id, ts) {
    return request({
        url: `lives/${id}/comments?created_at=${ts}`,
        method: 'get',
    })
}

export function getLiveHistoryCommentsLimit(id, ts, limit) {
    return request({
        url: `lives/${id}/comments?created_at=${ts}&limit=${limit}`,
        method: 'get',
    })
}

export function getLiveHistoryCommentsLimitPages(id, ts, limit, page) {
    return request({
        url: `lives/${id}/comments?created_at=${ts}&limit=${limit}&page=${page}`,
        method: 'get',
    })
}

// 開催中イベント
export function getLiveListEventShowing(limit, page) {
    return request({
        url: `/broadcast_events?status=16&orderBy=end_time&sortedBy=asc&limit=${limit}&page=${page}`,
        method: 'get',
    })
}

// 近日開催予定イベント
export function getLiveListEventToShow(limit, page) {
    return request({
        url: `/broadcast_events?status=14&orderBy=end_time&sortedBy=asc&limit=${limit}&page=${page}`,
        method: 'get',
    })
}

// 開催中＆近日開催イベント
export function getLiveListEventShowingAndToshow(limit, page) {
    return request({
        url: `/broadcast_events?status=30&orderBy=end_time&sortedBy=asc&limit=${limit}&page=${page}`,
        method: 'get',
    })
}

// 終了済みイベント
export function getLiveListEventShowed(limit, page) {
    return request({
        url: `/broadcast_events?status=32&orderBy=end_time;id&sortedBy=desc;asc&limit=${limit}&page=${page}`,
        method: 'get',
    })
}

export function getLiveListEventDetail(liveable_id) {
    return request({
        url: `/broadcast_events/${liveable_id}?include=lives,streamers`,
        method: 'get',
    })
}

// イベントに参加している配信者一覧
export function getLiveListEventEntryStreamer(event_id) {
  return request({
      url: `/broadcast_events/${event_id}/entry_streamer`,
      method: 'get',
  })
}

// イベントのランキング
export function getLiveListEventRanking(event_id) {
  return request({
      url: `/broadcast_events/${event_id}/ranks`,
      method: 'get',
  })
}
// 現在の順位を取得する
export function getLiveListEventRank(liveable_id, streamer_id) {
    return request({
        url: `/broadcast_events/${liveable_id}/lives/${streamer_id}/rank`,
        method: 'get',
    })
}

// 応援ランキング
export function getEventLiveViewerRankList(liveable_id, streamer_id) {
  return request({
      url: `/broadcast_events/${liveable_id}/streamers/${streamer_id}/ranks`,
      method: 'get',
  })
}

export function getLiveClubfun(liveable_id) {
    return request({
        url: `/broadcast_funds/${liveable_id}?include=live`,
        method: 'get',
    })
}

export function buyClubfunGiftCash(liveable_id, live_id, success_url, cancel_url, items) {
    return request({
        url: `/broadcast_funds/${liveable_id}/lives/${live_id}/gift_logs/payment`,
        method: 'post',
        data: {
            method_types: ["card"],
            success_url,
            cancel_url,
            items
        }
    })
}

export function buyClubfunGiftGold(liveable_id, live_id, items) {
    return request({
        url: `/broadcast_funds/${liveable_id}/lives/${live_id}/gift_logs`,
        method: 'post',
        data: {
            items
        },
    })
}

export function setClubfunInfo(live_id, data) {
    return request({
        url: `broadcast_funds/${live_id}/delivery_information`,
        method: 'put',
        data
    })
}

export function getClubfunInfo(live_id) {
    return request({
        url: `broadcast_funds/${live_id}/delivery_information`,
        method: 'get'
    })
}

export function getLiveListClubFunShowingIndexPage(index, pages) {
    return request({
        url: `/broadcast_funds?status=6&include=live&orderBy=end_time&sortedBy=asc&index=${index}&page=${pages}`,
        method: 'get',
    })
}

export function getLiveListClubFunShowedIndexPage(index, pages) {
    return request({
        url: `/broadcast_funds?status=8&include=live&orderBy=end_time&sortedBy=desc&index=${index}&page=${pages}`,
        method: 'get',
    })
}

export function getLivePayBuyInfo(liveable_id) {
    return request({
        url: `broadcast_pays/${liveable_id}?include=profile`,
        method: 'get'
    })
}

export function buyTicket(liveable_id) {
    return request({
        url: `broadcast_pays/${liveable_id}`,
        method: 'post',
    })
}

export function getLiveInfo(id) {
    return request({
        url: `/lives/${id}`,
        method: 'get',
    })
}

export function getLiveTarget(streamerId) {
    return request({
        url: `/streamers/${streamerId}/target`,
        method: 'get',
    })
}

export function getLiveTargetRanking(targetId) {
    return request({
        url: `streamers/targets/${targetId}/ranks`,
        method: 'get',
    })
}

export function getLiveSyncInfo(live_id) {
    return request({
        url: `lives/${live_id}/polling/`,
        method: 'get',
    })
}

export function getLiveAttrInfo(live_id) {
    return request({
        url: `live_infos/${live_id}`,
        method: 'get',
    })
}

export function  getScheduleLiveList(){
  return request({
    url: `lives/schedule`,
    method: 'get',
  })
}

export function setScheduleLive(live_id){
  return request({
    url: `/lives/${live_id}/schedule`,
    method: 'post',
  })
}

export function showScheduleLive(live_id){
  return request({
    url: `/lives/${live_id}/schedule`,
    method: 'get',
  })
}

export function destroyScheduleLive(live_id){
  return request({
    url: `/lives/${live_id}/schedule`,
    method: 'delete',
  })
}

export function getReservableLive(streamer_id){
  return request({
    url: `lives/${streamer_id}/reserve`,
    method: 'get'
  })
}
