import Vue from "vue";
import Vuex from "vuex";

import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import live from './modules/live'
import system from './modules/system'
import news from './modules/news'
import ad from './modules/ad'
import cost from './modules/cost'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    live,
    system,
    news,
    ad,
    cost,
  },
  getters
});
