<template lang="pug">
  .root
    <table style="border-collapse: collapse; width: 265px; border-style: hidden;" border="1">
      <tbody>
        <tr style="height: 22px;">
          <td style="width: 90px; height: 30x; border-style: hidden;" rowspan="3"><img :src="newsBanner" class="banner"></td>
          <td style="width: 157px; height: 22px;"><b style="color:#4b4b4b">{{ newsTitle }}</b></td>
        </tr>
        <tr style="">
          <td style="width: 90px;">
            <div style="width: 157px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ ToText(data.describe) }}</div>
          </td>
        </tr>
        <tr style="">
          <td style="width: 90px; border-style: hidden;">
            <p><b style="color:#4b4b4b">{{ newsTime | moment("YYYY/MM/DD HH:mm") }}</b></p>
          </td>
        </tr>
      </tbody>
    </table>
</template>

<script>
import { getImageUrl, isInvalidResImageUrl } from '@/utils/tools'
export default {
  name: 'NewsItem',
  props: {
    data:{
      type:Object,
      required:true
    },
  },
  mounted(){},
  created(){},
  methods: {
    ToText(HTML){
      var input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');  
    }
  },
  computed:{
    newsTitle(){
      return this.data.title === '' ? 'ニュースタイトルがない':this.data.title
    },
    newsTime(){
      return this.data.public_time
    },
    newsBanner(){
      if(this.data.banner_store_path != '' && !isInvalidResImageUrl(this.data.banner_store_path)){
        return getImageUrl(this.data.banner_store_path)
      }else{
        return '/img/test.png';
      }
    }
  }
}
</script>

<style src="./NewsItem.scss" lang="scss" scoped>
</style>
