<template lang="pug">
  .root
    .liveStayus-item-twitter
      a(:href="createSnsUrl()" target="_blank")
        img(src="/img/kakusan.png")
</template>

<style src="./index.scss" lang="scss" scoped>
</style>

<script>
export default {
  name: "ShareLink",
  components: {},
  data() {
    return {
    }
  },
  props: {
    text: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: "Aライブ"
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  created() {},
  updated() {},
  destroyed() {},
  methods: {
    createSnsUrl(){
      // Twitter用のurl作成 ハッシュタグもtxtを使用
      //return 'https://twitter.com/intent/tweet?text=' + this.text + '&hashtags=' + this.tag + '&url=' + this.url;
      const shareText = encodeURIComponent(this.text + "\n" + this.url + "\n\n" + "#" + this.tag);
      return 'https://twitter.com/intent/tweet?text=' + shareText;
    }
  }
};
</script>
