import request from '@/utils/request'


export function uploadImage(form) {
  return request.post('chat_rooms/images', form, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  })
}

// {
//   "name":"ddd",
//   "image_store_path": "viewer/11/f4/DXdSVoY4HEZhkDmnkV4RyOiGQ1dL7XUbBDLc1hcQ.png",
// }
export function createRoom({ name, image_store_path }) {
  return request({
    url: `chat_rooms`,
    method: 'post',
    data: {
      name,
      image_store_path,
    }
  })
}

export function searchUserList(profile_id, name,limit,page) {
  return request({
      url: `profiles/${profile_id}/followings?search=${name}&limit=${limit}&page=${page}`,
      method: 'get',
  })
}

export function addUsers(room_id, user_id_list) {
  return request({
      url: `chat_rooms/${room_id}/members`,
      method: 'post',
      data: {
        user_id_list
      }
  })
}

export function delUsers(room_id, user_id) {
  return request({
      url: `chat_rooms/${room_id}/members/${user_id}`,
      method: 'delete'
  })
}



// 作成したルーム一覧
export function getRoomListS1() {
  return request({
      url: `chat_rooms?user_status=s1`,
      method: 'get',
  })
}

// 承認待ちのルーム一覧
export function getRoomListS2() {
  return request({
      url: `chat_rooms?user_status=s2`,
      method: 'get',
  })
}

// 参加しているルーム一覧
export function getRoomListS3() {
  return request({
      url: `chat_rooms?user_status=s3`,
      method: 'get',
  })
}

// 承認する
export function setRoomEnterS3(room_id) {
  return request({
      url: `chat_rooms/${room_id}/members`,
      method: 'put',
      data: {
        "user_status": "s3"
      }
  })
}

// Exit Room
export function setRoomExitS3(room_id) {
  return request({
      url: `chat_rooms/${room_id}/members`,
      method: 'delete',
  })
}

// Update Room
export function updateRoom({ room_id, name, image_store_path }) {
  return request({
    url: `chat_rooms/${room_id}`,
    method: 'put',
    data: {
      name,
      image_store_path,
    }
  })
}

// Delete Room
export function deleteRoom(room_id) {
  return request({
    url: `chat_rooms/${room_id}`,
    method: 'delete'
  })
}

// メンバー情報取得
export function getMemberStatus(room_id) {
  return request({
    url: `chat_rooms/${room_id}/member_status`,
    method: 'get'
  })
}

export function getRoomHistoryCommentsLimit(id, ts, limit, page) {
  return request({
      url: `chat_rooms/${id}/comments?created_at=${ts}&limit=${limit}&page=${page}`,
      method: 'get',
  })
}
