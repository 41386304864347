<template lang="pug">
  .root
    div(class="container-flex")
      div(class="display-box")
          h1(class="title ml-14") 返金フォームの入力
          div(class="account-list")
            h2 
              span(class="list pl-14") 振込先口座情報
            div(class="ml-14")
              h4 ご本人様名義に限ります。
              p ※口座名義が本人氏名と異なる場合には、下記「備考」欄へ理由をお書きください。
              p(class="ml-14") 例）結婚による改正後の名義であり本人口座である。／海外在住のため、日本国内在住の家族名義口座を希望する（続柄：父）。
              p ※ゆうちょ銀行をご使用の場合、振込用の店名・口座種別・口座番号をご記入ください。
              p(class="ml-14") ご不明な場合は
                a(href="https://www.jp-bank.japanpost.jp/" class="link-bank") こちら（ゆうちょ銀行Webサイト）
                span よりご確認ください。
              div(class="input-flex")
                h2(class="ml-14") 金融機関名
                  span(class="font-red")  ※
                div(class="input-box")
                  input(v-model="test1" maxlength="20")
                  span  (全角)
              div(class="input-flex")
                h2(class="ml-14") 支店名
                  span(class="font-red")  ※
                div(class="input-box")
                  input(v-model="test2" maxlength="20")
                  span  (全角)
              div(class="input-flex")
                h2(class="ml-14") 口座種別
                  span(class="font-red")  ※
                div(class="input-radio")
                  label
                    input(type="radio" name="bank" value="普通" checked)
                    span 普通
                  label
                    input(type="radio" name="bank" value="当座")
                    span 当座
              div(class="input-flex")
                h2(class="ml-14") 口座番号
                  span(class="font-red")  ※
                div(class="input-box")
                  input(v-model="test3" maxlength="20")
                  span  (半角数字)
              div(class="input-flex")
                h2(class="ml-14") 口座名義人名（カナ）
                  span(class="font-red")  ※
                div(class="input-box")
                  input(v-model="test4" maxlength="20")
                  span  (全角)
            div(class="input-flex text-box")
              h2
                span(class="list pl-14") 備考（全角）
                span(class="font-red")  ※
              div(class="input-box")
                textarea(v-model="test5")
            div(class="account-submit")
              input(type="submit")

</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "RefundIndex",
  components: {
  },
  data() {
    return {
      test1:'',
      test2:'',
      test3:'',
      test4:'',
      test5:'',
    }
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>