<template lang="pug">
  .root
    div(class="side-content-tag")
      div(v-if="isPay" class="videoTag") <img src="/img/yuryo.png">
      div(v-if="isFund" class="videoTag") <img src="/img/crfun.png">
      div(v-if="isEvent" class="videoTag")
    div(class="side-content")
      div(class="side-img")
        img(@click="interceptClick" :src="roomBanner")
      div(class="side-message")
        <div class="side-text-box"><b style="color:#4b4b4b" @click="onStreamerNameClick()"> {{roomHost}} </b></div>
        <div class="side-text-box"><b style="color:#4b4b4b"> {{roomName}} </b></div>
        <div class="side-text-box"><b style="color:#4b4b4b"> {{roomPeriod  | moment("YYYY/MM/DD HH:mm") }} </b></div>
</template>

<script>
import ListItemComputed from './component/ListItemComputed'
import ListItemMethod from './component/ListItemMethod'

export default {
  name: 'LiveItem',
  mixins: [ListItemComputed, ListItemMethod],
  props: {
    data:{
      type:Object,
      required:true
    },
    interceptClickFunc:{
      type: Function,
      default: null,
    }
  },
  created(){
  },
  methods: {
    onStreamerNameClick() {
      const profile_id = this.data.profile_id
      let link = this.$router.resolve({ name: 'TopProfileStyle2',  params: { profile_id } });
      window.open(link.href,'_blank');
    },
    interceptClick(){
      if( this.interceptClickFunc )
      {
        this.interceptClickFunc(this);
        return;
      }
      this.onClickBanner();
    }
  },
}
</script>

<style src="./LiveItem.scss" lang="scss" scoped>
</style>
