<template lang="pug">
  .root
    component-header-index(v-if="$store.state.user.email")
    component-header-login(v-if="!$store.state.user.email")
    div(class="container-flex")
      div(class="content-info")
        <h3 style="text-align:center">退会</h3>
        div(class="unregister-content")
          p 退会の際は必ず利用規約をご確認ください。
          a(@click="TopTermsTerms") >> 利用規約を確認
        div(class="unregister-content")
          p 【注意事項】
          p ・退会後はサイトへログインできなくなります。
          p ・アカウント情報や投稿されていたデータは全て削除されます。
          p ・誤ってアカウントを削除してしまった場合でもアカウントの復旧はできません。
          p ・退会時に所持していた★♥Ｇは全て削除されます。復旧はできません。
        div(class="unregister-content")
          p 利用規約および注意事項を確認した上で
          <el-button type="text" @click="dialogVisible = true">>>Aライブを退会</el-button>
          el-dialog(:visible.sync="dialogVisible" :before-close="handleClose" width="30%")
            <span>本当に退会してもよろしいですか？</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="onDelete()">はい</el-button>
              <el-button type="primary" @click="dialogVisible = false">いいえ</el-button>
            </span>

    component-footer-index
</template>

<style src="./Unregister.scss" lang="scss" scoped>
</style>

<script>
import { getStaticPrivacyPolicy } from '@/api/system';
import { deleteUser } from '@/api/user';
import { removeToken } from '@/utils/auth'
import { Button, Dialog } from 'element-ui';

export default {
  name: "TopTermsUnregister",
  components: {
    'el-button': Button,
    'el-dialog': Dialog,
  },
  data() {
    return {
      ui: {
      },
      oneNewsInfo: {

      },
      text: '',
      dialogVisible: false,
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    const res = await getStaticPrivacyPolicy();
    this.text = res.privacy_policy;
  },
  updated() {},
  destroyed() {},
  methods: {
    handleClose(done) {
      done();
    },
    onDelete(){
      this.dialogVisible = false;
      this.deleteUserDialogVisible = true;
      this.handleDelete();
    },
    async handleDelete(){
      try {
        const { data } = await deleteUser();
        if (data[0]) {
          removeToken();
          this.$router.push({ name: 'LoginIndex', query: { from_unregister: true } })
        } else {
          window.alert('退会失敗しました')
        }
      } catch(e) {
          window.alert('退会失敗しました')
      }
    },
    TopTermsTerms() {
      let link = this.$router.resolve({ name: 'TopTermsTerms' });
      window.open(link.href,'_blank');
    }
  }
};
</script>
