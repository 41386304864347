import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";

import scroll from 'vue-seamless-scroll';
Vue.use(scroll)

Vue.config.devtools = (process.env.VUE_APP_ENV === "DEV");
Vue.config.productionTip = false;

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import "vue-resize/dist/vue-resize.css";
import VueResize from "vue-resize";
Vue.use(VueResize);

Vue.use(require('vue-moment'));

import {
  Message,
  MessageBox,
} from 'element-ui';
import locale from 'element-ui/lib/locale';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/ja';
locale.use(lang);

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;

import Toasted from 'vue-toasted';
const Options = { position: 'top-center', duration: 3000 }
Vue.use(Toasted, Options);

import preventReClick from '@/utils/preventReClick';
Vue.use(preventReClick);

import * as VeeValidate from 'vee-validate';
// import ja from 'vee-validate/dist/locale/ja.json';
Vue.use(VeeValidate);

// Global Components
import ComponentFooterIndex from '@/components/Footer/index';
import ComponentFooterLogin from '@/components/Footer/login';
import ComponentHeaderIndex from '@/components/Header/index';
import ComponentHeaderLogin from '@/components/Header/login';
import ComponentProfileIndex from '@/components/Profile/index';
import ComponentsTargetViewIndex from '@/components/TargetView/targetComponets';
import ComponentImageUploadIndex from '@/components/ImageUpload/Index';
import ComponentSystemMessage from "@/components/SystemMessage/index.vue";
Vue.component('component-footer-index', ComponentFooterIndex);
Vue.component('component-footer-login', ComponentFooterLogin);
Vue.component('component-header-index', ComponentHeaderIndex);
Vue.component('component-header-login', ComponentHeaderLogin);
Vue.component('component-profile-index', ComponentProfileIndex);
Vue.component('component-target-view-index', ComponentsTargetViewIndex);
Vue.component('component-image-upload-index', ComponentImageUploadIndex);
Vue.component('component-system-message', ComponentSystemMessage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
