<template lang="pug">
.root
  div
    //- //- 購入ポップアップ
    <div v-if="visible" id="clickbuy">
      <div class="right-content-border">
        //- <button href="#" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <button @click="onPanelClose()" title="close" class="close-button">Ｘ</button>

        <div v-if="!panel.confirm && !panel.notEnoughGold">
          <div class="roomBanner">
            <img :src="$store.state.system.config.storage_addr_list.public + $store.state.live.ticket.banner_store_path" />
          </div>
          <p>{{ $store.state.live.ticket.title }}</p>
          <p><span aria-hidden="true" class="glyphicon glyphicon-user" style="color:#00afec"></span>{{ $store.state.live.ticket.profile_account_name }}</p>
          <p v-if="isReservable || isLiving"><span aria-hidden="true" class="glyphicon glyphicon-time cc_cursor" style="color:#84b91f"></span> {{ $store.state.live.ticket.start_time | moment("M月DD日HH:mm") }}~{{ $store.state.live.ticket.end_time | moment("M月DD日HH:mm") }}(予定)</P>
          <p v-else><span aria-hidden="true" class="glyphicon glyphicon-time cc_cursor" style="color:#84b91f"></span> {{ $store.state.live.info.started_at | moment("M月DD日HH:mm") }}~{{ $store.state.live.info.ended_at | moment("M月DD日HH:mm") }}</P>
          <p>{{ $store.state.live.ticket.describe }}</p>
          <div class="liveprice">
            img(src="/img/gold-icon.png" class="gold-icon")
            span(class="price-text") {{ $store.state.live.ticket.price || 0 }}
          </div>
          <div class="priceattention">
            //- <p v-if="!ui.panelbuypay.paid">※この配信は有料配信です</p>
            <p v-if="isBoughtPay && !isLiving" class="caution-text">※この配信は購入済みです</p>
            <p v-if="isLiving && !isLiveOver"  class="caution-text">※現在配信中</p>
            <p v-if="isLiveOver && payLiveArchiveExpiredAt" class="caution-text-archive">※この配信はアーカイブです。{{"\n"}}※視聴期限は{{ payLiveArchiveExpiredAt | moment("YYYY年M月DD日 HH:mm")}}まで{{"\n"}}（購入は視聴期限の24時間前までです）</p>
          </div>
          <div class="kounyuyoyaku" v-if="!isBoughtPay">
            div(v-if="isReservable")
              <img src="/img/kounyuyoyaku.png" onmouseover="this.src='/img/kounyuyoyaku_g.png'" onmouseout="this.src='/img/kounyuyoyaku.png'" alt="" @click="onTryBuy()">
            div(v-else)
              <img src="/img/kounyu.png" style='cursor: pointer' alt="" @click="onTryBuy()">
          </div>
          <div class="kounyuyoyaku" v-if="isBoughtPay && isReservable"> 配信開始まで{{"\n"}}今しばらくお待ちください
          </div>
        </div>

        div(v-if="panel.confirm" class="pay-confirm")
          .confirm-text-area
            img(src="/img/gold-icon.png" class="gold-icon") 
            span(v-if="isReservable" class="confirm-text") で購入して視聴予約しますか?
            span(v-else class="confirm-text") で購入して視聴しますか?
          div(class="pay-confirm-cation-button")
            div(@click="onTicketBuy()") <img class="button-img" src="/img/button/yes.png">
            div(@click="panel.confirm = false;") <img class="button-img" src="/img/button/no.png">
        div(v-if="panel.notEnoughGold" class="pay-confirm")
          .confirm-text-area
            img(src="/img/gold-icon.png" class="gold-icon") 
            span(class="confirm-text") が不足しています。
          a(@click="onGoldBuy()") G購入はこちら

      </div>
    </div>
  //- .account-name(aria-hidden="true") {{ name }}
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { buyTicket, setScheduleLive } from '@/api/live';

export default {
  name: 'PaidBuyPanel',
  props: {
    showPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel:{
        confirm: false,
        notEnoughGold: false,
      },
      visible: false,
    }
  },
  async create() {},
  computed:{
    isBoughtPay(){
      return this.$store.state.live.info.is_buy_pay;
    },
    isReservable(){
      return this.$store.getters.enum_list.live_status_list.SCHEDULE == this.$store.state.live.info.status
    },
    isLiving(){
      // return  new Date(this.$store.state.live.ticket.start_time).getTime() < Date.now() && new Date(this.$store.state.live.ticket.end_time).getTime() > Date.now()
      return this.$store.getters.enum_list.live_status_list.ONGOING == this.$store.state.live.info.status
    },
    isLiveOver() {
      return this.$store.getters.enum_list.live_status_list.ENDED == this.$store.state.live.info.status
    },
    payLiveArchiveExpiredAt() {
      return this.$store.state.live.info.pay_archive_expired_at
    },
  },
  watch:{
    showPanel(val) {
      this.visible = val;
    },
    visible(val) { this.$emit("showPanelChange",val); } 
  },
  methods: {
    async onTicketBuy() {
      try {
        const {status} = await buyTicket(this.$store.state.live.ticket.liveable_id)
        // console.log(status);
        if(status == "success"){
          this.$store.dispatch('live/syncLiveInfo',this.$store.state.live.info.id)
          // 予約受付中
          if( this.isReservable ){
            // 予約処理
            await setScheduleLive(this.$store.state.live.info.id);
          } else if( this.isLiving ){
            // 配信中
            this.onPanelClose()
            let routeData = this.$router.resolve({
              name: "TopLivePaid",
              params: { id: this.$store.state.live.info.id },
              query: { liveable_id : this.$store.state.live.info.liveable_id }
            });
            window.open(routeData.href, '_blank');
          } else {
            // アーカイブ
            this.onPanelClose()
            let routeData = this.$router.resolve({
              name: "ArchiveLivePaid",
              params: { id: this.$store.state.live.info.id },
              query: { liveable_id : this.$store.state.live.info.liveable_id }
            });
            window.open(routeData.href, '_blank');
          }
        }
      }catch(e) {
        // ブロックされた場合エラーメッセージを出す
        if (e.response.data.code === 400114) {
          window.alert("購入が拒否されました")
        }else{
          console.log(e);
          this.$alert("クーポンが足りない","システムエラー");
        }
      }
    },
    onPanelClose() {
      this.panel.confirm = false;
      this.panel.notEnoughGold = false;
      this.visible = false
    },
    onTryBuy(){
      // ゴールドチェック
      if( this.$store.state.user.c1 < this.$store.state.live.ticket.price ){
        this.panel.notEnoughGold = true;
        return;
      }
      this.panel.confirm = true;
    },
    onGoldBuy(){
      let link = this.$router.resolve({ name: 'TopProfileBuy' });
      window.open(link.href,'_blank');
    },
  },
  mounted() {
    if (this.showPanel) {
      this.visible = true;
    }
  }
}
</script>
