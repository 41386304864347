<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")

      Payment

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./SelectPayment.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner';
import Payment from '@/components/Payment';

export default {
  name: "TopProfileSelectPayment",
  components: {
    Payment,
    PRBanner,
  },
}
</script>
