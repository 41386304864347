<template lang="pug">
  .root
    component-header-index

    div(class="container-flex")
      div(class="leftContainer")
        div(class="content-plofile-left")
          <div class="socilapp">
            <li v-if="haveLinkedTwitter"><img src="/img/socil_twitter.png" @click="onWindowOpen(twitterAccount)"></li>
          </div>
          <div class="changenavi">
            <li><a @click="TopProfileChange" >>> プロフィールを変更する</a></li>
            //component-image-upload-index(:mode="'backgroundUpload'")

            //<li><a><u @click="$router.push({ name: 'TopTermsManual' })">>> 操作マニュアル</u></a></li>
            <li><a @click="toAttributeDetail" >>> もう一度初期解説を見る</a></li>
            <li><a @click="onLogout()">>> ログアウト</a></li>
            <div @click="showPopupProfile" class="show-button"><img src="/img/hyouji_p.png" style="width:160px"></div>
          </div>
          <div class="display-check">
            <a href=""><img src="/img/hyouji_p.png" onmouseover="this.src='/img/hyouji_g.png'" onmouseout="this.src='/img/hyouji_p.png'" alt=""></a>
          </div>


        <div class="profilecenter">
          div(class="prof-bg-img")
            img.background(v-if="haveBG" :src="backgroundImageUrl")
          <div class="profilecenter-img">
            //- <div class="profile-avatar" v-bind:style="{ border: avatarBorderColor }" >
            //-   <img v-if="$store.state.user.profile_avatar_store_path"  :src="$store.state.system.config.storage_addr_list.public + $store.state.user.profile_avatar_store_path">
            //-   <img v-else src="/img/default_avatar.png">
            //- </div>
            <pan-thumb :avatar="avatarImageUrl" :attribute="$store.state.user.attribute" :current_title="$store.state.user.profile_profileable_current_title" size="124px"/>
          </div>
          <p class="account_name"><span class="" aria-hidden="true"></span><b>{{ $store.state.user.profile_account_name }}</b></p>
          <div class="follow-list">
            <div class="follow-list-item">
              <div @click="linkFollow('follow')"><img src="/img/profile_icon_pink.svg"> <span class="follow-list-item-bold">{{ $store.state.user.profile_following_total }}</span></div>
              <p @click="linkFollow('follow')">フォロー</p>
            </div>
            <div class="follow-list-item">
              <div @click="linkFollow('follower')"><img src="/img/profile_icon_blue.svg"> <span class="follow-list-item-bold">{{ $store.state.user.profile_follower_total }}</span></div>
              <p @click="linkFollow('follower')">フォロワー</p>
            </div>
          </div>
          <div class="introduction">
            <p><b style="color:black;">{{ $store.state.user.profile_introduction }}</b></p>
          </div>
          <div class="profilecenter-bottom"><li style="font-size:14px;"><a @click="TopProfileBuyHistory()">購入/使用履歴</a></li><li style="font-size:14px;"><a @click="TopProfileFollow">フォロー/フォロワー</a></li><li style="font-size:14px;"><a @click="TopProfileRoom">ルーム一覧</a></li></div>
        </div>

      <div v-if="isRightPanelShow" class="rightContainer">
        <div v-if="scheduleList.length > 0" class="side-part">
          <div class="pink-label">予約した配信</div>
          <div class="side-content">
            <div v-for="(item,index) in scheduleList">
              <live-item :interceptClickFunc="reservedBannerIntercept" :data="item"/>
            </div>
          </div>
        </div>
        <div v-if="paidList.length > 0" class="side-part">
          <div class="pink-label">購入済みアーカイブ</div>
          <div class="side-content">
            <div v-for="(item,index) in paidList">
              <live-item :data="item"/>
            </div>
            <div v-if="payArchiveMore" class="pointer">
              <a><b @click="getMorePayArchive()" style="color:##0099FF">>>もっと見る </b></a>
            </div>
          </div>
        </div>
        <div v-if="historyList.length > 0" class="side-part">
          <div class="pink-label">視聴履歴</div>
          <div class="side-content">
            <div v-for="(item,index) in historyList">
              <live-item :data="item"/>
            </div>
            div(v-if="isSmartPhone")
              <div v-if="spViewerHistoryMore" class="pointer">
                <a><b @click="spGetMoreHistory()" style="color:##0099FF">>>もっと見る </b></a>
              </div>
            div(v-else)
              <div v-if="viewerHistoryMore" class="pointer">
                <a><b @click="getMoreHistory()" style="color:##0099FF">>>もっと見る </b></a>
              </div>
          </div>
        </div>
      </div>

        div(v-if="isPopupProfileShow" class="rightContainer")
          component-profile-index(:profile_id="$store.state.user.profile_id" @close="onClosePopupProfile")

        div(v-if="PaidBuyPanelShow" class="rightContainer")
          .PaidByContainer
            PaidBuyPanel(ref="PaidBuyPanel" :showPanel="PaidBuyPanelShow" @showPanelChange="visibleChange")

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { uploadBackground, setUserInfo, getUserinfoById } from '@/api/user'
import { getViewHistory, getViewHistoryList, getPayArchiveList } from '@/api/viewer_history'
import { getImageUrl, isSmartPhone } from '@/utils/tools'
import LiveItem from './LiveItem'
import PanThumb from '@/components/PanThumb'
import PaidBuyPanel from '@/components/PaidBuyPanel'
import LiveHistoryParse from './component/LiveHistoryParse'

export default {
  name: "LoginProfileIndex",
  mixins: [LiveHistoryParse],
  components: {
    PanThumb,
    LiveItem,
    PaidBuyPanel,
    LiveHistoryParse,
  },
  watch:{},
  data() {
    return {
      isRightPanelShow: true,
      isPopupProfileShow:false,
      historyList:[],
      spHistoryList:[],
      paidList:[],
      scheduleList:[],
      historyPagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 1,
      },
      payArchivePagination: {
        total: 0,
        count: 0,
        per_page: 5,
        current_page: 1,
        total_pages: 1,
      },
      historyQuery: {
        page: 1,
      },
      PaidBuyPanelShow: false,
      isSmartPhone: false,
    }
  },
  computed: {
    haveLinkedTwitter(){
      return this.$store.state.user.profile.sns_infos && this.$store.state.user.profile.sns_infos.twitter.length > 0
    },
    haveBG(){
      return this.$store.state.user.profile_background_store_path ? true:false
    },
    backgroundImageUrl(){
      return getImageUrl(this.$store.state.user.profile_background_store_path)
    },
    avatarImageUrl(){
      return this.$store.state.user.profile_avatar_store_path
    },
    twitterAccount(){
      return this.$store.state.user.profile.sns_infos.twitter
    },
    viewerHistoryMore() {
      return this.historyPagination.current_page < this.historyPagination.total_pages
    },
    spViewerHistoryMore() {
      return this.spHistoryList.length > 0 || (this.historyPagination.current_page < this.historyPagination.total_pages)
    },
    payArchiveMore() {
      return this.payArchivePagination.current_page < this.payArchivePagination.total_pages
    },
  },
  async created() {
    this.setSmartPhone();
    await this.$store.dispatch('user/syncProfile');
    this.getList();
  },
  methods: {
    setSmartPhone() {
      this.isSmartPhone = isSmartPhone();
    },
    async getList(){
      const ret = await getViewHistory()
      const tmpHistories = ret.data.history.data.map(item => { return this.historyLive(item) }).filter(e => e)
      if (this.isSmartPhone) {
        this.historyList = tmpHistories.slice(0,2)
        this.spHistoryList = tmpHistories.slice(2)
      } else {
        this.historyList = tmpHistories
      }

      this.historyPagination = ret.data.history.meta.pagination
      this.paidList = ret.data.pay.data.map(live=>{
        return this.payLive(live)
      })
      this.payArchivePagination = ret.data.pay.meta.pagination;
      this.scheduleList = ret.data.schedule.data.map(item=>{
        const liveIsArray = item.live instanceof Array
        return {
          streamer:!liveIsArray ? item.live.profile.account_name : 'live is array,expect object',
          title:item.live.room_info ? item.live.room_info.name : 'room_infoがない',
          status:item.live.status ? item.live.status : this.$store.getters.enum_list.live_status_list.SCHEDULE,
          banner:item.live.room_info ? item.live.room_info.banner_store_path:'',
          date:item.live.schedule_start_time  ? item.live.schedule_start_time :'-',
          type:item.live.liveable_type ? item.live.liveable_type:'NORMAL',
          profile_id:item.live.profile_id,
          liveable_id:item.live.liveable_id,
          live_id:item.live.id,
          isArchive:false
        }
      })
    },
    onSearch() {
      this.$router.push({ name: 'TopSearchIndex' })
    },

    onLogout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push({ name: 'LoginIndex' })
      }).catch((e) => {
        console.log(e)
      })
    },
    showPopupProfile(){
      this.isRightPanelShow = false
      this.isPopupProfileShow = true
      this.PaidBuyPanelShow = false
    },
    onClosePopupProfile(){
      this.isPopupProfileShow = false
      this.isRightPanelShow = true
      this.PaidBuyPanelShow = false
    },

    showRightPanel() {
      this.isRightPanelShow = !this.isRightPanelShow
    },

    visibleChange(value) {
      this.isRightPanelShow = !value;
      this.isPopupProfileShow = false;
      this.PaidBuyPanelShow = value;
    },

    async spGetMoreHistory() {
      if (this.spHistoryList.length > 0) {
        const tmpHistories = this.spHistoryList.slice(0, 2);
        tmpHistories.forEach(element => { this.historyList.push(element) });
        this.spHistoryList.splice(0, 2)
      } else {
        this.historyQuery.page = this.historyQuery.page + 1
        const retHistories = await getViewHistoryList(this.historyQuery);
        const tmpHistories = retHistories.data.data.map(item => { return this.historyLive(item) }).filter(e => e)
        this.historyPagination = retHistories.data.meta.pagination
        tmpHistories.forEach((element, index) => {
          if (index < 2) {
            this.historyList.push(element)
          } else {
            this.spHistoryList.push(element)
          }
        });
      }
    },
    async getMoreHistory() {
      this.historyQuery.page = this.historyQuery.page + 1
      const retHistories = await getViewHistoryList(this.historyQuery);
      const tmpHistories = retHistories.data.data.map(item => { return this.historyLive(item) }).filter(e => e)
      tmpHistories.forEach(element => { this.historyList.push(element) });
      this.historyPagination = retHistories.data.meta.pagination
    },

    async getMorePayArchive(){
      this.payArchivePagination.current_page += 1;
      const retPayArchives = await getPayArchiveList(this.payArchivePagination.per_page,this.payArchivePagination.current_page);
      const tmpPayArchives = retPayArchives.data.data.map(item => { return this.payLive(item) }).filter(e => e)
      tmpPayArchives.forEach(element => { this.paidList.push(element) });
      this.payArchivePagination = retPayArchives.data.meta.pagination;
    },

    async onUpload(event) {
      var formData = new FormData();
      formData.append("file", event.target.files[0]);

      try {
        const { data } = await uploadBackground(formData);
        const ret1 = await getUserinfoById(this.$store.state.user.id);
        ret1.data.profile.background_store_path = data.store_path;
        await setUserInfo(ret1.data);
        await this.$store.dispatch('user/syncProfile');
      }catch(err) {
        this.$alert('画像サイズが大きすぎます','アップロード失敗', {
        confirmButtonText: 'OK',
        showClose: false,
      })
      }
    },
    linkFollow(type) {
      let tab = '';
      type == 'follow' ? tab = 'follow' : tab = 'follower';
      this.$router.push({ name: 'TopProfileFollow', query: { profile: this.$store.state.user.profile_id, tab: tab }});
    },
    TopProfileChange() {
      this.$router.push({
        name: "TopProfileChange",
      });
    },
    toAttributeDetail() {
      localStorage.setItem('buttonHide',true)
      let routeData = this.$router.resolve({
        name: "LoginInitNext",
      });
      window.open(routeData.href, '_blank');
    },
    onWindowOpen(link) {
      window.open(link,'_blank');
    },
    TopProfileBuyHistory() {
      this.$router.push({
        name: "TopProfileBuyHistory",
      });
    },
    TopProfileFollow() {
      this.$router.push({
        name: "TopProfileFollow",
      });
    },
    TopProfileRoom() {
      let TopProfileRoom = this.$router.resolve({
        name: "TopProfileRoom",
      });
      window.open(TopProfileRoom.href, '_blank');
    },
    //プロフィール文のボックスを可変にする関数
    calcIntroductionAreaHeight(){
      //規定の高さ
      const baseHeight = 128;
      const criteriaNum = 5;
      let newLineCounts = 0;
      let heightToBeAdded = 0;

      // --改行が存在した場合、その分だけ高さを伸ばす
      const newLines = (this.$store.state.user.profile_introduction.split(/\r?\n/g) || []);
      //分割した文字列のそれぞれの長さが21文字以上か調べる。そうして折り返した回数を調べる。
      newLines.forEach((str)=>{newLineCounts += Math.floor(str.length / 26) });

      //純粋な改行数も加算する。
      newLineCounts += newLines.length;

      //基準の回数より多くの改行数があった場合は、その分だけ高さを加算する。
      if(newLineCounts > criteriaNum){
        heightToBeAdded = (newLineCounts - criteriaNum) * 24;
      }

      return baseHeight + heightToBeAdded + 'px';
    },
    async reservedBannerIntercept(liveItem){
      // 有料配信だけ割り込む
      if( !liveItem.isPay ) {
        liveItem.onClickBanner();
        return;
      }
      let _this = this;
      await this.$store.dispatch('live/syncLiveInfo', liveItem.data.live_id).then(() => {
        this.$store.dispatch('live/syncLiveTicketInfo', liveItem.data.liveable_id).then(()=>{
          // 購入済み且つ配信中は配信視聴画面へ遷移
          if( _this.$store.state.live.info.is_buy_pay &&
            _this.$store.getters.enum_list.live_status_list.ONGOING == _this.$store.state.live.info.status ){
            let routeData = _this.$router.resolve({
              name: "TopLivePaid",
              params: { id: _this.$store.state.live.info.id },
            });
            window.open(routeData.href, '_blank');
            return;
          }
          _this.PaidBuyPanelShow = true;
        });
      });
    },
  }
}
</script>
