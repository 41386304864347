import request from '@/utils/request'

export function getDeliveryInfo() {
    return request({
        url: `/delivery_information`,
        method: 'get',
    })
}

export function setDeliveryInfo(data) {
    return request({
        url: `/delivery_information`,
        method: 'put',
        data: data
    })
}