<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-paymentformcvsresult")

        h4 お支払い情報を受付ました。
        div お支払い番号確認のメールを送信いたしました。
        div お支払いの際、お客様番号と確認番号が必要です。
        div メモを取るか、このページを印刷して、コンビニエンスストアまでお持ちください。
        div 受付番号、確認番号を控えて該当のコンビニエンスストアに行き、
        div 支払い期限内にお支払いを完了してください。

        div(class="cvsResult")
          table
            tr お客様番号
            td {{ resultData.ReceiptNo }}
            tr 確認番号
            td {{ resultData.ConfNo }}
            tr お支払い期限
            td {{ resultData.PaymentTermStr }}
            tr 受付日時
            td {{ resultData.TranDateStr }}
            tr 支払い合計金額
            td {{ totalPrice.toLocaleString() }}円

        div(v-if="resultData['howToPayUrl']" class="howtopay")
          button(@click="onHowToPay()") お支払い方法はこちら
        div(class="confirm")
          button.pink-button.onNextButton(@click="onBackTop()") トップページへ

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormCVSResult.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import moment from 'moment'

export default {
  name: "TopProfilePaymentFormCVSResult",
  components: {
    PRBanner,
  },
  data() {
    return {
      resultData: {},
      buyItemList: [],
      convenienceData: {
        '00007': {
          name: 'セブンイレブン',
          url: 'https://info.gmopg.jp/customer-convenience-store/seveneleven/',
        },
        '00009': {
          name: 'スリーエフ',
          url: '',
        },
        '10001': {
          name: 'ローソン',
          url: 'https://info.gmopg.jp/customer-convenience-store/lawson/',
        },
        '10002': {
          name: 'ファミリーマート',
          url: 'https://info.gmopg.jp/customer-convenience-store/familymart/',
        },
        '10003': {
          name: 'サンクス',
          url: '',
        },
        '10004': {
          name: 'サークルK',
          url: '',
        },
        '10005': {
          name: 'ミニストップ',
          url: 'https://info.gmopg.jp/customer-convenience-store/ministop/',
        },
        '10008': {
          name: 'セイコーマート',
          url: 'https://info.gmopg.jp/customer-convenience-store/seicomart/',
        },
      },
    }
  },
  async created() {
    this.resultData = this.$route.params.resultData;
    this.resultData['PaymentTermStr'] = moment(this.resultData['PaymentTerm'],"YYYYMMDDHHmmss").format('YYYY/MM/DD HH:mm');
    this.resultData['TranDateStr'] = moment(this.resultData['TranDate'],"YYYYMMDDHHmmss").format('YYYY/MM/DD HH:mm');
    this.resultData['howToPayUrl'] = this.convenienceData[this.resultData['Convenience']]['url'];
    this.buyItemList = this.$route.params.buyList;
    if( !this.buyItemList ){
      this.$alert('商品が正しく選択されていません。')
      this.$router.push({ name: 'TopProfileBuy'});
      return;
    }
  },
  computed: {
    totalPrice(){
      let price = 0;
      for(var key in this.buyItemList){
        price += (Number(this.buyItemList[key].type) * Number(this.buyItemList[key].amount));
      }
      // FIXME 手数料 80 円
      return price + 80;
    },
  },
  methods: {
    onHowToPay(){
      window.open(this.resultData['howToPayUrl'],'お支払い方法','width=800, height=600,toolbar=no,menubar=no,scrollbars=no');
    },
    onBackTop() {
      this.$router.push({
        name: 'TopIndex'
      })
    },

    onPaymentConfirm() {
      const resultData = this.resultData;
      const payChoice = 'cvs';
      this.$router.push({ name: 'TopProfilePaymentLoading', params: {
        resultData,
        payChoice,
      } });
    },
  }
}
</script>
