<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-buy")

        div(class="bread-list") >>
          span(class="display-title")  G購入

        <span class="my-money content-center"><img src="/img/gold-icon.png" alt="" width="30" height="30" /> 所持ゴールド{{ $store.state.user.c1 }} </span>

        div(class="buy-coin-item-list")
          <div v-for="(item, index) in buyItemList" class="coinCount">
            <table @click="onConfirm(index)">
              <tbody>
                <tr class="buy-content-item">
                  div(class="coin-item-detail")
                    div(class="content-center") <img src="/img/gold-icon.png" width="20px" height="20px">{{item.c1}}
                    div {{ item.type }}円
                  div(class="bonus-content")
                    span(class="content-center") ボーナス<img src="/img/star-icon.png" width="20px" height="20px"> {{item.c2}}獲得
                </tr>
              </tbody>
            </table>
          </div>

        div(class="coin-buy-footer-button")
          img(@click="onBack()" class="pointer" src="/img/modoru.png")

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./Buy.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import { isOverEighteen } from '@/utils/tools'
import _ from 'lodash'

export default {
  name: "TopProfileBuy",
  components: {
    PRBanner,
  },
  data() {
    return {
      buyItemList: [],
      birthdayCheck: false,
    }
  },
  async created() {
    this.buyItemList = _.chain(this.$store.state.system.config.coupon_price_list).toPairs().map(v => {
      return {
        type: parseInt(v[0]),
        amount: 1,
        c1: parseInt(v[1].c1),
        c2: parseInt(v[1].c2),
      }
    }).value();
    await this.$store.dispatch('user/syncCoupon');
  },
  methods: {
    async onConfirm(index) {
      if (!isOverEighteen(this.$store.state.user.profile_birthday)) {
        this.birthdayCheck = true;
        return;
      }

      const buyList = this.buyItemList.filter((v, i) => i === index).map(v => ({
        type: v.type,
        amount: v.amount,
      }))
      const buyType = "Gold"
      const successUrl = window.location.href.split('?')[0];
      const cancellUrl = window.location.href.split('?')[0];
      this.$router.push({ name: 'TopProfileSelectPayment', params: {
        buyType,
        buyList,
        successUrl,
        cancellUrl,
      } });
    },

    onBack() {
      this.$router.push({
        name: 'TopProfileBuyHistory'
      })
    },

    onClick1() {
      this.$router.push({ name: 'TopTermsTokushoho' });
    }
  }
}
</script>
