<template lang="pug">
  div(class="root coment-container")
    div(v-if="!isPassCheck" style="color:red;margin-top:20px") NGワードが含まれています
    div(class="commentcontainer")
      input(v-model="ui.inputComment", placeholder="コメントしよう!", class="coment-erea" @input="checkInput()")
      div(class="topright")
        //- button(:disabled="!isPassCheck" @click="onCommentSend()" class="pink-button" v-preventReClick="1000") 送信
        button(@click="onCommentSend()" class="pink-button" v-preventReClick="1000") 
    component-system-message(:type="'live'")
    <div class="center">
      <p style="margin-bottom: 0px;"><b>コメント</b></p>
    </div>
    div(class="container2")
      #comment-list-root
        div(v-for="(item, index) in commentList")
          template-vars(:itemEx="JSON.parse(item.payload.extension)")
            template(#default="{itemEx}")
              .commentTable
                span(v-if="$store.state.live.commentList.length - 1 === index" id="comment-list-last")
                div(v-if="item.payload.data === 'text'")
                  <div class="normalcomm">
                    <table>
                      <tbody>
                        div(class="comment-content")
                          div(class="comment-account-img" @click="onAvatarClick(itemEx.PROFILE_ID)")
                            //- <img :src="JSON.parse(item.payload.extension).AVATAR" alt="" width="40" height="40" />
                            <pan-thumb :avatar="itemEx.AVATAR" :attribute="itemEx.ATTRIBUTE" :current_title="itemEx.CURRENT_TITLE" :zIndex='0' size="40px"/>
                          div(class="comment-name-text" v-if="isMintInited")
                            <span v-if="giftSendUserList.indexOf(itemEx.PROFILE_ID) !== -1" style="color: #e03e2d;" class="comment-name" @click="onAvatarClick(itemEx.PROFILE_ID)">{{itemEx.NICKNAME}}<img v-if="itemEx.IS_FIRST" src="/img/newuser.png" alt="" width="10" height="15" /></span>
                            <span v-else style="color: #88623f;" class="comment-name" @click="onAvatarClick(itemEx.PROFILE_ID)">{{itemEx.NICKNAME}}<img v-if="itemEx.IS_FIRST" src="/img/newuser.png" alt="" width="10" height="15" /></span>
                            <span class="comment-text">{{replaceStar(item.payload.description)}}</span>
                      </tbody>
                    </table>
                  </div>
                div(v-if="item.payload.data === 'gift'")
                  <div class="newcomm" style="background: #fae8ed;border-radius: 0.5rem; padding: 8px 0;">
                    <table class="comment-table">
                      <tbody>
                        div(class="comment-content")
                          div(class="comment-content-left")
                            div(class="comment-account-img" @click="onAvatarClick(itemEx.PROFILE_ID)")
                              //- <img :src="JSON.parse(item.payload.extension).AVATAR" alt="" width="40" height="40" />
                              <pan-thumb :avatar="itemEx.AVATAR" :attribute="itemEx.ATTRIBUTE" :current_title="itemEx.CURRENT_TITLE" :zIndex='0' size="40px"/>
                            div(class="comment-name-text" v-if="isMintInited")
                              <span class="comment-user-name-gift" style="color: #e03e2d;" @click="onAvatarClick(itemEx.PROFILE_ID)">{{itemEx.NICKNAME}}<img v-if="itemEx.IS_FIRST" src="/img/newuser.png" alt="" width="10" height="15" /></span>
                              <span class="comment-text">{{replaceStar(item.payload.description)}}</span>
                              <span v-if="itemEx.GIFT_ID" style="color: #808080;" class="comment-text">{{itemEx.GIFT_ID}}を送りました！</span>
                          div
                            div(class="comment-gift-point")
                              div(class="comment-gift")
                                  <img :src="itemEx.giftIcon" /></span>
                                  <span> × {{ itemEx.RAW.amount }}</span>
                              div(v-if="itemEx.RAW.ratio > 0" class="comment-point-content")
                                template(v-for="boostPoint in { boostPoint: boostRatio(itemEx)}")
                                  div(v-if="boostPoint > 0" class="comment-point-total-img")
                                      <img src="/img/r-number/total.png">
                                  div(class="comment-point")
                                    div(v-if="boostPoint > 0" class="comment-point-img")
                                      <img :src="'/img/r-number/r' + String(boostPoint) + '.png'">
                                      <img src="/img/r-number/r0.png">
                                      span(class="comment-point-percent")
                                        <img src="/img/r-number/r_percent.png">
                                      span(class="comment-point-gif")
                                        <img src="/img/r-number/yajirushi_hikari-loop.gif">
                                    div(v-if="boostPoint > 0" class="comment-point-img")
                                      <img :src="'/img/r-number/r' + String(itemEx.RAW.ratio / 10 - boostPoint) + '.png'">
                                      <img src="/img/r-number/r0.png">
                                      span(class="comment-point-percent")
                                        <img src="/img/r-number/r_percent.png">
                                      span(class="comment-point-gif")
                                        <img src="/img/r-number/yajirushi_hikari-loop.gif">
                                    <div class="comment-point-img">
                                      <img :src="'/img/r-number/r' + String(itemEx.RAW.ratio / 10) + '.png'">
                                      <img src="/img/r-number/r0.png">
                                      span(class="comment-point-percent")
                                        <img src="/img/r-number/r_percent.png">
                                      span(class="comment-point-gif")
                                        <img src="/img/r-number/yajirushi_hikari-loop.gif">
                                    </div>

                        </tbody>
                    </table>
                  </div>
</template>

<style src="./Comment.scss" lang="scss" scoped>

</style>

<script>
import TIM from 'tim-js-sdk';
import VueScrollTo from 'vue-scrollto';
import _ from 'lodash';
import PanThumb from '@/components/PanThumb'
// import { getImageUrl } from '@/utils/tools'
import { getForbiddenWord } from '@/api/streamer'
import Mint from 'mint-filter'

export default {
  name: "LiveComponentComment",
  components: {
    PanThumb,
    TemplateVars: {
      functional: true,
      render: (h, {props, scopedSlots}) => h('div', scopedSlots.default(props)),
    },
  },
  filters:{
    parseItemEx(item){
      return JSON.parse(item.payload.extension)
    },
  },
  data() {
    return {
      isPassCheck:true,
      ui: {
        inputComment: ''
      },
      timer: {
        onlineCounter: undefined,
      },
      pointUp: "",
      forbiddenWordList:[],
      mint:{},
      isMintInited:false,
    }
  },
  props: {},
  computed: {
    commentList() {
      // streamerのメッセージをスキップ。
      if(this.$store.state.live.commentList.length > 0){
        const list = this.$store.state.live.commentList.filter(n => {
          const extension = JSON.parse(n.payload.extension);
          const isStreamerMessage = extension.PROFILEABLE_TYPE === 'streamer'
          return !isStreamerMessage;
        })
        return list;
      }
      return this.$store.state.live.commentList
    },
    giftSendUserList() {
      return this.$store.state.live.once_send_gift_profile_ids;
    },
    isLiveOver() {
      if (this.$store.getters.enum_list.live_status_list.ENDED == this.$store.state.live.info.status) {
        return true
      }
      return false
    },
  },
  watch: {},
  mounted() {},
  async created() {
  },
  updated() {},
  async beforeDestroy() {
    // TIM
    await this.$store.dispatch('live/timLogout');
    this.removeEventListenerToTIM();

    // Javascript: Timer
    this.stopTimOnlineCounter();
  },
  methods: {
    async init() {
      this.initMint()
      // TIM
      await this.$store.dispatch('live/timInit');
      this.$nextTick(() => {
        VueScrollTo.scrollTo('#comment-list-last', 100, {
          container: '#comment-list-root',
        })
      })

      await this.$store.dispatch('live/timLogin');
      await this.$store.dispatch('live/timJoinGroup');
      this.addEventListenerToTIM();

    },
    // Forbidden word
    async initMint(){
      this.isMintInited = false
      const { data } = await getForbiddenWord(this.$store.state.live.info.streamer_id)
      this.forbiddenWordList = data.word_list
      this.mint = new Mint(this.forbiddenWordList)
      this.isMintInited = true
    },
    checkInput(){
      const regMail = /[\w\-._]+@[\w\-._]+\.[A-Za-z]+/g
      const regUrl = /https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/g
      this.isPassCheck = this.mint.validator(this.ui.inputComment) && !regMail.test(this.ui.inputComment) && !regUrl.test(this.ui.inputComment)
      // this.isPassCheck = true
    },
    replaceStar(txt){
      // let txtClone = txt + ""
      const regMail = /[\w\-._]+@[\w\-._]+\.[A-Za-z]+/g
      const regUrl = /https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/g
      const res = this.mint.filterSync(txt)
      res.text = regMail.test(res.text) ? res.text.replace(regMail,'☆☆☆') : res.text
      res.text = regUrl.test(res.text) ? res.text.replace(regUrl,'☆☆☆') : res.text
      return res.pass ? res.text:res.text.replace(/([*])\1*/g,'☆☆☆')
      // return res.text.replace(/([*])\1*/g,'☆☆☆')
    },
    async onCommentSend() {
      if (!this.ui.inputComment || !this.$store.state.live.info.id) {
        return;
      }
      if(this.$store.state.live.info.status == this.$store.getters.enum_list.live_status_list.ENDED){
        this.$alert('配信が完了しましたので、送信できません')
        return;
      }
      // async version ----> mint.filter('word').then((res) => {})
      // const filtered = this.mint.filterSync(this.ui.inputComment,{replace:false})

      // const filtered = this.mint.filterSync(this.ui.inputComment)
      // this.ui.inputComment = filtered.text
      try {
        await this.$store.dispatch('live/timSendMessagePlain', {
          text: this.ui.inputComment,
          avatar: this.$store.state.user.profile_avatar_store_path
        });
      }catch(e) {
        this.$alert('送信失敗しました', {
          confirmButtonText: '確認',
          showClose: false,
        });
        console.log(e);
      }
      this.ui.inputComment = '';
      this.checkInput()
    },

    stopTimOnlineCounter() {
      if (this.timer.onlineCounter) {
        clearInterval(this.timer.onlineCounter);
      }
    },

    // filterWord(txt){
    //   let word = this.forbiddenWordList
    //   let filtered = txt
    //   for (let i=0;i<word.length;i++) {
    //     //
    //     //gi g global  i ignoreCase
    //     let reg = new RegExp(word[i],"gi")
    //     if(filtered.indexOf(word[i]) !== -1){
    //       filtered = filtered.replace(reg,"***")
    //     }
    //   }
    //   return filtered
    // },

    addEventListenerToTIM() {
      this.$store.state.live.insTIM.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onTimFunc1);
    },

    removeEventListenerToTIM() {
      this.$store.state.live.insTIM.off(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onTimFunc1);
    },

    onTimFunc1(event) {
      const target = _.chain(event.data).find({conversationID: 'GROUP' + this.$store.state.live.info.message_group}).value();
      if (target && target.lastMessage) {
        if (!_.find(this.commentList, {lastSequence: target.lastMessage.lastSequence})) {
          if (target.lastMessage.fromAccount !== '@TIM#SYSTEM') {
            this.$store.dispatch('live/timUpdateCommentList', _.cloneDeep(target.lastMessage));
            // console.log({commentList:this.commentList})
            this.$nextTick(() => {
              VueScrollTo.scrollTo('#comment-list-last', 100, {
                container: '#comment-list-root',
              })
            })
          }
        }
      }
    },
    boostRatio(itemEx){
      if( !itemEx.RAW.ratio > 0 ){
        return 0;
      }
      // 無理やりブースト値を計算する ゴッドギフトも全て10
      if( ( this.isAttributeGift(itemEx.RAW.type) || this.isGodGift(itemEx.RAW.type) ) && Number(itemEx.RAW.ratio) > 10 ){
        return 1;
      }
      return 0;
    },
    isAttributeGift(giftType){
      const giftList =  this.$store.state.system.enum_list.gift_type_list;
      const attributeGiftList = [
        giftList.SHOW_FIRE,
        giftList.SHOW_ICE,
        giftList.SHOW_WIND,
        giftList.SHOW_EARTH,
        giftList.BEAST_PHOENIX,
        giftList.BEAST_PEGASUS,
        giftList.BEAST_DRAGON,
        giftList.BEAST_WHITETIGER,
      ]
      return attributeGiftList.includes(giftType);
    },
    isGodGift(giftType){
      const giftList =  this.$store.state.system.enum_list.gift_type_list;
      const attributeGiftList = [
        giftList.SUPERLATIVE_ZEUS,
        giftList.SUPERLATIVE_GODDESS,
      ]
      return attributeGiftList.includes(giftType);
    },
    onAvatarClick(profile_id) {
      this.$emit('commentAvatarClick', profile_id)
    }
  }
};
</script>
