import request from '@/utils/request'

export function getCostHistoryGold(limit, page) {
  return request({
      url: `/exchanges?category=1&search=coupon:c1;remark:rp1&searchJoin=and&orderBy=id&sortedBy=desc&limit=${limit}&page=${page}`,
      method: 'get',
  })
}

export function getCostHistoryGift(limit, page) {
  return request({
      url: `/exchanges?category=2&search=coupon:c1,c2,c3;remark:rc1,rc2,rc3,rc4&searchJoin=and&orderBy=id&sortedBy=desc&limit=${limit}&page=${page}`,
      method: 'get',
  })
}

export function getCostHistoryCash(limit, page) {
  return request({
      url: `/exchanges?category=2&search=coupon:c0;remark:rc3&searchJoin=and&orderBy=id&sortedBy=desc&limit=${limit}&page=${page}`,
      method: 'get',
  })
}
