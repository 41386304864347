import { getCostHistoryGold, getCostHistoryGift, getCostHistoryCash } from '@/api/cost-history';

const getDefaultState = () => {
  return {
    gold_list: [],
    gold_next_page: 1,
    gold_limit: 6,

    gift_list: [],
    gift_next_page: 1,
    gift_limit: 4,

    cash_list: [],
    cash_next_page: 1,
    cash_limit: 4,
  }
}

const state = getDefaultState()

const mutations = {
  SET_LIST_GOLD: (state, data) => {
    state.gold_list = state.gold_list.concat(data.data);
    state.gold_next_page = data.meta.pagination.current_page === data.meta.pagination.total_pages ? -1: data.meta.pagination.current_page + 1;
  },
  SET_LIST_GIFT: (state, data) => {
    state.gift_list = state.gift_list.concat(data.data);
    state.gift_next_page = data.meta.pagination.current_page === data.meta.pagination.total_pages ? -1: data.meta.pagination.current_page + 1;
  },
  SET_LIST_CASH: (state, data) => {
    state.cash_list = state.cash_list.concat(data.data);
    state.cash_next_page = data.meta.pagination.current_page === data.meta.pagination.total_pages ? -1: data.meta.pagination.current_page + 1;
  },
  INIT_GOLD: () => {
    state.gold_list = [];
    state.gold_next_page = 1;
    state.gold_limit = 6;
  },
  INIT_GIFT: () => {
    state.gift_list = [];
    state.gift_next_page = 1;
    state.gift_limit = 4;
  },
  INIT_CASH: () => {
    state.cash_list = [];
    state.cash_next_page = 1;
    state.cash_limit = 4;
  },
}

const actions = {
  async syncListGold({ commit, state }, data) {
    if (data && data.init) {
      commit('INIT_GOLD');
    }
    if (state.gold_next_page === -1) {
      return;
    }
    const ret = await getCostHistoryGold(state.gold_limit, state.gold_next_page);
    commit('SET_LIST_GOLD', ret.data);
  },
  async syncListGift({ commit, state }, data) {
    if (data && data.init) {
      commit('INIT_GIFT');
    }
    if (state.gift_next_page === -1) {
      return;
    }
    const ret = await getCostHistoryGift(state.gift_limit, state.gift_next_page);
    commit('SET_LIST_GIFT', ret.data);
  },
  async syncListCash({ commit, state }, data) {
    if (data && data.init) {
      commit('INIT_CASH');
    }
    if (state.cash_next_page === -1) {
      return;
    }
    const ret = await getCostHistoryCash(state.cash_limit, state.cash_next_page);
    commit('SET_LIST_CASH', ret.data);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

