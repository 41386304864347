export default {
  methods: {
    // 視聴履歴用のliveをparseしてitem-listにわたす
    historyLive(item) {
      if (item.live.liveable_type === 'NORMAL' || item.live.liveable_type === 'EVENT') {
        return {
          streamer: item.live.profile ? item.live.profile.account_name : 'profileがない',
          title:item.live.room_info.name || '',
          status:item.live.status ? item.live.status : this.$store.getters.enum_list.live_status_list.SCHEDULE,
          banner:item.live.room_info ? item.live.room_info.banner_store_path:'',
          date:item.live.started_at,
          type:item.live.liveable_type ? item.live.liveable_type:'NORMAL',
          profile_id:item.live.profile_id,
          liveable_id:item.live.liveable_id,
          live_id:item.live.id,
          isArchive:item.is_archive
        }
      }
    },
    payLive(item) {
      const live = item.live;
      return {
        streamer:live.profile.account_name || `(streamer_id情報しかない)`,
        title:live.room_info.name,
        status:live.status,
        banner:live.room_info.banner_store_path,
        date:item.expire_time,
        type:live.liveable_type ? live.liveable_type:'PAY',
        profile_id:live.profile_id,
        liveable_id:live.liveable_id,
        live_id:live.id,
        isArchive:true
      }
    }
  },
}

