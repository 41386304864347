<template lang="pug">
  .root
    component-header-index(v-if="$store.state.user.email")
    component-header-login(v-if="!$store.state.user.email")
    div(class="container-flex")
      div(class="content-info")
        <h3 style="text-align:center">クラファン配信利用規約</h3>
        //- div(class="") {{ this.text }}
        div(class="term-content")
          <div v-html="text"></div>

    component-footer-index
</template>

</style>

<script>
import { getStaticFundsTerms } from '@/api/system';

export default {
  name: "TopTermsFundTerms",
  components: {
  },
  data() {
    return {
      ui: {
      },
      oneNewsInfo: {

      },
      text: '',
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    const res = await getStaticFundsTerms();
    this.text = res.fund_terms_of_use.content.describe;
  },
  updated() {},
  destroyed() {},
  methods: {
  }
};
</script>
