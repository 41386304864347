<template lang="pug">
  .root
    div(class="waiting")

      div(v-if="retry")
        p タイムアウトしました。
        p しばらくお待ちいただき、再度ご確認ください。
        div(class="retry" v-if="retry")
          button.pink-button.onNextButton(@click="this.retryPolling") 再確認
      div(v-else)
        p 支払いを確認しております
        p しばらくお待ちください...

</template>

<script>
import { getPolling } from '@/api/payment';

export default {
  name: "ComponentsWaitingLoad",
  data() {
    return {
      pollInterval: 3000,
      retryCount: -1,
      orderID: '',
      pollingFunc: undefined,
      payment: '',
      window: '',
      retry: '',
    }
  },
  async mounted() {
    this.resultData = this.$route.params.resultData;
    this.payChoice = this.$route.params.payChoice;

    // 必要データの格納
      if(this.payChoice == 'condo') {
        this.orderID = this.resultData.order_id;
      } else {
        this.orderID = this.resultData.OrderID;
      }
    // 開く必要があるurlがあれば開く
    this.urlCheck(this.resultData);
    // ポーリング
    this.pollingPayed(this.pollInterval,this.retryCount).then(value => {
      if(value) {
        // 5秒後に遷移
        this.payment = 'done';
        setTimeout(this.PaymentSucceed, 5000);
      } else {
        if (this.payChoice != 'card') {
          this.retry = 'retry';
        }
      }
    });
  },
  beforeDestroy() {
    if (this.pollInterval) {
      clearInterval(this.pollingFunc);
    }
  },
  methods: {
    urlCheck(resultData) {
      if (resultData.RedirectUrl) {
        this.window = window.open(resultData.RedirectUrl,'_blank','width=800, height=600,toolbar=no,menubar=no,scrollbars=no');
      }
      else if (resultData.url) {
        const linkStr = encodeURIComponent(resultData.url);
        let routeData = this.$router.resolve({
          name: "RedirectIndex",
          params: { link: linkStr }
        });
        window.open(routeData.href, '_blank');
      }
    },

    pollingPayed(time,retryCount) {
      return new Promise( (resolve) =>{
        let count = 0;
        this.pollingFunc = setInterval(async ()=>{
          count++;
          const res = await this.getPayedData();
          if( res || ( retryCount > 0 && count >= retryCount )){
            clearInterval(this.pollingFunc);
            this.pollingFunc = null;
            resolve(res);
          }
        },time);
      });
    },
    retryPolling(){
      this.pollingPayed(this.pollInterval,this.retryCount);
    },

    async getPayedData(){
      const load = await getPolling(this.orderID, this.payChoice);
      if(load.data.is_succeed) {
        return load.data;
      }
      else false;
    },

    PaymentSucceed:function() {
      const pay = this.payment
      this.$router.push({ name: 'TopProfilePaymentLoadingResult', params: {
        pay,
      } });
    }
  }
}
</script>