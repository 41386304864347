export const LIVE_TYPE = {
  NORMAL: Symbol('NORMAL'),
  EVENT: Symbol('EVENT'),
  FUND: Symbol('FUND'),
  PAID: Symbol('PAID'),
}

export const PANEL_TYPE = {
  GIFT: Symbol('GIFT'),
  BUY: Symbol('BUY'),
  BUY_PAY: Symbol('BUY_PAY'),
  BUY_COIN: Symbol('BUY_COIN'),
  TARGET: Symbol('TARGET'),
  ADDRESS: Symbol('ADDRESS'),
  PROFILE: Symbol('PROFILE'),
}