<template lang="pug">
  .root
    div(v-if="ui.panel == 'select'"  class="panel")

      div お支払い方法を選んでください。

      div(class="paymentList")
        <input class="payment-title" type="radio" id="card" value="card" v-model="selectedPaymentType">
        <label for="card">カード決済</label>
        div(class="paymentList-detail")
          div(class="card-img")
            <img src="/img/logo_visa.jpg">
            <img src="/img/logo_master_card.png">
            <img src="/img/logo_jcb.gif">
            <img style="width: 60px;height: 60px;" src="/img/logo_amex.jpg">
            <img style="width: 60px;height: 60px;" src="/img/logo_diners.jpg">
            

        //- <input class="payment-title" type="radio" id="cvs" value="cvs" v-model="selectedPaymentType">
        //- <label for="cvs">コンビニ決済</label>
        //- div(class="paymentList-detail")
        //-   div 手数料:80円
        //-   div <<ご利用可能店舗>>
        //-   div ローソン、ファミリーマート、ミニストップ、セイコーマート

        //- <input class="payment-title" type="radio" id="payeasy" value="payeasy" v-model="selectedPaymentType">
        //- <label for="payeasy">ペイジー決済（Pay-easy）</label>
        //- div(class="paymentList-detail")
        //-   div 手数料:80円
        //-   div 銀行ATMでのお支払い、各金融機関でのネットバンキング・モバイルバンキングのお支払いがご利用いただける決済サービスです。
        //-   div 購入者の支払情報が金融機関の画面にそのまま引き継がれるため、支払い忘れや入力ミスがなく、安心・確実に決済手続きが行えます。
        //-   div <<ご利用可能な金融機関>>
        //-   div ゆうちょ銀行・みずほ銀行・三井住友銀行・三菱UFJ銀行・りそな銀行・楽天銀行 他

        //- 一旦こんど払いもコメントアウト
        //- <input class="payment-title" type="radio" id="cvs-ap" value="cvs-ap" v-model="selectedPaymentType">
        //- <label for="cvs-ap">コンビニ後払い（こんど払い byGMO）</label>
        //- div(class="paymentList-detail")
        //-   div 手数料:220円
        //-   div ひと月分のご利用金額をまとめて翌月中の好きなタイミングでコンビニにて支払うことができる、後払い決済サービスです。
        //-   div お支払い時に別途こんど払いシステム利用料が発生いたします。
        //-   div ※こんど払いシステム利用料：手数料を含む1か月分のご利用金額合計 5万円未満：330円、5万円以上：660円（いずれも税込）
        //-   a(href="https://condo-pay.com/") 詳しくはこちら

        
      div(class="button-box")
        div(class="paymentNext")
          button.pink-button.onNextButton(@click="onPaymentNext()" v-preventReClick="3000") 次へ
        div(class="coin-buy-footer-button")
          button.pink-button.onNextButton(@click="onSelectBack()" class="pink-button") 戻る
        
    div(v-if="ui.panel == 'stripe'"  class="panel")
      div(class="container")
        div クレジットカードの登録
          span サイト内にクレジットカード情報は保持されません。
        div(class="stripeForm")
          h5 名前
          input(v-model="cardData.name")
          br
          h5 カード番号
          input(v-model="cardData.code")
          br
          div(class="expiration-date" style="margin-top: 16px") 有効期限
            div
              span(style="margin-right: 26px;") 月
              span(style="margin: 0 8px;font-weight: bold;font-size:15px;") /
              span 年(西暦下二桁)
            div
              input(v-model="cardData.expiration_date_month" class="input-g-w-border expiration-date-month")
              span(style="margin: 0 8px;font-weight: bold;font-size:16px;") /
              input(v-model="cardData.expiration_date_year" class="input-g-w-border expiration-date-year")
            span(style="color: red;") ※月/年は0を含める

              //- div(class="expiration-date") 有効期限
              //-   div
              //-     span(style="margin-right: 9%;") 月
              //-     span(style="margin: 0 8px;font-weight: bold;font-size:15px;") /
              //-     span 年(西暦下二桁)
              //-   div
              //-     input(v-model="cardData.expiration_date_month" class="input-g-w-border expiration-date-month")
              //-     span(style="margin: 0 8px;font-weight: bold;font-size:16px;") /
              //-     input(v-model="cardData.expiration_date_year" class="input-g-w-border expiration-date-year")
              //-   span ※月/年は0を含める


          h5 セキュリティコード
          input(v-model="cardData.security_code" style="margin-bottom:5px")

          .buttonArea
            .errorMessageArea
              .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}

          div(class="button-box")
            button.pink-button.onNextButton(@click="onConfirmCard()" v-preventReClick="3000") 登録
            button.pink-button(@click="onStripeBack()") 戻る

    div(v-if="ui.panel == 'stripeResult'"  class="panel")
      div(class="container")
        div クレジットカードの確認
        div(class="stripeForm")
          h5 名前
          p {{ cardData.holder_name }}
          h5 カード番号
          p {{ cardData.card_no }}
          h5 有効期限
          p(class="credit-font") {{ cardData.expiration_date_year }} / {{ cardData.expiration_date_month }}
          h5 支払い合計金額
          p {{ totalPrice.toLocaleString() }}円

        .buttonArea
          .errorMessageArea
            .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}
          
          
        div(class="change-button-box")
          button.pink-button.onNextButton(@click="onPaymentCredit()" v-preventReClick="3000") 購入する
          button.pink-button(v-if="canChange" @click="ui.panel = 'change'") 変更する
          button.pink-button(@click="onStripeResultBack()") 戻る
          h5 サイト内にクレジットカード情報は保持されません。
        //div ※ここで購入したGはAライブ内の全サービスでご利用いただけます。
        //div >>資金決済法に基づく表示
        //div(@click="onClick1()") >>特定商取引法の表記

    div(v-if="ui.panel == 'change'")
      div(class="right-content-border")
        button(@click="onChangeBack()" class="close-button") <i class="fas fa-times"></i>
        div(class="m-b") クレジットカードの変更
          div(class="m-b")
            div(class="credit-input-item") 名前
              input(v-model="cardData.name" class="input-g-w-border")
            div(class="credit-input-item") カード番号
              input(v-model="cardData.code" class="input-g-w-border")
            div(class="credit-input-item expiration-date") 有効期限
              div
                span(style="margin-right: 9%;") 月
                span(style="margin: 0 8px;font-weight: bold;font-size:15px;") /
                span 年(西暦下二桁)
              div
                input(v-model="cardData.expiration_date_month" class="input-g-w-border expiration-date-month")
                span(style="margin: 0 8px;font-weight: bold;font-size:16px;") /
                input(v-model="cardData.expiration_date_year" class="input-g-w-border expiration-date-year")
              span(style="color: red;") ※月/年は0を含める
            div(class="credit-input-item") セキュリティコード
              input(v-model="cardData.security_code" class="input-g-w-border")
          div(class="coin-buy-footer-button" style="margin-bottom:10px" )
            button(@click="onCreateCredit()" class="pink-button") 変更
          div(class="coin-buy-footer-button")
            button(@click="onChangeBack()" class="pink-button") 戻る

    div(v-if="ui.panel == 'loading'"  class="panel panel-coment")
      p 支払いを確認しております
      p しばらくお待ちください...
    div(v-if="ui.panel == 'done'"  class="panel panel-coment")
      p 支払いが完了しました
    
</template>

<style src="./ClubfunPayment.scss" lang="scss" scoped>
</style>


<script>
import { sendCoinCard, sendCoinGmoAp } from '@/api/funds_coin';
import { getStripeData, createPaymentCard, getPollingFund, DeleteCredit } from '@/api/payment';
import { isPrd } from '@/utils/tools';

export default {
  name: "LiveComponentClubfunPayment",
  components: {
  },
  props: {
    data:{
      type:Object,
      required:true
    },
    liveInfo: {
      type:Object,
      required:true
    },
    canChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pollingFunc: undefined,
      shopId: '',
      orderID: '',
      pollingTime: 3000,
      pollingRetryCount: -1,
      selectedPaymentType: '',
      buyItemList: [],
      buyType: '',
      successUrl: '',
      cancellUrl: '',
      sessionId: '',
      cardData: {
        name: '',
        code: '',
        expiration_date_month: '',
        expiration_date_year: '',
        security_code: '',
      },
      ui: {
        errorMessage: "Error",
        showError: false,
        panel: 'select'
      },
      resultData: '',
      payChoice: '',
    }
  },
  computed: {
    totalPrice(){
      let price = 0;
      for(var key in this.buyItemList){
        price += (Number(this.buyItemList[key].cash_price) * Number(this.buyItemList[key].amount));
      }
      return price;
    },
  },
  async created() {
    this.buyItemList = this.data.buyList;
    this.buyType = this.data.buyType;
    this.successUrl = this.data.successUrl;
    this.cancellUrl = this.data.cancellUrl;
  },
  async mounted(){
    // TODO : 外だし対応必要
    this.shopId = 'tshop00050484';
    let url = 'https://stg.static.mul-pay.jp/ext/js/token.js';
    // 本番はurlとshopIdが違う
    if ( isPrd() ){
      url = 'https://static.mul-pay.jp/ext/js/token.js';
      this.shopId = '9200003409720';
    }
    let cardTokenScript = document.createElement('script');
    cardTokenScript.setAttribute('src', url);
    document.head.appendChild(cardTokenScript);
  },
  methods: {
    async onConfirmStripe() {
      // const buyType = this.buyType;
      // const buyList = this.buyItemList;
      // const successUrl = this.successUrl;
      // const cancellUrl = this.cancellUrl;

      const card_data = await getStripeData()
      if(card_data['data']['ErrCode']) {
        this.ui.panel = 'stripe'
      } else {
        this.cardData.holder_name = card_data.data.HolderName;
        this.cardData.card_no = card_data.data.CardNo;
        this.cardData.ex_date = card_data.data.Expire;
        this.cardData.expiration_date_year = card_data.data.Expire.slice(2,4);
        this.cardData.expiration_date_month = card_data.data.Expire.slice(0,2);
        this.cardData.card_seq = card_data.data.CardSeq;
        this.ui.panel = 'stripeResult'
      }

    },
    async onConfirmGmoAp() {
      // const buyList = this.buyItemList;
      const {data} = await sendCoinGmoAp(this.buyItemList,this.liveInfo)
      // this.resultData = ret.data;
      this.orderID = data.OrderID;
      this.payChoice = 'condo';
      
      // ローディング
      this.ui.panel = 'loading'
      this.pollingPayed(this.pollingTime,this.pollingRetryCount)
      .then( () => {
        this.ui.panel = 'done';
        this.$emit('selectBack')
        this.$emit('onCashDone', data)
      });
    },
    async onConfirmGmoCvs() {
      const buyType = this.buyType;
      const buyList = this.buyItemList;
      const successUrl = this.successUrl;
      const cancellUrl = this.cancellUrl;
      this.$router.push({ name: 'TopProfilePaymentFormCVS', params: {
        buyType,
        buyList,
        successUrl,
        cancellUrl,
      } });
    },
    async onConfirmGmoPayeasy() {
      const buyType = this.buyType;
      const buyList = this.buyItemList;
      const successUrl = this.successUrl;
      const cancellUrl = this.cancellUrl;
      this.$router.push({ name: 'TopProfilePaymentFormPayeasy', params: {
        buyType,
        buyList,
        successUrl,
        cancellUrl,
      } });
    },
    async onPaymentNext() {
      if( !this.selectedPaymentType ){
        this.$alert('支払い方法が選択されていません。支払い方法を選択してください')
        return;
      }
      switch(this.selectedPaymentType){
        case 'card':
          await this.onConfirmStripe();
          break;
        case 'cvs-ap':
          await this.onConfirmGmoAp();
          break;
        case 'cvs':
          await this.onConfirmGmoCvs();
          break;
        case 'payeasy':
          await this.onConfirmGmoPayeasy();
          break;
      }
    },

    onSelectBack() {
      this.$emit('selectBack')
    },

    onStripeBack(){
      this.ui.panel = 'select'
    },

    async onConfirmCard() {
      if(
        !this.cardData.name ||
        !this.cardData.code ||
        !this.cardData.expiration_date_month ||
        !this.cardData.expiration_date_year ||
        !this.cardData.security_code
      ){
        this.ui.showError = true;
        this.ui.errorMessage = "未入力箇所があります。必ず入力してください。";
        return;
      }
      if(
        this.cardData.expiration_date_month.length != 2 ||
        this.cardData.expiration_date_year.length != 2
      ){
        this.ui.showError = true;
        this.ui.errorMessage = "年、月は数値二桁で入力してください";
        return;
      }
      // token 取得システム初期化
      window.Multipayment.init(this.shopId);
      // コールバック関数の設定
      const self_ = this;
      window.PaymentTokenCallbackTunnelFunds = function PaymentTokenCallbackTunnelFunds(result){
        self_.onTokenCallback(result);
      }
      // トークン変換
      window.Multipayment.getToken(
        {
          cardno: this.cardData.code,
          expire: this.cardData.expiration_date_year + this.cardData.expiration_date_month,
          securitycode: this.cardData.security_code,
          holdername: this.cardData.name,
        },
        window.PaymentTokenCallbackTunnelFunds);
    },
    async onTokenCallback(result){
      if( result.resultCode != '000' ){
        this.ui.showError = true;
        this.ui.errorMessage = "クレジットカードの認証に失敗しました。入力情報をお確かめください。";
        return;
      }
      try{
        await createPaymentCard(result.tokenObject.token);
      } catch( error ){
        this.$alert('決済エラーが発生しました。このカードは現在お取り扱いできません。')
        return;
      }
      const card_data = await getStripeData();
      if(card_data['data']['ErrCode']) {
        this.ui.panel = 'stripe'
      } else {
        this.cardData.holder_name = card_data.data.HolderName;
        this.cardData.card_no = card_data.data.CardNo;
        this.cardData.ex_date = card_data.data.Expire;
        this.cardData.card_seq = card_data.data.CardSeq;
        this.ui.panel = 'stripeResult'
      }
    },
    async onPaymentCredit() {
      const data = {
        method: 1,
        card_seq: this.cardData.card_seq,
        items: this.buyItemList
      }
      let resData
      try{
        resData = await sendCoinCard(data,this.liveInfo);
      } catch( error ){
        if (error.response.data.code === this.$store.state.system.enum_list.response_code_list.SERVICE_LIVE_NOT_AVAILABLE) {
          this.$alert("この配信ではご利用できない状態になっています。")
          return;
        }
        this.$alert("決済エラーが発生しました。このカードは現在お取り扱いできません。")
        return;
      }
      this.orderID = resData.data.OrderID;
      this.payChoice = 'card';
      // const payChoice = 'card';
      // this.$router.push({ name: 'TopProfilePaymentLoading', params: {
      //   resultData,
      //   payChoice,
      // } });

      // ローディング
      this.ui.panel = 'loading'
      this.pollingPayed(this.pollingTime,this.pollingRetryCount)
      .then( (res) => {
        this.ui.panel = 'done';
        this.$emit('onCashDone', res)
      });
    },

    pollingPayed(time,retryCount) {
      return new Promise( (resolve) =>{
        let count = 0;
        this.pollingFunc = setInterval(async ()=>{
          count++;
          const res = await this.getPayedData();
          if( res || ( retryCount > 0 && count >= retryCount )){
            clearInterval(this.pollingFunc);
            this.pollingFunc = null;
            resolve(res);
          }
        },time);
      });
    },

    async getPayedData(){
      const load = await getPollingFund(this.orderID, this.payChoice);
      if(load.data.is_succeed) {
        return load.data;
      }
      else false;
    },

    onStripeResultBack() {
      this.ui.panel = 'select'
    },

    async onCreateCredit() {
      if(
        !this.cardData.name ||
        !this.cardData.code ||
        !this.cardData.expiration_date_month ||
        !this.cardData.expiration_date_year ||
        !this.cardData.security_code
        ) {
        this.$alert("未入力箇所があります。必ず入力してください。")
        return;
      }
      this.onDeleteCredit()
      // token 取得システム初期化
      window.Multipayment.init(this.shopId);
      // コールバック関数の設定
      const self_ = this;
      window.PaymentTokenCallbackTunnel = function PaymentTokenCallbackTunnel(result){
        self_.onTokenCallback(result);
      }
      // トークン変換
      window.Multipayment.getToken(
        {
          cardno: this.cardData.code,
          expire: this.cardData.expiration_date_year + this.cardData.expiration_date_month,
          securitycode: this.cardData.security_code,
          holdername: this.cardData.name,
        },
        window.PaymentTokenCallbackTunnel);
    },

    async onDeleteCredit() {
      await DeleteCredit().catch(() => {
        this.$alert('決済エラーが発生しました。このカードは現在お取り扱いできません。')
      });
    },

    onChangeBack() {
      this.ui.panel = 'stripeResult'
    },
  }
}
</script>