import { login, getInfoFromAuth, refresh, logout, initUserinfo, getLoginBonusInfo } from '@/api/user'
import { getProfile } from '@/api/profile';
import { setAttribute, getAttribute } from '@/api/profile'
import { getToken, setToken, removeToken, setIsSaveFlag } from '@/utils/auth'
import { addFollowing, removeFollowers,updateRoomInvitationStatus } from '@/api/profile'
import { showCoupon } from '@/api/coupon';
import { sendGift } from '@/api/gift'
import { sortBy } from 'lodash'
import { getDefaultGiftList } from './system'
const getDefaultState = () => {
  return {
    token: getToken(),
    id: '',
    module: '',
    email: '',
    profile_id: '',
    daily_first_flag: false,

    // "FIRE":"a1", "ICE":"a2", "WIND":"a3", "EARTH":"a4"
    attribute: '',
    attribute_str: '',

    // GOLD
    c1: '0',
    // STAR
    c2: '0',
    // HEART
    c3: '0',

    gift_normal: [],
    gift_event: [],
    gift_pay: [],
    gift_attribute_booster: [],
    gift_attribute_animation: [],
    gift_attribute_boss: [],

    gift_attribute_all_booster: [],
    gift_attribute_all_boss_boy: [],
    gift_attribute_all_boss_girl: [],

    lastTsSyncUserInfo: 0,

    profile_account_id: undefined,
    profile_account_name: undefined,
    profile_introduction: undefined,
    profile_birthday: undefined,
    profile_avatar_store_path: undefined,
    profile_background_store_path: undefined,
    profile_sns_infos: undefined,
    profile_profileable_type: undefined,
    profile_following_total: undefined,
    profile_follower_total: undefined,
    profile_profileable_id: undefined,
    profile_profileable_viewer_id: undefined,
    profile_profileable_country: undefined,
    profile_profileable_province: undefined,
    profile_profileable_titles: [],
    profile_profileable_current_title: undefined,

    profile:undefined,
    keysFollowing: [],
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    const obj = Object.assign({}, state, getDefaultState());
    state = obj;
  },
  RESET_DAILY_FIRST_FLAG: ( state ) => {
    state.daily_first_flag = false;
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_DAILY_FIRST_FLAG: (state, daily_first_flag) => {
    state.daily_first_flag = daily_first_flag;
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_MODULE: (state, module) => {
    state.module = module
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_PROFILE_ID: (state, profile_id) => {
    state.profile_id = profile_id
  },
  SET_ATTRIBUTE: (state, data) => {
    state.attribute = data.attribute;
    state.attribute_str = data.attribute_str;

  },
  SET_LAST_TS_SYNC_USERINFO: (state, value) => {
    state.lastTsSyncUserInfo = value
  },
  SET_COUPON: (state, value) => {
    state.c1 = `${value.c1}`;
    state.c2 = `${value.c2}`;
    state.c3 = `${value.c3}`;
  },
  SET_GIFT: (state, value) => {
    state.gift_normal = value.gift_normal;
    state.gift_event = value.gift_event;
    state.gift_pay = value.gift_pay;
    state.gift_pay_normal = value.gift_pay_normal;

    state.gift_attribute_booster = value.gift_attribute_booster;
    state.gift_attribute_animation = value.gift_attribute_animation;
    state.gift_attribute_boss = value.gift_attribute_boss;

    state.gift_attribute_all_booster = value.gift_attribute_all_booster;
    state.gift_attribute_all_boss_boy = value.gift_attribute_all_boss_boy;
    state.gift_attribute_all_boss_girl = value.gift_attribute_all_boss_girl;
  },
  SET_PROFILE: (state, value) => {
    state.profile = value;
    state.profile_account_id = value.account_id;
    state.profile_account_name = value.account_name;
    state.profile_introduction = value.introduction;
    state.profile_birthday = value.birthday;
    state.profile_avatar_store_path = value.avatar_store_path;
    state.profile_background_store_path = value.background_store_path;
    state.profile_sns_infos = value.sns_infos;
    state.profile_profileable_type = value.profileable_type;
    state.profile_following_total = value.following_total;
    state.profile_follower_total = value.follower_total;

    state.profile_profileable_id = value.profileable.id;
    state.profile_profileable_viewer_id = value.profileable.viewer_id;
    state.profile_profileable_country = value.profileable.country;
    state.profile_profileable_province = value.profileable.province;
    state.profile_profileable_titles = value.profileable.titles;
    state.profile_profileable_current_title = value.profileable.current_title;
  },
  SET_KEYS_FOLLOWING: (state, data) => {
    state.keysFollowing = data
  },
  SET_CHATROOM_INVITATION_STATUS: (state,value)=> {
    state.profile.invited_room_mail = value
  },
  SET_PROFILE_AVATOR_STORE_PATH: (state,value)=> {
    state.profile_avatar_store_path = value;
  },
  SET_PROFILE_BACKGROUND_STORE_PATH: (state,value)=> {
    state.profile_background_store_path = value;
  }

}

const actions = {
  async login({ commit }, form) {
    const { email, password, isSave } = form
    const { data } = await login({ email: email.trim(), password: password });
    commit('SET_TOKEN', data.access_token);
    commit('SET_DAILY_FIRST_FLAG', data.daily_first_flag);
    setIsSaveFlag(isSave, email);
    setToken(data.access_token)
  },

  async refresh({ commit, dispatch }) {
    try {
      const { data } = await refresh();
      commit('SET_TOKEN', data.access_token)
      setToken(data.access_token)
      await dispatch('syncUserInfo');
    } catch(e) {
      removeToken()
      commit('RESET_STATE')
    }
  },

  async syncUserInfo({ dispatch, state, commit }) {
    if (new Date().valueOf() - state.lastTsSyncUserInfo > 1 * 1000) {
      await dispatch('syncAuthInfo');
      await dispatch('syncAttribute');
      await dispatch('syncProfile');
      await dispatch('syncCoupon');
      await dispatch('syncGift');
      commit('SET_LAST_TS_SYNC_USERINFO', new Date().valueOf());
    }
  },

  async syncAuthInfo({ commit, state }) {
    const { data } = await getInfoFromAuth(state.token)
    const { email, profile_id } = data

    commit('SET_EMAIL', email)
    commit('SET_PROFILE_ID', profile_id)
  },

  async logout({ commit }) {
    await logout();
    removeToken();
    commit('RESET_STATE');
  },

  async initByData({ commit }, {form, query}) {
    const { data } = await initUserinfo(form, query)
    commit('SET_TOKEN', data.access_token)
    setToken(data.access_token)
  },

  async setAttribute({ dispatch }, attribute) {
    await setAttribute(attribute)
    await dispatch('syncAttribute')
  },

  async checkLoginBonus({ commit }){
    const{ data } = await getLoginBonusInfo();
    commit('SET_DAILY_FIRST_FLAG', data.daily_first_flag);
  },

  async syncAttribute({ commit, rootState }) {
    const { data } = await getAttribute();
    const attribute = data[0]
    let attribute_str = '無属性';
    if (rootState.system.enum_list.attribute_list.FIRE === attribute) {
      attribute_str = '炎属性';
    } else if (rootState.system.enum_list.attribute_list.ICE === attribute) {
      attribute_str = '氷属性';
    } else if (rootState.system.enum_list.attribute_list.WIND === attribute) {
      attribute_str = '風属性';
    } else if (rootState.system.enum_list.attribute_list.EARTH === attribute) {
      attribute_str = '地属性';
    }

    commit('SET_ATTRIBUTE', {
      attribute,
      attribute_str,
    });
  },

  async syncGift({ rootState, state, commit }) {
    const attributeGiftList = ["1-18-0", "1-18-1", "1-18-2", "1-18-3", "1-18-4", "5-1-1", "5-2-1", "5-3-1", "5-4-1", "6-0-1", "6-1-1", "6-2-1", "6-3-1", "6-4-1", "7-1-1", "7-2-1"]
    const removeNormalGiftList = ["1-14-1", "1-16-1"];
    const removeEventGiftList = ["1-14-1", "1-16-1"];
    const removePayGiftList = ["1-14-1", "1-15-1"];
    const removePayNormalGiftList = ["1-14-1", "1-16-1"];

    const costList = rootState.system.config.gift_cost_list;
    const defaultGiftCostList = getDefaultGiftList();

    function getGiftDetail(list) {
      return sortBy(list.map(v => {
        let obj = {};
        obj.id = v;
        // default amount 1
        obj.amount = '1',
        obj.name = defaultGiftCostList[v] ? defaultGiftCostList[v].name : null;
        obj.icon = defaultGiftCostList[v] ? require("../../assets/gift-icon/" + defaultGiftCostList[v].icon) : null;
        obj.payKeys = Object.keys(costList[v]);
        Object.assign(obj, costList[v]);
        return obj;
      }), v => {
        return v[v.payKeys[0]]
      });
    }

    let gift_normal = getGiftDetail(rootState.system.config.allowed_gift_list.normal.filter(v => !attributeGiftList.includes(v)).filter(v => !removeNormalGiftList.includes(v)));
    let gift_event = getGiftDetail(rootState.system.config.allowed_gift_list.event.filter(v => !attributeGiftList.includes(v)).filter(v => !removeEventGiftList.includes(v)));
    let gift_pay = getGiftDetail(rootState.system.config.allowed_gift_list.pay.filter(v => !attributeGiftList.includes(v)).filter(v => !removePayGiftList.includes(v)));
    let gift_pay_normal = getGiftDetail(rootState.system.config.allowed_gift_list.pay_normal.filter(v => !attributeGiftList.includes(v)).filter(v => !removePayNormalGiftList.includes(v)));
    let gift_attribute_booster = getGiftDetail(["1-18-0", "1-18-1", "1-18-2", "1-18-3", "1-18-4"].filter(v => rootState.system.config.gift_special_require_list[v].attribute === state.attribute));
    let gift_attribute_animation = getGiftDetail(["5-1-1", "5-2-1", "5-3-1", "5-4-1"].filter(v => rootState.system.config.gift_special_require_list[v].attribute === state.attribute));
    let gift_attribute_boss = getGiftDetail(["6-1-1", "6-2-1", "6-3-1", "6-4-1"].filter(v => rootState.system.config.gift_special_require_list[v].attribute === state.attribute));
    let gift_attribute_all_booster = getGiftDetail(["1-18-0"]);
    let gift_attribute_all_boss_boy = getGiftDetail(["7-1-1"]);
    let gift_attribute_all_boss_girl = getGiftDetail(["7-2-1"]);
    if (gift_attribute_booster.length === 0) {
      gift_attribute_booster = getGiftDetail(["1-18-0"]);
    }

    function heartBoxFirst(list) {
      return sortBy(list, v => {
        return v.c3;
      });
    }
    gift_normal = heartBoxFirst(gift_normal);
    gift_event = heartBoxFirst(gift_event);
    gift_pay_normal = heartBoxFirst(gift_pay_normal);

    const result = {
      gift_normal,
      gift_event,
      gift_pay,
      gift_pay_normal,
      gift_attribute_booster,
      gift_attribute_animation,
      gift_attribute_boss,
      gift_attribute_all_booster,
      gift_attribute_all_boss_boy,
      gift_attribute_all_boss_girl
    };
    commit('SET_GIFT', result);
  },

  async syncProfile({ state, commit }) {
    const { data } = await getProfile(state.profile_id);
    commit('SET_PROFILE', data);
  },

  async addFollowing(obj, id) {
    await addFollowing(id);
  },

  async removeFollowers(obj, id) {
    await removeFollowers(id);
  },

  async syncCoupon({ commit }) {
    const { data } = await showCoupon();
    commit('SET_COUPON', data);
  },

  async sendGift({ rootState, dispatch }, data) {
    let ret = await sendGift({
      "gift": {
          "type": data.id,
          "amount": data.amount || 1
      },
      "coupon": data.coupon || data.payKeys[0]
      }, rootState.live.info);
    await dispatch('syncCoupon');
    await dispatch('syncProfile');
    return ret;
  },

  async setFollowingId({commit},data) {
    commit('SET_KEYS_FOLLOWING',data);
  },
  async resetDailyFirstFlag({commit}) {
    commit('RESET_DAILY_FIRST_FLAG');
  },
  async setChatRoomInvitationStatus({commit},value){
    const {data} = await updateRoomInvitationStatus(value)
    if(data[0]){
      commit('SET_CHATROOM_INVITATION_STATUS',value)
    }
  },
  async setProfileAvator({commit},value) {
    commit('SET_PROFILE_AVATOR_STORE_PATH',value)
  },
  async setProfileBackground({commit},value) {
    commit('SET_PROFILE_BACKGROUND_STORE_PATH',value)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

