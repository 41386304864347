<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(:class="ui.tab === 'PR' ? 'content-room' : 'content-room content-room-dn'")
        div(class="build-new-room")
          div(class="bread-list") >>
            span(class="display-title")  ルーム一覧
          div(class="build-new-room-item")
            div(@click="onClickCreate()" class="pointer") <img class="button-img2" src="/img/button/new_sakusei.png">

        div(class="room-list-type") 作成したルーム一覧
        table(class="room-list")
          tr(class="room-list-item" v-for="item in listRoom1")
            div(@click="onClickRoom(item, true)" class="room-img")
              img(:src="$store.state.system.config.storage_addr_list.public + item.image_store_path")
            div(class="room-detail")
              div(class="room-name") {{ item.name }}
              div(class="room-info")
                div(class="room-builder") {{ item.profile.account_name }}
                div(class="room-period") {{item.created_at  | moment("YYYY/MM/DD HH:mm")}}～{{item.end_time  | moment("YYYY/MM/DD HH:mm")}}

        div(class="room-list-type") 参加しているルーム一覧
        table(class="room-list")
          tr(class="room-list-item" v-for="item in listRoom2")
            div(@click="onClickRoom(item)" class="room-img")
              img(:src="$store.state.system.config.storage_addr_list.public + item.image_store_path")
            div(class="room-detail")
              div(class="room-name") {{ item.name }}
              div(class="room-info")
                div(class="room-builder") {{ item.profile.account_name }}
                div(class="room-period") {{item.created_at  | moment("YYYY/MM/DD HH:mm")}}～{{item.end_time  | moment("YYYY/MM/DD HH:mm")}}

        div(class="room-list-type-notification")
          div(class="room-list-type-notification-underline") 承認待ちルーム一覧
          div(class="pointer")
            div(class="notification-switch notification-switch-blue" v-if="canBeInvited" @click="onSwitchRoomNoti(false)") 通知オン
            div(class="notification-switch" v-if="!canBeInvited" @click="onSwitchRoomNoti(true)") 通知オフ
        table(class="room-list waiting-for-approval")
          tr(class="room-list-item" v-for="item in listRoom3")
            div(class="room-img")
              img(:src="$store.state.system.config.storage_addr_list.public + item.image_store_path")
            div(class="room-detail")
              div(class="room-name") {{ item.name }}
              div(class="room-info")
                div(class="room-builder") {{ item.profile.account_name }}
                div(class="room-period") {{item.created_at  | moment("YYYY/MM/DD HH:mm")}}～{{item.end_time  | moment("YYYY/MM/DD HH:mm")}}
                div
                  //- a >>通知オン
                div(class="approval-button")
                  button(class="gray-button" @click="onJoin(item)" v-preventReClick="3000") 承認する

      div(v-if="ui.tab === 'PR'")
        PRBanner
      div(v-if="ui.tab !== 'PR'")
        div(v-if="showProfilePanel" class="pos-r")
          component-profile-index(:profile_id="showingProfileId" @close="onCloseProfilePanel")
        div(v-if="!showProfilePanel" class="right-content-border")
          div(class="container-XXX")
            div(class="bread-crumb-sp")
              span(@click="onClose()" class="back-to-room-list") ルーム一覧
              span >>
              span(class="action-type")  {{ ui.tab === 'Create' ? '新規作成' : ui.tab === 'Room' ? 'ルーム' : ui.tab === 'Edit' ? 'ルーム編集' : 'ルーム削除' }}
            button(@click="onClose()" class="close-button build-new-room-content-close") X
            div(v-if="ui.tab === 'Create' || ui.tab === 'Edit'")
              div(class="room-content-title create-update") {{ ui.tab === 'Create' ? 'ルームを作成する' : 'ルーム内容を変更する' }}
              div(class="build-new-room-content")
                div(class="build-new-room-content-item")
                  span(class="") ルーム画像
                  img(class="room-img-thumbnail" v-if="image_store_path" :src="getRoomImage(image_store_path)")
                  div(id="build-new-room-img-view" class="build-new-room-img-view")
                    input(id="fileUpload" type="file" v-show="false" @change="onUpload")
                    label(@click="chooseFile()") <img class="button-img2 pointer" src="/img/button/file_select.png">

                  div(class="caution-t") ※比率16:9（横長）、500KB以上の際は圧縮いたします
                  div(class="caution-t") （推奨サイズ：615×346ピクセル、500KB以下）

                div(class="build-new-room-content-item")
                  span ルーム名
                  input(v-model="roomName" type="text" class="input-text")
                  p(v-if="roomName.length > 40" class="room-name-alert") ※ルーム名は40文字以下にしてください

              div(class="room-content-title") フォローユーザーから誘う
              div(v-if="listUser.length > 0" class="build-new-room-content-invite")
                div(v-for="(item, index) in listUser" class="build-new-room-content-invite-item")
                  //- div(class="invite-item-img")
                    //- img(:src="$store.state.system.config.storage_addr_list.public + item.avatar_store_path")
                  div(@click="onAvatarClick(item.id)")
                    pan-thumb(:avatar="getAvatarUrl(item)" :current_title="getCurrentTitle(item)" :attribute="getAttribute(item)"  size="60px")
                  div(class="invite-item-right")
                    div(class="invite-item-name") {{ item.account_name }}
                    div(v-if="listUserStatus[item.profileable.viewer_id] && listUserStatus[item.profileable.viewer_id].user_status == 's3'" @click="onDel(item)") <img class="button-img" src="/img/button/syonin_2.png">
                    div(v-else-if="listUserStatus[item.profileable.viewer_id]" @click="onDel(item)") <img class="button-img" src="/img/button/syonin_1.png">
                    div(v-else @click="onAdd(item)") <img class="button-img pointer" src="/img/button/syotai.png">
                a(v-if="isUserShowMore" style="cursor: pointer" @click="showMoreUsers()") >>もっと見る
              div(v-else) 検索したユーザーは見つかりませんでした。
              div(class="build-new-room-content-invite-search t-c m-t")
                div(class="build-new-room-content-invite-search-input")
                  div(class="kensaku-label") 検索
                    span(class="search-colon") :
                  input(type="text" class="input-text" v-model="searchText")
                div(@click="onSearch()") <img class="button-img2 pointer" src="/img/button/kensaku.png">
              div(class="build-new-room-build-button")
                div(@click="onCreateOrUpdate()" v-preventReClick="2000") <img class="button-img2 pointer" :src="ui.tab === 'Create' ? '/img/button/sakusei.png' : '/img/button/henkou.png'">


            div(v-if="ui.tab === 'Room'")
              div(class="talk-room-img")
                img(v-if="image_store_path" :src="getRoomImage(image_store_path)")
              div(class="talk-room-detail")
                div(class="talk-room-name") {{ roomName }}
                div(class="talk-room-period")
                  img(src="/img/clock_icon_green.svg")
                  span {{created_at  | moment("YYYY/MM/DD")}}～{{end_time  | moment("YYYY/MM/DD")}}
                div(v-if="isMyRoom" class="talk-room-option")
                  a(@click="onClickEdit()") >>ルームの編集
                  a(@click="onClickDelete()") >>ルームの削除
                div(v-else)
                  a(@click="onClickExit()") >>ルームから退出する
              div(class="send-comment")
                el-input(
                  type="textarea"
                  :rows="4"
                  placeholder="コメント入力欄"
                  v-model="ui.inputComment"
                  maxlength="100"
                  show-word-limit)
                div(@click="onCommentSend()" v-preventReClick="3000" style="margin-top:10px") <img class="button-img" src="/img/button/sousin.png">

              div(class="comment-list")
                div(class="comment-item" v-for="(item, index) in realtimeCommentList")
                  //- 参加メッセージ
                  div(v-if="isSystemMessage(item)" class="comment-item-system")
                    div(class="comment-item-comment") {{item.payload.Desc}}
                  div(v-else class="sent-message")
                    pan-thumb(:avatar="item.payload.Ext.AVATAR" :current_title="item.payload.Ext.CURRENT_TITLE" :attribute="item.payload.Ext.ATTRIBUTE"  size="60px")
                    div(class="comment-item-right")
                      div(class="comment-item-name") {{item.payload.Ext.NICKNAME}}
                      div(class="comment-item-comment") {{item.payload.Desc}}
                a(v-if="!commentListCompletedFlag" @click="showMore()") もっと表示
            div(v-if="ui.tab === 'Delete' || ui.tab === 'Exit'")
              div(v-if="ui.tab === 'Exit'" class="talk-room-img")
                img(:src="$store.state.system.config.storage_addr_list.public + image_store_path")
              div(class="room-content-title" v-if="ui.tab === 'Delete'") ルームを削除する

              div(class="talk-room-detail")
                div(class="talk-room-name") {{ roomName }}
                div(class="talk-room-period")
                  img(src="/img/clock_icon_green.svg")
                  span {{created_at  | moment("YYYY/MM/DD")}}～{{end_time  | moment("YYYY/MM/DD")}}


              div(v-if="ui.tab === 'Exit'") このルームから退出しますか？

              div(class="talk-room-exit-cation" v-if="ui.tab === 'Delete'")
                div 一度削除すると元に戻せません（ルームの内容も閲覧できなくなります）
                div 本当にこのルームを削除してもよろしいですか？
              div(class="talk-room-exit-cation" v-else)
                div ※退出すると今までの内容は全て閲覧できなくなります
              div(class="talk-room-exit-cation-button")
                div(@click="ui.tab === 'Delete' ? onDeleteConfirm() : onExitConfirm()") <img class="button-img" src="/img/button/yes.png">
                div(@click="onConfirmCancel()") <img class="button-img" src="/img/button/no.png">

    component-footer-index
</template>

<style src="./Room.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import Compressor from 'compressorjs';
import { uploadImage, searchUserList, createRoom, addUsers, delUsers, getRoomListS1, getRoomListS2, getRoomListS3, deleteRoom, setRoomExitS3, updateRoom, setRoomEnterS3, getRoomHistoryCommentsLimit, getMemberStatus } from '@/api/room'
import TIM from 'tim-js-sdk';
import VueScrollTo from 'vue-scrollto';
import _ from 'lodash';
import {getImageUrl} from '@/utils/tools'
import PanThumb from '@/components/PanThumb'
import { Input } from 'element-ui';

export default {
  name: "TopProfileRoom",
  components: {
    PRBanner,
    PanThumb,
    'el-input': Input,
  },
  data() {
    return {
      lastCommentData: undefined,
      reqTs: undefined,
      limit: 15,
      showProfilePanel:false,
      showingProfileId:0,
      ui: {
        tab: 'PR',
        inputComment: undefined,
      },
      isMyRoom: true,

      image_store_path: undefined,
      listUser: [],
      listUserStatus:{},
      searchText: '',
      roomName: '',
      roomId: undefined,
      created_at: undefined,
      end_time: undefined,
      message_group: undefined,
      listRoom1: [],
      listRoom2: [],
      listRoom3: [],
      groupCommentList: [],
      nextCommentListId: '',
      commentListCompletedFlag: true,
      followUserLimit: 5,
      followUserPagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 1,
      },
    }
  },
  computed: {
    realtimeCommentList() {
      return this.groupCommentList.slice(0).reverse();
    },
    canBeInvited(){
      return this.$store.state.user.profile.invited_room_mail === this.$store.getters.room_invited_status_list.ACCEPTED
    },
    isUserShowMore(){
      return this.followUserPagination.total > this.listUser.length
    },
  },
  async created() {
    await this.init();
    await this.$store.dispatch('live/timInit');
    this.$nextTick(() => {
      VueScrollTo.scrollTo('#comment-list-last', 100, {
        container: '#comment-list-root',
      })
    })
    this.addEventListenerToTIM();
  },
  async beforeDestroy() {
    // TIM
    await this.$store.dispatch('live/timLogout');
    this.removeEventListenerToTIM();
  },
  methods: {
    async init() {
      this.listRoom1 = (await getRoomListS1()).data.data;
      this.listRoom2 = (await getRoomListS3()).data.data;
      this.listRoom3 = (await getRoomListS2()).data.data;
    },
    async syncUser() {
      const ret1 = await searchUserList(this.$store.state.user.profile_id,"",this.followUserLimit,1);
      if( ret1.data.meta ){
        this.followUserPagination = ret1.data.meta.pagination;
      }
      this.listUser = ret1.data.data;
    },
    async onClickCreate() {
      this.initCreateData();
      await this.syncUser();
      this.ui.tab = 'Create'
    },
    async onClickRoom(item, isMyRoom = false) {
      this.reqTs = undefined;
      this.isMyRoom = isMyRoom;
      this.image_store_path = item.image_store_path;
      this.roomName = item.name;
      this.roomId = item.id;
      this.created_at = item.created_at;
      this.end_time = item.end_time;
      this.groupCommentList = [];
      await this.getCommentHistory(1);
      // console.log(item.message_group);
      this.message_group = item.message_group;
      try {
        await this.$store.dispatch('live/timLogin',item.sig);
      }catch(err) {
        this.$alert('ルームログインに失敗しました', {
          confirmButtonText: '確認',
        });
      }
      try {
        await this.$store.dispatch('live/timJoinGroup', this.message_group);
      }catch(err) {
        this.$alert('グループが解散されました', {
          confirmButtonText: '確認',
        });
      }
      this.ui.tab = 'Room'
    },
    async onClickEdit() {
      await this.syncUser();
      await this.syncMemberStatus()
      this.prevListUserStatus = _.cloneDeep(this.listUserStatus)
      this.ui.tab = 'Edit'
    },
    onClickDelete() {
      this.ui.tab = 'Delete'
    },
    onClickExit() {
      this.ui.tab = 'Exit'
    },
    onClose() {
      this.ui.tab = 'PR'
    },
    onCreateConfirm() {
      this.ui.tab = 'PR'
    },
    async onDeleteConfirm() {
      await deleteRoom(this.roomId);
      await this.init();
      this.ui.tab = 'PR'
    },
    async onExitConfirm() {
      await setRoomExitS3(this.roomId);
      await this.init();
      this.ui.tab = 'PR'
    },
    onConfirmCancel() {
      this.ui.tab = 'Room'
    },

    initCreateData() {
      this.image_store_path = undefined;
      this.listUser = [];
      this.listUserStatus = {};
      this.prevListUserStatus ={};
      this.searchText = '';
      this.roomName = '';
      this.message_group = undefined;

      this.roomId = undefined;
      this.created_at = undefined;
      this.end_time = undefined;
    },

    async syncMemberStatus(){
      const { data } = await getMemberStatus(this.roomId);
      this.listUserStatus = {};
      for (const key in data) {
        const temp = data[key];
        if( temp.user_status == "s2" || temp.user_status == "s3" ){
          this.listUserStatus[temp.user_id] = temp;
        }
      }
    },

    chooseFile() {
      document.getElementById("fileUpload").click();
    },

    async onUpload(event) {
      let _this = this;
      this.file = event.target.files[0];
      if (!this.file) {
        return;
      }
      //let imagSize = this.file.size;
      new Compressor(this.file, {
        convertSize: 500000, // 500KB
        maxWidth: 512,
        maxHeight: 280,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        async success(result) {
          var formData = new FormData();
          formData.append("file", result);
          // var formData = new FormData();
          // formData.append('file', result, result.name);
          if (formData) {
            try {
              const { data } = await uploadImage(formData);
              _this.image_store_path = data.store_path;
            }catch(e) {
              console.log(e)
              _this.$alert('アップロードに失敗しました。不正なファイル形式です。')
            }
          }
        },
        error(err) {
          console.log(err.message);
        }
        })
    },
    onAdd(item) {
      const viewer_id = item.profileable.viewer_id
      // 変更の時だけ招待を動的にする場合
      //if( this.ui.tab !== 'Create' ){
      //  addUsers(this.roomId,[viewer_id])
      //}
      this.$set(this.listUserStatus,viewer_id,{ user_id: viewer_id, user_status: "s2", is_new: true });
    },
    onDel(item){
      const viewer_id = item.profileable.viewer_id
      this.listUserStatus[viewer_id] = null;
      this.$delete(this.listUserStatus,viewer_id);
    },
    async onSearch() {
      const ret1 = await searchUserList(this.$store.state.user.profile_id, this.searchText,this.followUserLimit,1)
      if( ret1.data.meta ){
        this.followUserPagination = ret1.data.meta.pagination;
      }
      this.listUser = ret1.data.data.map(v => {
        return v;
      });
    },
    async showMoreUsers() {
      const ret1 = await searchUserList(this.$store.state.user.profile_id, this.searchText,this.followUserLimit,this.followUserPagination.current_page + 1)
      if( ret1.data.meta ){
        this.followUserPagination = ret1.data.meta.pagination;
      }
      const temp = ret1.data.data;
      for (const key in temp) {
        this.listUser.push(temp[key])
      }
    },

    async getCommentHistory(page) {
      let tempTs = this.reqTs;
      if( !tempTs ){
        tempTs = parseInt(new Date().valueOf());
      }
      const res = await getRoomHistoryCommentsLimit(this.roomId,tempTs,this.limit,page)
      this.lastCommentData = res.data;
      const commentList = res.data.data;
      let tempList = [];
      // 新しいものが後になるように配列を反転させておく
      commentList.reverse();
      for (const key in commentList) {
        // 1ページ目の最後なら最新のコメントとする
        if( page == 1 && key == commentList.length - 1 ){
          // プラスバッファ
          this.reqTs = commentList[key].created_at + 10;
        }
        tempList.push(commentList[key]);
      }
      // 古い物が先頭に来るように結合する
      this.groupCommentList = tempList.concat(this.groupCommentList);
      this.commentListCompletedFlag = true;
      // もっと見る
      if( res.data.meta.pagination.total > res.data.meta.pagination.count + (page - 1) * res.data.meta.pagination.per_page ){
        this.commentListCompletedFlag = false;
      }
    },

    async onCreateOrUpdate() {
      if (!this.roomName) {
        this.$alert('ルーム名を入力してください', {
          confirmButtonText: '確認',
        });
        return;
      }
      if (this.roomName.length > 40) {
        this.$alert('ルーム名は40文字以下にしてください', {
          confirmButtonText: '確認',
        });
        return;
      }
      if (!this.image_store_path) {
        this.$alert('ファイルをアップロードしてください。', {
          confirmButtonText: '確認',
        });
        return;
      }
      let data = {};
      try {
        if(this.ui.tab === 'Create') {
          const ret = await createRoom({
            name: this.roomName,
            image_store_path: this.image_store_path,
          });
          data = ret.data;
          // setRoomEnterS3(data.id);
        } else {
          const ret = await updateRoom({
            room_id: this.roomId,
            name: this.roomName,
            image_store_path: this.image_store_path,
          });
          data = ret.data;
        }
      } catch (err) {
        if (err.response.status === 422) {
          this.$alert('ルームが上限に達しているので作成できません', {
            confirmButtonText: '確認',
          })
        }
      }
      // メンバー追加、削除
      const toAddList = [];
      // メンバー追加
      for (const key in this.listUserStatus) {
        if( this.listUserStatus[key].is_new ){
          toAddList.push( this.listUserStatus[key].user_id )
        }
      }
      // 削除
      for (const key in this.prevListUserStatus) {
        if( !this.listUserStatus[key] ){
          await delUsers(this.roomId,this.prevListUserStatus[key].user_id)
        }
      }
      // 変更の時だけ招待を動的にする場合
      //if (toAddList.length > 0 && this.ui.tab === 'Create') {
      //  await addUsers(data.id, toAddList);
      //}
      if (toAddList.length > 0){
        await addUsers(data.id, toAddList);
      }
      if(this.ui.tab !== 'Create') {
        this.ui.tab = 'Room'
      }else {
        this.onClose();
      }
      await this.init();
    },
    // 承認する
    async onJoin(item) {
      await setRoomEnterS3(item.id);
      await this.init();
    },
    // コメント送信
    async onCommentSend() {
      if (!this.ui.inputComment) {
        return;
      }
      await this.$store.dispatch('live/timSendMessagePlainForChat', {
        id: this.roomId,
        messageGroup: this.message_group,
        text: this.ui.inputComment,
        type: 'text',
        avatar: this.$store.state.user.profile_avatar_store_path
      });
      this.ui.inputComment = '';
    },
    // もっと表示
    async showMore() {
      await this.getCommentHistory(this.lastCommentData.meta.pagination.current_page + 1)
    },

    addEventListenerToTIM() {
      this.$store.state.live.insTIM.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onTimFunc1);
    },

    removeEventListenerToTIM() {
      this.$store.state.live.insTIM.off(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onTimFunc1);
    },

    onTimFunc1(event) {
      const target = _.chain(event.data).find({conversationID: 'GROUP' + this.message_group}).value();
      if (target && target.lastMessage) {
        if (!_.find(this.groupCommentList, {lastSequence: target.lastMessage.lastSequence})) {
          if (target.lastMessage.fromAccount !== '@TIM#SYSTEM') {
            let temp = _.cloneDeep(target.lastMessage);
            if( (temp.lastTime * 1000) < this.reqTs ){
              return;
            }
            temp.payload.Ext = JSON.parse(temp.payload.extension)
            temp.payload.Desc = temp.payload.description;
            temp.payload.Data = temp.payload.data;
            this.groupCommentList.push(temp);
            // this.$nextTick(() => {
            //   VueScrollTo.scrollTo('#comment-list-last', 100, {
            //     container: '#comment-list-root',
            //   })
            // })
          }
        }
      }
    },
    async onSwitchRoomNoti(bOn){
      const status = bOn ? this.$store.getters.room_invited_status_list.ACCEPTED:this.$store.getters.room_invited_status_list.DISACCEPTED
      this.$store.dispatch('user/setChatRoomInvitationStatus',status)
    },
    getAvatarUrl(item){
      return (item.avatar_store_path)
    },
    getAttribute(item){
      return item.attribute
    },
    getCurrentTitle(item){
      return item.profileable.current_title
    },
    getRoomImage(url){
      return getImageUrl(url)
    },
    onAvatarClick(profile_id) {
      this.showProfilePanel = false;
      this.$nextTick(() => {
        this.showProfilePanel = true
        this.showingProfileId = profile_id
      })

      // let routeData = this.$router.resolve({
      //   name: 'TopProfileStyle2',
      //   params: { profile_id },
      // });
      // window.open(routeData.href, '_blank');
      // this.$router.push({ name: 'TopProfileStyle2', params: { profile_id } });
    },
    onCloseProfilePanel(){
      this.showProfilePanel = false
      this.showingProfileId = 0
    },
    isSystemMessage(msg){
      if( msg.payload.Ext.TYPE == "system" ){
        return true;
      }
      return false;
    }
  }
}
</script>
