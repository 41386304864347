export const LIVE_TYPE = {
  NORMAL: Symbol('NORMAL'),
  EVENT: Symbol('EVENT'),
  FUND: Symbol('FUND'),
  PAID: Symbol('PAID'),
}

export const PANEL_TYPE = {
  GIFT: Symbol('GIFT'),
  BUY: Symbol('BUY'),
  BUY_PAY: Symbol('BUY_PAY'),
  BUY_COIN: Symbol('BUY_COIN'),
  TARGET: Symbol('TARGET'),
  ADDRESS: Symbol('ADDRESS'),
  PROFILE: Symbol('PROFILE'),
  PAYMENT: Symbol('PAYMENT'),
}
export const province = [
  "", "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"
]

export const AtrributeList = [
  "5-1-1","5-2-1","5-3-1","5-4-1"
]

export const AtrributeBoosterIdList = [
  "1-18-1","1-18-2","1-18-3","1-18-4"
]

export const BeastList = [
  "6-1-1","6-2-1","6-3-1","6-4-1"
]

export const GodIdList = [
  "7-1-1","7-2-1"
]

export const boosterIdList = [
  "1-18-0","1-18-1","1-18-2","1-18-3","1-18-4"
]

export const penLightList = [
  "3-1-1-1",
  "3-1-1-2",
  "3-1-1-3",
  "3-1-1-4",
  "3-1-1-5",
  "3-1-1-6",
  "3-1-1-7",
  "3-1-1-8",
  "3-1-1-9",
  "3-1-1-10",
  "3-1-1-11",
  "3-1-2-1",
  "3-1-2-2",
  "3-1-2-3",
  "3-1-2-4",
  "3-1-2-5",
  "3-1-2-6",
  "3-1-2-7",
  "3-1-2-8",
  "3-1-2-9",
  "3-1-2-10",
  "3-1-2-11",
  "3-1-3-1",
  "3-1-3-2",
  "3-1-3-3",
  "3-1-3-4",
  "3-1-3-5",
  "3-1-3-6",
  "3-1-3-7",
  "3-1-3-8",
  "3-1-3-9",
  "3-1-3-10",
  "3-1-3-11",
]

export const penMovementList = [
  { value : 1, name: '縦振り'},
  { value : 2, name: '横振り'},
  { value : 3, name: 'ケチャ'}
]

export const penColorList = [
  { value : 1, name: '赤'},
  { value : 2, name: 'ピンク'},
  { value : 3, name: 'オレンジ'},
  { value : 4, name: '黄色'},
  { value : 5, name: '青'},
  { value : 6, name: '水色'},
  { value : 7, name: '緑'},
  { value : 8, name: '黄緑'},
  { value : 9, name: '紫'},
  { value : 10, name: '白'},
  { value : 11, name: '黒'},
]