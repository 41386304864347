const TOKEN_KEY = 'akipro_client_token_key_v1'
const TOKEN_SAVE = 'akipro_client_token_save_v1'
const TOKEN_SAVE_EMAIL = 'akipro_client_token_save_email_v1'

export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY)
}

export function setToken(token) {
  removeToken();
  localStorage.setItem(TOKEN_KEY, token)
  // if (getIsSaveFlag()) {
  //   localStorage.setItem(TOKEN_KEY, token)
  // } else {
  //   sessionStorage.setItem(TOKEN_KEY, token)
  // }
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY)
  sessionStorage.removeItem(TOKEN_KEY)
}

export function setIsSaveFlag(flag, email) {
  if (flag) {
    localStorage.setItem(TOKEN_SAVE, flag)
    localStorage.setItem(TOKEN_SAVE_EMAIL, email)
  } else {
    localStorage.removeItem(TOKEN_SAVE)
    localStorage.removeItem(TOKEN_SAVE_EMAIL)
  }
}

export function getIsSaveFlag() {
  return localStorage.getItem(TOKEN_SAVE)
}

export function getEmailSaved() {
  return localStorage.getItem(TOKEN_SAVE_EMAIL)
}