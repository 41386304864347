import request from '@/utils/request'

export function sendGift(data, liveInfo) {
  let apiType = '';
  if (liveInfo.liveable_type === "FUND") {
    apiType = 'broadcast_funds';
  } else if (liveInfo.liveable_type === "PAY") {
    apiType = 'broadcast_pays';
  } else if (liveInfo.liveable_type === "NORMAL") {
    apiType = 'broadcast_normals';
  } else if (liveInfo.liveable_type === "EVENT") {
    apiType = 'broadcast_events';
  }
  return request({
      url: `/${apiType}/${liveInfo.liveable_id}/lives/${liveInfo.id}/gift_logs`,
      method: 'post',
      data,
  })
}
