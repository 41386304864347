<template lang="pug">
.root
  component-header-index(v-if="$store.state.user.email")
  component-header-login(v-if="!$store.state.user.email")

  .container
    .breadCrumbs
      span ログイン >>
        sapn(class="display-title") お問い合わせ

    .formContainer
      div(v-if="!ui.isConfirm && !ui.isFinished")
        p.item
          span.itemTitle お問合せ内容
          span.asterisk ＊
        select.selectArea(v-model="form.type")
          option(v-for="option in ui.type", :value="option") {{ option }}
        br/
        p.item
          span.itemTitle お名前
          span.asterisk ＊
        input.inputArea(v-model="form.name")
        br/
        p.item
          span.itemTitle 会社名
        input.inputArea(v-model="form.company")
        br/
        p.item
          span.itemTitle 業種
        select.selectArea(v-model="form.work")
          option(v-for="option in ui.work", :value="option") {{ option }}
        br/
        p.item
          span.itemTitle 郵便番号
        input.postalCodeArea(v-model="postcode1")
        span.postalCodetext -
        input.postalCodeArea(v-model="postcode2")
        br/
        p.item
          span.itemTitle 住所
        input.inputArea(v-model="form.address")
        br/
        p.item
          span.itemTitle 電話番号
        input.inputArea(v-model="form.phone")
        br/
        p.item
          span.itemTitle メールアドレス
          span.asterisk ＊
        input.inputArea(v-model="form.email" autocapitalize="off")
        br/
        p.item
          span.itemTitle メールアドレス[確認用]
          span.asterisk ＊
        input.inputArea(v-model="form.emailConfirm" autocapitalize="off")
        br/
        p.item
          span.itemTitle 本文
          span.asterisk ＊
        div.textarea_content
          textarea.textArea(
            v-model="form.content",
            type="text",
            maxlength="300",
            rows="3"
          )
          p.textCount {{ form.content.length }}/300

        .asteriskExplanation
          span.asterisk ＊
          span は必須項目です。スペースは入れずにご入力ください。

        div(class="no-robot")
          vue-recaptcha(ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            :sitekey="getSiteKey()")

        .buttonArea
          .errorMessageArea
            .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}
          div(@click="onOK()") <img src="/img/kakunin.png">


      div(v-if="ui.isConfirm")
        .confirmContainer
          p.item
            span.itemTitle お名前
          p.inputArea {{ form.name }}
          br/
          p.item
            span.itemTitle 会社名
          p.inputArea {{ form.company }}
          br/
          p.item
            span.itemTitle 業種
          p.inputArea {{ form.work }}
          br/
          p.item
            span.itemTitle 問合せ内容
          p.inputArea {{ form.type }}
          br/
          p.item
            span.itemTitle 郵便番号
          p.inputArea {{ form.postcode }}
          br/
          p.item
            span.itemTitle 住所
          p.inputArea {{ form.address }}
          br/
          p.item
            span.itemTitle 電話番号
          p.inputArea {{ form.phone }}
          br/
          p.item
            span.itemTitle メールアドレス
          p.inputArea {{ form.email }}
          br/
          p.item
            span.itemTitle 本文
          p.inputArea(style="word-wrap: break-word") {{ form.content }}
          br/
          p.submit この内容で送信しますか？
        .buttonArea
          div( class="send_icon" @click="onConfirm()" ) <img src="/img/soushin.png">
          br/
          div( class="send_icon" @click="onBack()" ) <img src="/img/modoru.png">

      div(v-if="ui.isFinished")
        .sendText お問い合わせを承りました。担当者より連絡させていただきます。
        .buttonArea
          button.login-button(@click="onReturnLogin()" v-if="!$store.state.user.email") ログインページへ戻る
          button.login-button(@click="onReturnLogin()" v-if="$store.state.user.email") TOPページへ戻る

  component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { addContact } from '@/api/contact'
import VueRecaptcha from 'vue-recaptcha'
import { isNum } from '@/utils/inputLimit';

export default {
  name: "LoginContactIndex",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      ui: {
        isConfirm: false,
        showError: false,
        isFinished: false,
        errorMessage: "",
        work: [
          "[選択]",
          "芸能事務所",
          "ライバー事務所",
          "フリータレント",
          "メディア",
          "その他",
        ],
        type: [
          "[選択]",
          "配信登録を希望する",
          "取材・掲載を希望する",
          "その他",
        ],
      },
      form: {
        name: "",
        company: "",
        work: "[選択]",
        type: "[選択]",
        postcode: "",
        address: "",
        phone: "",
        email: "",
        content: "",
      },
      emailConfirm: "",
      postcode1: "",
      postcode2: "",
      recaptcha: false,
    };
  },
  props: {},
  computed: {},
  watch: {
    postcode1() {
      this.form.postcode = this.postcode1 + this.postcode2;
    },
    postcode2() {
      this.form.postcode = this.postcode1 + this.postcode2;
    },
  },
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    onOK() {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      const regPostcode = /^\d{7}$/;
      if (this.form.company_name == undefined) {
        this.form.company_name = this.form.company;
      }
      if (this.form.email && this.form.emailConfirm !== this.form.email) {
        this.ui.errorMessage = "※確認用のメールアドレスが一致していません";
        this.ui.showError = true;
        return;
      } else if (
        this.form.postcode &&
        !regPostcode.test(this.form.postcode)
      ) {
        this.ui.errorMessage = "郵便番号(7桁)で入力して下さい";
        this.ui.showError = true;
        return;
      } else if (this.form.phone && (this.form.phone.length > 11 || !isNum(this.form.phone))) {
        this.ui.errorMessage = "電話番号は半角数字11桁以内、記号を使用せずに入力してください"
        this.ui.showError = true;
        return;
      }  else if (
        !this.form.email
      ) {
        this.ui.errorMessage = "※メールアドレスが入力されていません";
        this.ui.showError = true;
        return;
      } else if (
        !this.form.name ||
        this.form.type === "[選択]" ||
        !this.form.content
      ) {
        this.ui.errorMessage = "＊は必須項目です、必ず入力してください";
        this.ui.showError = true;
        return;
      }  else if (
        !regEmail.test(this.form.email)
      ) {
        this.ui.errorMessage = "※メールアドレスが正しい形式ではありません";
        this.ui.showError = true;
        return;
      }
      if (!this.recaptcha) {
        this.ui.errorMessage = "※「私はロボットではありません」にチェックを入れてください";
        this.ui.showError = true;
        return;
      }
      this.ui.isConfirm = true;
    },
    async onConfirm() {
      await addContact({
          name: this.form.name,
          company_name: this.form.company,
          business_type: this.form.work,
          expectation: this.form.type,
          address: this.form.address,
          phone_number: this.form.phone,
          email: this.form.email,
          postal_code: this.form.postcode ? this.form.postcode : null,
          content: this.form.content,
      });
      this.ui.isConfirm = false;
      this.ui.isFinished = true;
    },
    onBack() {
      this.ui.showError = false;
      this.ui.isConfirm = false;
      this.recaptcha =  false;
    },
    onReturnLogin() {
      this.$router.push({ name: "LoginIndex" });
    },
    onVerify(recaptcha) {
      this.recaptcha = recaptcha;
    },
    onExpired() {
      this.recaptcha = false;
    },
    getSiteKey() {
      return process.env.VUE_APP_ENV === `DEV` ? `6Legtq8aAAAAAFoVMWr9rl7-TnyJYiNJOip0D4HO` : `6LccCbcaAAAAALOM0Kdw_muzKpg2UZ16GEC6scXV`
    }
  },
};
</script>
