import request from "@/utils/request";

export function createPaymentCard(token) {
  return request({
    url: `/payment/card?token=${token}`,
    method: "post",
  });
}

export function createPaymentCredit(cardSeq, items) {
  return request({
    url: `/payment/credit?card_seq=${cardSeq}&method=1`,
    method: "post",
    data: {
      items
    }
  });
}

export function getStripeData() {
  return request({
    url: `/payment/card`,
    method: "get",
  });
}

export function DeleteCredit() {
  return request({
    url: `/payment/card?card_seq=0`,
    method: "delete",
  });
}

export function getPolling(orderID, type) {
  return request({
    url: `/payment/polling?order_id=${orderID}&type=${type}`,
    method: "get",
  });
}

export function getPollingFund(orderID, type) {
  return request({
    url: `/payment/polling?order_id=${orderID}&type=${type}&live_type=FUND`,
    method: "get",
  });
}