import request from '@/utils/request'

export function createCouponGmoCvs(customerData, items) {
    return request({
        url: `/coupon/cvs`,
        method: 'post',
        data: {
            customer_name: customerData.customer_name,
            customer_kana: customerData.customer_kana,
            tel_no: customerData.tel_no,
            convenience: customerData.convenience,
            mail: customerData.mail,
            items
        },
    })
}
export function createCouponGmoPayeasy(customerData, items) {
    return request({
        url: `/coupon/easy`,
        method: 'post',
        data: {
            customer_name: customerData.customer_name,
            customer_kana: customerData.customer_kana,
            tel_no: customerData.tel_no,
            mail: customerData.mail,
            items
        },
    })
}

export function createCouponGmoAp(items) {
    return request({
        url: `/coupon/gmo`,
        method: 'post',
        data: {
            items
        },
    })
}

export function createCouponStripe(successUrl, cancelUrl, items) {
    return request({
        url: `/coupon`,
        method: 'post',
        data: {
            method_types: ["card"],
            success_url: successUrl,
            cancel_url: cancelUrl,
            items
        },
    })
}

export function showCoupon() {
    return request({
        url: `/coupon`,
        method: 'get',
    })
}
export function updateCouponHeart(LiveId) {
    return request({
        url: `coupon_heart_present/${LiveId}`,
        method: 'put',
    })
}