<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-serch")
      div(class="bread-list") >>
        span(class="display-title")  検索
        div(:class="ui.tab === 'PR' ? 'accountList-content' : 'accountList-content accountList-content-dn'")
          <div v-for="item in list" class="accountList" :style="item.profileable_type === `VIEWER` ? `background-color: white;` : `background-color: #FFF799;`">
            <li class="serch_account_img" style="list-style-type:none;" @click="item.profileable_type === `VIEWER` ? onAvatarClickViewer(item.id) : onAvatarClickStreamer(item.id)">
              <pan-thumb :avatar="getAvatar(item)" :attribute="item.attribute" :current_title="item.profileable.current_title" :isStreamer="isStreamer(item)" size="120px"/>
            </li>
            <p @click="item.profileable_type === `VIEWER` ? onAvatarClickViewer(item.id) : onAvatarClickStreamer(item.id)">{{ item.account_name }} </p>
            <button v-if="keysFollowing.includes(item.id)" @click="onFollowCancel(item.id)" class="gray-button"></button>
            <button v-else @click="onFollow(item.id)" class="pink-button"></button>
          </div>
        a(v-if="haveMore" class="next-view" @click="onShowMore()") >>続きを表示

      .pr-list
        PRBanner(v-if="ui.tab === 'PR'")
      div(v-if="ui.tab === 'profile'" class="pos-r prof-content")
        component-profile-index(v-if="ui.profile" :profile_id="profile_id" @close="onClose")

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getProfileListByAccountName } from '@/api/profile'
import PRBanner from '@/components/PRBanner'
// import { getImageUrl } from '@/utils/tools'
import PanThumb from '@/components/PanThumb'

export default {
  name: "LoginProfileIndex",
  components: {
    PRBanner,
    PanThumb
  },
  data() {
    return {
      ui: {
        profile: false,
        tab: 'PR',
      },
      profile_id: -1,
      list: [],
      meta:{pagination:{}},
      listQuery: {
        search:'',
        page: 1,
        limit: 15,
      },
    }
  },
  props: {},
  computed: {
    haveMore(){
      return this.meta.pagination.current_page < this.meta.pagination.total_pages
    },
    keysFollowing() {
      return this.$store.state.user.keysFollowing;
    }
  },
  watch: {
      async '$route.query.content'() {
          await this.init();
      }
  },
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      this.list = []
      this.listQuery.page = 1
      this.listQuery.search = `account_name:${this.$route.query.content}`
      // this.listQuery.introduction = this.$route.query.content
      this.getList()
      // const { data } = (await getProfileListByAccountName(this.listQuery))
      // this.list = data.data;
      // this.meta = data.meta;
      // this.keysFollowing = data.meta.following_id_list;
    },

    async getList(){
      const { data } = await getProfileListByAccountName(this.listQuery)
      this.list.push.apply(this.list,data.data);
      let listId = this.keysFollowing
      listId.push.apply(listId,data.meta.following_id_list)
      await this.$store.dispatch("user/setFollowingId",listId)
      this.meta = data.meta;
    },

    async onShowMore(){
      if(this.haveMore){
        this.listQuery.page = this.meta.pagination.current_page + 1
        this.getList()
      }
    },
    async onFollow(id) {
      await this.$store.dispatch("user/addFollowing", id);
      let listId = this.keysFollowing;
      listId.push(id);
      await this.$store.dispatch("user/setFollowingId",listId);
    },
    async onFollowCancel(id) {
      await this.$store.dispatch("user/removeFollowers", id);
      await this.$store.dispatch("user/setFollowingId",this.keysFollowing.filter(item => item != id));
    },
    isStreamer(item){
      return item.profileable_type === 'STREAMER';
    },
    getAvatar(item){
      return (item.avatar_store_path)
    },

    onAvatarClickStreamer(profile_id) {
      let routeData = this.$router.resolve({
        name: 'TopProfileStyle2',
        params: { profile_id } ,
        query: { profile_id }
      });
      window.open(routeData.href, "_blank")
    },
    onAvatarClickViewer(profile_id) {
      this.ui.profile = false;
      this.ui.tab = 'profile'

      this.$nextTick(() => {
        this.profile_id = profile_id;
        this.ui.profile = true;
      })
    },
    onClose() {
      this.ui.tab = 'PR'
    },
  }
};
</script>
