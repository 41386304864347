import request from '@/utils/request'

// クレジットカード(GMO PG)
export function sendCoinCard(data, liveInfo) {
  return request({
      url: `/broadcast_funds/${liveInfo.liveable_id}/lives/${liveInfo.id}/gift_logs/payment`,
      method: 'post',
      data,
  })
}

// クレジットカード(GMO こんど払い)
export function sendCoinGmoAp(data, liveInfo) {
  return request({
      url: `/broadcast_funds/${liveInfo.liveable_id}/lives/${liveInfo.id}/gift_logs/payment/gmo`,
      method: 'post',
      data,
  })
}
