<template lang="pug">
.bottomNav
  div(class ="bottomNav-img")
    img(src="/img/logo.png")
  ul
    Li
      a(@click="$router.push({ name: 'TopTermsManual' })") ヘルプ
    Li
      a(@click="$router.push({ name: 'TopTermsTerms' })") 利用規約
    Li
      a(@click="$router.push({ name: 'TopTermsPrivacy' })") プライバシーポリシー
    Li
      a(@click="$router.push({ name: 'TopTermsTokushoho' })") 特定商取引法に基づく表記
    Li(class ="bottomNav-twitter-img" @click="onTwitter()")
      img(src="/img/twitter.png")


  <div class="copyright">Copyright © 2021 Aライブ All Rights Reseved.</div>
</template>

<style src="./footer.scss" lang="scss" scoped>
</style>

<script>
export default {
  name: "ComponentsFooterLogin",
  components: {},
  data() {
    return {};
  },
  methods: {
    onTwitter() {
      window.open('https://twitter.com/a_live_info', '_blank');
    },
  },
};
</script>