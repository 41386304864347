<template lang="pug">
  .root
    div イベントバナー
    div 1位
    img(src="/img/logo.png")
    div ｱｶｳﾝﾄ名
    div 2位
    img(src="/img/logo.png")
    div ｱｶｳﾝﾄ名
    div 3位
    img(src="/img/logo.png")
    div ｱｶｳﾝﾄ名
</template>

<style src="./Block.scss" lang="scss" scoped>
</style>

<script>
export default {
  name: "TopEventComponentBlock",
  components: {},
  data() {
    return {
    }
  },
  props: {
  },
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
  }
};
</script>
