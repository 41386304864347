<template lang="pug">
  .root
    <div class="liveStatus">
      <li v-if="isPay"><img src="/img/yuryo.png"></li>
      <li v-if="isFund"><img src="/img/crfun.png"></li>
    </div>
    <table style="border-collapse: collapse; width: 265px; height: 60px; border-style: hidden;" border="1">
      <tbody>
        <tr style="height: 22px;">
          <td style="width: 90px; height: 30x; border-style: hidden;" rowspan="2"><img :src="roomBanner" class="banner"></td>
          <td style="width: 157px; height: 22px;"><b style="color:#4b4b4b">{{ roomName }}</b></td>
        </tr>
        <tr style="height: 30px;">
          <td style="width: 90px; height: 30px;">
          <p><img src="/img/clock_icon_green.svg"><b style="color:#4b4b4b">{{ roomPeriod | moment("YYYY/MM/DD HH:mm") }}</b></p>
        </td>
        </tr>
      </tbody>
    </table>
</template>

<script>
import ListItemComputed from './component/ListItemComputed'

export default {
  name: 'LiveScheduleItem',
  components: {},
  mixins: [ListItemComputed],
  props: {
    data:{
      type: Object,
      required: true
    },
  },
  data() {
    return {
      SchedulePanelShow : false,
    }
  },
  created(){},
}
</script>

<style src="./LiveScheduleItem.scss" lang="scss" scoped>
</style>
