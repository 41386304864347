<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-paymentformpayeasy")

        div ペイジー決済（Pay-easy）の必要事項を入力してください。

        div(class="payeasyForm")
          h5 お客様の氏名
          input(v-model="customerData.customer_name")
          br
          h5 お客様のフリガナ
          input(v-model="customerData.customer_kana")
          br
          h5 お客様の電話番号
          input(v-model="customerData.tel_no")
          br
          h5 お客様のメールアドレス
          input(v-model="customerData.mail" autocapitalize="off")
          br


        .buttonArea
          .errorMessageArea
            .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}
          button.pink-button.onNextButton(@click="onConfirmGmoPayeasy()") 次へ
        
        div(class="coin-buy-footer-button")
          button(@click="onBack()" class="pink-button") 戻る

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormPayeasy.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'

export default {
  name: "TopProfilePaymentFormPayeasy",
  components: {
    PRBanner,
  },
  data() {
    return {
      ui: {
        errorMessage: "Error",
        showError: false,
      },
      customerData: {
        customer_name: '',
        customer_kana: '',
        tel_no: '',
        mail: '',
      },
      buyItemList: [],
      buyType: '',
      successUrl: '',
      cancellUrl: '',
    }
  },
  async created() {
    this.buyItemList = this.$route.params.buyList;
    if( !this.buyItemList ){
      this.$alert('商品が正しく選択されていません。')
      this.$router.push({ name: 'TopProfileBuy'});
      return;
    }
  },
  methods: {
    async onConfirmGmoPayeasy() {
      if( 
        !this.customerData.customer_name ||
        !this.customerData.customer_kana ||
        !this.customerData.tel_no ||
        !this.customerData.mail
      ){
        this.ui.showError = true;
        this.ui.errorMessage = "未入力箇所があります。必ず入力してください。";
        return;
      }
      const customerData = this.customerData;
      const buyList = this.buyItemList;
      this.$router.push({ name: 'TopProfilePaymentFormPayeasyConfirm', params: {
        customerData,
        buyList,
      } });
    },

    onBack() {
      this.$router.push({
        name: 'TopProfileBuy'
      })
    },

    onClick1() {
      this.$router.push({ name: 'TopTermsTokushoho' });
    }
  }
}
</script>
