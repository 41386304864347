<template lang="pug">
  .root
    component-header-login
    .login-center
      .breadCrumbs ログイン >>
        span(class="display-title") パスワードを忘れた方はこちら
        <br><br>
      .leftContainer
        div パスワードを変更するとサイトから自動ログアウトされますので、変更したパスワードで再度ログインしてください。
          p 変更したパスワードは必ずメモなどして忘れないようにしてください。

        div ＜パスワードに関する注意事項＞
        div アカウント名＋誕生日など、個人情報から簡単に推測できるパスワードは大変危険です。
        div また、Aライブがメール等でパスワードを質問することは絶対にありません。
        div パスワードは第三者に教えないよう十分ご注意下さい。 
      .rightContainer
        .pink-label パスワード変更する
        .right-content-border
          div URLの発行が完了しました。本サイトに登録されている メールをご確認ください。
          div(style="color: red; padding: 8px 0px;") ※ パスワードの変更はまだ済んでいません
          button(@click="onReturn()" class="pink-button" style="width:100%;") ログインページに戻る
    
    component-footer-login
</template>

<style src="./Next.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "LoginResetNext",
  components: {},
  data() {
    return {}
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    onReturn() {
      this.$router.push({ name: 'LoginIndex' })
    }
  }
};
</script>
