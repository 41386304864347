<template lang="pug">
  .root(style="width: 100%;")
    div(v-if="this.showMessage.length !== 0" class="system-message" :class="{blink:isBlink}") {{ this.showMessage | moment("YYYY/MM/DD HH:mm")  }} からメンテナンスです。ご注意ください。
</template>

<style src="./index.scss" lang="scss" scoped>
</style>

<script>
import moment from 'moment'

export default{
  data() {
    return {
      showMessage: '',
      isBlink: false,
      timer: null,
    }
  },
  props: {
    type: {
      type: String,
      default() {
        return '';
      }
    }
  },
  async created() {
    await this.$store.dispatch('news/syncMaintenance');
    const beforeTime = this.type === 'live' ? 1 : 24;
    if(moment().add(beforeTime, 'h').format("YYYY-MM-DD HH:mm:ss") >= this.$store.state.news.maintenance){
        this.showMessage = this.$store.state.news.maintenance;
    }

    if (this.type === 'live') {
      this.timer = setInterval(() => {
        this.isBlink = moment().add(30, 'm').format("YYYY-MM-DD HH:mm:ss") >= this.$store.state.news.maintenance;
        if (this.isBlink) {
          this.clearTimer();
        }
      },1000)
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    clearTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  }
}
</script>
