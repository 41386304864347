import { getAdList } from '@/api/ad';

const getDefaultState = () => {
  return {
    list: [],
  }
}

const state = getDefaultState()

const mutations = {
  SET_LIST: (state, data) => {
    state.list = data
  },
}

const actions = {
  async getList({ commit }) {
    const { data } = await getAdList();
    commit('SET_LIST', data.data);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

