<template lang="pug">
  .root
    component-header-index
    <div class="base-window">
        component-profile-index(:profile_id="otherProfile_id" @close="onClose")
    </div>
    component-footer-index
</template>

<style src="./ViewerProfile.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "TopProfileViewerProfile",
  components: {
  },
  data() {
    return {
      otherProfile_id: '',
    }
  },
  props: {},
  computed: {
  },
  watch: {},
  mounted() {},
  async created() {
    this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const otherProfile_id = this.$route.params.profile_id;
      this.otherProfile_id = parseInt(otherProfile_id);
    },
    onClose() {
        window.close();
    }
  }
};
</script>
