<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")

      WaitingLoad

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentLoading.scss" lang="scss" scoped>
</style>

<script>
import WaitingLoad from '@/components/WaitingLoad';
import PRBanner from '@/components/PRBanner';

export default {
  name: "TopProfilePaymentLoading",
  components: {
    WaitingLoad,
    PRBanner,
  },
}
</script>
