import request from '@/utils/request'

export function getProfile(id) {
    return request({
        url: `/profiles/${id}`,
        method: 'get',
    })
}

export function getProfileListByIdList(idList) {
    return request({
        url: `/profiles?search=id:${idList.join(',')}`,
        method: 'get',
    })
}

export function getProfileListByAccountId(accountId) {
    return request({
        url: `/profiles?search=account_id:${accountId}`,
        method: 'get',
    })
}

export function getProfileListByAccountName(query) {
    return request({
        url: `/profiles`,
        method: 'get',
        params:query
    })
}

export function getProfileListByViewer() {
    return request({
        url: `/profiles/viewer`,
        method: 'get',
    })
}

export function getProfileListByStreamer() {
    return request({
        url: `/profiles/streamer`,
        method: 'get',
    })
}

export function getProfileListAll() {
    return request({
        url: `/profiles`,
        method: 'get',
    })
}

export function addFollowing(id) {
    return request({
        url: `/profiles/followings/${id}`,
        method: 'put',
    })
}

/**
 * 指定したIDがfollowしている人一覧を取得する
 * 15件ずつしか取れないので注意
 */
export function getFollowings(id,query) {
    return request({
        url: `/profiles/${id}/followings`,
        method: 'get',
        params:query
    })
}

export function getFollowers(id,query) {
    return request({
        url: `/profiles/${id}/followers`,
        method: 'get',
        params:query
    })
}

export function removeFollowers(id) {
    return request({
        url: `profiles/followings/${id}`,
        method: 'delete',
    })
}

export function setAttribute(attribute) {
    return request({
        url: `/profiles/attribute`,
        method: 'put',
        data: {
            "attribute": attribute
        }
    })
}

export function getAttribute() {
    return request({
        url: `/profiles/attribute`,
        method: 'get',
    })
}

export function getRankList(streamer_id) {
    return request({
        url: `/streamers/${streamer_id}/point_logs/ranks`,
        method: 'get',
    })
}

export function getLocation() {
    return request({
        url: `/location`,
        method: 'get',
    })
}

export function setLocation(data) {
    return request({
        url: `/location`,
        method: 'put',
        data,
    })
}

export function uploadAvatar(file) {
    let data = new FormData();
    data.append('file', file);
    return request({
        url: `/avatar`,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data,
    })
}

export function uploadBackground(file) {
    let data = new FormData();
    data.append('file', file);
    return request({
        url: `/background`,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data,
    })
}

export function setQuestionnaire(stream_id, answerId) {
    return request({
        url: `streamers/${stream_id}/questionnaire`,
        method: 'post',
        data: {
            "answer": answerId
        }
    });
}

export function getArchiveLive(streamer_id, limit, pay_limit) {
    return request({
        url: `streamers/${streamer_id}/archive_lives?limit=${limit}&pay_limit=${pay_limit}`,
        method: 'get',
    })
}

export function getListArchiveLive(streamer_id, limit, page) {
    return request({
        url: `streamers/${streamer_id}/list_archive_live?limit=${limit}&page=${page}`,
        method: 'get',
    })
}

export function getListPayArchiveLive(streamer_id, limit, page) {
    return request({
        url: `streamers/${streamer_id}/list_pay_archive_live?limit=${limit}&page=${page}`,
        method: 'get',
    })
}

export function getNormalArchiveLive(streamer_id) {
    return request({
        url: `streamers/${streamer_id}/archive_lives?category=NORMAL`,
        method: 'get',
    })
}

export function getPayArchiveLive(streamer_id) {
    return request({
        url: `streamers/${streamer_id}/archive_lives?category=PAY`,
        method: 'get',
    })
}

export function getStreamingLiveByProfileId(profile_id){
  return request({
    url: `lives?search=status:s3;profile_id:`+profile_id+`&searchJoin=and`,
    method: 'get'
  })
}

export function getQuestionnaire(streamer_id){
  return request({
    url: `streamers/`+streamer_id+`/questionnaire`,
    method: 'get'
  })
}

export function updateRoomInvitationStatus(room_invitation_status){
  return request({
    url:`profiles/chat_room_invitation`,
    method:'put',
    data:{
      "chat_room_invitation":room_invitation_status
    }
  })
}

