<template lang="pug">
  div.video-box
    video.video-js.vjs-big-play-centered(ref="videoPlayer" class="video")
</template>

<style src="./index.scss" lang="scss" scoped>
</style>

<script>
import videojs from 'video.js';

export default {
  name: "ComponentsVideoPlayerIndex",
  props: {
    src: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, {
        autoplay: false,
        controls: true,
        preload: 'auto',
        controlBar: {
          children: [
            // "playToggle",
            // "volumeMenuButton",
            // "durationDisplay",
            // "timeDivider",
            // "currentTimeDisplay",
            "progressControl",
            "remainingTimeDisplay",
            "fullscreenToggle"
          ]
        },
        sources: [
          {
            src: this.src,
            type: "video/mp4",
          }
        ]
      }, function onPlayerReady() {
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>