<template lang="pug">
  .root
    component-header-index(v-if="$store.state.user.email")
    component-header-login(v-if="!$store.state.user.email")
    div(class="container-flex")
      div(class="content-left")
        div(class="help-header") >>
          span(:class="projectName ? `test-c-a`: `test-c-b`" @click="onHelp()") ヘルプ
          span.test-c-b(v-if="projectName && ui.isSearch === false") >> {{ projectName }}

        el-input(placeholder="検索キーワードを入力" v-model="filterText")
        el-tree(
          class="filter-tree"
          :data="data"
          @node-click="handleNodeClick"
          node-key="id"
          :props="defaultProps"
          :filter-node-method="filterNode"
          ref="tree"
          accordion
          empty-text="検索キーワードがありません"
        )
      div(class="content-right")
        PRBanner
    el-dialog(:title="popupValue.label" :visible.sync="dialogVisible" width="50%" height="100%"
      :before-close="handleClose")
      div(v-html="popupValue.describe")
      span(slot="footer" class="dialog-footer")

    component-footer-index(v-if="$store.state.user.email")
    component-footer-login(v-if="!$store.state.user.email")
</template>

<style src="./Manual.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner';
import { getStaticManual, getStaticManualSP, getStaticManualDescribe } from '@/api/system';
import { Dialog, Input, Tree } from 'element-ui';
import { isSmartPhone } from '@/utils/tools';

export default {
  name: "TopTermsManual",
  components: {
    PRBanner,
    'el-input': Input,
    'el-dialog': Dialog,
    'el-tree': Tree,
  },
  data() {
    return {
      popupValue: '',
      filterText: '',
      dialogVisible: false,
      deleteUserDialogVisible: false,
      defaultProps: {
          children: 'children',
          label: 'label'
      },
      projectName: undefined,
      projectDetail: {
        key: undefined,
        value: undefined,
      },
      ui: {
        isSearch: false
      },
      inputText: '',
      dataSearch: undefined,
      data: [],
    }
  },
  props: {},
  computed: {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {},
  async created() {
    await this.init();
    let manualData;
    if (isSmartPhone()) {
      try{
        const res = await getStaticManualSP();
        manualData = res.operation_manual_viewer_sp.content.data;
      } catch {
        const res = await getStaticManual();
        manualData = res.operation_manual_viewer.content.data;
      }
    } else {
      const res = await getStaticManual();
      manualData = res.operation_manual_viewer.content.data;
    }
    this.data = manualData.filter(v => v.label != '閲覧者')
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      this.dataSearch = [];
      for (let i = 0; i !== this.data.length; i++) {
        for (let j = 0; j !== this.data[i].value.length; j++) {
          this.dataSearch.push({
            key: `${this.data[i].key}>>${this.data[i].value[j].key}`,
            value: this.data[i].value[j].value,
            show: false,
          })
        }
      }
    },
    async onChange() {
      this.ui.isSearch = this.inputText.length > 0;
      if (this.ui.isSearch) {
        await this.init();
        this.dataSearch = this.dataSearch.filter(v => v.key.includes(this.inputText) || v.value.includes(this.inputText))
      } else {
        this.onHelp();
      }
    },
    onHelp() {
      this.projectName = undefined;
      this.projectDetail = {
        key: undefined,
        value: undefined,
      };
    },
    onProject(item) {
      this.projectName = item.key;
      this.projectDetail = item;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    async handleNodeClick (data) {
      if (data.children == undefined || data.children == null || data.children <= 0) {
        if (data.key) {
          this.popupValue = await this.getDescribeData(data.key);
        } else {
          this.popupValue = data;
        }
        this.dialogVisible = true;
      }
    },
    handleClose(done) {
      done();
    },
    async getDescribeData($key) {
      try {
        return await getStaticManualDescribe($key);
      } catch (e) {
        return {
          label: 'エラー',
          describe: 'データの取得に失敗しました。'
        }
      }
    },
  }
};
</script>
