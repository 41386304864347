/* 名前入力文字制限 */
export function nameTextLimit(data) {
    // ひらがな、カタカナ、漢字、半角英字対応
    data = data.replace(/[^ぁ-んァ-ンa-zA-Z一-龠]/g, '');
    return data;
}

/**
 * @returns true:入力OK, false:入力エラー
 */
export function isAlphaNum(value){
  let regexp = /^[a-zA-Z0-9_¥-]+$/;
  if(!regexp.test(value)){
    return false;
  }
  return true;
}

/**
 * @returns true:入力OK, false:入力エラー
 */
export function isNum(value){
  let regexp = /^[0-9]+$/;
  if(!regexp.test(value)){
    return false;
  }
  return true;
}

export function validStringCount(str, count) {
  const len = stringCount(str);
  return len <= count;
}

/* 全角は2文字 半角は1文字にして文字数を返す */
export function stringCount(str) {
  if (!str) return 0;
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
  }
  return len;
}

/* 文字数をカウントして上限値までの文字を返す */
export function sliceString(str, str_length) {
  let len = 0;
  let size = 0;
  for (let i = 0; i < str.length; i++) {
    (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
    if (len > str_length) {
      break
    }
    size += 1;
  }
  return str.slice(0, size);
}

