import request from '@/utils/request'
import requestNoAuth from '@/utils/requestNoAuth'
import { isPrd } from '@/utils/tools'

export function getSystemInfo() {
  return request({
    url: `/system`,
    method: 'get',
  })
}
export function getStaticManual() {
  let url = process.env.VUE_APP_RESOURCE_BASE_PATH + "static_info/operation_manual_viewer.json"
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}
export function getStaticManualSP() {
  let url = process.env.VUE_APP_RESOURCE_BASE_PATH + "static_info/operation_manual_viewer_sp.json"
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}
export function getStaticFundsTerms() {
  let url = "https://resource.a-live.tokyo/static_info/fund_terms_of_use.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/fund_terms_of_use.json"
  }
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}
export function getStaticPrivacyPolicy() {
  let url = "https://resource.a-live.tokyo/static_info/privacy_policy.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/privacy_policy.json"
  }
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}
export function getStaticTermOfUse() {
  let url = "https://resource.a-live.tokyo/static_info/terms_of_use.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/terms_of_use.json"
  }
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}
export function getStaticNotation() {
  let url = "https://resource.a-live.tokyo/static_info/notation.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/notation.json"
  }
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}
export function getEventNotice(){
  let url = "https://resource.a-live.tokyo/static_info/event_note.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/event_note.json"
  }
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}
export function getSystemTimeStamp(){
  return request({
    url: `/system?only_timestamp=true`,
    method: 'get',
  })
}

export function getSystemNgWord(){
  return request({
    url: `/system/ng_word`,
    method: 'get',
  })
}

export function getStaticManualDescribe($key){
  let url = process.env.VUE_APP_RESOURCE_BASE_PATH + "static_info/describe/" + $key +".json"
  return requestNoAuth({
      url: url,
      method: 'get',
  })
}

