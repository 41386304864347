<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-paymentformpayeasyresult")

        h4 お支払い情報を受付ました。
        div ペイジー(Pay-easy)決済お支払番号確認のメールを送信いたしました。
        div お支払いの際、収納機関番号、お客様番号、確認番号が必要です。
        div メモを取るか、このページを印刷してお持ちください。
        div ご利用の金融機関の案内に従って、ペイジーでのお支払いにお進みください。
        div お支払期限までに銀行ＡＴＭまたはネットバンキングにてお支払ください。

        div(class="payeasyResult")
          table
            tr 収納機関番号
            td {{ resultData.BkCode }}
            tr お客様番号
            td {{ resultData.CustID }}
            tr 確認番号
            td {{ resultData.ConfNo }}
            tr お支払い期限
            td {{ resultData.PaymentTermStr }}
            tr 受付日時
            td {{ resultData.TranDateStr }}
            tr 支払い合計金額
            td {{ totalPrice.toLocaleString() }}円

        div(class="confirm")
          button.pink-button.onNextButton(@click="onBackTop()") トップページへ

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormPayeasyResult.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import moment from 'moment'

export default {
  name: "TopProfilePaymentFormPayeasyResult",
  components: {
    PRBanner,
  },
  data() {
    return {
      resultData: {},
    }
  },
  async created() {
    this.buyItemList = this.$route.params.buyList;
    if( !this.buyItemList ){
      this.$alert('商品が正しく選択されていません。')
      this.$router.push({ name: 'TopProfileBuy'});
      return;
    }
    this.resultData = this.$route.params.resultData;
    this.resultData['PaymentTermStr'] = moment(this.resultData['PaymentTerm'],"YYYYMMDDHHmmss").format('YYYY/MM/DD HH:mm');
    this.resultData['TranDateStr'] = moment(this.resultData['TranDate'],"YYYYMMDDHHmmss").format('YYYY/MM/DD HH:mm');
  },
  computed: {
    totalPrice(){
      let price = 0;
      for(var key in this.buyItemList){
        price += (Number(this.buyItemList[key].type) * Number(this.buyItemList[key].amount));
      }
      // FIXME 手数料 80 円
      return price + 80;
    },
  },
  methods: {
    onBackTop() {
      this.$router.push({
        name: 'TopIndex'
      })
    },

    onPaymentConfirm() {
      const resultData = this.resultData;
      const payChoice = 'easy';
      this.$router.push({ name: 'TopProfilePaymentLoading', params: {
        resultData,
        payChoice,
      } });
    },
  }
}
</script>
