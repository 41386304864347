import {padStart, floor, isNumber} from 'lodash';
import store from '@/store'
import moment from 'moment'

export function getLiveDurationStr(tsStart) {
  const secondDiff = moment().unix() - (isNumber(tsStart) ? tsStart : moment(tsStart).unix())
  if (secondDiff < 0) {
    return '0:00:00'
  }

  const newStr = `${padStart(floor(secondDiff/3600), 1, '0')}:${padStart(floor(secondDiff/60%60), 2, '0')}:${padStart(floor(secondDiff%60), 2, '0')}`;

  return !newStr.includes('NaN') ? newStr : '00:00:00';
}

export function getImageUrl(relativeUrl){
  return store.state.system.config.storage_addr_list.public + relativeUrl
}

export function isInvalidResImageUrl(url){
  return (store.state.system.config.storage_addr_list.public === url || url == undefined || store.state.system.config.storage_addr_list.public + "undefined" === url)
}

export function isDevelop(){
  return isDev() || isLocal();
}

export function isDev(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "dev" ){
    return true;
  }
  return false;
}

export function isStg(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "stg" ){
    return true;
  }
  return false;
}

export function isLocal(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "loc" ){
    return true;
  }
  return false;
}

export function isPrd(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "prd" ){
    return true;
  }
  return false;
}

export function serverMoment(){
  return moment(moment()+store.getters.time_diff)
}

export function countLength(str) {
  var r = 0;
  if(str) {
    for (var i = 0; i < str.length; i++) {
      var c = str.charCodeAt(i);
      if ( (c >= 0x0 && c < 0x81) || (c == 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
          r += 1;
      } else {
          r += 2;
      }
    }
  }
  return r;
}

export function isOverEighteen(birthDate) {
  return 18 <= calcAge(birthDate)
}

export function calcAge(birthDate) {
  birthDate = moment(birthDate).format("YYYY-MM-DD");
	birthDate = birthDate.replace(/[/-]/g, "");
	const today = new Date();
	const targetDate = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();
	return (Math.floor((targetDate - birthDate) / 10000));
}

export function isSmartPhone() {
  if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
    return true;
  } else {
    return false;
  }
}

export function buyCoinInfoValidation(buyCoinInfo) {
  let errorText = ''
  if(countLength(buyCoinInfo.family_name) > 16) {
    errorText = '苗字は全角８文字、半角16文字にしてください'
  }
  if (countLength(buyCoinInfo.given_name) > 16) {
    errorText += errorText ? '<br/>名前は全角８文字、半角16文字にしてください' : '名前は全角８文字、半角16文字にしてください'
  }
  if ( !myIsNaN( parseInt( buyCoinInfo.phone_number1 ) )) {
    if (countLength(buyCoinInfo.phone_number1) !== 3 || countLength(buyCoinInfo.phone_number1) !== 4 ) {
      errorText += errorText ? '<br/>電話番号1を正しく入力してください' : '電話番号1を正しく入力してください'
    }
  }
  if ( !myIsNaN( parseInt( buyCoinInfo.phone_number1 ) )) {
    if (countLength(buyCoinInfo.phone_number2) !== 4 ) {
      errorText += errorText ? '<br/>電話番号2を正しく入力してください' : '電話番号2を正しく入力してください'
    }
  }
  if ( !myIsNaN( parseInt( buyCoinInfo.phone_number1 ) )) {
    if (countLength(buyCoinInfo.phone_number3) !== 4 ) {
      errorText += errorText ? '<br/>電話番号3を正しく入力してください' : '電話番号3を正しく入力してください'
    }
  }
  if ( !myIsNaN(parseInt(buyCoinInfo.postal_code1)) ) {
    if (countLength(buyCoinInfo.postal_code1) !== 3 ) {
      errorText += errorText ? '<br/>郵便番号1を正しく入力してください' : '郵便番号1を正しく入力してください'
    }
  }
  if ( !myIsNaN(parseInt(buyCoinInfo.postal_code2)) ) {
    if(countLength(buyCoinInfo.postal_code2) !== 4 ) {
      errorText += errorText ? '<br/>郵便番号2を正しく入力してください' : '電話番号2を正しく入力してください'
    }
  }
  if (countLength(buyCoinInfo.address) > 44) {
    errorText += errorText ? '<br/>住所は全角22文字、半角44文字にしてください' : '住所は全角22文字、半角44文字にしてください'
  }
  if (!myIsNaN(parseInt(buyCoinInfo.house_number1))) {
    if(countLength(buyCoinInfo.house_number1) > 3){
      errorText += errorText ? '<br/>番地1は数字3文字以下にしてください' : '番地1は数字3文字以下にしてください'
    }
  }
  if (!myIsNaN(parseInt(buyCoinInfo.house_number2))) {
    if(countLength(buyCoinInfo.house_number2) > 3){
      errorText += errorText ? '<br/>番地2は数字3文字以下にしてください' : '番地2は数字3文字以下にしてください'
    }
  }
  if (!myIsNaN(parseInt(buyCoinInfo.house_number3))) {
    if(countLength(buyCoinInfo.house_number3) > 3){
      errorText += errorText ? '<br/>番地3は数字3文字以下にしてください' : '番地3は数字3文字以下にしてください'
    }
  }
  if ( !myIsNaN(parseInt(buyCoinInfo.mansion_name)) ) {
    if (countLength(buyCoinInfo.mansion_name) > 28) {
      errorText += errorText ? '<br/>マンション名は全角14文字、半角28文字にしてください' : 'マンション名は全角14文字、半角28文字にしてください'
    }
  }
  if ( !myIsNaN(parseInt(buyCoinInfo.room_number)) ) {
    if (countLength(buyCoinInfo.room_number) > 5) {
      errorText += errorText ? '<br/>半角数字のみ入力可能です。' : '部屋番号は数字4文字以下にしてください'
    }
  }
  if (countLength(buyCoinInfo.company_name) > 50) {
    errorText += errorText ? '<br/>会社名・部門名は全角25文字にしてください' : '会社名・部門名は全角25文字にしてください'
  }

  return errorText; 
}

function myIsNaN(value) {
  return typeof value === 'number' && !isNaN(value);
}