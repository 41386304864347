<template lang="pug">
.root
  component-header-index
  .center
    component-system-message
    .leftContainer

      div(class="content-live")
        //- クラファン配信
        //- 開催情報
        div(v-if="isShowingListExists_Fund")
          div(class="living")
            div(class="coorperList")
              <img src="/img/pc_cloudbar.png" alt="">
            div(class="kaisaing")
              div(class="eventnow" v-for="item in ui.showingList_Fund")
                a(@click="onClubfunDetailClick(item.id)")
                  div(class="haisinbk_now" )
                    div.videoTag(v-if="item.live && item.live.id && item.live.status === $store.getters.enum_list.live_status_list.ONGOING")  配信中
                    div(class="haisinbk_now__image")
                      img(:src="getImage(item.banner_store_path)")

            div(class="goondisplay" )
              a(v-if="showingHasMore_Fund" @click="showMore_Fund('showing')" style="color:black"  ) >> もっと見る


        //- 配信配信（過去開催)
        div(v-if="isShowedListExists_Fund" )
          div(class="living")
            div(class="coorperList")
              <img src="/img/pc_cloud02.png" alt="">
            div(class="kaisaing")
              div(class="eventnow_pc" v-for="item in ui.showedList_Fund")
                a(@click="onClubfunDetailClick(item.id)")
                  div(class="haisinbk" )
                    img(:src="getImage(item.banner_store_path)")

            div(class="goondisplay")
               a(v-if="showedHasMore_Fund" @click="showMore_Fund('showed')" style="color:black") >> もっと見る
      
        //-  オーディション
        //- 開催
        div(v-if="isShowingAndToshowListExists_Event")
          div(class="living")
            div(class="coorperList")
              <img src="/img/pc_oudhishon.png" alt="">
        div(class="kaisaing")
          div(class="eventnow" v-for="item in ui.showingAndToshowList_Event")
            a(@click="onEventDetailClick(item.id)")
              div(class="haisinbk_now" )
                div.videoTag(v-if="isEventShowing(item)")  開催中
                div(class="haisinbk_now__image")
                  img(:src="getImage(item.banner_store_path)")

        <div class="goondisplay">
          <u><button v-if="showingAndToshowHasMore_Event" @click="showMore_Event('showingAndToshow')" style="color:black,cursor: pointer" class="show-more-button" v-preventReClick="1000">>> もっと見る</button></u>
        </div>

        //- 過去開催
        div(class="event-showed" v-if="isShowedListExists_Event")
          div(class="living")
            div(class="coorperList")
              <img src="/img/pc_oudhishon02.png" alt="">
          div(class="kaisaing")
            div(class="eventnow_pc" v-for="item in ui.showedList_Event")
              a(@click="onEventDetailClick(item.id)")
                div(class="haisinbk_event" :style="{background: 'url(' + getImage(item.banner_store_path) + ') no-repeat center' }")
          
          <div class="goondisplay">
            <u><button v-if="showedHasMore_Event" @click="showMore_Event('showed')" style="color:black" class="show-more-button" v-preventReClick="1000">>> もっと見る </button></u>
          </div>   

    .rightContainer
      td.pickUp
        .pr-list
          PRBanner
  component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner'
import { getImageUrl, isSmartPhone } from '@/utils/tools'
import moment from 'moment'

export default {
  name: "LoginTopIndex",
  components: {
    PRBanner,
  },
  data() {
    return {
      currentPage:1,
      ui: {
        showingList_Fund: [],
        showedList_Fund: [],
        showingAndToshowList_Event: [],
        showedList_Event: [],
      },

      readLimit: 3,
      showingFundPage: {
        pc: {
          initLimit: 2,
          moreLimit: 2,
        },
        sp: {
          initLimit: 1,
          moreLimit: 3,
        }
      },
      showedFundPage: {
        pc: {
          initLimit: 3,
          moreLimit: 3,
        },
        sp: {
          initLimit: 1,
          moreLimit: 3,
        }
      },
      showingAndToshowEventPage: {
        pc: {
          initLimit: 2,
          moreLimit: 2,
        },
        sp: {
          initLimit: 1,
          moreLimit: 3,
        }
      },
      showedEventPage: {
        pc: {
          initLimit: 3,
          moreLimit: 3,
        },
        sp: {
          initLimit: 1,
          moreLimit: 3,
        }
      },
    };
  },
  props: {},
  computed: {
    showingList_Fund() {
      return this.$store.state.live.liveListClubfun.showing.data;
    },
    showedList_Fund() {
      return this.$store.state.live.liveListClubfun.showed.data;
    },
    showingHasMore_Fund() {
      if(this.ui.showingList_Fund.length < this.$store.state.live.liveListClubfun.showing.meta.pagination.total){
        return true
      }
      return false
    },
    showedHasMore_Fund() {
       if(this.ui.showedList_Fund.length < this.$store.state.live.liveListClubfun.showed.meta.pagination.total){
        return true
      }
      return false
    },
    isShowingListExists_Fund() {
      return this.ui.showingList_Fund.length > 0
    },
    isShowedListExists_Fund() {
      return this.ui.showedList_Fund.length > 0
    },
    
    showingAndToshowList_Event() {
      return this.$store.state.live.liveListEvent.showingAndToshow.data;
    },
    showedList_Event() {
      return this.$store.state.live.liveListEvent.showed.data;
    },
    isShowingAndToshowListExists_Event() {
      return this.showingAndToshowList_Event.length > 0
    },
    isShowedListExists_Event() {
      return this.showedList_Event.length > 0
    },
    showingAndToshowHasMore_Event() {
        if(this.ui.showingAndToshowList_Event.length < this.$store.state.live.liveListEvent.showingAndToshow.meta.pagination.total){
          return true
        }
      return false
    },
    showedHasMore_Event() {
      if(this.ui.showedList_Event.length < this.$store.state.live.liveListEvent.showed.meta.pagination.total){
        return true
      }
      return false
    },
  },
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      await this.getClubfunData();
      await this.getEventData();
      await this.checkLoginBonus();
    },

    async getClubfunData() {
      await this.$store.dispatch("live/syncLiveListClubfun", {index:this.readLimit, page: 1});

      const showingCount = isSmartPhone() ?
        this.showingFundPage.sp.initLimit :this.showingFundPage.pc.initLimit;

      this.showingList_Fund.forEach((element,index) => {
        if (index < showingCount) {
          this.ui.showingList_Fund.push(element);
        }
      });
      
      const showedCount = isSmartPhone() ?
        this.showedFundPage.sp.initLimit :this.showedFundPage.pc.initLimit;

      this.showedList_Fund.forEach((element,index) => {
        if (index < showedCount) {
          this.ui.showedList_Fund.push(element);
        }
      });
    },

    async getEventData() {
      await this.$store.dispatch("live/syncLiveListEvent", {isShowingToshowMix: true, limit:this.readLimit, page: 1});

      const showingAndToshowCount = isSmartPhone() ?
          this.showingAndToshowEventPage.sp.initLimit :this.showingAndToshowEventPage.pc.initLimit;

      this.showingAndToshowList_Event.forEach((element,index) => {
          if (index < showingAndToshowCount) {
            this.ui.showingAndToshowList_Event.push(element);
        }
      });

      const showedCount = isSmartPhone() ?
          this.showedEventPage.sp.initLimit :this.showedEventPage.pc.initLimit;

      this.showedList_Event.forEach((element,index) => {
          if (index < showedCount) {
            this.ui.showedList_Event.push(element);
        }
      });
    },

    async checkLoginBonus() {
      // ログインボーナスの確認
      const prevFirstLogin = this.$store.state.user.daily_first_flag;
      await this.$store.dispatch("user/checkLoginBonus");
      if (prevFirstLogin || this.$store.state.user.daily_first_flag) {
        this.openGetStartDialog();
        this.$store.commit('user/SET_DAILY_FIRST_FLAG', false);
      }
    },

    onClubfunDetailClick(liveable_id) {
      this.$router.push({
        name: 'TopFundDetail',
        params: { liveable_id },
      });
    },
    async showMore_Fund(value) {
      if (value == 'showing') {
        const count = isSmartPhone() ?
          this.showingFundPage.sp.moreLimit : this.showingFundPage.pc.moreLimit;

        let x = this.ui.showingList_Fund.length;
        
        if (this.showingList_Fund.length < x + count &&
          this.showingList_Fund.length < this.$store.state.live.liveListClubfun.showing.meta.pagination.total) {
          const nextPage = this.$store.state.live.liveListClubfun.showing.meta.pagination.current_page + 1
          await this.$store.dispatch("live/showMoreClubfunShowing", {
            index: this.readLimit,
            page: nextPage
          });
        }
        this.showingList_Fund.forEach((element, index) => {
          if(index > x - 1 && index < x + count){
            this.ui.showingList_Fund.push(element);
          }
        });
      } else if (value == 'showed') {
        const count = isSmartPhone() ?
          this.showedFundPage.sp.moreLimit : this.showedFundPage.pc.moreLimit;

        let x = this.ui.showedList_Fund.length;        
        if (this.showedList_Fund.length < x + count &&
          this.showedList_Fund.length < this.$store.state.live.liveListClubfun.showed.meta.pagination.total) {
          const nextPage = this.$store.state.live.liveListClubfun.showed.meta.pagination.current_page + 1;
          await this.$store.dispatch("live/showMoreClubfunShowed", {
            index: this.readLimit,
            page: nextPage
          });
        }
        this.showedList_Fund.forEach((element, index) => {
          if(index > x - 1 && index < x + count){
            this.ui.showedList_Fund.push(element);
          }
        });
      }
    },

    getImage(url){
      return url ? getImageUrl(url) : '/img/test.png';
    },

    async showMore_Event(liveType) {
      if(liveType == "showingAndToshow") {
        
        const count = isSmartPhone() ?
          this.showingAndToshowEventPage.sp.moreLimit : this.showingAndToshowEventPage.pc.moreLimit;

        let x = this.ui.showingAndToshowList_Event.length;
        if (this.showingAndToshowList_Event.length < x + count &&
          this.showingAndToshowList_Event.length < this.$store.state.live.liveListEvent.showingAndToshow.meta.pagination.total) {
          const nextPage = this.$store.state.live.liveListEvent.showingAndToshow.meta.pagination.current_page + 1
          await this.$store.dispatch("live/showMoreEventShowingAndToshow", {
            limit: this.readLimit,
            page:nextPage,
          });
        }
        this.showingAndToshowList_Event.forEach((element,index) => {
          if (index > x - 1 && index < x + count) {
            this.ui.showingAndToshowList_Event.push(element);
          }
        });
      }
      if(liveType == "showed") {
        const count = isSmartPhone() ?
          this.showedEventPage.sp.moreLimit : this.showedEventPage.pc.moreLimit;

        let x = this.ui.showedList_Event.length;
        if (this.showedList_Event.length < x + count && 
          this.showedList_Event.length < this.$store.state.live.liveListEvent.showed.meta.pagination.total) {
          const nextPage = this.$store.state.live.liveListEvent.showed.meta.pagination.current_page + 1
          await this.$store.dispatch("live/showMoreEventShowed", {
            limit: this.readLimit,
            page: nextPage,
          });
        }
        this.showedList_Event.forEach((element,index) => {
          if (index > x - 1 && index < x + count) {
            this.ui.showedList_Event.push(element);
          }
        });
      }

    },
    onEventDetailClick(liveable_id) {
      this.$router.push({
        name: 'TopEventDetail',
        params: { liveable_id },
      });
    },

    openGetStartDialog() {
      this.$alert('100スター獲得', 'ログインボーナス', {
        confirmButtonText: 'OK',
        showClose: false,
        callback: () => {
          this.$store.dispatch('live/resetDailyFirstFlag');
        }
      })
    },

    isEventShowing(event) {
      const start = moment(event.start_time);
      const end = moment(event.end_time);
      const now = moment();
      return start <= now && now <= end;
    }
  },
};
</script>
