<template lang="pug">
  .root
    component-header-index
    div ↓ Aライブの楽しみ方はこちら ↓
    .video
      video-player(src="/video/test.mp4")
    div 画像[諸説明] 属性やｸﾗﾌｧﾝ配信があることからアーティスト支援の流れ

    div {{ isPassword ? 'パスワードの変更が完了しました。' : 'ﾒｰﾙアドレスの変更が完了しました。' }}

    component-footer-index
</template>

<style src="./Finish.scss" lang="scss" scoped>

</style>

<script>
import VideoPlayer from "@/components/VideoPlayer/index.vue";

export default {
  name: "TopProfileFinish",
  components: {
    VideoPlayer
  },
  data() {
    return {
      isPassword: false
    }
  },
  created() {
    this.isPassword = this.$route.query.isPassword;
  },
  methods: {
    
  }
}
</script>
