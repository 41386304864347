<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-paymentformstripe")

        div クレジットカードの登録

        div(class="stripeForm")
          h5 名前
          input(v-model="cardData.name")
          br
          h5 カード番号
          input(v-model="cardData.code")
          br
          div(class="expiration-date" style="margin-top: 16px") 有効期限
            div
              span(style="margin-right: 26px;") 月
              span(style="margin: 0 8px;font-weight: bold;font-size:15px;") /
              span 年(西暦下二桁)
            div
              input(v-model="cardData.expiration_date_month" class="input-g-w-border expiration-date-month")
              span(style="margin: 0 8px;font-weight: bold;font-size:16px;") /
              input(v-model="cardData.expiration_date_year" class="input-g-w-border expiration-date-year")
            span(style="color: red;") ※月/年は0を含める

              //- div(class="expiration-date") 有効期限
              //-   div
              //-     span(style="margin-right: 9%;") 月
              //-     span(style="margin: 0 8px;font-weight: bold;font-size:15px;") /
              //-     span 年(西暦下二桁)
              //-   div
              //-     input(v-model="cardData.expiration_date_month" class="input-g-w-border expiration-date-month")
              //-     span(style="margin: 0 8px;font-weight: bold;font-size:16px;") /
              //-     input(v-model="cardData.expiration_date_year" class="input-g-w-border expiration-date-year")
              //-   span ※月/年は0を含める


          h5 セキュリティコード
          input(v-model="cardData.security_code")

        .buttonArea
          .errorMessageArea
            .errorMessage(v-if="ui.showError", style="color: red") {{ ui.errorMessage }}
          button.pink-button.onNextButton(@click="onConfirmCard()") 登録

        //div ※ここで購入したGはAライブ内の全サービスでご利用いただけます。
        //div >>資金決済法に基づく表示
        //div(@click="onClick1()") >>特定商取引法の表記
        div(class="coin-buy-footer-button")
          button(@click="onBack()" class="pink-button") 戻る
          h5 サイト内にクレジットカード情報は保持されません。

      div(class="pr-list")
        PRBanner

    component-footer-index
</template>

<style src="./PaymentFormStripe.scss" lang="scss" scoped>

</style>

<script>
import PRBanner from '@/components/PRBanner'
import { createPaymentCard } from '@/api/payment';
import { isPrd } from '@/utils/tools';

export default {
  name: "TopProfilePaymentFormStripe",
  components: {
    PRBanner,
  },
  data() {
    return {
      ui: {
        errorMessage: "Error",
        showError: false,
      },
      cardData: {
        name: '',
        code: '',
        expiration_date_month: '',
        expiration_date_year: '',
        security_code: '',
      },
      buyItemList: [],
      buyType: '',
      successUrl: '',
      cancellUrl: '',
      shopId: '',
    }
  },
  async created() {
    this.buyItemList = this.$route.params.buyList;
    this.buyType = this.$route.params.buyType;
    this.successUrl = this.$route.params.successUrl;
    this.cancellUrl = this.$route.params.cancellUrl;
    if( !this.buyItemList ){
      this.$alert('商品が正しく選択されていません。')
      this.$router.push({ name: 'TopProfileBuy'});
      return;
    }
  },
  mounted() {
    // TODO : 外だし対応必要
    this.shopId = 'tshop00050484';
    let url = 'https://stg.static.mul-pay.jp/ext/js/token.js';
    // 本番はurlとshopIdが違う
    if ( isPrd() ){
      url = 'https://static.mul-pay.jp/ext/js/token.js';
      this.shopId = '9200003409720';
    }
    let cardTokenScript = document.createElement('script');
    cardTokenScript.setAttribute('src', url);
    document.head.appendChild(cardTokenScript);
  },
  activated(){
  },
  methods: {
    async onConfirmCard() {
      if(
        !this.cardData.name ||
        !this.cardData.code ||
        !this.cardData.expiration_date_month ||
        !this.cardData.expiration_date_year ||
        !this.cardData.security_code
      ){
        this.ui.showError = true;
        this.ui.errorMessage = "未入力箇所があります。必ず入力してください。";
        return;
      }
      if(
        this.cardData.expiration_date_month.length != 2 ||
        this.cardData.expiration_date_year.length != 2
      ){
        this.ui.showError = true;
        this.ui.errorMessage = "年、月は数値二桁で入力してください";
        return;
      }
      // token 取得システム初期化
      window.Multipayment.init(this.shopId);
      // コールバック関数の設定
      const self_ = this;
      window.PaymentTokenCallbackTunnel = function PaymentTokenCallbackTunnel(result){
        self_.onTokenCallback(result);
      }
      // トークン変換
      window.Multipayment.getToken(
        {
          cardno: this.cardData.code,
          expire: this.cardData.expiration_date_year + this.cardData.expiration_date_month,
          securitycode: this.cardData.security_code,
          holdername: this.cardData.name,
        },
        window.PaymentTokenCallbackTunnel);
    },
    async onTokenCallback(result){
      if( result.resultCode != '000' ){
        this.ui.showError = true;
        this.ui.errorMessage = "クレジットカードの認証に失敗しました。入力情報をお確かめください。";
        return;
      }
      try{
        await createPaymentCard(result.tokenObject.token);
      } catch( error ){
        this.$alert('決済エラーが発生しました。このカードは現在お取り扱いできません。')
        return;
      }
      const buyList = this.buyItemList;
      this.$router.push({ name: 'TopProfilePaymentFormStripeResult', params: {
        buyList,
      }});
    },

    onBack() {
      this.$router.push({
        name: 'TopProfileBuy'
      })
    },

    onClick1() {
      this.$router.push({ name: 'TopTermsTokushoho' });
    }
  }
}
</script>
