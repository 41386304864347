<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-follow")
        div(class="content-follow-crumb") >>
          button(@click="onTabClick('Follow')" class="follow-menu-button" v-bind:class="{ 'tab-active':isActiveFollowing }") フォロー
          button(@click="onTabClick('Follower')" class="follow-menu-button" v-bind:class="{ 'tab-active':isActiveFollower }") フォロワー

        div(v-if="!ui.profile")
          table(v-if="ui.tab === 'Follow'"  class="follow-list")
            tr(v-for="item in followList" :class="item.profileable_type === `VIEWER` ? `follow-list-item` : `follow-list-item streamer`")
              div(class="follow-list-item-part")
                a(@click="item.profileable_type === `VIEWER` ? onAvatarClickViewer(item.id) : onAvatarClickStreamer(item.id)")
                  div(class="follow-img-thumbanail")
                    <pan-thumb :avatar="getAvatar(item)" :attribute="getAttribute(item)" :current_title="item.profileable.current_title" :isStreamer="isStreamer(item)" size="120px"/>
                div(class="acount-name") {{ item.account_name }}
                button(v-if="item.is_following && isNotSelf(item.id)" @click="onFollowCancel(item.id)" class="gray-button")
                button(v-else-if="isNotSelf(item.id)" @click="onFollow(item.id)" class="pink-button")

          table(v-if="ui.tab === 'Follower'"  class="follow-list")
            tr(v-for="item in followerList" :class="item.profileable_type === `VIEWER` ? `follow-list-item` : `follow-list-item streamer`")
              div(class="follow-list-item-part")
                div(class="follow-img-thumbanail" @click="item.profileable_type === `VIEWER` ? onAvatarClickViewer(item.id) : onAvatarClickStreamer(item.id)")
                  <pan-thumb :avatar="getAvatar(item)" :attribute="getAttribute(item)" :current_title="item.profileable.current_title" :isStreamer="isStreamer(item)" size="120px"/>
                div(class="acount-name") {{ item.account_name }}
                button(v-if="item.is_following && isNotSelf(item.id)" @click="onFollowCancel(item.id)" class="gray-button")
                button(v-else-if="isNotSelf(item.id)" @click="onFollow(item.id)" class="pink-button")
          a(v-if="haveMore" class="next-view" @click="onShowMore()") >>続きを表示

      .pr-list
        PRBanner(v-if="!ui.profile")
      div(v-if="ui.profile" class="pos-r")
        component-profile-index(:profile_id="profile_id" @close="onClose")

    component-footer-index
</template>

<style src="./Follow.scss" lang="scss" scoped>

</style>

<script>
import { getFollowings, getFollowers, addFollowing, removeFollowers } from '@/api/profile'
import PRBanner from '@/components/PRBanner'
import PanThumb from '@/components/PanThumb'

export default {
  name: "TopProfileFollow",
  components: {
    PRBanner,
    PanThumb
  },
  computed: {
    haveMore(){
      return this.meta.pagination.current_page < this.meta.pagination.total_pages
    },
    isActiveFollowing(){
      return this.ui.tab == "Follow"
    },
    isActiveFollower(){
      return this.ui.tab == "Follower"
    },
    keysFollowing() {
      return this.$store.state.user.keysFollowing;
    }
  },
  data() {
    return {
      ui: {
        tab: 'Follow',
        profile: false,
      },
      profile_id: -1,
      followList: [],
      followerList: [],
      listQuery: {
        page: 1,
        limit: 6,
      },
      meta: { pagination: {} }
    }
  },

  async created() {
    await this.init();
  },
  methods: {
    getAvatar(item){
      return (item.avatar_store_path)
    },
    getAttribute(item){
      return item.attribute
    },
    isStreamer(item){
      return item.profileable_type === 'STREAMER';
    },
    onTabClick: function(tabnm){
      if(this.ui.tab === tabnm){
        return;
      }
      this.followList = []
      this.followerList = []
      this.listQuery.page = 1
      this.ui.tab = tabnm
      if(this.isActiveFollowing){
        this.getFollowingList()
      } else {
        this.getFollowerList()
      }
    },
    async init() {
      this.$route.query.tab && this.$route.query.tab == 'follower' ? this.ui.tab = "Follower" : this.ui.tab = "Follow";
      this.isActiveFollowing ? this.getFollowingList() : this.getFollowerList()
    },
    async getFollowingList(){
      let params;
      if(this.$route.query.profile) {
        params = this.$route.query.profile;
      } else {
        params = this.$store.state.user.profile_id;
      }
      const { data } = (await getFollowings(params, this.listQuery))
      let tmpFollowList = [];
      for(var follow of data.data) {
        follow.is_following = true;
        tmpFollowList.push(follow);
      }
      this.followList.push.apply(this.followList, tmpFollowList)
      this.meta = data.meta
    },
    async getFollowerList(){
      let params;
      if(this.$route.query.profile) {
        params = this.$route.query.profile;
      } else {
        params = this.$store.state.user.profile_id;
      }
      const { data } = (await getFollowers(params, this.listQuery))
      this.followerList.push.apply(this.followerList, data.data)
      this.meta = data.meta
    },
    async onShowMore(){
      this.isActiveFollower ? this.onShowMoreFollower() : this.onShowMoreFollowing()
    },
    async onShowMoreFollowing(){
      if(this.haveMore){
        this.listQuery.page = this.meta.pagination.current_page + 1
        this.getFollowingList()
      }
    },
    async onShowMoreFollower(){
      if(this.haveMore){
        this.listQuery.page = this.meta.pagination.current_page + 1
        this.getFollowerList()
      }
    },
    // フォローのリストにあるフォロー状態を反転する
    inverseFollowList(profile_id, status) {
      this.followList.forEach((follow) => {
        if (follow.id === profile_id) {
          follow.is_following = status;
        }
      })
    },
    // フォロワーのリストにあるフォロー状態を反転する
    inverseFollowerList(profile_id, status) {
      this.followerList.forEach((follower) => {
        if (follower.id === profile_id) {
          follower.is_following = status;
        }
      })
    },
    async onFollow(profile_id) {
      try {
        await addFollowing(profile_id);
        if (this.isActiveFollowing) {
          this.inverseFollowList(profile_id, true)
        } else {
          this.inverseFollowerList(profile_id, true)
        }
      } catch(e) {
        return false;
      }
    },
    async onFollowCancel(profile_id) {
      try {
        await removeFollowers(profile_id);
        if (this.isActiveFollowing) {
          this.inverseFollowList(profile_id, false)
        } else {
          this.inverseFollowerList(profile_id, false)
        }
      } catch(e) {
        return false;
      }
    },
    onAvatarClickStreamer(profile_id) {
      // let link = this.$router.resolve({ name: 'TopProfileStyle2', params: { profile_id } });
      // window.open(link.href,'_blank');
      this.$router.push({ name: 'TopProfileStyle2', params: { profile_id } })
    },
    onAvatarClickViewer(profile_id) {
      this.ui.profile = false;

      this.$nextTick(() => {
        this.profile_id = profile_id;
        this.ui.profile = true;
      })
    },
    onClose() {
      this.ui.profile = false;
    },
    isNotSelf(id){
      return this.$store.state.user.profile_id !== id
    },
  }
}
</script>
