<template lang="pug">
  .root
    component-header-index(v-if="$store.state.user.email")
    component-header-login(v-if="!$store.state.user.email")
    div(class="container-flex")
      div(class="content-info")
        <h3 style="text-align:center">Aライブについて</h3>
        div(class="") {{ this.text }}


    component-footer-index
</template>

<style src="./HowTo.scss" lang="scss" scoped>
</style>

<script>

import { getStaticManual } from '@/api/system';

export default {
  name: "TopBroadcastIndex",
  components: {
  },
  data() {
    return {
      ui: {
      },
      oneNewsInfo: {

      },
      text: '',
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    const res = await getStaticManual();
    this.text = res.about_live;
  },
  updated() {},
  destroyed() {},
  methods: {
  }
};
</script>
